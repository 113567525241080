<div class="tw-flex tw-justify-end">
    <button
        mat-icon-button
        aria-label="close-wallet-invite"
        (click)="close()">
        <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
</div>

<div class="tw-grid tw-h-full tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2">
    <div class="tw-flex tw-items-center tw-justify-center">
        <app-wallet
            *ngIf="linkQrCode"
            [date]="deliveryNoteBundle?.dateCreated"
            [mapNr]="deliveryNoteBundle?.bundleNumber"
            [consigneeName]="deliveryNoteBundle?.consigneeName"
            [carrierName]="deliveryNoteBundle?.carrierName"
            [consignorName]="deliveryNoteBundle?.consignorName"
            [showQrCode]="true"
            [qrCodeValue]="linkQrCode"
            [qrHref]="linkQrCode"></app-wallet>
    </div>

    <div class="tw-flex tw-items-center tw-justify-center">
        <div class="content">
            <div>
                <div class="headline">
                    {{ 'SEND-WALLET-INVITE.HEADLINE' | translate }}
                </div>
                <div class="subhead">
                    {{ 'SEND-WALLET-INVITE.SUBHEADLINE' | translate }}
                </div>
            </div>

            <div class="mt-5">
                <ng-container>
                    <form [formGroup]="invitationForm">
                        <div>
                            <div class="headline">
                                {{
                                    'SEND-WALLET-INVITE.TICKET-EMAIL.HEADLINE'
                                        | translate
                                }}
                            </div>
                            <div class="subhead">
                                {{
                                    'SEND-WALLET-INVITE.TICKET-EMAIL.SUBHEADLINE'
                                        | translate
                                }}
                            </div>
                        </div>
                        <div class="mt-5">
                            <mat-form-field
                                appearance="fill"
                                class="tw-mb-0">
                                <mat-label>
                                    {{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.INPUT-LABEL'
                                            | translate
                                    }}</mat-label
                                >
                                <input
                                    matInput
                                    formControlName="email"
                                    type="email"
                                    placeholder="{{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.INPUT-PLACEHOLDER'
                                            | translate
                                    }}" />
                                <mat-error
                                    *ngIf="
                                        invitationForm?.controls?.email?.value
                                            .length &&
                                        invitationForm?.controls?.email?.invalid
                                    ">
                                    {{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.INPUT-ERROR'
                                            | translate
                                    }}
                                </mat-error>
                            </mat-form-field>

                            <button
                                mat-raised-button
                                color="primary"
                                [disabled]="
                                    invitationForm?.controls?.email?.value ===
                                        lastInvited ||
                                    loading ||
                                    invitationForm?.invalid
                                "
                                [class.spinner]="loading"
                                (click)="sendInvitation()">
                                {{
                                    'SEND-WALLET-INVITE.TICKET-EMAIL.SEND-BUTTON'
                                        | translate
                                }}
                            </button>

                            <div
                                *ngIf="
                                    invitationForm?.controls?.email?.value ===
                                    lastInvited
                                ">
                                {{ lastInvited }} -
                                {{
                                    'SEND-WALLET-INVITE.TICKET-EMAIL.SENT-SUCCESSFULLY'
                                        | translate
                                }}
                            </div>
                        </div>
                        <div
                            class="subhead emphasize has-pointer d-flex align-items-center mt-4"
                            (click)="onPrintQRCodeCanvas()">
                            <mat-icon fontSet="material-symbols-outlined"
                                >print</mat-icon
                            >
                            <span class="clickable-link">{{
                                'SEND-WALLET-INVITE.PRINT-QR-TICKET' | translate
                            }}</span>
                        </div>
                        <div
                            class="subhead emphasize has-pointer d-flex align-items-center mt-3"
                            (click)="onPrintAllDeliveryNotes()">
                            <mat-icon fontSet="material-symbols-outlined"
                                >print</mat-icon
                            >
                            <span class="clickable-link">{{
                                'SEND-WALLET-INVITE.PRINT-ALL-DELIVERYNOTES'
                                    | translate
                            }}</span>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!--
<div mat-dialog-actions class="justify-content-center mb">
  <div>
    <button (click)="close()" class="big fixed-width" mat-stroked-button>
      {{ 'SEND-WALLET-INVITE.BUTTON-CLOSE' | translate }}
    </button>
  </div>

  <div class="ms-4">
    <button class="big fixed-width" mat-raised-button color="primary">
      {{ 'SEND-WALLET-INVITE.BUTTON-SEND-EMAIL' | translate }}
    </button>
  </div>
</div>
-->
