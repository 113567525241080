import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appBolderEntry]',
})
export class BolderEntryDirective {
    constructor(el: ElementRef) {
        el.nativeElement.style.fontWeight = '700';
        el.nativeElement.style.color = '#191919';
    }
}
