/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { ConsigneeAttachmentResponse } from '../models/consignee-attachment-response';
import { deleteConsigneeReceipt } from '../fn/receipts/delete-consignee-receipt';
import { DeleteConsigneeReceipt$Params } from '../fn/receipts/delete-consignee-receipt';
import { GetConsigneeAttachmentsResponse } from '../models/get-consignee-attachments-response';
import { getConsigneeDiscrepancyReceiptAttachment$Heic } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heic';
import { GetConsigneeDiscrepancyReceiptAttachment$Heic$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heic';
import { getConsigneeDiscrepancyReceiptAttachment$Heif } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heif';
import { GetConsigneeDiscrepancyReceiptAttachment$Heif$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heif';
import { getConsigneeDiscrepancyReceiptAttachment$Jpeg } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-jpeg';
import { GetConsigneeDiscrepancyReceiptAttachment$Jpeg$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-jpeg';
import { getConsigneeDiscrepancyReceiptAttachment$Pdf } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-pdf';
import { GetConsigneeDiscrepancyReceiptAttachment$Pdf$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-pdf';
import { getConsigneeDiscrepancyReceiptAttachment$Png } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-png';
import { GetConsigneeDiscrepancyReceiptAttachment$Png$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-png';
import { getConsigneeReceiptsForDeliveryNote } from '../fn/receipts/get-consignee-receipts-for-delivery-note';
import { GetConsigneeReceiptsForDeliveryNote$Params } from '../fn/receipts/get-consignee-receipts-for-delivery-note';
import { postConsigneeReceiptToDeliveryNote } from '../fn/receipts/post-consignee-receipt-to-delivery-note';
import { PostConsigneeReceiptToDeliveryNote$Params } from '../fn/receipts/post-consignee-receipt-to-delivery-note';

@Injectable({ providedIn: 'root' })
export class ReceiptsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postConsigneeReceiptToDeliveryNote()` */
  static readonly PostConsigneeReceiptToDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/receipts/file/{fileName}';

  /**
   * Post a receipt file.
   *
   * Add a new consignee receipt, with or without the flag 'discrepancies', to a existing delivery note.
   * The delivery note must be in a started checkin.
   * Only PDF files are accepted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeReceiptToDeliveryNote()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  postConsigneeReceiptToDeliveryNote$Response(params: PostConsigneeReceiptToDeliveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeReceiptToDeliveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * Post a receipt file.
   *
   * Add a new consignee receipt, with or without the flag 'discrepancies', to a existing delivery note.
   * The delivery note must be in a started checkin.
   * Only PDF files are accepted.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeReceiptToDeliveryNote$Response()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  postConsigneeReceiptToDeliveryNote(params: PostConsigneeReceiptToDeliveryNote$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeReceiptToDeliveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /** Path part for operation `getConsigneeDiscrepancyReceiptAttachment()` */
  static readonly GetConsigneeDiscrepancyReceiptAttachmentPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/receipts/{attachmentKey}';

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Pdf$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Pdf(params: GetConsigneeDiscrepancyReceiptAttachment$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heic$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heic(params: GetConsigneeDiscrepancyReceiptAttachment$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heif$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heif(params: GetConsigneeDiscrepancyReceiptAttachment$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Jpeg$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Jpeg(params: GetConsigneeDiscrepancyReceiptAttachment$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Png$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Png(params: GetConsigneeDiscrepancyReceiptAttachment$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `deleteConsigneeReceipt()` */
  static readonly DeleteConsigneeReceiptPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/receipts/{attachmentKey}';

  /**
   * Delete a receipt files for a delivery note.
   *
   * Delete a receipt attachment for the consignee from a delivery note.
   * The delivery note bundle must be in status 'sent'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConsigneeReceipt()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConsigneeReceipt$Response(params: DeleteConsigneeReceipt$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteConsigneeReceipt(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a receipt files for a delivery note.
   *
   * Delete a receipt attachment for the consignee from a delivery note.
   * The delivery note bundle must be in status 'sent'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConsigneeReceipt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConsigneeReceipt(params: DeleteConsigneeReceipt$Params, context?: HttpContext): Observable<void> {
    return this.deleteConsigneeReceipt$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConsigneeReceiptsForDeliveryNote()` */
  static readonly GetConsigneeReceiptsForDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/receipts';

  /**
   * Get all receipt files for a delivery note.
   *
   * get a list of receipt attachments for a single delivery note for the consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeReceiptsForDeliveryNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeReceiptsForDeliveryNote$Response(params: GetConsigneeReceiptsForDeliveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeAttachmentsResponse>> {
    return getConsigneeReceiptsForDeliveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all receipt files for a delivery note.
   *
   * get a list of receipt attachments for a single delivery note for the consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeReceiptsForDeliveryNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeReceiptsForDeliveryNote(params: GetConsigneeReceiptsForDeliveryNote$Params, context?: HttpContext): Observable<GetConsigneeAttachmentsResponse> {
    return this.getConsigneeReceiptsForDeliveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeAttachmentsResponse>): GetConsigneeAttachmentsResponse => r.body)
    );
  }

}
