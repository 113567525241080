<div class="ticket">
    <div class="top">
        <div class="tw-flex tw-w-full tw-justify-between">
            <div class="tw-flex tw-flex-col">
                <ng-container *ngIf="date">
                    <div class="tw-font-bold">
                        {{
                            'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.CREATED'
                                | translate
                        }}
                    </div>
                    <div>
                        {{ date | date: undefined : currentLanguageCode }}
                    </div>
                </ng-container>
            </div>
            <div class="tw-flex tw-flex-col tw-text-end">
                <ng-container *ngIf="mapNr">
                    <div class="tw-font-bold">
                        {{
                            'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.FOLDER-KEY'
                                | translate
                        }}
                    </div>
                    <div>
                        {{ mapNr }}
                    </div>
                </ng-container>
                <ng-container *ngIf="tourNr">
                    <div class="tw-font-bold">
                        {{ 'SPEDITEUR.TABLE.HEADERS.TOUR-KEY' | translate }}
                    </div>
                    <div>{{ tourNr }}</div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="consigneeName">
            <div class="mt-2 tw-flex tw-items-center tw-font-bold">
                <div class="tw-w-8">
                    <img
                        src="./assets/images/folder_checked.svg"
                        width="20"
                        [alt]="
                            'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.CONSIGNEE'
                                | translate
                        " />
                </div>
                {{
                    'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.CONSIGNEE'
                        | translate
                }}
            </div>
            <div class="label">
                {{ consigneeName }}
            </div>
        </div>
    </div>
    <div class="rip"></div>
    <div class="bottom">
        <div *ngIf="carrierName">
            <div class="mt-2 tw-flex tw-items-center tw-font-bold">
                <div class="tw-w-8">
                    <img
                        src="./assets/images/truck.svg"
                        width="20"
                        [alt]="
                            'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.CARRIER'
                                | translate
                        " />
                </div>
                {{
                    'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.CARRIER'
                        | translate
                }}
            </div>
            <div class="label">
                {{ carrierName }}
            </div>
        </div>
        <div *ngIf="consignorName">
            <div class="mt-2 tw-flex tw-items-center tw-font-bold">
                <div class="tw-w-8">
                    <img
                        src="./assets/images/direction.svg"
                        width="20"
                        [alt]="
                            'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.CONSIGNOR'
                                | translate
                        " />
                </div>
                {{
                    'OUTGOING-GOODS.NEW-BUNDLE.DRIVER-INVITATION.CONSIGNOR'
                        | translate
                }}
            </div>
            <div class="label">
                {{ consignorName }}
            </div>
        </div>

        <div class="gradient tw-flex tw-items-center tw-justify-center">
            <a
                [href]="qrHref"
                aria-label="wallet-qr-code-link"
                target="_blank">
                <qr-code
                    *ngIf="showQrCode && qrCodeValue"
                    class="wallet-qrcode"
                    [value]="qrCodeValue"
                    aria-label="wallet-qr-code"
                    size="180"
                    errorCorrectionLevel="L"
                    centerImageSrc="./assets/images/truck.png"
                    centerImageSize="30"
                    [margin]="4"
                    id="qrcode"
                    (click)="qrClick.next()">
                    >
                </qr-code>
            </a>
            <div
                *ngIf="!showQrCode"
                class="tw-flex tw-h-28 tw-w-28 tw-items-center tw-justify-center tw-rounded-md tw-bg-white">
                <mat-icon
                    fontSet="material-symbols-outlined"
                    class="!tw-mr-0 !tw-h-[96px] !tw-w-[96px] !tw-text-[96px]"
                    >qr_code_scanner</mat-icon
                >
            </div>
        </div>
    </div>
</div>
