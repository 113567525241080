/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { CarrierDeliveryNotesInPool } from '../models/carrier-delivery-notes-in-pool';
import { CarrierLookupDeliveryNoteResponse } from '../models/carrier-lookup-delivery-note-response';
import { ConsigneeDeliveryNotesInPool } from '../models/consignee-delivery-notes-in-pool';
import { CreateDeliveryNoteResponse } from '../models/create-delivery-note-response';
import { deleteDeliveryNote } from '../fn/delivery-notes/delete-delivery-note';
import { DeleteDeliveryNote$Params } from '../fn/delivery-notes/delete-delivery-note';
import { deleteDeliveryNoteInBundle } from '../fn/delivery-notes/delete-delivery-note-in-bundle';
import { DeleteDeliveryNoteInBundle$Params } from '../fn/delivery-notes/delete-delivery-note-in-bundle';
import { deleteDeliveryNotesFromDeliveryNoteBundle } from '../fn/delivery-notes/delete-delivery-notes-from-delivery-note-bundle';
import { DeleteDeliveryNotesFromDeliveryNoteBundle$Params } from '../fn/delivery-notes/delete-delivery-notes-from-delivery-note-bundle';
import { DeliveryNoteArray } from '../models/delivery-note-array';
import { DeliveryNoteInPoolArray } from '../models/delivery-note-in-pool-array';
import { getCarrierDeliveryNoteCombinedPdf } from '../fn/delivery-notes/get-carrier-delivery-note-combined-pdf';
import { GetCarrierDeliveryNoteCombinedPdf$Params } from '../fn/delivery-notes/get-carrier-delivery-note-combined-pdf';
import { getCarrierDeliveryNotePdf } from '../fn/delivery-notes/get-carrier-delivery-note-pdf';
import { GetCarrierDeliveryNotePdf$Params } from '../fn/delivery-notes/get-carrier-delivery-note-pdf';
import { getCarrierDeliveryNotePdfsForBundle } from '../fn/delivery-notes/get-carrier-delivery-note-pdfs-for-bundle';
import { GetCarrierDeliveryNotePdfsForBundle$Params } from '../fn/delivery-notes/get-carrier-delivery-note-pdfs-for-bundle';
import { getCarrierDeliveryNotesMetadataInPool } from '../fn/delivery-notes/get-carrier-delivery-notes-metadata-in-pool';
import { GetCarrierDeliveryNotesMetadataInPool$Params } from '../fn/delivery-notes/get-carrier-delivery-notes-metadata-in-pool';
import { getConsigneeDeliveryNoteFromPool } from '../fn/delivery-notes/get-consignee-delivery-note-from-pool';
import { GetConsigneeDeliveryNoteFromPool$Params } from '../fn/delivery-notes/get-consignee-delivery-note-from-pool';
import { getConsigneeDeliveryNotePdfsForBundle } from '../fn/delivery-notes/get-consignee-delivery-note-pdfs-for-bundle';
import { GetConsigneeDeliveryNotePdfsForBundle$Params } from '../fn/delivery-notes/get-consignee-delivery-note-pdfs-for-bundle';
import { getConsigneeDeliveryNotesMetadataInPool } from '../fn/delivery-notes/get-consignee-delivery-notes-metadata-in-pool';
import { GetConsigneeDeliveryNotesMetadataInPool$Params } from '../fn/delivery-notes/get-consignee-delivery-notes-metadata-in-pool';
import { getConsignorDeliveryNoteCombinedPdf } from '../fn/delivery-notes/get-consignor-delivery-note-combined-pdf';
import { GetConsignorDeliveryNoteCombinedPdf$Params } from '../fn/delivery-notes/get-consignor-delivery-note-combined-pdf';
import { getDeliveryNoteFromPool } from '../fn/delivery-notes/get-delivery-note-from-pool';
import { GetDeliveryNoteFromPool$Params } from '../fn/delivery-notes/get-delivery-note-from-pool';
import { getDeliveryNoteKeysArchived } from '../fn/delivery-notes/get-delivery-note-keys-archived';
import { GetDeliveryNoteKeysArchived$Params } from '../fn/delivery-notes/get-delivery-note-keys-archived';
import { getDeliveryNoteMetadataInBundle } from '../fn/delivery-notes/get-delivery-note-metadata-in-bundle';
import { GetDeliveryNoteMetadataInBundle$Params } from '../fn/delivery-notes/get-delivery-note-metadata-in-bundle';
import { getDeliveryNotePdfsForBundle } from '../fn/delivery-notes/get-delivery-note-pdfs-for-bundle';
import { GetDeliveryNotePdfsForBundle$Params } from '../fn/delivery-notes/get-delivery-note-pdfs-for-bundle';
import { getDeliveryNotesMetadataInPool } from '../fn/delivery-notes/get-delivery-notes-metadata-in-pool';
import { GetDeliveryNotesMetadataInPool$Params } from '../fn/delivery-notes/get-delivery-notes-metadata-in-pool';
import { getSingleDeliveryNoteMetaData } from '../fn/delivery-notes/get-single-delivery-note-meta-data';
import { GetSingleDeliveryNoteMetaData$Params } from '../fn/delivery-notes/get-single-delivery-note-meta-data';
import { KeyArrayType } from '../models/key-array-type';
import { lookupDeliveryNoteKeys } from '../fn/delivery-notes/lookup-delivery-note-keys';
import { LookupDeliveryNoteKeys$Params } from '../fn/delivery-notes/lookup-delivery-note-keys';
import { patchDeliveryNoteMetadata } from '../fn/delivery-notes/patch-delivery-note-metadata';
import { PatchDeliveryNoteMetadata$Params } from '../fn/delivery-notes/patch-delivery-note-metadata';
import { postDeliveryNoteIntoBundle } from '../fn/delivery-notes/post-delivery-note-into-bundle';
import { PostDeliveryNoteIntoBundle$Params } from '../fn/delivery-notes/post-delivery-note-into-bundle';
import { postDeliveryNoteToPool } from '../fn/delivery-notes/post-delivery-note-to-pool';
import { PostDeliveryNoteToPool$Params } from '../fn/delivery-notes/post-delivery-note-to-pool';
import { postExistingDeliveryNoteIntoExistingBundle } from '../fn/delivery-notes/post-existing-delivery-note-into-existing-bundle';
import { PostExistingDeliveryNoteIntoExistingBundle$Params } from '../fn/delivery-notes/post-existing-delivery-note-into-existing-bundle';
import { putConsigneeOrganicCertificationNumberControls } from '../fn/delivery-notes/put-consignee-organic-certification-number-controls';
import { PutConsigneeOrganicCertificationNumberControls$Params } from '../fn/delivery-notes/put-consignee-organic-certification-number-controls';
import { shareDeliveryNotesRights } from '../fn/delivery-notes/share-delivery-notes-rights';
import { ShareDeliveryNotesRights$Params } from '../fn/delivery-notes/share-delivery-notes-rights';
import { SingleDeliveryNote } from '../models/single-delivery-note';


/**
 * Endpoints to handle single delivery notes
 */
@Injectable({ providedIn: 'root' })
export class DeliveryNotesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCarrierDeliveryNotesMetadataInPool()` */
  static readonly GetCarrierDeliveryNotesMetadataInPoolPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-notes';

  /**
   * Get delivery notes w/o load carriers as carrier.
   *
   * Get carrier delivery notes from delivery note pool.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierDeliveryNotesMetadataInPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotesMetadataInPool$Response(params: GetCarrierDeliveryNotesMetadataInPool$Params, context?: HttpContext): Observable<StrictHttpResponse<CarrierDeliveryNotesInPool>> {
    return getCarrierDeliveryNotesMetadataInPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get delivery notes w/o load carriers as carrier.
   *
   * Get carrier delivery notes from delivery note pool.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierDeliveryNotesMetadataInPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotesMetadataInPool(params: GetCarrierDeliveryNotesMetadataInPool$Params, context?: HttpContext): Observable<CarrierDeliveryNotesInPool> {
    return this.getCarrierDeliveryNotesMetadataInPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarrierDeliveryNotesInPool>): CarrierDeliveryNotesInPool => r.body)
    );
  }

  /** Path part for operation `getCarrierDeliveryNotePdfsForBundle()` */
  static readonly GetCarrierDeliveryNotePdfsForBundlePath = '/organization-sites/{organizationSiteKey}/carrier/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-note-pdfs';

  /**
   * Get bundle PDF as carrier.
   *
   * Get all delivery note PDF's as merged PDF from carrier delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierDeliveryNotePdfsForBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdfsForBundle$Response(params: GetCarrierDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getCarrierDeliveryNotePdfsForBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle PDF as carrier.
   *
   * Get all delivery note PDF's as merged PDF from carrier delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierDeliveryNotePdfsForBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdfsForBundle(params: GetCarrierDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getCarrierDeliveryNotePdfsForBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getCarrierDeliveryNotePdf()` */
  static readonly GetCarrierDeliveryNotePdfPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF as carrier.
   *
   * Get the PDF of a carrier delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierDeliveryNotePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdf$Response(params: GetCarrierDeliveryNotePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getCarrierDeliveryNotePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF as carrier.
   *
   * Get the PDF of a carrier delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierDeliveryNotePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdf(params: GetCarrierDeliveryNotePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getCarrierDeliveryNotePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `shareDeliveryNotesRights()` */
  static readonly ShareDeliveryNotesRightsPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-notes/share-rights';

  /**
   * Share rights of delivery note.
   *
   * Share rights of a delivery note to another carrier organization site.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shareDeliveryNotesRights()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shareDeliveryNotesRights$Response(params: ShareDeliveryNotesRights$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return shareDeliveryNotesRights(this.http, this.rootUrl, params, context);
  }

  /**
   * Share rights of delivery note.
   *
   * Share rights of a delivery note to another carrier organization site.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shareDeliveryNotesRights$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shareDeliveryNotesRights(params: ShareDeliveryNotesRights$Params, context?: HttpContext): Observable<void> {
    return this.shareDeliveryNotesRights$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `lookupDeliveryNoteKeys()` */
  static readonly LookupDeliveryNoteKeysPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-notes/lookup';

  /**
   * Get the delivery note key by number, optional order number and gln of consignor.
   *
   * Get the delivery note key by number, optional order number and the gln of the consignor, if it exists and the organization site has access.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupDeliveryNoteKeys()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupDeliveryNoteKeys$Response(params: LookupDeliveryNoteKeys$Params, context?: HttpContext): Observable<StrictHttpResponse<CarrierLookupDeliveryNoteResponse>> {
    return lookupDeliveryNoteKeys(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the delivery note key by number, optional order number and gln of consignor.
   *
   * Get the delivery note key by number, optional order number and the gln of the consignor, if it exists and the organization site has access.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupDeliveryNoteKeys$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupDeliveryNoteKeys(params: LookupDeliveryNoteKeys$Params, context?: HttpContext): Observable<CarrierLookupDeliveryNoteResponse> {
    return this.lookupDeliveryNoteKeys$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarrierLookupDeliveryNoteResponse>): CarrierLookupDeliveryNoteResponse => r.body)
    );
  }

  /** Path part for operation `putConsigneeOrganicCertificationNumberControls()` */
  static readonly PutConsigneeOrganicCertificationNumberControlsPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/controls/organic-certification-number';

  /**
   * Update the organicCertificationNumberChecked flag whether it has been checked or not.
   *
   * Set the boolean to true or false whether the organic certification number was checked or not.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putConsigneeOrganicCertificationNumberControls()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsigneeOrganicCertificationNumberControls$Response(params: PutConsigneeOrganicCertificationNumberControls$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putConsigneeOrganicCertificationNumberControls(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the organicCertificationNumberChecked flag whether it has been checked or not.
   *
   * Set the boolean to true or false whether the organic certification number was checked or not.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putConsigneeOrganicCertificationNumberControls$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsigneeOrganicCertificationNumberControls(params: PutConsigneeOrganicCertificationNumberControls$Params, context?: HttpContext): Observable<void> {
    return this.putConsigneeOrganicCertificationNumberControls$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNotesMetadataInPool()` */
  static readonly GetConsigneeDeliveryNotesMetadataInPoolPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes';

  /**
   * Get delivery notes w/o load carriers as consignee.
   *
   * Get consignee delivery notes from delivery note pool.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNotesMetadataInPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotesMetadataInPool$Response(params: GetConsigneeDeliveryNotesMetadataInPool$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeDeliveryNotesInPool>> {
    return getConsigneeDeliveryNotesMetadataInPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get delivery notes w/o load carriers as consignee.
   *
   * Get consignee delivery notes from delivery note pool.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNotesMetadataInPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotesMetadataInPool(params: GetConsigneeDeliveryNotesMetadataInPool$Params, context?: HttpContext): Observable<ConsigneeDeliveryNotesInPool> {
    return this.getConsigneeDeliveryNotesMetadataInPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeDeliveryNotesInPool>): ConsigneeDeliveryNotesInPool => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNoteFromPool()` */
  static readonly GetConsigneeDeliveryNoteFromPoolPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF as consignee.
   *
   * Get a delivery note PDF from the consignee delivery note pool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNoteFromPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteFromPool$Response(params: GetConsigneeDeliveryNoteFromPool$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDeliveryNoteFromPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF as consignee.
   *
   * Get a delivery note PDF from the consignee delivery note pool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNoteFromPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteFromPool(params: GetConsigneeDeliveryNoteFromPool$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDeliveryNoteFromPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNotePdfsForBundle()` */
  static readonly GetConsigneeDeliveryNotePdfsForBundlePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-note-pdfs';

  /**
   * Get bundle PDF as consignee.
   *
   * Get all delivery note PDF's as merged PDF from consignee delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNotePdfsForBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotePdfsForBundle$Response(params: GetConsigneeDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDeliveryNotePdfsForBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle PDF as consignee.
   *
   * Get all delivery note PDF's as merged PDF from consignee delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNotePdfsForBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotePdfsForBundle(params: GetConsigneeDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDeliveryNotePdfsForBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `postDeliveryNoteIntoBundle()` */
  static readonly PostDeliveryNoteIntoBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/pdf';

  /**
   * Add PDF to bundle.
   *
   * Adds the posted delivery note PDF to the delivery note bundle.
   * The delivery note must be a PDF in base64 format.
   * The PDF is converted to PDF/A3.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDeliveryNoteIntoBundle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDeliveryNoteIntoBundle$Response(params: PostDeliveryNoteIntoBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateDeliveryNoteResponse>> {
    return postDeliveryNoteIntoBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Add PDF to bundle.
   *
   * Adds the posted delivery note PDF to the delivery note bundle.
   * The delivery note must be a PDF in base64 format.
   * The PDF is converted to PDF/A3.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDeliveryNoteIntoBundle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDeliveryNoteIntoBundle(params: PostDeliveryNoteIntoBundle$Params, context?: HttpContext): Observable<CreateDeliveryNoteResponse> {
    return this.postDeliveryNoteIntoBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateDeliveryNoteResponse>): CreateDeliveryNoteResponse => r.body)
    );
  }

  /** Path part for operation `getDeliveryNotesMetadataInPool()` */
  static readonly GetDeliveryNotesMetadataInPoolPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes';

  /**
   * Get delivery notes data w/o load carriers.
   *
   * Get delivery notes metadata from delivery note pool.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryNotesMetadataInPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNotesMetadataInPool$Response(params: GetDeliveryNotesMetadataInPool$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryNoteInPoolArray>> {
    return getDeliveryNotesMetadataInPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get delivery notes data w/o load carriers.
   *
   * Get delivery notes metadata from delivery note pool.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryNotesMetadataInPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNotesMetadataInPool(params: GetDeliveryNotesMetadataInPool$Params, context?: HttpContext): Observable<DeliveryNoteInPoolArray> {
    return this.getDeliveryNotesMetadataInPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryNoteInPoolArray>): DeliveryNoteInPoolArray => r.body)
    );
  }

  /** Path part for operation `postDeliveryNoteToPool()` */
  static readonly PostDeliveryNoteToPoolPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes';

  /**
   * Upload delivery note PDF.
   *
   * Adds the uploaded delivery note PDF to the delivery note pool.
   * The delivery note must be a PDF in base64 format.
   * The PDF is converted to PDF/A3. The pool is a collection of
   * loose delivery notes without a assigned supply chain or bundle.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDeliveryNoteToPool()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDeliveryNoteToPool$Response(params: PostDeliveryNoteToPool$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateDeliveryNoteResponse>> {
    return postDeliveryNoteToPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload delivery note PDF.
   *
   * Adds the uploaded delivery note PDF to the delivery note pool.
   * The delivery note must be a PDF in base64 format.
   * The PDF is converted to PDF/A3. The pool is a collection of
   * loose delivery notes without a assigned supply chain or bundle.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDeliveryNoteToPool$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDeliveryNoteToPool(params: PostDeliveryNoteToPool$Params, context?: HttpContext): Observable<CreateDeliveryNoteResponse> {
    return this.postDeliveryNoteToPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateDeliveryNoteResponse>): CreateDeliveryNoteResponse => r.body)
    );
  }

  /** Path part for operation `getDeliveryNotePdfsForBundle()` */
  static readonly GetDeliveryNotePdfsForBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-note-pdfs';

  /**
   * Get bundle PDF from bundle.
   *
   * Returns a PDF file containing all delivery notes associated with a specific delivery note bundle.
   * If the delivery note bundle does not have any delivery note an HTTP
   * status code 400 is returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryNotePdfsForBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNotePdfsForBundle$Response(params: GetDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getDeliveryNotePdfsForBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle PDF from bundle.
   *
   * Returns a PDF file containing all delivery notes associated with a specific delivery note bundle.
   * If the delivery note bundle does not have any delivery note an HTTP
   * status code 400 is returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryNotePdfsForBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNotePdfsForBundle(params: GetDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getDeliveryNotePdfsForBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getDeliveryNoteMetadataInBundle()` */
  static readonly GetDeliveryNoteMetadataInBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes';

  /**
   * Get delivery notes data from bundle w/o load carriers.
   *
   * Get delivery notes metadata from a delivery note bundle.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryNoteMetadataInBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteMetadataInBundle$Response(params: GetDeliveryNoteMetadataInBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryNoteArray>> {
    return getDeliveryNoteMetadataInBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get delivery notes data from bundle w/o load carriers.
   *
   * Get delivery notes metadata from a delivery note bundle.
   * Property loadCarriers is always an empty array. To get the
   * load carriers please use the load carriers endpoint.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryNoteMetadataInBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteMetadataInBundle(params: GetDeliveryNoteMetadataInBundle$Params, context?: HttpContext): Observable<DeliveryNoteArray> {
    return this.getDeliveryNoteMetadataInBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryNoteArray>): DeliveryNoteArray => r.body)
    );
  }

  /** Path part for operation `postExistingDeliveryNoteIntoExistingBundle()` */
  static readonly PostExistingDeliveryNoteIntoExistingBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes';

  /**
   * Add delivery note to open bundle.
   *
   * Add existing delivery notes into an existing delivery note bundle.
   * The delivery note must not in any bundle when adding.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postExistingDeliveryNoteIntoExistingBundle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postExistingDeliveryNoteIntoExistingBundle$Response(params: PostExistingDeliveryNoteIntoExistingBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postExistingDeliveryNoteIntoExistingBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Add delivery note to open bundle.
   *
   * Add existing delivery notes into an existing delivery note bundle.
   * The delivery note must not in any bundle when adding.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postExistingDeliveryNoteIntoExistingBundle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postExistingDeliveryNoteIntoExistingBundle(params: PostExistingDeliveryNoteIntoExistingBundle$Params, context?: HttpContext): Observable<void> {
    return this.postExistingDeliveryNoteIntoExistingBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteDeliveryNotesFromDeliveryNoteBundle()` */
  static readonly DeleteDeliveryNotesFromDeliveryNoteBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/some';

  /**
   * Detach delivery notes from bundle.
   *
   * Detach existing delivery notes from an existing delivery note bundle.
   * All delivery notes must be in the same bundle when removing.
   * The delivery note bundle must be in status 'open'.
   * The delevery notes would become detached from bundle and remain in pool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDeliveryNotesFromDeliveryNoteBundle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteDeliveryNotesFromDeliveryNoteBundle$Response(params: DeleteDeliveryNotesFromDeliveryNoteBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteDeliveryNotesFromDeliveryNoteBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Detach delivery notes from bundle.
   *
   * Detach existing delivery notes from an existing delivery note bundle.
   * All delivery notes must be in the same bundle when removing.
   * The delivery note bundle must be in status 'open'.
   * The delevery notes would become detached from bundle and remain in pool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDeliveryNotesFromDeliveryNoteBundle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteDeliveryNotesFromDeliveryNoteBundle(params: DeleteDeliveryNotesFromDeliveryNoteBundle$Params, context?: HttpContext): Observable<void> {
    return this.deleteDeliveryNotesFromDeliveryNoteBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDeliveryNoteFromPool()` */
  static readonly GetDeliveryNoteFromPoolPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF from pool.
   *
   * Get delivery note PDF from delivery note pool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryNoteFromPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteFromPool$Response(params: GetDeliveryNoteFromPool$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getDeliveryNoteFromPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF from pool.
   *
   * Get delivery note PDF from delivery note pool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryNoteFromPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteFromPool(params: GetDeliveryNoteFromPool$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getDeliveryNoteFromPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `deleteDeliveryNote()` */
  static readonly DeleteDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}';

  /**
   * Delete delivery note from pool.
   *
   * Delete a delivery note with its PDF. The delivery note must not in
   * a delivery note bundle. Remaining load carriers or attachments are
   * also deleted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDeliveryNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDeliveryNote$Response(params: DeleteDeliveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteDeliveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete delivery note from pool.
   *
   * Delete a delivery note with its PDF. The delivery note must not in
   * a delivery note bundle. Remaining load carriers or attachments are
   * also deleted.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDeliveryNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDeliveryNote(params: DeleteDeliveryNote$Params, context?: HttpContext): Observable<void> {
    return this.deleteDeliveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchDeliveryNoteMetadata()` */
  static readonly PatchDeliveryNoteMetadataPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}';

  /**
   * Update delivery note data.
   *
   * Patch a delivery note. If the delivery note belongs to a delivery
   * note bundle the data can only be updated if the bundle is in status
   * 'open'. The number and order number must be set with the first patch
   * call for a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDeliveryNoteMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDeliveryNoteMetadata$Response(params: PatchDeliveryNoteMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchDeliveryNoteMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Update delivery note data.
   *
   * Patch a delivery note. If the delivery note belongs to a delivery
   * note bundle the data can only be updated if the bundle is in status
   * 'open'. The number and order number must be set with the first patch
   * call for a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDeliveryNoteMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDeliveryNoteMetadata(params: PatchDeliveryNoteMetadata$Params, context?: HttpContext): Observable<void> {
    return this.patchDeliveryNoteMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteDeliveryNoteInBundle()` */
  static readonly DeleteDeliveryNoteInBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}';

  /**
   * Detach delivery note from open bundle and delete load carrier.
   *
   * Detaches a delivery note from a bundle. Deletes also the load
   * carriers that hang on the delivery note. The bundles must be in
   * status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDeliveryNoteInBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDeliveryNoteInBundle$Response(params: DeleteDeliveryNoteInBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteDeliveryNoteInBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Detach delivery note from open bundle and delete load carrier.
   *
   * Detaches a delivery note from a bundle. Deletes also the load
   * carriers that hang on the delivery note. The bundles must be in
   * status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDeliveryNoteInBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDeliveryNoteInBundle(params: DeleteDeliveryNoteInBundle$Params, context?: HttpContext): Observable<void> {
    return this.deleteDeliveryNoteInBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSingleDeliveryNoteMetaData()` */
  static readonly GetSingleDeliveryNoteMetaDataPath = '/delivery-notes/{deliveryNoteKey}';

  /**
   * Get meta data for a delivery note.
   *
   * Get meta data for a single delivery note from delivery note pool.
   * The property loadCarriers is deprecated. Please use loadCarriersConsignor
   * and loadCarriersConsignee instead.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleDeliveryNoteMetaData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleDeliveryNoteMetaData$Response(params: GetSingleDeliveryNoteMetaData$Params, context?: HttpContext): Observable<StrictHttpResponse<SingleDeliveryNote>> {
    return getSingleDeliveryNoteMetaData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get meta data for a delivery note.
   *
   * Get meta data for a single delivery note from delivery note pool.
   * The property loadCarriers is deprecated. Please use loadCarriersConsignor
   * and loadCarriersConsignee instead.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleDeliveryNoteMetaData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleDeliveryNoteMetaData(params: GetSingleDeliveryNoteMetaData$Params, context?: HttpContext): Observable<SingleDeliveryNote> {
    return this.getSingleDeliveryNoteMetaData$Response(params, context).pipe(
      map((r: StrictHttpResponse<SingleDeliveryNote>): SingleDeliveryNote => r.body)
    );
  }

  /** Path part for operation `getDeliveryNoteKeysArchived()` */
  static readonly GetDeliveryNoteKeysArchivedPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/archived';

  /**
   * Retrieve all delivery note keys that are ready to be archived.
   *
   * A delivery note counts as ready for archiving if fineDiscrepancy was
   * added or a checkin is older than a specified time interval. In
   * both cases the checkin needs to be in status 'finished'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryNoteKeysArchived()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteKeysArchived$Response(params: GetDeliveryNoteKeysArchived$Params, context?: HttpContext): Observable<StrictHttpResponse<KeyArrayType>> {
    return getDeliveryNoteKeysArchived(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all delivery note keys that are ready to be archived.
   *
   * A delivery note counts as ready for archiving if fineDiscrepancy was
   * added or a checkin is older than a specified time interval. In
   * both cases the checkin needs to be in status 'finished'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryNoteKeysArchived$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteKeysArchived(params: GetDeliveryNoteKeysArchived$Params, context?: HttpContext): Observable<KeyArrayType> {
    return this.getDeliveryNoteKeysArchived$Response(params, context).pipe(
      map((r: StrictHttpResponse<KeyArrayType>): KeyArrayType => r.body)
    );
  }

  /** Path part for operation `getConsignorDeliveryNoteCombinedPdf()` */
  static readonly GetConsignorDeliveryNoteCombinedPdfPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: all delivery notes,
   * all attachments, all signatures and all images as a consignor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsignorDeliveryNoteCombinedPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorDeliveryNoteCombinedPdf$Response(params: GetConsignorDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsignorDeliveryNoteCombinedPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: all delivery notes,
   * all attachments, all signatures and all images as a consignor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsignorDeliveryNoteCombinedPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorDeliveryNoteCombinedPdf(params: GetConsignorDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsignorDeliveryNoteCombinedPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getCarrierDeliveryNoteCombinedPdf()` */
  static readonly GetCarrierDeliveryNoteCombinedPdfPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: all delivery notes,
   * all attachments, all signatures and all images as a carrier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierDeliveryNoteCombinedPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNoteCombinedPdf$Response(params: GetCarrierDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getCarrierDeliveryNoteCombinedPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: all delivery notes,
   * all attachments, all signatures and all images as a carrier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierDeliveryNoteCombinedPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNoteCombinedPdf(params: GetCarrierDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getCarrierDeliveryNoteCombinedPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
