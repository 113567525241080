import { Injectable } from '@angular/core';
import printJS from 'print-js';

@Injectable({
    providedIn: 'root',
})
export class PrintService {
    constructor() {}

    /**
     *
     * @param dataUrl
     * @param header
     */
    printImage(dataUrl: string, header: string = '', style: string = '') {
        printJS({
            printable: dataUrl,
            type: 'image',
            imageStyle: 'width:27%',
            header: header,
            style: style,
        });
    }

    /**
     * Prints a PDF from base64 string
     * @param base64
     */
    printPdfBase64(base64: string) {
        printJS({ printable: base64, type: 'pdf', base64: true });
    }

    /**
     * Prints a PDF from blob data
     * @param file
     */
    printPdf(file: Blob) {
        printJS(URL.createObjectURL(file));
    }
}
