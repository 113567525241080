<signature-pad
    #signature
    class="signature-pad"
    [class.truck]="showTruck"
    [options]="signaturePadOptions"
    (drawStart)="drawStart($event)"
    (drawEnd)="drawComplete($event)"></signature-pad>
<button
    mat-icon-button
    class="tw-absolute tw-top-2 tw-right-2"
    [matTooltip]="'SIGNATURE-FIELD.RESET' | translate"
    (click)="clear()">
    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
</button>
<button
    *ngIf="showPullSignature"
    mat-icon-button
    class="tw-absolute tw-top-2 tw-left-2"
    [matTooltip]="'SIGNATURE-FIELD.LOAD' | translate"
    (click)="loadSignature()">
    <mat-icon fontSet="material-symbols-outlined">file_download</mat-icon>
</button>

<ng-content></ng-content>
