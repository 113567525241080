import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { ToastService } from '@app/core/services/toast.service';
import { RoutingEndpoints } from '@app/shared/models/routing/routing-endpoints';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LoginOrganizationSite, LoginResponse, UserRoles } from 'api/models';
import { MatMenuTrigger } from '@angular/material/menu';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
    @Input() isExternal: boolean;
    @Input() userSession: LoginResponse;

    @ViewChild('userMenuTrigger') userMenuTrigger: MatMenuTrigger;

    hideRating: boolean = false;
    currentSite: LoginOrganizationSite;
    sitesInfo: LoginOrganizationSite[] = [];

    rolesMap: Map<string, boolean> = new Map();
    showRolesMenu: boolean = false;
    favoriteOrganisationSite: string;

    UserRoles = UserRoles;
    forwardTo: boolean = false;

    constructor(
        private router: Router,
        public sessionService: SessionService,
        private translateService: TranslateService,
        private storageService: StorageService,
        private toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.forwardTo =
                    !event.url.includes('/external') ||
                    !event.url.includes('/tos');
            }
        });

        const organizationSites =
            this.sessionService.getSession()?.organizationSites;

        const initialOrganizationSite = organizationSites.find(
            o => o._key.length !== 0
        );
        const site = initialOrganizationSite;

        this.sitesInfo = organizationSites;
        if (initialOrganizationSite !== undefined && site !== undefined) {
            this.currentSite = site;
            this.rolesMap.set('consignor', this.currentSite.consignor === true);
            this.rolesMap.set('consignee', this.currentSite.consignee === true);
            this.rolesMap.set('carrier', this.currentSite.carrier === true);
        }

        if (this.storageService.itemExists('favoriteOrganizationSiteKey')) {
            this.favoriteOrganisationSite = this.storageService.getItemAsObject(
                'favoriteOrganizationSiteKey'
            );
        }

        if (this.storageService.itemExists('currentSiteRole')) {
            const currentSiteRole =
                this.storageService.getItemAsObject('currentSiteRole');
            if (this.forwardTo) {
                this.onChangeRole(
                    currentSiteRole.role,
                    currentSiteRole.siteInfo
                );
            }
        }

        for (let [key, value] of this.rolesMap) {
            if (value) {
                this.showRolesMenu = value;
                break;
            }
        }

        this.hideRating =
            this.storageService.getItem('hideRating') == 'true' ? true : false;

        this.sessionService.currentSiteRole$.subscribe(data => {
            if (data) {
                this.currentSite = data.siteInfo;
                this.rolesMap.set('consignor', this.currentSite.consignor);
                this.rolesMap.set('consignee', this.currentSite.consignee);
                this.rolesMap.set('carrier', this.currentSite.carrier);
            }
        });
    }

    getRoleTooltipContent(): string {
        let tooltipContent = '';
        if (this.sessionService.isSiteAdmin)
            tooltipContent = this.translateService
                .instant('ADMIN-PANEL.USERS.ROLES.SITEADMIN')
                .replaceAll(' ', '-');

        if (this.sessionService.isOrganizationAdmin)
            tooltipContent = this.translateService
                .instant('ADMIN-PANEL.USERS.ROLES.ORGADMIN')
                .replaceAll(' ', '-');

        if (this.sessionService.isServiceProvider) {
            tooltipContent = this.translateService
                .instant('ADMIN-PANEL.USERS.ROLES.SERVICEPROVIDER')
                .replaceAll(' ', '-');
        }

        return tooltipContent;
    }

    openSettings(): void {
        this.router.navigate(['/settings']);
    }

    onChangeRole(expectedRole: string, site: LoginOrganizationSite) {
        let role: string = expectedRole;
        switch (expectedRole) {
            case 'consignor':
                if (site.roles.includes(UserRoles.RemoteConsignor)) {
                    role = 'remote_consignor';
                }
                break;

            case 'consignee':
                if (site.roles.includes(UserRoles.RemoteConsignee)) {
                    role = 'remote_consignee';
                }
                break;

            case 'carrier':
                if (site.roles.includes(UserRoles.RemoteCarrier)) {
                    role = 'remote_carrier';
                }
                break;
        }

        const currentSiteRole = {
            role: role,
            siteInfo: site,
        };
        this.sessionService.currentSiteRole$.next(currentSiteRole);
        this.sessionService.setCurrentOrganizationSite(site._key);
        this.storageService.setItem('currentSiteRole', currentSiteRole);
        this.currentSite = site;
        const target: RoutingEndpoints = (<any>RoutingEndpoints)[expectedRole];
        this.router.navigate([target]);
    }

    setFavoriteSite(siteInfo: LoginOrganizationSite) {
        this.storageService.setItem(
            'favoriteOrganizationSiteKey',
            siteInfo._key
        );
    }
    onSetRatingDialog(opt: string) {
        switch (opt) {
            case 'false': {
                localStorage.setItem('hideRating', 'false');

                this.toastService.success(
                    this.translateService.instant(
                        'USER-PROFILE.FEEDBACK-CONFIRM.ON'
                    )
                );
                this.hideRating = false;
                break;
            }
            case 'true': {
                localStorage.setItem('hideRating', 'true');

                this.toastService.success(
                    this.translateService.instant(
                        'USER-PROFILE.FEEDBACK-CONFIRM.OFF'
                    )
                );
                this.hideRating = true;
                break;
            }
        }
    }
}
