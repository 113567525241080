import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { UsersService } from 'api/services/users.service';
import {
    BehaviorSubject,
    catchError,
    lastValueFrom,
    map,
    of,
    switchMap,
    take,
} from 'rxjs';
import { OrganizationSite } from 'api/models/organization-site';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelfCheckinInformation } from 'api/models/self-checkin-information';
import {
    LoginResponse,
    SelfCheckinDriverWaitingTriggerEvent,
    TriggerEventType,
} from 'api/models';
import { EventsService, SelfServiceCheckinsService } from 'api/services';
import { ToastService } from '@app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiInterceptor } from '@app/core/interceptors/api.interceptor';
import { UtilsService } from '@app/core/services/client-services/utils-service/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { OneButtonDialogComponent } from '@app/shared/components/one-button-dialog/one-button-dialog.component';

type RequestParams = {
    organizationSiteKey: string;
    tourNumber?: string;
    bundleNumber?: string;
    checkinToken?: string;
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class SelfserviceService {
    //OrganizationSite
    private organizationSiteSubject = new BehaviorSubject<
        OrganizationSite | undefined
    >(undefined);
    public organizationSite$ = this.organizationSiteSubject.asObservable();
    //Tour
    public tourSubject = new BehaviorSubject<
        SelfCheckinInformation | undefined
    >(undefined);
    public tour$ = this.tourSubject.asObservable();
    //Me
    public meSubject = new BehaviorSubject<LoginResponse | undefined>(
        undefined
    );
    public me$ = this.meSubject.asObservable();

    private lastUsedCheckinOrNumber: string | null;

    constructor(
        private storageService: StorageService,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private router: Router,
        private selfCheckinService: SelfServiceCheckinsService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private ngxSpinnerService: NgxSpinnerService,
        private apiInjector: ApiInterceptor,
        private utilsService: UtilsService,
        private dialog: MatDialog,
        private eventsService: EventsService
    ) {
        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.urlAfterRedirects.startsWith('/selfservice')) {
                    this.initializeSession();
                }
            }
        });
    }

    /**
     * This function is used to initialize the session with the API key and organization site retrieved from query parameters
     * or local storage in case they are not provided as query parameters.
     * If both 'apiKey' and 'orgSite' parameters are missing, this method returns undefined.
     * After successful initialization of session, it saves the session permanently into local storage and loads the
     * organization site using `loadOrganizationSite` method from administration service.
     */
    async initializeSession(): Promise<void> {
        let apiKey = this.route.snapshot.queryParamMap.get('apiKey');
        let orgSite = this.route.snapshot.queryParamMap.get('orgSite');

        if (!apiKey) {
            const storedApiKey =
                this.storageService.getItem('sessionIdPermanent');
            apiKey = storedApiKey ? this.tryParseJson(storedApiKey) : null;
        }

        if (!orgSite) {
            const storedOrgSite =
                this.storageService.getItem('orgSitePermanent');
            orgSite = storedOrgSite ? this.tryParseJson(storedOrgSite) : null;
        }

        if (!apiKey || !orgSite) {
            return undefined;
        }

        if (apiKey && orgSite) {
            this.savePermanentSession(apiKey, orgSite);
            await this.loadOrganizationSite(orgSite);
        }
    }

    /**
     * This function is used to save the API key and organization site permanently in local storage so they can be retrieved
     * later for session initialization purpose.
     * @param {string} apiKey - The API key of the current session.
     * @param {string} orgSite - The organization site key associated with the current session.
     */
    public savePermanentSession(apiKey: string, orgSite: string): void {
        this.storageService.setItem('sessionIdPermanent', apiKey);
        this.storageService.setItem('orgSitePermanent', orgSite);
    }

    /**
     * This function retrieves user data for the given organization site key from accessible user sites.
     * It uses `getAccessibleUserSites` method of users service to fetch all accessible user sites and then finds the one with the provided orgSite key.
     * If no matching user site is found, it returns undefined.
     * @param {string} orgSite - The organization site key for which the user data is being fetched.
     */
    private async getUserData(
        orgSite: string
    ): Promise<OrganizationSite | undefined> {
        const sites = await lastValueFrom(
            this.usersService.getAccessibleUserSites()
        );
        if (sites && sites.length > 0) {
            return sites.find(site => site._key === orgSite);
        }
        return undefined;
    }

    /**
     * This function attempts to parse the provided value as JSON, if it fails it returns the original unparsed value.
     * It's used for parsing data that was stored in local storage as a string before and needs to be converted back into its original format.
     * @param {string} value - The value to be parsed as JSON.
     */
    private tryParseJson(value: string): any {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    /**
     * This function is used to reload the organization site from local storage using the 'orgSite' key stored in local storage if it exists.
     * After fetching and loading the data, this updates the `organizationSite` state by emitting the loaded information through
     * the `organizationSiteSubject` using `next()` function.
     */
    public async reloadOrganizationSite(): Promise<void> {
        const orgSite = this.storageService.getItem('orgSite');
        if (orgSite) {
            await this.loadOrganizationSite(orgSite);
        }
    }

    /**
     * This function loads an organization site data using the provided orgSite key and updates the `organizationSite`
     * state by emitting the loaded information through the `organizationSiteSubject` using `next()` function.
     * @param {string} orgSite - The organization site key for which the data is being fetched & loaded.
     */
    private async loadOrganizationSite(orgSite: string): Promise<void> {
        this.apiInjector.disableToastService();
        try {
            const organizationSite = await this.getUserData(orgSite);
            if (!organizationSite?.selfCheckin) {
                this.openDialog('', 'SELFSERVICE.LANDING.DISABLED');
            } else {
                this.organizationSiteSubject.next(organizationSite);
                this.apiInjector.enableToastService();
            }
        } catch (error) {
            this.openDialog('', 'SELFSERVICE.LANDING.DISABLED');
        }
        this.apiInjector.enableToastService();
    }

    /**
     * This function retrieves the bundle or tour number based on the last four digits provided.
     * It constructs the request parameters, calls the service to get bundles and tours,
     * and handles the response accordingly. If multiple results are found, it shows an error toast.
     * If no results are found, it shows a generic error toast. Otherwise, it processes the single result.
     *
     * @param {string} lastFourNumbers - The last four digits of the bundle or tour number to search for.
     */
    async getBundleOrTourNumber(lastFourNumbers: string) {
        try {
            let requestParams: any = {
                organizationSiteKey: this.organizationSiteSubject.value?._key!,
                lastFourBundleOrTourNumbers: lastFourNumbers,
            };

            const response = await lastValueFrom(
                this.selfCheckinService.getBundlesAndTours(requestParams)
            );

            if (response.length > 1) {
                this.toastService.error(
                    this.translateService.instant(
                        'SELFSERVICE.BUNDLE_NUMBER.CONTACT_TEAM'
                    )
                );
            } else if (response.length === 0) {
                this.toastService.error(
                    this.translateService.instant('GLOBAL.ERROR.UNSPECIFIED')
                );
                return;
            } else {
                this.getSelfCheckinData(response[0].bundleOrTourNumber);
            }
        } catch (error) {
            this.toastService.error(
                this.translateService.instant('GLOBAL.ERROR.UNSPECIFIED')
            );
        }
    }

    /**
     * Fetches self-checkin data based on the current organization site and optional parameters such as
     * the bundle tour number and checkin token. This method ensures that the organization site data is up-to-date
     * by utilizing the organizationSite$ observable before making a request for self-checkin data.
     *
     * Constructs a request parameter object and invokes the getSelfCheckinInformation method of the selfCheckinService.
     * Handles errors gracefully and communicates them via the tourSubject BehaviorSubject.
     *
     * @param {string} [bundleTourNumber] - Optional parameter specifying the tour number.
     * @param {string} [checkinToken] - Optional parameter specifying the checkin token.
     */

    async getSelfCheckinData(
        bundleTourNumber?: string,
        checkinToken?: string
    ): Promise<SelfCheckinInformation | undefined> {
        return new Promise((resolve, reject) => {
            if (bundleTourNumber) {
                this.lastUsedCheckinOrNumber = bundleTourNumber;
            } else if (checkinToken) {
                this.lastUsedCheckinOrNumber = checkinToken;
            }
            this.organizationSite$
                .pipe(
                    take(1),
                    switchMap(organizationSite => {
                        if (!organizationSite) {
                            throw new Error('Organization site not available.');
                        }

                        let requestParams: RequestParams = {
                            organizationSiteKey: organizationSite._key,
                        };

                        if (checkinToken) {
                            requestParams.checkinToken = checkinToken;
                        } else {
                            if (bundleTourNumber?.startsWith('M')) {
                                requestParams.bundleNumber = bundleTourNumber;
                            } else if (bundleTourNumber?.startsWith('T')) {
                                requestParams.tourNumber = bundleTourNumber;
                            }
                        }

                        return this.selfCheckinService
                            .getSelfCheckinInformation(requestParams)
                            .pipe(
                                map(data => ({ data })),
                                catchError(error => {
                                    return of({ error });
                                })
                            );
                    }),
                    take(1)
                )
                .pipe(untilDestroyed(this))
                .subscribe(result => {
                    if ('error' in result) {
                        reject(result.error);
                    } else {
                        if (result.data) {
                            this.tourSubject.next(result.data);
                            resolve(result.data);
                        } else {
                            resolve(undefined);
                        }
                    }
                });
        });
    }

    /**
     * Clears the last used check-in or number.
     * Sets the `lastUsedCheckinOrNumber` property to null.
     */
    clearlastUsedCheckinOrNumber() {
        this.lastUsedCheckinOrNumber = null;
    }

    /**
     * Retriggers the fetching of self-check-in data based on the last used check-in or number.
     * Checks the format of the `lastUsedCheckinOrNumber` and calls the appropriate service method.
     * If the response is successful, clears the `lastUsedCheckinOrNumber` and returns true.
     * If an error occurs, logs the error and returns false.
     * @returns {Promise<boolean>} A promise that resolves to true if the data is successfully retrieved, otherwise false.
     */
    async retriggergetSelfCheckinData(): Promise<boolean> {
        if (
            this.lastUsedCheckinOrNumber &&
            (this.lastUsedCheckinOrNumber.startsWith('M') ||
                this.lastUsedCheckinOrNumber.startsWith('T'))
        ) {
            try {
                const response = await this.getSelfCheckinData(
                    this.lastUsedCheckinOrNumber,
                    ''
                );
                if (response) {
                    this.clearlastUsedCheckinOrNumber();
                    return true;
                }
            } catch (e) {
                console.error(e);
                return false;
            }
        } else if (this.lastUsedCheckinOrNumber) {
            try {
                const response = await this.getSelfCheckinData(
                    '',
                    this.lastUsedCheckinOrNumber
                );
                if (response) {
                    this.clearlastUsedCheckinOrNumber();
                    return true;
                }
            } catch (e) {
                console.error(e);
                return false;
            }
        }
        return false;
    }

    /**
     * This async function retrieves the current user information from the server and broadcasts it to any subscribers of 'meSubject'.
     * If an error occurs during this process, the value of 'meSubject' is set to undefined.
     */
    async getMe(): Promise<void> {
        try {
            const me = await lastValueFrom(this.usersService.getMe());
            if (me) {
                this.meSubject.next(me);
            }
        } catch (error) {
            this.meSubject.next(undefined);
        }
    }

    /**
     * Clears all stored user data within the service by resetting the relevant BehaviorSubjects.
     * This method sets the 'meSubject' BehaviorSubject to undefined, effectively clearing any
     * cached user data. It's typically used to reset the state when a user logs out or when you need
     * to ensure that stale data is not presented after certain actions, like switching user accounts
     * or when reinitializing components that depend on fresh user data.
     *
     * @returns {Promise<void>} - A promise that resolves immediately, indicating that the data has been cleared.
     */
    cleanAllData(): Promise<void> {
        this.meSubject.next(undefined);
        this.tourSubject.next(undefined);
        this.lastUsedCheckinOrNumber = null;
        return Promise.resolve();
    }

    /**
     * Ensures that the organization site data is loaded into the service.
     * This method checks the current value of the 'organizationSiteSubject'.
     * If the value is undefined, indicating that the organization site data has not been loaded or has been cleared,
     * it calls 'reloadOrganizationSite' to load the organization site data from local storage.
     * This is particularly useful for maintaining session continuity, ensuring that components dependent on this data
     * can function correctly without needing to manually check and reload data.
     *
     * This method is typically called before performing operations that require access to the organization site data,
     * ensuring that the data is present and up-to-date.
     *
     * @returns {Promise<void>} - A promise that resolves after the organization site data has been reloaded (if necessary).
     * The resolution of this promise indicates that the organization site data is ready for use.
     */
    private async ensureOrganizationSiteLoaded(): Promise<void> {
        if (!this.organizationSiteSubject.getValue()) {
            await this.reloadOrganizationSite();
        }
    }

    /**
     * Determines whether the hand scanner is active for the current user's organization site.
     * This method first ensures that both the organization site data and the user data are loaded by invoking
     * `ensureOrganizationSiteLoaded` and `getMe` methods. It then uses RxJS operators to process these data streams.
     *
     * The actual determination is made by first checking if the user and organization site data are available.
     * If so, it attempts to match the organization site associated with the user to the one currently loaded in the service.
     * If a matching site is found, it checks the `selfCheckinConfig.scanner` property to determine if the hand scanner is enabled.
     *
     * The method uses a combination of `switchMap` and `map` operators to handle the asynchronous data flow,
     * and it employs error handling to ensure that any issues in the process default to returning `false`, indicating
     * the scanner is not active. This method is crucial for features that depend on hardware integration and need to
     * adapt their behavior based on the availability of specific devices.
     *
     * @returns {Promise<boolean>} A promise that resolves to a boolean value indicating whether the hand scanner is active.
     */
    async isHandScannerActive(): Promise<boolean> {
        await this.ensureOrganizationSiteLoaded();
        await this.getMe();

        return new Promise<boolean>((resolve, reject) => {
            this.me$
                .pipe(
                    switchMap(me => {
                        if (!me || !me.organizationSites) {
                            return of(null);
                        }
                        return this.organizationSite$.pipe(
                            map(site =>
                                me.organizationSites.find(
                                    s => s._key === site?._key
                                )
                            )
                        );
                    }),
                    map(site => site?.selfCheckinConfig?.scanner || false),
                    catchError(error => {
                        console.error(
                            'Error in checking hand scanner status',
                            error
                        );
                        resolve(false);
                        return of(false);
                    }),
                    take(1)
                )
                .pipe(untilDestroyed(this))
                .subscribe(isActive => {
                    resolve(isActive);
                });
        });
    }

    /**
     * This function checks if all delivery notes within the provided SelfCheckinInformation object share the same self check-in state and completion status.
     * It returns a boolean indicating whether they do or not. The comparison is based on the first delivery note's selfCheckinState and checkinComplete fields.
     * @param {SelfCheckinInformation} selfCheckinInformation - Object containing information about the self-checkin process for each delivery note.
     * @return {boolean} - Returns true if all delivery notes have the same selfCheckinState and checkinComplete, false otherwise.
     */
    checkState(selfCheckinInformation: SelfCheckinInformation): boolean {
        const deliveryNotes = selfCheckinInformation.deliveryNotes;
        if (!deliveryNotes || deliveryNotes.length === 0) {
            return true;
        }

        const firstSelfCheckinState = deliveryNotes[0].selfCheckinState;
        const firstCheckinComplete = deliveryNotes[0].checkinComplete;

        for (let i = 1; i < deliveryNotes.length; i++) {
            if (
                deliveryNotes[i].selfCheckinState !== firstSelfCheckinState ||
                deliveryNotes[i].checkinComplete !== firstCheckinComplete
            ) {
                this.toastService.error(
                    this.translateService.instant('GLOBAL.ERROR.UNSPECIFIED')
                );
                return false;
            }
        }

        return true;
    }

    /**
     * Opens a dialog with a specified headline and message.
     * Sets the close button visibility to false.
     * After the dialog is closed, navigates to the self-service page.
     * @param translationKeyheadline The translation key for the dialog headline.
     * @param translationKeyMsg The translation key for the dialog message.
     */
    openDialog(translationKeyheadline: string, translationKeyMsg: string) {
        this.utilsService.setCloseButtonVisibilty(false);
        const dialogRef = this.dialog.open(OneButtonDialogComponent, {
            minWidth: '600px',
            maxWidth: '600px',
            disableClose: true,
            data: {
                headline: translationKeyheadline,
                msg: translationKeyMsg,
                showCloseButton: false,
                headlineFontSize: 34,
                msgFontSize: 34,
                buttonFontSize: 34,
                buttonHeight: 80,
            },
        });

        const timer = setTimeout(() => {
            this.router.navigate(['/selfservice']);
        }, 10000);

        dialogRef.afterClosed().subscribe(result => {
            clearTimeout(timer);
            this.router.navigate(['/selfservice']);
        });
    }

    /**
     * Triggers a self-check-in driver waiting event.
     * Constructs the event and parameters required for the event service.
     * Calls the event service to trigger the event and handles any errors that occur.
     * @throws Will throw an error if the event triggering fails.
     */
    async triggerEvent() {
        const event: SelfCheckinDriverWaitingTriggerEvent = {
            eventType: TriggerEventType.SelfCheckinDriverWaiting,
            checkinKey: this.tourSubject.value?.deliveryNotes[0].checkinKey!,
        };

        const params = {
            organizationSiteKey: this.organizationSiteSubject.value?._key!,
            body: event,
        };

        try {
            await lastValueFrom(this.eventsService.triggerEvent(params));
        } catch (error) {
            throw error;
        }
    }
}
