<mat-dialog-content class="mat-typography">
    {{ data.message }}
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        mat-dialog-close>
        {{ 'ALERT-DIALOG.ACTION' | translate }}
    </button>
</mat-dialog-actions>
