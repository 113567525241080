import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '@app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    public closeButtonVisibilty$: BehaviorSubject<boolean>;
    public discrepancySaveButtonDisabled$: BehaviorSubject<boolean>;

    constructor(
        private toastrService: ToastService,
        private readonly injector: Injector
    ) {
        this.closeButtonVisibilty$ = new BehaviorSubject<boolean>(true);
        this.discrepancySaveButtonDisabled$ = new BehaviorSubject<boolean>(
            true
        );
    }

    /**
     * Subscription to handle visibilty of the "Close" Button of an modal dialog
     * @param status
     */
    setCloseButtonVisibilty(status: boolean): void {
        this.closeButtonVisibilty$.next(status);
    }

    /**
     * Handles the button status (enabled/disabled) of the discrepancy form
     * @param status
     */
    setDiscrepancySaveButtonDisabled(status: boolean): void {
        this.discrepancySaveButtonDisabled$.next(status);
    }

    checkMaxFilesize(filesize: number) {
        const maxFileSize = environment.maxFileSize; // 20 MB
        if (filesize > maxFileSize) {
            const translate = this.injector.get(TranslateService);
            this.toastrService.error(translate.instant('ERRORS.FILESIZE'));
            return false;
        }
        return true;
    }

    downloadBlob(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
    }
}
