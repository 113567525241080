/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getServiceProviderBillingCsv } from '../fn/service-provider/get-service-provider-billing-csv';
import { GetServiceProviderBillingCsv$Params } from '../fn/service-provider/get-service-provider-billing-csv';
import { getServiceProviderBillingCsvGroupedBySite } from '../fn/service-provider/get-service-provider-billing-csv-grouped-by-site';
import { GetServiceProviderBillingCsvGroupedBySite$Params } from '../fn/service-provider/get-service-provider-billing-csv-grouped-by-site';
import { getServiceProviderBillingCsvGroupedBySiteTimestamp } from '../fn/service-provider/get-service-provider-billing-csv-grouped-by-site-timestamp';
import { GetServiceProviderBillingCsvGroupedBySiteTimestamp$Params } from '../fn/service-provider/get-service-provider-billing-csv-grouped-by-site-timestamp';
import { getServiceProviderOrganizations } from '../fn/service-provider/get-service-provider-organizations';
import { GetServiceProviderOrganizations$Params } from '../fn/service-provider/get-service-provider-organizations';
import { Organization } from '../models/organization';
import { Organizations } from '../models/organizations';
import { patchServiceProviderOrganization } from '../fn/service-provider/patch-service-provider-organization';
import { PatchServiceProviderOrganization$Params } from '../fn/service-provider/patch-service-provider-organization';
import { postServiceProviderOrganization } from '../fn/service-provider/post-service-provider-organization';
import { PostServiceProviderOrganization$Params } from '../fn/service-provider/post-service-provider-organization';

@Injectable({ providedIn: 'root' })
export class ServiceProviderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getServiceProviderOrganizations()` */
  static readonly GetServiceProviderOrganizationsPath = '/service-provider/organizations';

  /**
   * get list of all organizations on the platform.
   *
   * Get a list of all organizations that are on the platform.
   * The quotas are returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceProviderOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderOrganizations$Response(params?: GetServiceProviderOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<Organizations>> {
    return getServiceProviderOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * get list of all organizations on the platform.
   *
   * Get a list of all organizations that are on the platform.
   * The quotas are returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceProviderOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderOrganizations(params?: GetServiceProviderOrganizations$Params, context?: HttpContext): Observable<Organizations> {
    return this.getServiceProviderOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Organizations>): Organizations => r.body)
    );
  }

  /** Path part for operation `postServiceProviderOrganization()` */
  static readonly PostServiceProviderOrganizationPath = '/service-provider/organizations';

  /**
   * Create organization.
   *
   * Create a new organization and the first admin for this organization.
   * This endpoint is for the service provider.
   * The quotas are returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postServiceProviderOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postServiceProviderOrganization$Response(params: PostServiceProviderOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<Organization>> {
    return postServiceProviderOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Create organization.
   *
   * Create a new organization and the first admin for this organization.
   * This endpoint is for the service provider.
   * The quotas are returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postServiceProviderOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postServiceProviderOrganization(params: PostServiceProviderOrganization$Params, context?: HttpContext): Observable<Organization> {
    return this.postServiceProviderOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<Organization>): Organization => r.body)
    );
  }

  /** Path part for operation `patchServiceProviderOrganization()` */
  static readonly PatchServiceProviderOrganizationPath = '/service-provider/organizations/{organizationKey}';

  /**
   * Update organization.
   *
   * Update a organization. All properties are optional.
   * The property phone numbers is replaced with the new property.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchServiceProviderOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchServiceProviderOrganization$Response(params: PatchServiceProviderOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchServiceProviderOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Update organization.
   *
   * Update a organization. All properties are optional.
   * The property phone numbers is replaced with the new property.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchServiceProviderOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchServiceProviderOrganization(params: PatchServiceProviderOrganization$Params, context?: HttpContext): Observable<void> {
    return this.patchServiceProviderOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getServiceProviderBillingCsv()` */
  static readonly GetServiceProviderBillingCsvPath = '/service-provider/billing/csv';

  /**
   * Get billing CSV.
   *
   * Get billing CSV for a specified timeframe.
   * From and to must have millisecond resolution.
   * Constrain: from <= timestamp < to
   * All times are in UTC.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceProviderBillingCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderBillingCsv$Response(params: GetServiceProviderBillingCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getServiceProviderBillingCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * Get billing CSV.
   *
   * Get billing CSV for a specified timeframe.
   * From and to must have millisecond resolution.
   * Constrain: from <= timestamp < to
   * All times are in UTC.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceProviderBillingCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderBillingCsv(params: GetServiceProviderBillingCsv$Params, context?: HttpContext): Observable<string> {
    return this.getServiceProviderBillingCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getServiceProviderBillingCsvGroupedBySiteTimestamp()` */
  static readonly GetServiceProviderBillingCsvGroupedBySiteTimestampPath = '/service-provider/billing/csv-grouped-by-site/timestamp';

  /**
   * Get billing CSV grouped by site transactions for a year and month.
   *
   * Get billing CSV for a specified timestamp grouped by organization sites.
   * All times are in UTC.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceProviderBillingCsvGroupedBySiteTimestamp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderBillingCsvGroupedBySiteTimestamp$Response(params: GetServiceProviderBillingCsvGroupedBySiteTimestamp$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getServiceProviderBillingCsvGroupedBySiteTimestamp(this.http, this.rootUrl, params, context);
  }

  /**
   * Get billing CSV grouped by site transactions for a year and month.
   *
   * Get billing CSV for a specified timestamp grouped by organization sites.
   * All times are in UTC.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceProviderBillingCsvGroupedBySiteTimestamp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderBillingCsvGroupedBySiteTimestamp(params: GetServiceProviderBillingCsvGroupedBySiteTimestamp$Params, context?: HttpContext): Observable<string> {
    return this.getServiceProviderBillingCsvGroupedBySiteTimestamp$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getServiceProviderBillingCsvGroupedBySite()` */
  static readonly GetServiceProviderBillingCsvGroupedBySitePath = '/service-provider/billing/csv-grouped-by-site/timeframe';

  /**
   * Get billing CSV grouped by site transactions.
   *
   * Get billing CSV for a specified timeframe grouped by organization sites.
   * From and to must have millisecond resolution.
   * Constrain: from <= timestamp < to
   * All times are in UTC.
   * The maximum range between from and to is 35 days.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceProviderBillingCsvGroupedBySite()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderBillingCsvGroupedBySite$Response(params: GetServiceProviderBillingCsvGroupedBySite$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getServiceProviderBillingCsvGroupedBySite(this.http, this.rootUrl, params, context);
  }

  /**
   * Get billing CSV grouped by site transactions.
   *
   * Get billing CSV for a specified timeframe grouped by organization sites.
   * From and to must have millisecond resolution.
   * Constrain: from <= timestamp < to
   * All times are in UTC.
   * The maximum range between from and to is 35 days.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServiceProviderBillingCsvGroupedBySite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceProviderBillingCsvGroupedBySite(params: GetServiceProviderBillingCsvGroupedBySite$Params, context?: HttpContext): Observable<string> {
    return this.getServiceProviderBillingCsvGroupedBySite$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
