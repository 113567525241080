import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { SelfserviceMainComponent } from './selfservice-main.component';
import { SelfserviceHeaderComponent } from './components/selfservice-header/selfservice-header.component';
import { SelfserviceLanguageSelectorComponent } from './components/selfservice-language-selector/selfservice-language-selector.component';
import { SelfserviceTimerComponent } from './components/selfservice-timer/selfservice-timer.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
    declarations: [
        SelfserviceMainComponent,
        SelfserviceHeaderComponent,
        SelfserviceLanguageSelectorComponent,
        SelfserviceTimerComponent,
    ],
    imports: [CommonModule, SharedModule, RouterModule, NgxSpinnerModule],
})
export class SelfserviceMainModule {}
