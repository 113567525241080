import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { ToastService } from '@app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginOrganizationSite, LoginResponse, UserRoles } from 'api/models';
import { RoutingEndpoints } from '../models/routing/routing-endpoints';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(
        private sessionService: SessionService,
        private storageService: StorageService,
        private router: Router,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    private getInitialOrganizationSite(
        session: LoginResponse
    ): LoginOrganizationSite | null {
        let initialOrganizationSiteKey: string = this.storageService.getItem(
            'favoriteOrganizationSiteKey'
        );

        if (!initialOrganizationSiteKey) {
            initialOrganizationSiteKey = session.organizationSites[0]?._key;
        }

        const initialOrg = session.organizationSites.find(
            _org => _org._key === initialOrganizationSiteKey
        );

        if (!initialOrg && !session.organizationSites.length) return null;
        return initialOrg ? initialOrg : session.organizationSites[0];
    }

    routeToPage(termsAccepted: boolean = false, folderId?: number): any {
        const session = this.sessionService.getSession();

        // No Organization - assume service provider
        if (!session.organizations[0]) {
            return this.router.navigate(['/admin/manage-companies']);
        }

        const initialSite = this.getInitialOrganizationSite(session);

        if (!initialSite || this.sessionService.isOrganizationAdmin) {
            // todo improve this condition with a role check
            return this.router.navigate(['/admin']);
        }

        this.sessionService.currentOrganizationSite$.next(initialSite);

        if (this.sessionService.isOnlySiteAdmin(initialSite)) {
            return this.router.navigate(['/admin']);
        }

        // Consignor
        if (
            initialSite.consignor &&
            (initialSite.roles.includes(UserRoles.Consignor) ||
                initialSite.roles.includes(UserRoles.RemoteConsignor))
        ) {
            const currentSiteRole = {
                role: 'consignor',
                siteInfo: initialSite,
            };
            this.storageService.setItem('currentSiteRole', currentSiteRole);
            this.sessionService.currentSiteRole$.next(currentSiteRole);

            if (!session.termsOfService.accepted && !termsAccepted) {
                return this.router.navigate(['/settings/tos']);
            }
            return this.router.navigate([RoutingEndpoints.consignor]);
        }

        // Consignee
        if (
            initialSite.consignee &&
            (initialSite.roles.includes(UserRoles.Consignee) ||
                initialSite.roles.includes(UserRoles.RemoteConsignee))
        ) {
            const currentSiteRole = {
                role: 'consignee',
                siteInfo: initialSite,
            };
            this.storageService.setItem('currentSiteRole', currentSiteRole);
            this.sessionService.currentSiteRole$.next(currentSiteRole);
            if (!session.termsOfService.accepted && !termsAccepted) {
                return this.router.navigate(['/settings/tos']);
            }
            return this.router.navigate([
                RoutingEndpoints.consignee,
                {
                    queryParams: { folderId },
                },
            ]);
        }

        // Carrier
        if (
            initialSite.carrier &&
            (initialSite.roles.includes(UserRoles.Carrier) ||
                initialSite.roles.includes(UserRoles.RemoteCarrier))
        ) {
            const currentSiteRole = {
                role: 'carrier',
                siteInfo: initialSite,
            };
            this.storageService.setItem('currentSiteRole', currentSiteRole);
            this.sessionService.currentSiteRole$.next(currentSiteRole);
            if (!session.termsOfService.accepted && !termsAccepted) {
                return this.router.navigate(['/settings/tos']);
            }
            return this.router.navigate([RoutingEndpoints.carrier]);
        }

        if (!initialSite.roles.length) {
            this.toastService.error(
                this.translateService.instant('AUTH.NO-ROLE')
            );
        }
    }
}
