<div class="header spacer-bottom tw-fixed tw-top-0 tw-left-0 tw-z-50 tw-w-full">
    <div class="tw-flex tw-items-center">
        <div
            class="flex-grow-0 tw-flex tw-h-full tw-items-center tw-bg-white tw-px-4"
            [ngClass]="isLoggedIn && !isExternal ? 'tw-flex' : ''">
            <a
                class="claim tw-block tw-w-[100px] tw-cursor-pointer"
                [routerLink]="['/']"></a>
        </div>

        <div
            class="tw-flex tw-w-full tw-flex-col tw-px-6 tw-gap-0"
            *ngIf="isLoggedIn && !isExternal">
            <div *ngIf="sessionService.isServiceProvider">
                <div class="tw-font-bold">
                    {{ 'ADMIN-PANEL.USERS.ROLES.SERVICEPROVIDER' | translate }}
                </div>
            </div>

            <div
                *ngIf="
                    location.path().startsWith('/admin') &&
                    sessionService.isOrganizationAdmin
                ">
                <div class="tw-font-bold tw-m-0">
                    {{ 'ADMIN-PANEL.USERS.ROLES.ORGADMIN' | translate }}
                </div>
            </div>

            <div
                *ngIf="
                    currentSiteRole && !location.path().startsWith('/admin')
                ">
                <div class="tw-font-bold tw-leading-none tw-m-0">
                    {{
                        'HEADER.ROLES.' + currentSiteRole?.role
                            | uppercase
                            | translate
                    }}
                </div>
                <!-- <div class="tw-text-xs">
                    {{ organisationName }}/{{ currentSiteRole?.siteInfo?.name }}
                </div> -->
                <div class="tw-text-sm tw-leading-snug tw-m-0">
                    {{ currentSiteRole?.siteInfo?.fullSiteName }}
                </div>
            </div>

            <div
                *ngIf="
                    sessionService.isSiteAdmin &&
                    !sessionService.isOrganizationAdmin
                ">
                <div class="tw-font-bold tw-leading-none tw-m-0">
                    {{ 'HEADER.ROLES.SITEADMIN' | translate }}
                </div>
            </div>
        </div>
        <div
            *ngIf="appConfig.languageSwitcher"
            class="language-select has-pointer md:!tw-flex"
            [matMenuTriggerFor]="languageMenu"
            [matTooltip]="'HEADER.LANGUAGE-SELECTION' | translate">
            <mat-icon fontSet="material-symbols-outlined">language</mat-icon>
            <span class="ms-1">{{ currentLanguage | translate }}</span>
        </div>
        <mat-menu #languageMenu="matMenu">
            <button
                *ngFor="let language of availableLanguages"
                mat-menu-item
                (click)="changeLanguage(language.code)">
                {{ language.label }}
            </button>
        </mat-menu>

        <div class="tw-flex">
            <app-user-profile
                *ngIf="!isExternal && userSession"
                [userSession]="userSession"
                [isExternal]="isExternal"></app-user-profile>
            <button
                class="!tw-text-white tw-whitespace-nowrap"
                *ngIf="canSelectAdminPanel()"
                mat-button
                [routerLink]="['/admin']">
                {{ 'ADMIN-PANEL.TITLE' | translate }}
            </button>
            <button
                *ngIf="isLoggedIn && !isExternal"
                class="!tw-text-white"
                mat-button
                id="logout-button"
                [matTooltip]="'HEADER.LOGOUT' | translate"
                (click)="sessionService.logout()">
                <mat-icon fontSet="material-symbols-outlined">logout</mat-icon>
            </button>
        </div>
    </div>
</div>
