import { Injectable } from '@angular/core';
import { CurrentSiteRole } from '@app/shared/models/authentication/session';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import {
    GetMeQuotasResponse,
    LoginOrganizationSite,
    LoginResponse,
    OrganizationSite,
    UserRoles,
} from 'api/models';
import { StorageService } from '../storage-service/storage.service';
import { Role } from '@app/shared/models/authentication/role';
import { AuthenticationService, UsersService } from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoutingEndpoints } from '@app/shared/models/routing/routing-endpoints';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    public currentSiteRole$: ReplaySubject<CurrentSiteRole> = new ReplaySubject(
        1
    );
    public userSession$: ReplaySubject<LoginResponse | null> =
        new ReplaySubject(1);
    private userSession: LoginResponse | null;

    public currentOrganizationSite$: ReplaySubject<LoginOrganizationSite> =
        new ReplaySubject(1);
    public organizationSite: LoginOrganizationSite;
    public currentOrganizationKey: string;

    private organizationSites: OrganizationSite[] = [];
    public quotas: GetMeQuotasResponse;

    constructor(
        private storageService: StorageService,
        private usersService: UsersService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        let currentSiteRole = this.storageService.getItem('currentSiteRole');
        if (currentSiteRole) {
            currentSiteRole = JSON.parse(currentSiteRole);
            this.currentSiteRole$.next(currentSiteRole);
        }

        this.userSession$.subscribe(session => {
            if (session) {
                this.userSession = session;
            } else this.userSession = null;
        });
    }

    public getMyOrganizationSites() {
        return this.organizationSites;
    }

    public get isServiceProvider() {
        return !this.userSession?.organizations.length;
    }

    public get isOrganizationAdmin() {
        return this.userSession?.roles.includes(UserRoles.OrganizationAdmin);
    }

    public get isSiteAdmin() {
        return this.userSession?.roles.includes(UserRoles.SiteAdmin);
    }

    public getUserRole(
        site: LoginOrganizationSite | OrganizationSite
    ):
        | UserRoles.Carrier
        | UserRoles.Consignee
        | UserRoles.Consignor
        | UserRoles.RemoteConsignor
        | UserRoles.RemoteConsignee
        | UserRoles.RemoteCarrier
        | undefined {
        if (site.consignee && site.roles?.includes(UserRoles.Consignee)) {
            return UserRoles.Consignee;
        }
        if (site.consignee && site.roles?.includes(UserRoles.RemoteConsignee)) {
            return UserRoles.RemoteConsignee;
        }
        if (site.consignor && site.roles?.includes(UserRoles.Consignor)) {
            return UserRoles.Consignor;
        }
        if (site.consignor && site.roles?.includes(UserRoles.RemoteConsignor)) {
            return UserRoles.RemoteConsignor;
        }
        if (site.carrier && site.roles?.includes(UserRoles.Carrier)) {
            return UserRoles.Carrier;
        }
        if (site.carrier && site.roles?.includes(UserRoles.RemoteCarrier)) {
            return UserRoles.RemoteCarrier;
        }
        return undefined;
    }

    public isConsignee(site: LoginOrganizationSite | OrganizationSite) {
        return (
            site.consignee &&
            (site.roles?.includes(UserRoles.Consignee) ||
                site.roles?.includes(UserRoles.RemoteConsignee))
        );
    }

    public isConsignor(site: LoginOrganizationSite | OrganizationSite) {
        return (
            site.consignor &&
            (site.roles?.includes(UserRoles.Consignor) ||
                site.roles?.includes(UserRoles.RemoteConsignor))
        );
    }

    public isCarrier(site: LoginOrganizationSite | OrganizationSite) {
        return (
            site.carrier &&
            (site.roles?.includes(UserRoles.Carrier) ||
                site.roles?.includes(UserRoles.RemoteCarrier))
        );
    }

    public async getFreshSiteInfos(siteKey: string) {
        const me = await lastValueFrom(this.usersService.getMe());
        const site = me.organizationSites.find(site => site._key === siteKey);

        if (!site) return null;

        return site;
    }

    // user is site admin but nothing else on the site
    public isOnlySiteAdmin(
        currentSite: LoginOrganizationSite | OrganizationSite
    ) {
        let isOnlySiteAdmin = this.userSession?.roles.includes(
            UserRoles.SiteAdmin
        );

        if (this.isCarrier(currentSite)) return false;
        if (this.isConsignee(currentSite)) return false;
        if (this.isConsignor(currentSite)) return false;

        return isOnlySiteAdmin;
    }

    /**
     * Removes the session from the localStorage of the browser
     */
    public deleteSession(): void {
        sessionStorage.clear();

        // Preserve 'receiveNotifications_' items
        let itemsToKeep: { [key: string]: string } = {};
        const prefix = 'receiveNotifications_';

        // preserve lng
        let lng;
        if (this.storageService.itemExists('language_code')) {
            lng = this.storageService.getItemAsObject('language_code');
        }
        // Iterate through all localStorage items
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            if (key && key.startsWith(prefix)) {
                itemsToKeep[key] = localStorage.getItem(key) as string;
            }
        }
        localStorage.clear();
        if (lng) {
            this.storageService.setItem('language_code', lng);
        }
        for (const key in itemsToKeep) {
            localStorage.setItem(key, itemsToKeep[key]);
        }

        this.userSession$.next(null);
    }

    public getSessionId() {
        const currentPath = this.router.url;

        let sessionId: string | null = null;
        if (currentPath.startsWith('/selfservice')) {
            sessionId = this.storageService.getItem('sessionIdPermanent');
        } else {
            sessionId = this.storageService.getItem('sessionId');
        }
        if (sessionId) {
            return sessionId.replaceAll('"', '');
        }

        return null;
    }

    public orgSiteTypeFlagToRole(organizationSite: LoginOrganizationSite) {
        if (organizationSite.consignor) {
            return Role.CONSIGNOR;
        } else if (organizationSite.consignee) {
            return Role.CONSIGNEE;
        } else if (organizationSite.carrier) {
            return Role.CARRIER;
        }
        return Role.CONSIGNOR;
    }

    public sessionExists(): boolean {
        return this.userSession !== null;
    }

    public async isLoggedIn() {
        try {
            await lastValueFrom(await this.usersService.getMe());
            return true;
        } catch (error) {
            return false;
        }
    }

    public getSession(): LoginResponse {
        return this.userSession as LoginResponse;
    }

    public async loadUserFromApi() {
        const sessionId = this.getSessionId();
        if (!sessionId) return;

        try {
            const user = await lastValueFrom(this.usersService.getMe());
            this.userSession$.next(user);

            if (user) {
                this.organizationSites = await lastValueFrom(
                    this.usersService.getAccessibleUserSites()
                );
            }

            if (user?.organizationSites?.length > 0) {
                const currentSiteRole =
                    this.storageService.getItem('currentSiteRole');
                const parsedSiteRole = currentSiteRole
                    ? JSON.parse(currentSiteRole)
                    : null;
                const currentSite = parsedSiteRole
                    ? user.organizationSites.find(
                          site => site._key === parsedSiteRole.siteInfo._key
                      )!
                    : user.organizationSites[0];

                this.currentSiteRole$.next(parsedSiteRole);
                this.currentOrganizationSite$.next(currentSite);
                this.organizationSite = currentSite;
                this.currentOrganizationKey = currentSite._key;
            }
        } catch (error) {}
    }

    async getQuotas(
        force: boolean = false
    ): Promise<GetMeQuotasResponse | undefined> {
        if (!force) {
            if (this.quotas) return this.quotas;
        }

        try {
            const quotas = await lastValueFrom(this.usersService.getMeQuotas());
            this.quotas = quotas;
        } catch (error) {}
        return this.quotas;
    }

    /**
     * Checks whether the authenticated user has the given role.
     * @param role
     */
    public authorizedUserHasRole(role: Role): boolean {
        const allSites = this.getSession()?.organizationSites;
        if (allSites?.length > 0) {
            const site = allSites.find(
                org => org._key === this.currentOrganizationSiteKey()
            );

            if (site?.consignor && role === Role.CONSIGNOR) {
                return true;
            }

            if (site?.consignee && role === Role.CONSIGNEE) {
                return true;
            }

            if (site?.carrier && role === Role.CARRIER) {
                return true;
            }
        }

        return false;
    }

    /**
     * change current organization sitekey
     */
    public setCurrentOrganizationSite(organizationSiteKey: string) {
        const currentOrganizationSite =
            this.getSession().organizationSites.filter(
                org => org._key === organizationSiteKey
            );

        this.currentOrganizationSite$.next(currentOrganizationSite[0]);
        this.organizationSite = currentOrganizationSite[0];
        this.currentOrganizationKey = currentOrganizationSite[0]._key;
    }

    public currentOrganizationSiteKey() {
        return this.currentOrganizationKey;
    }

    public currentOrganizationSite() {
        return this.organizationSite;
    }

    public async logout() {
        await this.spinner.show();
        try {
            await lastValueFrom(
                this.authenticationService.postLogout({ body: [] })
            );
            this.deleteSession();
        } catch (error) {}
        await this.router.navigateByUrl(RoutingEndpoints.login);
        location.reload();
    }

    public async logoutAfterPasswordChange() {
        await this.spinner.show();
        try {
            this.deleteSession();
        } catch (error) {}
        await this.router.navigateByUrl(RoutingEndpoints.login);
        location.reload();
    }
}
