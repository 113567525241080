import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordCheckComponent } from './password-check.component';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
    ],
    declarations: [PasswordCheckComponent],
    exports: [PasswordCheckComponent],
})
export class PasswordCheckModule {}
