<div class="tw-grid tw-grid-cols-12">
    <div
        class="tw-border-r-1 tw-col-span-1 tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-gray-300"></div>
    <div class="tw-col-span-11 tw-grid tw-grid-cols-11">
        <div class="tw-col-span-1"></div>
        <div
            class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2"></div>
        <div class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="bold">{{
                'OUTGOING-GOODS.DELIVERY-NOTE-LIST-VIEW.TABLE-HEADER.DELIVERY-NO'
                    | translate
            }}</span>
        </div>
        <div class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="bold">{{
                'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.ORDER-NO' | translate
            }}</span>
        </div>
        <div class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="bold">{{
                'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.ADVISED-DATE'
                    | translate
            }}</span>
        </div>
        <div class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="bold">{{
                'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.RECEIVE-DATE'
                    | translate
            }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2"
            *ngIf="isCarrier">
            <span class="bold">{{
                'SPEDITEUR.TABLE.HEADERS.PLATE-OUT' | translate
            }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2"
            *ngIf="isCarrier">
            <span class="bold">{{
                'SPEDITEUR.TABLE.HEADERS.PLATE-IN' | translate
            }}</span>
        </div>
        <div class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="bold">{{
                'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.STATE' | translate
            }}</span>
        </div>
        <div class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="bold">{{
                'OUTGOING-GOODS.DELIVERY-NOTE-LIST-VIEW.TABLE-HEADER.DISCREPANCIES'
                    | translate
            }}</span>
        </div>
        <div class="tw-col-span-1 tw-break-all tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="bold">{{
                'OUTGOING-GOODS.DELIVERY-NOTE-LIST-VIEW.TABLE-HEADER.PALLET-TICKET'
                    | translate
            }}</span>
        </div>
    </div>
</div>
<div
    class="tw-grid tw-grid-cols-12"
    *ngFor="
        let deliveryNote of deliveryNoteBundle?.deliveryNotes;
        let i = index
    ">
    <div
        class="tw-border-r-1 tw-col-span-1 tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-gray-300"></div>
    <div class="tw-col-span-11 tw-grid tw-grid-cols-11">
        <div class="tw-col-span-1 tw-grid tw-grid-rows-2 tw-pt-2">
            <div></div>
            <div
                class="tw-border-t-1 tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-solid tw-border-gray-300"></div>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-row tw-flex-wrap tw-justify-start tw-bg-gray-100 tw-pr-1 tw-pt-2">
            <div class="place tw-mb-2">
                <button
                    mat-icon-button
                    matTooltip="{{
                        'OUTGOING-GOODS.FOLDER-VIEW.HOVER-TEXT.VIEW-DELIVERY-NOTE'
                            | translate
                    }}"
                    id="view-deliveryNote-button"
                    (click)="viewDeliveryNote(deliveryNote)">
                    <mat-icon fontSet="material-symbols-outlined"
                        >visibility</mat-icon
                    >
                </button>
            </div>
            <app-download-note-button
                [organizationSiteKey]="organizationSiteKey"
                [deliveryNote]="deliveryNote"
                [fetchCompletePDF]="
                    deliveryNoteBundle?.status !== 'open' ? true : false
                "
                [orgType]="orgType"></app-download-note-button>
            <button
                *ngIf="deliveryNote.dateReceived && isConsignee && !readOnly"
                class="fine-discre-button"
                mat-icon-button
                matTooltip="{{
                    'OUTGOING-GOODS.FOLDER-VIEW.HOVER-TEXT.ADD-DELIVERY-NOTE-APPENDIX'
                        | translate
                }}"
                id="add-appendix-note-button"
                [disabled]="deliveryNote.fineDiscrepancies"
                (click)="
                    deliveryNoteKeyForFineDiscrepancyUpload = deliveryNote._key;
                    fineDiscrepancyFileInput.click()
                ">
                <mat-icon svgIcon="add-deviations"></mat-icon>
            </button>

            <button
                *ngIf="deliveryNoteBundle?.status === 'open' && !readOnly"
                mat-icon-button
                matTooltip="{{
                    'OUTGOING-GOODS.FOLDER-VIEW.HOVER-TEXT.EDIT-DELIVERY-NOTE'
                        | translate
                }}"
                id="edit-note-button"
                (click)="editDeliveryNote(deliveryNote)">
                <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
            </button>

            <button
                *ngIf="deliveryNoteBundle?.status === 'open' && !readOnly"
                mat-icon-button
                matTooltip="{{
                    'OUTGOING-GOODS.FOLDER-VIEW.HOVER-TEXT.UNBIND-DELIVERY-NOTE'
                        | translate
                }}"
                id="delete-note-button"
                (click)="deleteDeliveryNote(deliveryNote._key)">
                <mat-icon fontSet="material-symbols-outlined">cut</mat-icon>
            </button>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span [matTooltip]="deliveryNote.number || ''">{{
                deliveryNote.number
            }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span class="truncate">{{ deliveryNote.orderNumber }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span *ngIf="deliveryNote.dateAnnounced">{{
                deliveryNote.dateAnnounced
                    | date: undefined : currentLanguageCode
            }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span *ngIf="deliveryNote.dateReceived">{{
                deliveryNote.dateReceived
                    | date: undefined : currentLanguageCode
            }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2"
            *ngIf="isCarrier">
            <span>{{ deliveryNote.carrierNumberPlateIncoming }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2"
            *ngIf="isCarrier">
            <span>{{ deliveryNoteBundle?.carrierNumberPlateOutgoing }}</span>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <span *ngIf="deliveryNote.dateReceived">
                {{
                    'OUTGOING-GOODS.DELIVERY-NOTE-BUNDLE.STATUS.CLOSED'
                        | uppercase
                        | translate
                }}</span
            >
            <span *ngIf="!deliveryNote.dateReceived">
                {{
                    'OUTGOING-GOODS.DELIVERY-NOTE-BUNDLE.STATUS.' +
                        deliveryNoteBundle?.status
                        | uppercase
                        | translate
                }}</span
            >
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <div
                class="tw-flex tw-h-auto tw-flex-row tw-justify-start tw-align-middle"
                *ngIf="
                    deliveryNote.discrepancies || deliveryNote.fineDiscrepancies
                ">
                <div
                    *ngIf="deliveryNote.discrepancies"
                    class="tw-leading-6">
                    {{
                        'OUTGOING-GOODS.DELIVERY-NOTE-LIST-VIEW.ROUGH-CONTROL'
                            | translate
                    }}
                </div>
                <div
                    class="tw-mx-1 tw-leading-6"
                    *ngIf="
                        deliveryNote.discrepancies &&
                        deliveryNote.fineDiscrepancies
                    ">
                    |
                </div>
                <div
                    *ngIf="deliveryNote.fineDiscrepancies"
                    class="tw-leading-6">
                    {{
                        'OUTGOING-GOODS.DELIVERY-NOTE-LIST-VIEW.FINIT-CONTROL'
                            | translate
                    }}
                </div>
                <mat-icon
                    class="tw-ml-3"
                    svgIcon="red-error"></mat-icon>
            </div>
            <div
                *ngIf="
                    deliveryNoteBundle?.status === 'closed' &&
                    !deliveryNote.discrepancies &&
                    !deliveryNote.fineDiscrepancies
                ">
                -
            </div>
        </div>
        <div
            class="tw-col-span-1 tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-pr-5 tw-pt-2">
            <img
                *ngIf="deliveryNote.palletTickets"
                matTooltip="{{
                    'OUTGOING-GOODS.FOLDER-VIEW.HAS-PALLETTICKET' | translate
                }}"
                src="assets/images/load_carrier_icon.svg"
                alt="exists"
                width="23"
                height="23" />
        </div>
    </div>
</div>
<input
    class="d-none"
    multiple
    #fineDiscrepancyFileInput
    type="file"
    accept=".pdf"
    (change)="selectFineDiscrepancyFile($event)" />
