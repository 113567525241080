/* tslint:disable */
/* eslint-disable */
export enum UserRoles {
  Carrier = 'carrier',
  Consignee = 'consignee',
  Consignor = 'consignor',
  OrganizationAdmin = 'organization_admin',
  SiteAdmin = 'site_admin',
  RemoteConsignor = 'remote_consignor',
  RemoteConsignee = 'remote_consignee',
  RemoteCarrier = 'remote_carrier',
  SelfCheckin = 'self_checkin'
}
