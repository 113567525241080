/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SignaturePadBundlesCheckin } from '../../models/signature-pad-bundles-checkin';
import { Token64LengthType } from '../../models/token-64-length-type';

export interface ExternalGetDataForSignatureCheckin$Params {
  token: Token64LengthType;
}

export function externalGetDataForSignatureCheckin(http: HttpClient, rootUrl: string, params: ExternalGetDataForSignatureCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<SignaturePadBundlesCheckin>> {
  const rb = new RequestBuilder(rootUrl, externalGetDataForSignatureCheckin.PATH, 'get');
  if (params) {
    rb.query('token', params.token, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SignaturePadBundlesCheckin>;
    })
  );
}

externalGetDataForSignatureCheckin.PATH = '/external/signature-pads/checkin/bundle-data';
