/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConsigneeLookupDeliveryNoteResponse } from '../../models/consignee-lookup-delivery-note-response';
import { KeyArrayType } from '../../models/key-array-type';
import { KeyType } from '../../models/key-type';

export interface GetConsigneeCheckinLookup$Params {
  organizationSiteKey: KeyType;
      body: KeyArrayType
}

export function getConsigneeCheckinLookup(http: HttpClient, rootUrl: string, params: GetConsigneeCheckinLookup$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeLookupDeliveryNoteResponse>> {
  const rb = new RequestBuilder(rootUrl, getConsigneeCheckinLookup.PATH, 'post');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConsigneeLookupDeliveryNoteResponse>;
    })
  );
}

getConsigneeCheckinLookup.PATH = '/organization-sites/{organizationSiteKey}/consignee/checkins/lookup';
