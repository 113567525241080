<footer
    class="fixedFooter tw-z-10"
    *ngIf="footerVisibility">
    <div class="footer">
        <div class="navigation">
            <div class="navitems tw-flex-col tw-justify-center">
                <div
                    class="navitem"
                    *ngFor="let footerLink of selfServiceFooterLinks">
                    <a
                        href="javascript:void(0);"
                        (click)="viewLegalNote()">
                        <span class="legal">
                            {{ footerLink.translationKey | translate }}</span
                        >
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
