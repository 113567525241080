import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { RoutingEndpoints } from '@app/shared/models/routing/routing-endpoints';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard {
    constructor(
        public sessionService: SessionService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const expectedRoles = route.data.expectedRoles;
        const sessionExists = this.sessionService.sessionExists();

        let authorizedRole = false;

        if (sessionExists) {
            //authorizedRole = this.sessionService.authorizedUserHasRole(expectedRole);
            authorizedRole = this.validateRole(expectedRoles);
        }

        if (!sessionExists || !authorizedRole) {
            this.router.navigate([RoutingEndpoints.login], {
                queryParams: { folderId: route.queryParams.folderId },
            });
            return false;
        }

        return true;
    }

    validateRole(expectedRoles: string[]): boolean {
        let authorizedRole: boolean = false;
        for (const site of this.sessionService.getSession().organizationSites) {
            if (site.roles.some(r => expectedRoles.includes(r))) {
                authorizedRole = true;
                break;
            }
        }

        return authorizedRole;
    }
}
