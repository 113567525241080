<div class="unauth-footer">
    <div class="d-flex justify-content-end flex-row">
        <div class="navigation">
            <div class="navitems">
                <ng-container *ngIf="appConfig.languageSwitcher">
                    <div
                        class="navitem"
                        *ngFor="let language of availableLanguages">
                        <a
                            class="has-pointer"
                            (click)="changeLanguage(language.code)"
                            >{{ language.label }}</a
                        >
                    </div>
                </ng-container>

                <div class="navitem">
                    <a
                        href="https://www.gs1-germany.de/impressum/"
                        target="_blank"
                        >{{ 'GENERAL.IMPRINT' | translate }}</a
                    >
                </div>
                <div class="navitem">
                    <a
                        href="https://www.gs1-germany.de/datenschutz/"
                        target="_blank"
                        >{{ 'GENERAL.PRIVACY_POLICY' | translate }}</a
                    >
                </div>
                <div class="navitem">
                    <a
                        href="https://www.gs1-germany.de/service/datei-download/?tx_mwbase%5Baction%5D=processDownload&tx_mwbase%5Bfilename%5D=&tx_mwbase%5Bsource%5D=fal&tx_mwbase%5Buid%5D=93934&cHash=8dcdc7798b93539121a53ed11a2c664b"
                        target="_blank"
                        >{{ 'GENERAL.AGB' | translate }}</a
                    >
                </div>
                <div
                    class="navitem"
                    [matTooltip]="versionString"
                    matTooltipClass="tw-whitespace-pre-line tw-max-w-[320px]">
                    <span>{{ 'GENERAL.VERSION' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
