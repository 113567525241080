import { Injectable } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/heic',
        'image/heif',
    ];

    functionMap: any = {
        'image/png': 'Png',
        'image/jpeg': 'Jpeg',
        'image/jpg': 'Jpeg',
        'image/heic': 'Heic',
        'image/heif': 'Heic',
    };

    constructor(
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    isFileTypeAllowed(fileType: string, allowedTypes?: string[]): boolean {
        if ((allowedTypes || this.allowedTypes).includes(fileType)) {
            return true;
        }
        this.toastService.error(this.translateService.instant('ERRORS.IMAGE'));
        return false;
    }

    prepareFile(file: File): {
        fileBlob: Blob;
        fileName: string;
        fileType: string;
    } {
        const fileName: string = file.name;
        let fileType =
            file.type || `image/${fileName?.split('.')?.pop()?.toLowerCase()}`;
        let fileBlob = new Blob([file], { type: fileType });

        return {
            fileBlob: fileBlob,
            fileName,
            fileType: fileType!,
        };
    }
}
