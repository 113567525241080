import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    /**
     * Return the element of the localStorage with the given key.
     * @param key
     */
    getItem(key: string): any {
        return localStorage.getItem(key);
    }

    /**
     * Parses the JSON string into an object.
     * @param key
     */
    getItemAsObject(key: string): any {
        return JSON.parse(localStorage.getItem(key) as string);
    }

    /**
     * Sets an element with the given key in the localStorage.
     * @param key
     * @param value
     */
    setItem(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Removes an element with the given key from the localStorage.
     * @param key
     */
    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    /**
     * Removes all items from the localStorage
     */
    removeAllItems(): void {
        localStorage.clear();
    }

    /**
     * Return whether a item with the given key exists in the localStorage.
     * @param key
     */
    itemExists(key: string): boolean {
        return localStorage.getItem(key) != null;
    }
}
