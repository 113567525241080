/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getOrganization } from '../fn/organizations/get-organization';
import { GetOrganization$Params } from '../fn/organizations/get-organization';
import { getOrganizations } from '../fn/organizations/get-organizations';
import { GetOrganizations$Params } from '../fn/organizations/get-organizations';
import { Organization } from '../models/organization';


/**
 * Endpoints which manipulate data and grant acces to it on organization level
 */
@Injectable({ providedIn: 'root' })
export class OrganizationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrganizations()` */
  static readonly GetOrganizationsPath = '/organizations';

  /**
   * Get all permitted organizations of user.
   *
   * Get all permitted organizations for the requesting user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Response(params?: GetOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Organization>>> {
    return getOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all permitted organizations of user.
   *
   * Get all permitted organizations for the requesting user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations(params?: GetOrganizations$Params, context?: HttpContext): Observable<Array<Organization>> {
    return this.getOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Organization>>): Array<Organization> => r.body)
    );
  }

  /** Path part for operation `getOrganization()` */
  static readonly GetOrganizationPath = '/organizations/{organizationKey}';

  /**
   * Get single org by key of user.
   *
   * Get a single permitted organization of a user by organization key.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Response(params: GetOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<Organization>> {
    return getOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Get single org by key of user.
   *
   * Get a single permitted organization of a user by organization key.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization(params: GetOrganization$Params, context?: HttpContext): Observable<Organization> {
    return this.getOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<Organization>): Organization => r.body)
    );
  }

}
