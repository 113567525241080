import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    Renderer2,
} from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    AsyncValidatorFn,
    ControlValueAccessor,
    NG_ASYNC_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    ValidationErrors,
} from '@angular/forms';

import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ICustomFile extends File {
    errors?: {
        fileSize?: boolean;
        fileType?: boolean;
        fileExt?: boolean;
    };
}

@Directive({
    selector:
        // eslint-disable-next-line @angular-eslint/directive-selector
        'input[type=file][accept],input[type=file][formControlName],input[type=file][ngModel]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputAccessor),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => FileInputAccessor),
            multi: true,
        },
    ],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FileInputAccessor implements ControlValueAccessor, AsyncValidator {
    @Input() allowedTypes: RegExp | string | string[];

    @Input() size: number;

    @Input() withMeta: boolean;

    @Input() maxHeight: number;

    @Input() maxWidth: number;

    @Input() minHeight: number;

    @Input() minWidth: number;

    @Input()
    set allowedExt(value: RegExp | string | string[]) {
        if (typeof value === 'string') {
            value = value + '$';
        }
        if (value instanceof Array) {
            value = value.join('|') + '$';
        }
        this._allowedExt = value;
    }

    get allowedExt(): RegExp | string | string[] {
        return this._allowedExt;
    }

    validator: AsyncValidatorFn;

    private _allowedExt: RegExp | string | string[];

    @HostListener('change', ['$event.target.files']) onChange = (_: any) => {};
    @HostListener('blur') onTouched = () => {};

    constructor(
        private _renderer: Renderer2,
        private _elementRef: ElementRef
    ) {
        this.validator = this.generateAsyncValidator();
    }

    writeValue(value: any) {
        this._renderer.setProperty(
            this._elementRef.nativeElement,
            'value',
            null
        );
    }

    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = this.onChangeGenerator(fn);
    }

    registerOnTouched(fn: () => {}): void {}

    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(
            this._elementRef.nativeElement,
            'disabled',
            isDisabled
        );
    }

    validate(
        c: AbstractControl
    ): Observable<ValidationErrors | null> | Promise<ValidationErrors | null> {
        return this.validator(c);
    }

    private generateAsyncValidator(): any {
        return (c: UntypedFormControl): Observable<ValidationErrors> => {
            if (!c.value || !c.value.length || c.disabled) return of({});

            const errors: ValidationErrors = {};
            const loaders: ReplaySubject<ProgressEvent>[] = [];

            for (const f of c.value) {
                if (this.size && this.size < f.size) {
                    f.errors.fileSize = true;
                    errors.fileSize = true;
                }

                if (!this.allowedExt && !this.allowedTypes) continue;

                const extP = this.generateRegExp(this.allowedExt);
                const typeP = this.generateRegExp(this.allowedTypes);

                if (extP && !extP.test(f.name)) {
                    f.errors.fileExt = true;
                    errors.fileExt = true;
                }

                if (typeP && !typeP.test(f.type)) {
                    f.errors.fileType = true;
                    errors.fileType = true;
                }
            }
            if (loaders.length) {
                return forkJoin(...loaders).pipe(map(() => errors));
            }
            return of(errors);
        };
    }

    private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
        return (files: ICustomFile[]) => {
            const fileArr: File[] = [];

            for (const f of files) {
                f.errors = {};
                fileArr.push(f);
            }
            fn(fileArr);
        };
    }

    private generateRegExp(pattern: RegExp | string | string[]): RegExp | null {
        if (!pattern) return null;

        if (pattern instanceof RegExp) {
            return new RegExp(pattern);
        } else if (typeof pattern === 'string') {
            return new RegExp(pattern, 'ig');
        } else if (pattern instanceof Array) {
            return new RegExp(`(${pattern.join('|')})`, 'ig');
        }
        return null;
    }
}
