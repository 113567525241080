<div class="tw-flex tw-items-center tw-justify-between">
    <h5 class="dialog tw-margin-0">
        <mat-icon fontSet="material-symbols-outlined">{{
            data.iconClass || data.icon
        }}</mat-icon>

        {{ data.headline | translate }}
    </h5>
</div>

<mat-dialog-content class="mat-typography">
    <div class="container-fluid">
        {{ data.msg | translate: { refNumber: data.refNumber } }}
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        id="button-bottom-yes"
        tabindex="-1"
        type="button"
        mat-stroked-button
        (click)="closeWithResult(modalResult.YES)">
        {{ data.confirmText | translate }}
    </button>
    <button
        *ngIf="!data.hideCancelButton"
        id="button-bottom-no"
        tabindex="1"
        type="button"
        mat-flat-button
        color="primary"
        (click)="closeWithResult(modalResult.NO)">
        {{ data.cancelText | translate }}
    </button>
</mat-dialog-actions>
