export enum RoutingEndpoints {
    login = 'login',
    forgot_password = 'forgot-password',
    reset_user_password = 'external/reset-user-password',
    check_email = 'check-email',
    legal = 'legal',

    signature_pad = 'signature-pad',
    signature_pad_checkin_driver = 'signature-pad-checkin',

    consignor = 'consignor',
    consignor_overview = 'overview',
    consignor_new_folder = 'new-folder',
    consignor_checkout_bundle = 'checkout-bundle',
    consignor_edit_bundle = 'edit-bundle',

    consignee = 'consignee',
    consignee_overview = 'overview',
    consignee_checkin_bundle = 'checkin-bundles',
    consignee_checkin_notes = 'checkin-notes',
    checkin = 'checkin/:folderId',

    carrier = 'spediteur',
    carrier_overview = 'overview',

    spediteur = 'spediteur',
    spediteur_uebersicht = 'overview',

    external = 'external',
    wallet_invite = 'wallet-invite',
    gate = 'gate',
    gate_view = 'gate-view',

    selfservice = 'selfservice',
    selfservice_qrscan = 'qrscan',
    selfservice_handscan = 'handscan',
    selfservice_bundle = 'bundle',
    selfservice_data = 'data',
    selfservice_control = 'control',
    selfservice_pending = 'pending',
    selfservice_configuration = 'configuration',

    settings = 'settings',
    settings_profile = '',
    settings_email = 'email',
    settings_password = 'password',

    email_got_verified = 'email-success',

    admin = 'admin',
    admin_dashboard = 'dashboard',
}
