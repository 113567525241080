<div
    class="tw-fixed tw-top-0 tw-left-0 tw-flex tw-h-screen tw-w-screen tw-items-center tw-justify-center tw-bg-primaryBlue">
    <div>
        <div class="tw-text-xl tw-uppercase tw-text-white">Maintenance</div>
        <div class="tw-text-4xl tw-font-bold tw-text-white">
            {{ 'SLOGAN.TITLE' | translate }}
        </div>
        <div class="tw-text-2xl tw-text-white">
            {{ 'SLOGAN.SUBTITLE' | translate }}
        </div>

        <div class="tw-mt-5 tw-h-5">
            <img
                src="./assets/images/TSY_Slogan_Basic_3c_n.svg"
                alt="T-Systems" />
        </div>
    </div>
</div>
