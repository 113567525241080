<div class="tw-p-5">
    <h2>{{ 'QUOTAS.TITLE' | translate }}</h2>
    <div class="tw-py-5">
        <p>
            {{ 'QUOTAS.TEXT-1' | translate }}
        </p>
        <p>
            {{ 'QUOTAS.TEXT-2' | translate }}
        </p>
        <div class="tw-mb-3 tw-text-base">
            <div class="tw-rounded tw-bg-slate-200 tw-p-5">
                <table class="tw-w-full tw-table-auto">
                    <tbody>
                        <tr>
                            <td>
                                {{ 'QUOTAS.BundlesPerMonth' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{ quotas?.usedQuotas?.usedBundlesPerMonth }} /
                                {{ quotas?.maxQuotas?.maxBundlesPerMonth }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'QUOTAS.CheckinsPerMonth' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{ quotas?.usedQuotas?.usedCheckinsPerMonth }} /
                                {{ quotas?.maxQuotas?.maxCheckinsPerMonth }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'QUOTAS.CheckoutsPerMonth' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{ quotas?.usedQuotas?.usedCheckoutsPerMonth }}
                                /
                                {{ quotas?.maxQuotas?.maxCheckoutsPerMonth }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'QUOTAS.DeliveryNotesPerMonth' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{
                                    quotas?.usedQuotas
                                        ?.usedDeliveryNotesPerMonth
                                }}
                                /
                                {{
                                    quotas?.maxQuotas?.maxDeliveryNotesPerMonth
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'QUOTAS.ToursPerMonth' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{ quotas?.usedQuotas?.usedToursPerMonth }} /
                                {{ quotas?.maxQuotas?.maxToursPerMonth }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'QUOTAS.MaxAttachment' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{
                                    quotas?.maxQuotas
                                        ?.maxDeliveryNoteAttachments
                                }}
                            </td>
                        </tr>
                        <tr
                            *ngIf="
                                sessionService.isOrganizationAdmin ||
                                sessionService.isSiteAdmin
                            ">
                            <td>
                                {{ 'QUOTAS.MaxUsers' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{ quotas?.usedQuotas?.usedUsers }} /{{
                                    quotas?.maxQuotas?.maxUsers
                                }}
                            </td>
                        </tr>
                        <tr
                            *ngIf="
                                sessionService.isOrganizationAdmin ||
                                sessionService.isSiteAdmin
                            ">
                            <td>
                                {{ 'QUOTAS.MaxSites' | translate }}
                            </td>
                            <td class="tw-whitespace-nowrap tw-text-right">
                                {{ quotas?.usedQuotas?.usedOrganizationSites }}
                                /
                                {{ quotas?.maxQuotas?.maxOrganizationSites }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p>
            {{ 'QUOTAS.TEXT-4' | translate }}
        </p>
    </div>
    <div class="tw-flex tw-w-full tw-justify-end">
        <button
            mat-button
            color="primary"
            (click)="dialog.closeAll()">
            {{ 'QUOTAS.CLOSE' | translate }}
        </button>
    </div>
</div>
