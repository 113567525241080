<div
    class="landing-background tw-fixed tw-top-0 tw-left-0 tw-z-50 tw-flex tw-h-screen tw-w-screen tw-flex-col tw-overflow-hidden tw-bg-white">
    <div
        class="tw-flex tw-h-16 tw-items-center tw-justify-between tw-bg-white tw-py-2 tw-px-6">
        <div>
            <img
                width="200"
                src="assets/images/cloud4log_log.png"
                alt="cloud4log" />
        </div>

        <div class="tw-flex tw-space-x-2">
            <img
                width="60"
                src="assets/images/GS1-Germany-Logo.svg"
                alt="GS1 Germany" />
            <img
                width="60"
                src="assets/images/BVL123_RZ_Logo_rgb.png"
                alt="BVL" />
        </div>
    </div>

    <div
        class="tw-flex tw-h-20 tw-flex-col tw-items-center tw-justify-center tw-bg-primaryBlue/50 tw-text-center tw-text-3xl tw-font-semibold tw-text-white tw-shadow-xl tw-backdrop-blur">
        <div>{{ 'LANDING.HEADING-1' | translate }}</div>
        <div class="tw-text-xl tw-font-thin">
            {{ 'LANDING.HEADING-2' | translate }}
        </div>
    </div>

    <div
        class="-tw-mt-[37px] tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-y-auto md:tw-justify-start md:tw-pl-20">
        <div class="tw-flex tw-w-96 tw-flex-col">
            <div
                class="tw-flex tw-w-full tw-flex-col tw-items-center tw-bg-primaryBlue/50 tw-pt-10 tw-pb-7 tw-text-white tw-shadow-xl tw-backdrop-blur">
                <form [formGroup]="loginForm">
                    <div class="mat-field-container">
                        <mat-form-field class="tw-w-80">
                            <mat-label>{{
                                'LOGIN.USERNAME' | translate
                            }}</mat-label>
                            <mat-icon
                                color="primary"
                                matPrefix
                                fontSet="material-symbols-outlined"
                                >mail</mat-icon
                            >

                            <input
                                matInput
                                aria-label="input-username"
                                formControlName="username" />
                            <mat-error
                                *ngIf="loginForm.controls.username.invalid"
                                >{{
                                    'LOGIN.USERNAME-REQUIRED' | translate
                                }}</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="mat-field-container">
                        <mat-form-field
                            appearance="fill"
                            class="tw-w-80">
                            <mat-label>{{
                                'LOGIN.PASSWORD' | translate
                            }}</mat-label>
                            <mat-icon
                                color="primary"
                                matPrefix
                                fontSet="material-symbols-outlined"
                                >key</mat-icon
                            >
                            <input
                                [type]="hidePassword ? 'password' : 'text'"
                                matInput
                                aria-label="input-password"
                                formControlName="password" />
                            <mat-error
                                *ngIf="loginForm.controls.password.invalid"
                                >{{
                                    'LOGIN.PASSWORD-REQUIRED' | translate
                                }}</mat-error
                            >

                            <button
                                matSuffix
                                mat-icon-button
                                (click)="hidePassword = !hidePassword">
                                <mat-icon fontSet="material-symbols-outlined">
                                    {{
                                        hidePassword
                                            ? 'visibility_off'
                                            : 'visibility'
                                    }}
                                </mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <button
                        type="button"
                        mat-raised-button
                        aria-label="button-login"
                        class="tw-w-full"
                        color="primary"
                        [disabled]="loginForm.invalid || isLoading"
                        [ngClass]="{ spinner: isLoading }"
                        (click)="onSubmit()">
                        {{ 'LOGIN.LOGIN-BUTTON' | translate }}
                    </button>

                    <div class="tw-my-4 tw-text-center">
                        <a
                            class="!tw-text-white"
                            [routerLink]="['/forgot-password']"
                            routerLinkActive="router-link-active"
                            >{{ 'LOGIN.FORGOT-PASSWORD' | translate }}
                        </a>
                    </div>
                </form>
            </div>

            <div
                class="tw-flex tw-w-full tw-flex-col tw-items-center tw-bg-blue-100 tw-py-8 tw-text-primaryBlue">
                <div class="tw-px-4 tw-text-center">
                    <span class="tw-font-semibold">{{
                        'LANDING.PRODUCT-NAME' | translate
                    }}</span>
                    -
                    {{ 'LANDING.LABEL-1' | translate }}
                </div>

                <div class="tw-mt-6 tw-text-center">
                    <div class="tw-text-xl tw-font-semibold">
                        {{ 'LANDING.LABEL-2' | translate }}
                    </div>
                </div>

                <button
                    mat-flat-button
                    class="tw-my-5 tw-hidden tw-bg-primaryBlue/50 tw-px-12 tw-text-white">
                    {{ 'LANDING.REGISTER-BUTTON' | translate }}
                </button>

                <div class="tw-flex tw-flex-col tw-items-center">
                    <a
                        class="!tw-text-primaryBlue"
                        target="_blank"
                        href="http://www.cloud4log.de"
                        >{{ 'LANDING.MORE-LINK' | translate }}
                    </a>
                    <a
                        class="!tw-text-primaryBlue"
                        target="_blank"
                        href="mailto:c4l-support@t-systems.com?subject={{
                            'LANDING.SUPPORT.SUBJECT' | translate
                        }}"
                        >{{ 'LANDING.SUPPORT.TITLE' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div
        class="tw-pointer-events-none tw-absolute tw-bottom-0 tw-right-0 tw-mx-8 tw-my-14 tw-hidden tw-w-52 tw-flex-col tw-items-start md:tw-flex">
        <img
            class="-tw-ml-3 tw-h-[32px]"
            src="assets/images/TSY_Slogan_Basic_3c_n.svg"
            alt="T-Systems" />

        <div class="tw-text-white">
            {{ 'LANDING.LABEL-3' | translate }}
        </div>

        <div class="gradientbg"></div>
    </div>
    <app-unauth-footer page="login"></app-unauth-footer>
</div>
