import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    lastValueFrom,
    Observable,
    ReplaySubject,
} from 'rxjs';
import { DiscrepanciesService } from '../../../api/services/discrepancies.service';
import { ConsigneeLoadCarrierFilesService } from '../../../api/services/consignee-load-carrier-files.service';
import { PalletTicketsService } from '../../../api/services/pallet-tickets.service';
import { LoadCarriersService } from '../../../api/services/load-carriers.service';
import { GetDiscrepanciesResponse } from '../../../api/models/get-discrepancies-response';
import { GetConsigneeAttachmentsResponse } from '../../../api/models/get-consignee-attachments-response';
import { LoadCarrier } from '../../../api/models/load-carrier';
import { ReceiptsService } from '../../../api/services/receipts.service';
import { DiscrepancyImagesService } from '../../../api/services/discrepancy-images.service';
import { ConsigneeCheckinResponse } from '../../../api/models/consignee-checkin-response';
import { CheckinsService } from '../../../api/services/checkins.service';
import { SingleDeliveryNote } from '../../../api/models/single-delivery-note';
import { DiscrepancyResponse } from '../../../api/models/discrepancy-response';
import { ConsigneeAttachmentResponse } from '../../../api/models/consignee-attachment-response';

export interface EnrichedDeliveryNote extends SingleDeliveryNote {
    loadCarrier: LoadCarrier[] | undefined;
    fullDiscrepancies: DiscrepancyResponse[] | undefined;
    attachments: ConsigneeAttachmentResponse[] | undefined;
    fullPalletTickets: ConsigneeAttachmentResponse[] | undefined;
    receipts: ConsigneeAttachmentResponse[] | undefined;
    discrepancyImages: ConsigneeAttachmentResponse[] | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class CheckinService {
    /**
     * org site Key,
     * being set on main checkin page component's onInit
     */
    orgSiteKey: string = '';

    /**
     * checkin
     */
    _checkin: ConsigneeCheckinResponse;

    private checkinForComponentsSubject = new BehaviorSubject<any>(null);
    public checkinForComponents$: Observable<any> =
        this.checkinForComponentsSubject.asObservable();

    /**
     * delivery notes
     * @private
     */
    private _deliveryNotes: SingleDeliveryNote[] = [];
    deliveryNotesReplaySubject: ReplaySubject<SingleDeliveryNote[]> =
        new ReplaySubject<SingleDeliveryNote[]>(1);

    /**
     * enriched delivery notes
     */
    private _enrichedDeliveryNotes: EnrichedDeliveryNote[] = [];
    enrichedDeliveryNotesReplaySubject: ReplaySubject<EnrichedDeliveryNote[]> =
        new ReplaySubject<EnrichedDeliveryNote[]>(1);

    /**
     * discrepancy map
     * @private
     */
    private _discrepancyMap: Map<string, GetDiscrepanciesResponse> = new Map();
    discrepancyMapReplaySubject: ReplaySubject<
        Map<string, GetDiscrepanciesResponse>
    > = new ReplaySubject<Map<string, GetDiscrepanciesResponse>>(1);

    /**
     * attachment map
     * @private
     */
    private _attachmentMap: Map<string, GetConsigneeAttachmentsResponse> =
        new Map();
    attachmentMapReplaySubject: ReplaySubject<
        Map<string, GetConsigneeAttachmentsResponse>
    > = new ReplaySubject<Map<string, GetConsigneeAttachmentsResponse>>(1);

    /**
     * pallet tickets map
     * @private
     */
    private _palletTicketsMap: Map<string, GetConsigneeAttachmentsResponse> =
        new Map();
    palletTicketMapReplaySubject: ReplaySubject<
        Map<string, GetConsigneeAttachmentsResponse>
    > = new ReplaySubject<Map<string, GetConsigneeAttachmentsResponse>>(1);

    /**
     * load carriers map
     * @private
     */
    private _loadCarriersMap: Map<string, Array<LoadCarrier>> = new Map();
    loadCarriersMapReplaySubject: ReplaySubject<
        Map<string, Array<LoadCarrier>>
    > = new ReplaySubject<Map<string, Array<LoadCarrier>>>(1);

    /**
     * receipts map
     * @private
     */
    private _receiptsMap: Map<string, GetConsigneeAttachmentsResponse> =
        new Map();
    receiptsMapReplaySubject: ReplaySubject<
        Map<string, GetConsigneeAttachmentsResponse>
    > = new ReplaySubject<Map<string, GetConsigneeAttachmentsResponse>>(1);

    /**
     * discrepancy images map
     * @private
     */
    private _discrepancyImagesMap: Map<
        string,
        GetConsigneeAttachmentsResponse
    > = new Map();
    discrepancyImagesMapReplaySubject: ReplaySubject<
        Map<string, GetConsigneeAttachmentsResponse>
    > = new ReplaySubject<Map<string, GetConsigneeAttachmentsResponse>>(1);

    driverSignaturePadInvalidator: ReplaySubject<boolean> =
        new ReplaySubject<boolean>(1);

    constructor(
        private discrepanciesService: DiscrepanciesService,
        private consigneeLoadCarrierFilesService: ConsigneeLoadCarrierFilesService,
        private palletTicktsService: PalletTicketsService,
        private loadCarriersService: LoadCarriersService,
        private receiptsService: ReceiptsService,
        private discrepancyImagesService: DiscrepancyImagesService,
        private checkinsService: CheckinsService
    ) {}

    /**
     * clearing all data
     */
    clearAll() {
        this._deliveryNotes = [];
        this._enrichedDeliveryNotes = [];
        this._discrepancyMap = new Map();
        this._attachmentMap = new Map();
        this._palletTicketsMap = new Map();
        this._loadCarriersMap = new Map();
        this._receiptsMap = new Map();
        this._discrepancyImagesMap = new Map();
    }

    /**
     * Registering checkin
     */
    async fetchCheckin(checkinKey: string) {
        this._checkin = await lastValueFrom(
            this.checkinsService.getConsigneeCheckin({
                organizationSiteKey: this.orgSiteKey,
                checkinKey,
            })
        );
        if (this._checkin) {
            this.setCheckinForComponents(this._checkin);
        }
    }

    /**
     * Set checkin for components and emit the new value
     */
    setCheckinForComponents(checkin: any) {
        this.checkinForComponentsSubject.next(checkin);
    }

    initEnrichedDeliveryNotes() {
        this._enrichedDeliveryNotes = this._deliveryNotes.map(dn => {
            return {
                ...dn,
                loadCarrier: [],
                fullDiscrepancies: [],
                attachments: [],
                fullPalletTickets: [],
                receipts: [],
                discrepancyImages: [],
            };
        });
    }

    async enrichDeliveryNotesFull() {
        await this.reloadAttachments();
        await this.reloadPalletTickets();
        await this.reloadDiscrepancies();
        await this.reloadLoadCarrier();
        await this.reloadReceipts();
        await this.reloadDiscrepancyImages();

        this._enrichedDeliveryNotes = [];
        for (const deliveryNote of this._deliveryNotes) {
            const enrichedDeliveryNote: EnrichedDeliveryNote = {
                ...deliveryNote,
                fullDiscrepancies: this._discrepancyMap.get(deliveryNote._key),
                attachments: this._attachmentMap.get(deliveryNote._key),
                fullPalletTickets: this._palletTicketsMap.get(
                    deliveryNote._key
                ),
                receipts: this._receiptsMap.get(deliveryNote._key),
                discrepancyImages: this._discrepancyImagesMap.get(
                    deliveryNote._key
                ),
                loadCarrier: this._loadCarriersMap.get(deliveryNote._key),
            };
            this._enrichedDeliveryNotes.push(enrichedDeliveryNote);
        }
        this.enrichedDeliveryNotesReplaySubject.next(
            this._enrichedDeliveryNotes
        );
    }

    async updateEnrichedDeliveryNotes(
        shouldReloadAttachments: boolean,
        shouldReloadPalletTickets: boolean,
        shouldReloadDiscrepancies: boolean,
        shouldReloadLoadCarrier: boolean,
        shouldReloadReceipts: boolean,
        shouldReloadDiscrepancyImages: boolean
    ) {
        if (shouldReloadAttachments) {
            await this.reloadAttachments();
        }
        if (shouldReloadPalletTickets) {
            await this.reloadPalletTickets();
        }
        if (shouldReloadDiscrepancies) {
            await this.reloadDiscrepancies();
        }
        if (shouldReloadLoadCarrier) {
            await this.reloadLoadCarrier();
        }
        if (shouldReloadReceipts) {
            await this.reloadReceipts();
        }
        if (shouldReloadDiscrepancyImages) {
            await this.reloadDiscrepancyImages();
        }

        for (const deliveryNote of this._enrichedDeliveryNotes) {
            if (shouldReloadAttachments) {
                deliveryNote.attachments = this._attachmentMap.get(
                    deliveryNote._key
                );
            }
            if (shouldReloadPalletTickets) {
                deliveryNote.fullPalletTickets = this._palletTicketsMap.get(
                    deliveryNote._key
                );
            }
            if (shouldReloadDiscrepancies) {
                deliveryNote.fullDiscrepancies = this._discrepancyMap.get(
                    deliveryNote._key
                );
            }
            if (shouldReloadLoadCarrier) {
                deliveryNote.loadCarrier = this._loadCarriersMap.get(
                    deliveryNote._key
                );
            }
            if (shouldReloadReceipts) {
                deliveryNote.receipts = this._receiptsMap.get(
                    deliveryNote._key
                );
            }
            if (shouldReloadDiscrepancyImages) {
                deliveryNote.discrepancyImages = this._discrepancyImagesMap.get(
                    deliveryNote._key
                );
            }
        }
        this.enrichedDeliveryNotesReplaySubject.next(
            this._enrichedDeliveryNotes
        );
    }

    /**
     * load and update attachments
     */
    async reloadAttachments() {
        if (this.orgSiteKey && this._deliveryNotes.length) {
            this._attachmentMap = new Map();
            for (const deliveryNote of this._deliveryNotes) {
                const result = await lastValueFrom(
                    this.consigneeLoadCarrierFilesService.getConsigneeLoadCarrierFilesMetadata(
                        {
                            organizationSiteKey: this.orgSiteKey,
                            deliveryNoteKey: deliveryNote._key,
                        }
                    )
                );
                this._attachmentMap.set(deliveryNote._key, result);
            }
            this.attachmentMapReplaySubject.next(this._attachmentMap);
        }
    }

    async loadAttachmentsForDeliveryNote(deliveryNoteKey: string) {
        if (deliveryNoteKey) {
            const result = await lastValueFrom(
                this.consigneeLoadCarrierFilesService.getConsigneeLoadCarrierFilesMetadata(
                    {
                        organizationSiteKey: this.orgSiteKey,
                        deliveryNoteKey: deliveryNoteKey,
                    }
                )
            );
            this._attachmentMap.set(deliveryNoteKey, result);
            this.attachmentMapReplaySubject.next(this._attachmentMap);
        }
    }

    /**
     * load and update pallet tickets
     */
    async reloadPalletTickets() {
        if (this.orgSiteKey && this._deliveryNotes.length) {
            this._palletTicketsMap = new Map();
            for (const deliveryNote of this._deliveryNotes) {
                const result = await lastValueFrom(
                    this.palletTicktsService.getPalletTicketsForDeliveryNote({
                        organizationSiteKey: this.orgSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                this._palletTicketsMap.set(deliveryNote._key, result);
            }
            this.palletTicketMapReplaySubject.next(this._palletTicketsMap);
        }
    }

    async loadPalletTicketsForDeliveryNote(deliveryNoteKey: string) {
        if (deliveryNoteKey) {
            const result = await lastValueFrom(
                this.palletTicktsService.getPalletTicketsForDeliveryNote({
                    organizationSiteKey: this.orgSiteKey,
                    deliveryNoteKey: deliveryNoteKey,
                })
            );
            this._palletTicketsMap.set(deliveryNoteKey, result);
            this.palletTicketMapReplaySubject.next(this._palletTicketsMap);
        }
    }

    /**
     * load and update load carriers
     */
    async reloadLoadCarrier() {
        if (this.orgSiteKey && this._deliveryNotes.length) {
            this._loadCarriersMap = new Map();
            for (const deliveryNote of this._deliveryNotes) {
                const result = await lastValueFrom(
                    this.loadCarriersService.getConsigneeLoadCarriers({
                        organizationSiteKey: this.orgSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                this._loadCarriersMap.set(deliveryNote._key, result);
            }
            this.loadCarriersMapReplaySubject.next(this._loadCarriersMap);
        }
    }

    async loadLoadCarrierForDeliveryNote(
        deliveryNoteKey: string,
        shouldReplay: boolean
    ) {
        if (deliveryNoteKey) {
            const result = await lastValueFrom(
                this.loadCarriersService.getConsigneeLoadCarriers({
                    organizationSiteKey: this.orgSiteKey,
                    deliveryNoteKey: deliveryNoteKey,
                })
            );
            this._loadCarriersMap.set(deliveryNoteKey, result);
            if (shouldReplay)
                this.loadCarriersMapReplaySubject.next(this._loadCarriersMap);
        }
    }

    /**
     * load and update discrepancies
     */
    async reloadDiscrepancies() {
        if (this.orgSiteKey && this._deliveryNotes.length) {
            this._discrepancyMap = new Map();
            for (const deliveryNote of this._deliveryNotes) {
                const result = await lastValueFrom(
                    this.discrepanciesService.getDiscrepanciesForDelviveryNote({
                        organizationSiteKey: this.orgSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                this._discrepancyMap.set(deliveryNote._key, result);
            }
            this.discrepancyMapReplaySubject.next(this._discrepancyMap);
        }
    }

    async loadDiscrepanciesForDeliveryNote(
        deliveryNoteKey: string,
        shouldReplay: boolean
    ) {
        if (deliveryNoteKey) {
            const result = await lastValueFrom(
                this.discrepanciesService.getDiscrepanciesForDelviveryNote({
                    organizationSiteKey: this.orgSiteKey,
                    deliveryNoteKey: deliveryNoteKey,
                })
            );
            this._discrepancyMap.set(deliveryNoteKey, result);
            if (shouldReplay)
                this.discrepancyMapReplaySubject.next(this._discrepancyMap);
        }
    }

    /**
     * load and update receipts
     */
    async reloadReceipts() {
        if (this.orgSiteKey && this._deliveryNotes.length) {
            this._receiptsMap = new Map();
            for (const deliveryNote of this._deliveryNotes) {
                const result = await lastValueFrom(
                    this.receiptsService.getConsigneeReceiptsForDeliveryNote({
                        organizationSiteKey: this.orgSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                this._receiptsMap.set(deliveryNote._key, result);
            }
            this.receiptsMapReplaySubject.next(this._receiptsMap);
        }
    }

    async loadReceiptsForDeliveryNote(deliveryNoteKey: string) {
        if (deliveryNoteKey) {
            const result = await lastValueFrom(
                this.receiptsService.getConsigneeReceiptsForDeliveryNote({
                    organizationSiteKey: this.orgSiteKey,
                    deliveryNoteKey: deliveryNoteKey,
                })
            );
            this._receiptsMap.set(deliveryNoteKey, result);
            this.receiptsMapReplaySubject.next(this._receiptsMap);
        }
    }

    /**
     * load and update discrepancy images
     */
    async reloadDiscrepancyImages() {
        if (this.orgSiteKey && this._deliveryNotes.length) {
            this._discrepancyImagesMap = new Map();
            for (const deliveryNote of this._deliveryNotes) {
                const result = await lastValueFrom(
                    this.discrepancyImagesService.getConsigneeDiscrepancyImagesMetadata(
                        {
                            organizationSiteKey: this.orgSiteKey,
                            deliveryNoteKey: deliveryNote._key,
                        }
                    )
                );
                this._discrepancyImagesMap.set(deliveryNote._key, result);
            }
            this.discrepancyImagesMapReplaySubject.next(
                this._discrepancyImagesMap
            );
        }
    }

    async loadDiscrepancyImagesForDeliveryNote(deliveryNoteKey: string) {
        if (deliveryNoteKey) {
            const result = await lastValueFrom(
                this.discrepancyImagesService.getConsigneeDiscrepancyImagesMetadata(
                    {
                        organizationSiteKey: this.orgSiteKey,
                        deliveryNoteKey: deliveryNoteKey,
                    }
                )
            );
            this._discrepancyImagesMap.set(deliveryNoteKey, result);
            this.discrepancyImagesMapReplaySubject.next(
                this._discrepancyImagesMap
            );
        }
    }

    /**
     * getter setter
     */
    get deliveryNotes(): SingleDeliveryNote[] {
        return this._deliveryNotes;
    }

    set deliveryNotes(value: SingleDeliveryNote[]) {
        this._deliveryNotes = value;
        this.deliveryNotesReplaySubject.next(this._deliveryNotes);
    }

    get checkin(): ConsigneeCheckinResponse {
        return this._checkin;
    }

    get discrepancyMap(): Map<string, GetDiscrepanciesResponse> {
        return this._discrepancyMap;
    }

    get attachmentMap(): Map<string, GetConsigneeAttachmentsResponse> {
        return this._attachmentMap;
    }

    get palletTicketsMap(): Map<string, GetConsigneeAttachmentsResponse> {
        return this._palletTicketsMap;
    }

    get loadCarriersMap(): Map<string, Array<LoadCarrier>> {
        return this._loadCarriersMap;
    }

    get receiptsMap(): Map<string, GetConsigneeAttachmentsResponse> {
        return this._receiptsMap;
    }

    get discrepancyImagesMap(): Map<string, GetConsigneeAttachmentsResponse> {
        return this._discrepancyImagesMap;
    }

    get enrichedDeliveryNotes(): EnrichedDeliveryNote[] {
        return this._enrichedDeliveryNotes;
    }
}
