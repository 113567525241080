<div class="header spacer-bottom tw-fixed tw-top-0 tw-left-0 tw-z-50 tw-w-full">
    <div class="tw-flex tw-h-full tw-items-center tw-justify-between">
        <div class="tw-flex-1"></div>
        <div
            class="tw-px-6"
            style="flex: 0 0 auto">
            <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
                <div class="tw-text-center tw-font-bold">
                    {{ 'SELFSERVICE.HEADER.HEADLINE' | translate }}:
                    {{ headerName }}
                </div>
            </div>
        </div>
        <div class="tw-flex-1">
            <div class="tw-flex tw-h-full tw-items-center tw-justify-end">
                <app-selfservice-timer></app-selfservice-timer>
            </div>
        </div>
    </div>
</div>
