import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { FilterObject } from '@app/shared/models/components/filter-object';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

export interface FilterKeys {
    keyName: string;
    label: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
    providers: [DatePipe],
})
export class TableFilterComponent implements OnInit {
    @ViewChild('filterSearch') filterSearch: ElementRef<HTMLInputElement>;
    @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;

    @Input() isTours: boolean = false;
    @Input() dataSource: MatTableDataSource<any>;
    @Input() hasCheckBoxFilter: boolean = true;
    @Input() keysForFilteringObj: FilterKeys[];
    @Input() ofContext: boolean = false;
    @Input() showClosedLabel: string;

    _keysForFilteringObj: FilterKeys[];
    textFilter: string = '';
    checkboxFilter: boolean = true;
    searchDisabled: boolean = false;
    filterBy: string;
    statuses: FilterKeys[] = [
        {
            keyName: 'open',
            label: 'OUTGOING-GOODS.DELIVERY-NOTE-BUNDLE.STATUS.OPEN',
        },
        {
            keyName: 'sent',
            label: 'OUTGOING-GOODS.DELIVERY-NOTE-BUNDLE.STATUS.SENT',
        },
        {
            keyName: 'closed',
            label: 'OUTGOING-GOODS.DELIVERY-NOTE-BUNDLE.STATUS.CLOSED',
        },
    ];
    boolStatus: FilterKeys[] = [
        {
            keyName: 'true',
            label: 'TRUE',
        },
        {
            keyName: 'false',
            label: 'FALSE',
        },
    ];
    isStatus: boolean = false;
    selection = new SelectionModel<any>(true, []);

    constructor(
        private datePipe: DatePipe,
        private translate: TranslateService,
        private sessionService: SessionService
    ) {}

    ngOnInit(): void {
        this.checkboxFilter = true;
        this._keysForFilteringObj = this.keysForFilteringObj;
        this.setFilterPredicate();
        this.applyFilter();
    }

    private setFilterPredicate(): void {
        this.dataSource.filterPredicate = (
            data: any,
            filter: string
        ): boolean => {
            let matching: boolean = false;
            const filterObj: FilterObject = JSON.parse(filter);

            if (
                filterObj.checkClosed ||
                (!filterObj.checkClosed &&
                    data.status !== 'closed' &&
                    data.deliveryNoteBundleStatus !== 'closed')
            ) {
                if (!filterObj.text || filterObj.text.length === 0) {
                    matching = false;
                }

                this._keysForFilteringObj.forEach((filterkey: FilterKeys) => {
                    let dataOfRow: any = data[filterkey.keyName];

                    if (dataOfRow !== null && dataOfRow !== undefined) {
                        if (typeof dataOfRow === 'number' && dataOfRow > 0) {
                            dataOfRow = this.datePipe.transform(dataOfRow);
                        }

                        if (
                            filterkey.keyName === 'status' ||
                            filterkey.keyName === 'deliveryNoteBundleStatus'
                        ) {
                            if (dataOfRow.toString() === 'open')
                                matching =
                                    matching ||
                                    'offen'.includes(filterObj.text);
                            if (dataOfRow.toString() === 'sent')
                                matching =
                                    matching ||
                                    'versendet'.includes(filterObj.text);
                            if (dataOfRow.toString() === 'closed')
                                matching =
                                    matching ||
                                    'geschlossen'.includes(filterObj.text);
                        }

                        if (filterkey.keyName === 'palletTickets') {
                            if (dataOfRow.toString() === 'true')
                                matching =
                                    matching || 'ja'.includes(filterObj.text);
                            if (dataOfRow.toString() === 'false')
                                matching =
                                    matching || 'nein'.includes(filterObj.text);
                        }

                        if (filterkey.keyName === 'discrepancies') {
                            if (dataOfRow.toString() === 'true')
                                matching =
                                    matching || 'ja'.includes(filterObj.text);
                            if (dataOfRow.toString() === 'false')
                                matching =
                                    matching || 'nein'.includes(filterObj.text);
                        }

                        matching =
                            matching ||
                            dataOfRow
                                .toString()
                                .toLowerCase()
                                .includes(filterObj.text);
                    }
                });
            }
            return matching;
        };
    }

    onFilterChanges(filterKey: FilterKeys) {
        this.textFilter = '';
        this.closeFilterMenu();
        this._keysForFilteringObj = [filterKey];
        this.isStatus = false;
        this.applyFilter();
    }

    onStatusFilterChanges(filterKey: FilterKeys) {
        if (
            this.keysForFilteringObj.some(
                key => key.keyName === 'deliveryNoteBundleStatus'
            )
        ) {
            this._keysForFilteringObj = [
                { keyName: 'deliveryNoteBundleStatus', label: 'STATUS.STATE' },
            ];
        } else {
            this._keysForFilteringObj = [
                { keyName: 'status', label: 'STATUS.STATE' },
            ];
        }

        this.textFilter = this.translate.instant(filterKey.label);
        this.applyFilter();
        this.isStatus = true;
    }

    onPalletFilterChanges(filterKey: FilterKeys) {
        this._keysForFilteringObj = [
            {
                keyName: 'palletTickets',
                label: 'OUTGOING-GOODS.DELIVERY-NOTE-LIST-VIEW.TABLE-HEADER.PALLET-TICKET',
            },
        ];

        this.textFilter = this.translate.instant(filterKey.label);
        this.applyFilter();
    }

    onDiscrepanciesFilterChanges(filterKey: FilterKeys) {
        this._keysForFilteringObj = [
            {
                keyName: 'discrepancies',
                label: 'OUTGOING-GOODS.DELIVERY-NOTE-LIST-VIEW.TABLE-HEADER.DISCREPANCIES',
            },
        ];
        this.textFilter = this.translate.instant(filterKey.label);
        this.applyFilter();
    }

    async applyFilter(ev?: any) {
        // https://github.com/stephanrauh/ngx-extended-pdf-viewer/issues/8
        const userSession = this.sessionService.getSession();
        if (ev && ev === userSession.emailAddress) {
            this.filterSearch.nativeElement.value = '';
            this.resetFilter();
            return;
        }

        const filter: FilterObject = {
            text: this.textFilter?.toLowerCase(),
            checkClosed: this.checkboxFilter,
        };
        this.dataSource.filter = JSON.stringify(filter);
    }

    resetFilter() {
        this.filterBy = '';
        this.textFilter = '';
        this._keysForFilteringObj = this.keysForFilteringObj;
        this.applyFilter();
        this.closeFilterMenu();
    }

    closeFilterMenu() {
        this.menuTrigger.closeMenu();
    }
}
