/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BinaryFileType } from '../../models/binary-file-type';
import { ConsigneeAttachmentResponse } from '../../models/consignee-attachment-response';
import { KeyType } from '../../models/key-type';
import { NameType } from '../../models/name-type';

export interface PostConsigneeReceiptToDeliveryNote$Params {
  organizationSiteKey: KeyType;
  deliveryNoteKey: KeyType;
  fileName: NameType;
  discrepancy: boolean;
      body: BinaryFileType
}

export function postConsigneeReceiptToDeliveryNote(http: HttpClient, rootUrl: string, params: PostConsigneeReceiptToDeliveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
  const rb = new RequestBuilder(rootUrl, postConsigneeReceiptToDeliveryNote.PATH, 'post');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
    rb.path('deliveryNoteKey', params.deliveryNoteKey, {});
    rb.path('fileName', params.fileName, {});
    rb.query('discrepancy', params.discrepancy, {});
    rb.body(params.body, 'application/pdf');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConsigneeAttachmentResponse>;
    })
  );
}

postConsigneeReceiptToDeliveryNote.PATH = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/receipts/file/{fileName}';
