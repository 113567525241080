import { Component, HostListener, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@app/app.config';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { ToastService } from '@app/core/services/toast.service';
import { LoginService } from '@app/shared/services/login.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LoginRequest, LoginResponse, UserRoles } from 'api/models';
import {
    AuthenticationService,
    DiscrepanciesService,
    LoadCarrierTemplatesService,
} from 'api/services';
import { lastValueFrom } from 'rxjs';
import { TwofaVerificationDialogComponent } from '@app/core/pages/landing/twofa-verification-dialog/twofa-verification-dialog.component';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
    @HostListener('document:keydown.enter', ['$event'])
    onKeydownHandler(event: KeyboardEvent): void {
        event.stopPropagation();
        event.preventDefault();
        if (this.loginForm.valid) {
            this.onSubmit();
        }
    }

    loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
    });
    hidePassword = true;
    isLoading = false;
    hideInfo = false;
    folderId: number;

    constructor(
        private toastr: ToastService,
        private translate: TranslateService,
        private appConfig: AppConfig,
        private loginService: LoginService,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private storageService: StorageService,
        private activeRoute: ActivatedRoute,
        private sessionService: SessionService,
        private loadCarrierTemplatesService: LoadCarrierTemplatesService,
        private discrepanciesService: DiscrepanciesService
    ) {}

    ngOnInit(): void {
        this.activeRoute.queryParams
            .pipe(untilDestroyed(this))
            .subscribe(params => {
                this.folderId = params.folderId;
            });

        this.hideInfo =
            this.storageService.getItem('hideInfo') == 'true' ? true : false;

        const session = this.sessionService.getSession();
        if (session) {
            this.loginService.routeToPage(
                this.sessionService.getSession()?.termsOfService?.accepted,
                this.folderId
            );
        }
    }

    async onSubmit(): Promise<void> {
        // stop here if form is invalid
        if (this.loginForm.invalid || this.isLoading) {
            return;
        }

        const loginRequest: LoginRequest = {
            emailAddress: this.loginForm.controls
                .username!.value!.trim()
                .toLowerCase(),
            password: this.loginForm.controls.password!.value!.trim(),
        };

        this.isLoading = true;

        this.appConfig.twoFactorEnabled
            ? this.signInWithTwoFactorEnabled(loginRequest)
            : this.signIn(loginRequest);
    }

    private async finishAuthenticatingUser(session: LoginResponse) {
        this.storageService.setItem('sessionId', session.sessionId);
        await this.sessionService.loadUserFromApi();
        this.loadDiscrepancyChoices();
        this.loginService.routeToPage(
            this.sessionService.getSession()?.termsOfService?.accepted,
            this.folderId
        );
    }

    async signInWithTwoFactorEnabled(
        loginRequest: LoginRequest
    ): Promise<void> {
        try {
            await lastValueFrom(
                this.authenticationService.postLogin2FaRequest({
                    body: loginRequest,
                })
            );

            const dialogRef = this.dialog.open(
                TwofaVerificationDialogComponent,
                {
                    width: '600px',
                    data: loginRequest,
                    autoFocus: true,
                    disableClose: true,
                    enterAnimationDuration: 250,
                    exitAnimationDuration: 200,
                }
            );

            const enteredCode = await lastValueFrom(dialogRef.afterClosed());
            if (enteredCode) {
                try {
                    const response = await lastValueFrom(
                        this.authenticationService.postLogin({
                            body: {
                                ...loginRequest,
                                twoFaCode: enteredCode,
                            },
                        })
                    );

                    this.finishAuthenticatingUser(response);
                } catch (e: any) {
                    this.isLoading = false;
                    if (e.status === 403) {
                        this.toastr.error(
                            this.translate.instant('ERROR.LOGIN-TO-MANY-TRYS')
                        );
                    } else {
                        this.toastr.error(
                            this.translate.instant('ERROR.LOGIN-WRONG-CODE')
                        );
                    }
                }
            }
        } catch (e) {
            this.toastr.error(this.translate.instant('ERROR.LOGIN'));
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    async signIn(loginRequest: LoginRequest): Promise<void> {
        try {
            const response = await lastValueFrom(
                this.authenticationService.postLogin({
                    body: loginRequest,
                })
            );

            this.isLoading = false;

            if (response) this.finishAuthenticatingUser(response);
        } catch {
            this.isLoading = false;
            this.toastr.error(this.translate.instant('ERROR.LOGIN'));
        }
    }

    private saveCarrierTypes(): void {
        const organizations = this.sessionService.getSession()?.organizations;
        // const isCarrier = this.sessionService
        //   .getSession()
        //   ?.roles.includes(UserRoles.Carrier);

        const hasPermissionToRead =
            this.sessionService
                .getSession()
                ?.roles.includes(UserRoles.Consignee) ||
            this.sessionService
                .getSession()
                ?.roles.includes(UserRoles.Consignor);
        this.setCarrierTypes(organizations[0]._key, hasPermissionToRead);
    }

    private async setCarrierTypes(
        organizationKey: string,
        hasPermissionToRead: boolean
    ): Promise<void> {
        if (
            hasPermissionToRead &&
            !this.storageService.itemExists('loadCarrierTemplates')
        ) {
            const response = await lastValueFrom(
                this.loadCarrierTemplatesService.getLoadCarrierTemplates({
                    organizationKey: organizationKey,
                })
            );
            this.storageService.setItem('allCarrierTypes', response);
        }
    }

    /**
     * Loads the discrpepancy choices for the app and save them into the localStorgage.
     * @private
     */
    private async loadDiscrepancyChoices(): Promise<void> {
        if (!this.storageService.itemExists('discrepancyChoices')) {
            const response = await lastValueFrom(
                this.discrepanciesService.getDiscrepanciesChoices()
            );
            this.storageService.setItem('discrepancyChoices', response);
        }
    }

    /**
     * Gets all form controls of the login form.
     */
    get formControls(): { [p: string]: AbstractControl } {
        return this.loginForm.controls;
    }

    onToggleInfo(): void {
        this.hideInfo = !this.hideInfo;
        this.storageService.setItem('hideInfo', this.hideInfo);
    }
}
