/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { BundleGateData } from '../models/bundle-gate-data';
import { externalGetBundleWalletInviteData } from '../fn/external/external-get-bundle-wallet-invite-data';
import { ExternalGetBundleWalletInviteData$Params } from '../fn/external/external-get-bundle-wallet-invite-data';
import { externalGetDataForSignatureCheckin } from '../fn/external/external-get-data-for-signature-checkin';
import { ExternalGetDataForSignatureCheckin$Params } from '../fn/external/external-get-data-for-signature-checkin';
import { externalGetDataForSignatureCheckout } from '../fn/external/external-get-data-for-signature-checkout';
import { ExternalGetDataForSignatureCheckout$Params } from '../fn/external/external-get-data-for-signature-checkout';
import { externalGetGateData } from '../fn/external/external-get-gate-data';
import { ExternalGetGateData$Params } from '../fn/external/external-get-gate-data';
import { externalGetGateDeliveryNotePdf } from '../fn/external/external-get-gate-delivery-note-pdf';
import { ExternalGetGateDeliveryNotePdf$Params } from '../fn/external/external-get-gate-delivery-note-pdf';
import { externalGetGateDeliveryNotePdfs } from '../fn/external/external-get-gate-delivery-note-pdfs';
import { ExternalGetGateDeliveryNotePdfs$Params } from '../fn/external/external-get-gate-delivery-note-pdfs';
import { externalGetPdfForSignature } from '../fn/external/external-get-pdf-for-signature';
import { ExternalGetPdfForSignature$Params } from '../fn/external/external-get-pdf-for-signature';
import { externalGetSingleAttachmentForSignature$Heic } from '../fn/external/external-get-single-attachment-for-signature-heic';
import { ExternalGetSingleAttachmentForSignature$Heic$Params } from '../fn/external/external-get-single-attachment-for-signature-heic';
import { externalGetSingleAttachmentForSignature$Heif } from '../fn/external/external-get-single-attachment-for-signature-heif';
import { ExternalGetSingleAttachmentForSignature$Heif$Params } from '../fn/external/external-get-single-attachment-for-signature-heif';
import { externalGetSingleAttachmentForSignature$Jpeg } from '../fn/external/external-get-single-attachment-for-signature-jpeg';
import { ExternalGetSingleAttachmentForSignature$Jpeg$Params } from '../fn/external/external-get-single-attachment-for-signature-jpeg';
import { externalGetSingleAttachmentForSignature$Pdf } from '../fn/external/external-get-single-attachment-for-signature-pdf';
import { ExternalGetSingleAttachmentForSignature$Pdf$Params } from '../fn/external/external-get-single-attachment-for-signature-pdf';
import { externalGetSingleAttachmentForSignature$Png } from '../fn/external/external-get-single-attachment-for-signature-png';
import { ExternalGetSingleAttachmentForSignature$Png$Params } from '../fn/external/external-get-single-attachment-for-signature-png';
import { externalGetSinglePdfForSignature } from '../fn/external/external-get-single-pdf-for-signature';
import { ExternalGetSinglePdfForSignature$Params } from '../fn/external/external-get-single-pdf-for-signature';
import { externalGetTourGateData } from '../fn/external/external-get-tour-gate-data';
import { ExternalGetTourGateData$Params } from '../fn/external/external-get-tour-gate-data';
import { externalGetTourGateDeliveryNotePdf } from '../fn/external/external-get-tour-gate-delivery-note-pdf';
import { ExternalGetTourGateDeliveryNotePdf$Params } from '../fn/external/external-get-tour-gate-delivery-note-pdf';
import { externalGetTourGateDeliveryNotePdfs } from '../fn/external/external-get-tour-gate-delivery-note-pdfs';
import { ExternalGetTourGateDeliveryNotePdfs$Params } from '../fn/external/external-get-tour-gate-delivery-note-pdfs';
import { externalGetTourWalletInviteData } from '../fn/external/external-get-tour-wallet-invite-data';
import { ExternalGetTourWalletInviteData$Params } from '../fn/external/external-get-tour-wallet-invite-data';
import { externalPatchSignaturePad } from '../fn/external/external-patch-signature-pad';
import { ExternalPatchSignaturePad$Params } from '../fn/external/external-patch-signature-pad';
import { getExternalDeliveryNoteCompletePdf } from '../fn/external/get-external-delivery-note-complete-pdf';
import { GetExternalDeliveryNoteCompletePdf$Params } from '../fn/external/get-external-delivery-note-complete-pdf';
import { MultiBundleWalletInviteData } from '../models/multi-bundle-wallet-invite-data';
import { SignaturePadBundlesCheckin } from '../models/signature-pad-bundles-checkin';
import { SignaturePadBundlesCheckout } from '../models/signature-pad-bundles-checkout';
import { Token64LengthType } from '../models/token-64-length-type';
import { TourGateData } from '../models/tour-gate-data';
import { TourWalletInviteData } from '../models/tour-wallet-invite-data';


/**
 * Endpoints accessable through tokens instead of a login
 */
@Injectable({ providedIn: 'root' })
export class ExternalService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalPatchSignaturePad()` */
  static readonly ExternalPatchSignaturePadPath = '/external/signature-pads/{signaturePadType}';

  /**
   * Update signature via carrier.
   *
   * This endpoint updates the signature stored on a signature pad, utilizing the carrierAccessToken obtained during the pad's setup.
   * The behavior of the response varies based on the type of signature pad specified:
   * 'checkin'-Type: As soon as the checkin process is finished, it returns a 204 status.
   * 'checkout'-Type: Returns an accessToken for the checkout process.
   * It grants access to either a wallet invite page or a gate page.
   * In both scenarios, if the required conditions are not met within 15 minutes, the server will return an error response.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalPatchSignaturePad()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  externalPatchSignaturePad$Response(params: ExternalPatchSignaturePad$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken': Token64LengthType;
}>> {
    return externalPatchSignaturePad(this.http, this.rootUrl, params, context);
  }

  /**
   * Update signature via carrier.
   *
   * This endpoint updates the signature stored on a signature pad, utilizing the carrierAccessToken obtained during the pad's setup.
   * The behavior of the response varies based on the type of signature pad specified:
   * 'checkin'-Type: As soon as the checkin process is finished, it returns a 204 status.
   * 'checkout'-Type: Returns an accessToken for the checkout process.
   * It grants access to either a wallet invite page or a gate page.
   * In both scenarios, if the required conditions are not met within 15 minutes, the server will return an error response.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalPatchSignaturePad$Response()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  externalPatchSignaturePad(params: ExternalPatchSignaturePad$Params, context?: HttpContext): Observable<{
'accessToken': Token64LengthType;
}> {
    return this.externalPatchSignaturePad$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken': Token64LengthType;
}>): {
'accessToken': Token64LengthType;
} => r.body)
    );
  }

  /** Path part for operation `externalGetBundleWalletInviteData()` */
  static readonly ExternalGetBundleWalletInviteDataPath = '/external/bundle/wallet-invite';

  /**
   * Get data for wallet invite page.
   *
   * Returns all data that is necessary to build the wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetBundleWalletInviteData()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetBundleWalletInviteData$Response(params: ExternalGetBundleWalletInviteData$Params, context?: HttpContext): Observable<StrictHttpResponse<MultiBundleWalletInviteData>> {
    return externalGetBundleWalletInviteData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get data for wallet invite page.
   *
   * Returns all data that is necessary to build the wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetBundleWalletInviteData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetBundleWalletInviteData(params: ExternalGetBundleWalletInviteData$Params, context?: HttpContext): Observable<MultiBundleWalletInviteData> {
    return this.externalGetBundleWalletInviteData$Response(params, context).pipe(
      map((r: StrictHttpResponse<MultiBundleWalletInviteData>): MultiBundleWalletInviteData => r.body)
    );
  }

  /** Path part for operation `externalGetTourWalletInviteData()` */
  static readonly ExternalGetTourWalletInviteDataPath = '/external/tour/wallet-invite';

  /**
   * Get tour data for wallet invite page.
   *
   * Returns all data that is necessary to build the tour wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourWalletInviteData()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourWalletInviteData$Response(params: ExternalGetTourWalletInviteData$Params, context?: HttpContext): Observable<StrictHttpResponse<TourWalletInviteData>> {
    return externalGetTourWalletInviteData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tour data for wallet invite page.
   *
   * Returns all data that is necessary to build the tour wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourWalletInviteData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourWalletInviteData(params: ExternalGetTourWalletInviteData$Params, context?: HttpContext): Observable<TourWalletInviteData> {
    return this.externalGetTourWalletInviteData$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourWalletInviteData>): TourWalletInviteData => r.body)
    );
  }

  /** Path part for operation `externalGetGateData()` */
  static readonly ExternalGetGateDataPath = '/external/gate';

  /**
   * Get data for gate page.
   *
   * Returns all data that is necessary to build the gate page.
   * The token is the accessToken from a wallet.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetGateData()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateData$Response(params: ExternalGetGateData$Params, context?: HttpContext): Observable<StrictHttpResponse<BundleGateData>> {
    return externalGetGateData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get data for gate page.
   *
   * Returns all data that is necessary to build the gate page.
   * The token is the accessToken from a wallet.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetGateData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateData(params: ExternalGetGateData$Params, context?: HttpContext): Observable<BundleGateData> {
    return this.externalGetGateData$Response(params, context).pipe(
      map((r: StrictHttpResponse<BundleGateData>): BundleGateData => r.body)
    );
  }

  /** Path part for operation `externalGetTourGateData()` */
  static readonly ExternalGetTourGateDataPath = '/external/tour/gate';

  /**
   * Get data for gate page.
   *
   * Returns all data that is necessary to build the tour gate page.
   * The token is the accessToken from a wallet.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourGateData()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateData$Response(params: ExternalGetTourGateData$Params, context?: HttpContext): Observable<StrictHttpResponse<TourGateData>> {
    return externalGetTourGateData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get data for gate page.
   *
   * Returns all data that is necessary to build the tour gate page.
   * The token is the accessToken from a wallet.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourGateData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateData(params: ExternalGetTourGateData$Params, context?: HttpContext): Observable<TourGateData> {
    return this.externalGetTourGateData$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourGateData>): TourGateData => r.body)
    );
  }

  /** Path part for operation `externalGetDataForSignatureCheckout()` */
  static readonly ExternalGetDataForSignatureCheckoutPath = '/external/signature-pads/checkout/bundle-data';

  /**
   * Get bundle checkout data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkout. The token is the carrierAccessToken.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetDataForSignatureCheckout()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckout$Response(params: ExternalGetDataForSignatureCheckout$Params, context?: HttpContext): Observable<StrictHttpResponse<SignaturePadBundlesCheckout>> {
    return externalGetDataForSignatureCheckout(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle checkout data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkout. The token is the carrierAccessToken.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetDataForSignatureCheckout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckout(params: ExternalGetDataForSignatureCheckout$Params, context?: HttpContext): Observable<SignaturePadBundlesCheckout> {
    return this.externalGetDataForSignatureCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignaturePadBundlesCheckout>): SignaturePadBundlesCheckout => r.body)
    );
  }

  /** Path part for operation `externalGetDataForSignatureCheckin()` */
  static readonly ExternalGetDataForSignatureCheckinPath = '/external/signature-pads/checkin/bundle-data';

  /**
   * Get bundle checkin data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkin. The token is the carrierAccessToken.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetDataForSignatureCheckin()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckin$Response(params: ExternalGetDataForSignatureCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<SignaturePadBundlesCheckin>> {
    return externalGetDataForSignatureCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle checkin data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkin. The token is the carrierAccessToken.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetDataForSignatureCheckin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckin(params: ExternalGetDataForSignatureCheckin$Params, context?: HttpContext): Observable<SignaturePadBundlesCheckin> {
    return this.externalGetDataForSignatureCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignaturePadBundlesCheckin>): SignaturePadBundlesCheckin => r.body)
    );
  }

  /** Path part for operation `externalGetPdfForSignature()` */
  static readonly ExternalGetPdfForSignaturePath = '/external/signature-pads/{signaturePadType}/bundle-data/pdf';

  /**
   * Get all delivery note PDFs as one pdf for signature pad.
   *
   * Return PDF with all delivery note pdfs from the checkout
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetPdfForSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetPdfForSignature$Response(params: ExternalGetPdfForSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetPdfForSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all delivery note PDFs as one pdf for signature pad.
   *
   * Return PDF with all delivery note pdfs from the checkout
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetPdfForSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetPdfForSignature(params: ExternalGetPdfForSignature$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetPdfForSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetSinglePdfForSignature()` */
  static readonly ExternalGetSinglePdfForSignaturePath = '/external/signature-pads/checkin/delivery-note/{deliveryNoteKey}/pdf';

  /**
   * Get a delivery note PDF by deliveryNoteKey.
   *
   * Return a PDF for a deliveryNoteKey for the checkin with carrier access key
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSinglePdfForSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSinglePdfForSignature$Response(params: ExternalGetSinglePdfForSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSinglePdfForSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a delivery note PDF by deliveryNoteKey.
   *
   * Return a PDF for a deliveryNoteKey for the checkin with carrier access key
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSinglePdfForSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSinglePdfForSignature(params: ExternalGetSinglePdfForSignature$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSinglePdfForSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetSingleAttachmentForSignature()` */
  static readonly ExternalGetSingleAttachmentForSignaturePath = '/external/signature-pads/checkin/delivery-note/{deliveryNoteKey}/attachments/{attachmentKey}';

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Pdf$Response(params: ExternalGetSingleAttachmentForSignature$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Pdf(params: ExternalGetSingleAttachmentForSignature$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heic$Response(params: ExternalGetSingleAttachmentForSignature$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heic(params: ExternalGetSingleAttachmentForSignature$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heif$Response(params: ExternalGetSingleAttachmentForSignature$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heif(params: ExternalGetSingleAttachmentForSignature$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Jpeg$Response(params: ExternalGetSingleAttachmentForSignature$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Jpeg(params: ExternalGetSingleAttachmentForSignature$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Png$Response(params: ExternalGetSingleAttachmentForSignature$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Png(params: ExternalGetSingleAttachmentForSignature$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetGateDeliveryNotePdf()` */
  static readonly ExternalGetGateDeliveryNotePdfPath = '/external/gate/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF for gate page.
   *
   * Get a single delivery note PDF from a gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetGateDeliveryNotePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdf$Response(params: ExternalGetGateDeliveryNotePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetGateDeliveryNotePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF for gate page.
   *
   * Get a single delivery note PDF from a gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetGateDeliveryNotePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdf(params: ExternalGetGateDeliveryNotePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetGateDeliveryNotePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetTourGateDeliveryNotePdf()` */
  static readonly ExternalGetTourGateDeliveryNotePdfPath = '/external/tour/gate/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF for tour gate page.
   *
   * Get a single delivery note PDF from a tour gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourGateDeliveryNotePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdf$Response(params: ExternalGetTourGateDeliveryNotePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetTourGateDeliveryNotePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF for tour gate page.
   *
   * Get a single delivery note PDF from a tour gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourGateDeliveryNotePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdf(params: ExternalGetTourGateDeliveryNotePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetTourGateDeliveryNotePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetGateDeliveryNotePdfs()` */
  static readonly ExternalGetGateDeliveryNotePdfsPath = '/external/gate/delivery-note-pdfs';

  /**
   * Get all delivery note PDFs as one PDF.
   *
   * Get all delivery note PDF's from a gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetGateDeliveryNotePdfs()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdfs$Response(params: ExternalGetGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetGateDeliveryNotePdfs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all delivery note PDFs as one PDF.
   *
   * Get all delivery note PDF's from a gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetGateDeliveryNotePdfs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdfs(params: ExternalGetGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetGateDeliveryNotePdfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetTourGateDeliveryNotePdfs()` */
  static readonly ExternalGetTourGateDeliveryNotePdfsPath = '/external/tour/gate/delivery-note-pdfs';

  /**
   * Get all delivery note PDFs as one PDF for a tour gate.
   *
   * Get all delivery note PDF's from a tour gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourGateDeliveryNotePdfs()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdfs$Response(params: ExternalGetTourGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetTourGateDeliveryNotePdfs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all delivery note PDFs as one PDF for a tour gate.
   *
   * Get all delivery note PDF's from a tour gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourGateDeliveryNotePdfs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdfs(params: ExternalGetTourGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetTourGateDeliveryNotePdfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getExternalDeliveryNoteCompletePdf()` */
  static readonly GetExternalDeliveryNoteCompletePdfPath = '/external/gate/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete sanitized pdf with checkout and checkin data.
   *
   * Get a complete sanitized pdf with checkout and checkin data: the delivery note,
   * all attachments and all images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalDeliveryNoteCompletePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDeliveryNoteCompletePdf$Response(params: GetExternalDeliveryNoteCompletePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getExternalDeliveryNoteCompletePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete sanitized pdf with checkout and checkin data.
   *
   * Get a complete sanitized pdf with checkout and checkin data: the delivery note,
   * all attachments and all images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalDeliveryNoteCompletePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDeliveryNoteCompletePdf(params: GetExternalDeliveryNoteCompletePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getExternalDeliveryNoteCompletePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
