<div class="split-view-wrapper">
    <app-left-splitview></app-left-splitview>
    <div class="right-col d-flex justify-content-center">
        <div class="input-container">
            <h2>{{ 'FORGOT-PASSWORD.CHECK-MAIL.TITLE' | translate }}</h2>
            <h3>{{ 'FORGOT-PASSWORD.CHECK-MAIL.SUBTITLE' | translate }}</h3>
            <div class="d-flex justify-content-center mt-4 mb-3">
                <img
                    width="60%"
                    src="./assets/images/check-mail.png"
                    alt="check emails" />
            </div>
            <div class="text-center">
                <h3 class="text-grey">
                    {{ 'FORGOT-PASSWORD.CHECK-MAIL.MAIl-FAILED' | translate }}
                    <a
                        class="service-link"
                        [routerLink]="['/forgot-password']"
                        routerLinkActive="router-link-active"
                        >{{
                            'FORGOT-PASSWORD.CHECK-MAIL.OTHER-EMAIL' | translate
                        }}</a
                    >
                </h3>
            </div>
            <div class="text-center -tw-mb-[80px]">
                <div class="mt-5 mb-4">
                    <a
                        class="service-link"
                        [routerLink]="['/login']"
                        routerLinkActive="router-link-active"
                        >{{ 'LOGIN.LOGIN-BUTTON' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <app-unauth-footer page="forgot-password"></app-unauth-footer>
</div>
