<span *ngIf="userSession && !isExternal">
    <button
        class="!tw-text-white"
        aria-label="user-profile"
        mat-button
        [matMenuTriggerFor]="usermenu"
        #userMenuTrigger="matMenuTrigger"
        [matTooltip]="getRoleTooltipContent()"
        matTooltipClass="linebreak-tooltip">
        <mat-icon fontSet="material-symbols-outlined">person</mat-icon>
        <span class="tw-hidden md:tw-inline-flex tw-whitespace-nowrap">{{
            userSession.fullName
        }}</span>
    </button>
</span>
<mat-menu #usermenu="matMenu">
    <button
        aria-label="role-switch"
        mat-menu-item
        [matMenuTriggerFor]="siteMenu"
        *ngIf="showRolesMenu">
        {{ 'USER-PROFILE.ROLE-SWITCH' | translate }}
    </button>
    <button
        mat-menu-item
        (click)="openSettings()">
        {{ 'USER-PROFILE.SETTINGS' | translate }}
    </button>
    <button
        *ngIf="hideRating"
        mat-menu-item
        (click)="onSetRatingDialog('false')">
        {{ 'USER-PROFILE.FEEDBACK-ON' | translate }}
    </button>
    <button
        *ngIf="!hideRating"
        mat-menu-item
        (click)="onSetRatingDialog('true')">
        {{ 'USER-PROFILE.FEEDBACK-OFF' | translate }}
    </button>
</mat-menu>

<mat-menu #siteMenu="matMenu">
    <div
        *ngIf="userSession"
        class="tw-flex tw-flex-col">
        <div
            mat-menu-item
            *ngFor="let site of sitesInfo"
            [matTooltip]="
                (site._key === currentSite._key ? 'HEADER.CURRENT_SITE' : '')
                    | translate
            "
            (click)="$event.stopPropagation()"
            matTooltipPosition="below"
            class="tw-cursor-default"
            [ngClass]="site._key === currentSite._key ? 'active' : ''">
            <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
                <div class="tw-flex tw-text-sm">
                    <span class="tw-mx-2">{{ site.fullSiteName }}</span
                    ><span
                        *ngIf="
                            sessionService.getUserRole(site)?.includes('remote')
                        ">
                        ({{ 'USER-PROFILE.READ-ONLY' | translate }})</span
                    >

                    <span
                        *ngIf="sessionService.isOnlySiteAdmin(site)"
                        class="tw-mx-2 tw-text-xs tw-text-gray-500">
                        {{ 'HEADER.ROLES.SITEADMIN' | translate }}
                    </span>
                </div>

                <div class="tw-grid tw-grid-cols-3">
                    <span class="tw-min-w-[40px] tw-col-span-1">
                        <button
                            aria-label="consignor-role-switch"
                            mat-icon-button
                            (click)="
                                onChangeRole('consignor', site);
                                userMenuTrigger.closeMenu()
                            "
                            [matTooltip]="'HEADER.ROLES.CONSIGNOR' | translate"
                            matTooltipPosition="below"
                            *ngIf="
                                sessionService.isConsignor(site) &&
                                !sessionService.isOnlySiteAdmin(site)
                            ">
                            <img
                                src="./assets/images/direction.svg"
                                width="20"
                                [alt]="
                                    'HEADER.ROLES.CONSIGNOR' | translate
                                " /></button
                    ></span>
                    <span class="tw-min-w-[40px] tw-col-span-1">
                        <button
                            mat-icon-button
                            (click)="
                                onChangeRole('carrier', site);
                                userMenuTrigger.closeMenu()
                            "
                            [matTooltip]="'HEADER.ROLES.CARRIER' | translate"
                            matTooltipPosition="below"
                            *ngIf="
                                sessionService.isCarrier(site) &&
                                !sessionService.isOnlySiteAdmin(site)
                            ">
                            <img
                                src="./assets/images/truck.svg"
                                width="20"
                                [alt]="
                                    'HEADER.ROLES.CARRIER' | translate
                                " /></button
                    ></span>
                    <span class="tw-min-w-[40px] tw-col-span-1">
                        <button
                            aria-label="consignee-role-switch"
                            mat-icon-button
                            (click)="
                                onChangeRole('consignee', site);
                                userMenuTrigger.closeMenu()
                            "
                            [matTooltip]="'HEADER.ROLES.CONSIGNEE' | translate"
                            matTooltipPosition="below"
                            *ngIf="
                                sessionService.isConsignee(site) &&
                                !sessionService.isOnlySiteAdmin(site)
                            ">
                            <img
                                src="./assets/images/folder_checked.svg"
                                width="20"
                                [alt]="
                                    'HEADER.ROLES.CONSIGNEE' | translate
                                " /></button
                    ></span>
                </div>
            </div>
        </div>
    </div>
</mat-menu>
