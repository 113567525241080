/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { CreateSignaturePadResponse } from '../models/create-signature-pad-response';
import { externalGetDataForSignatureCheckin } from '../fn/external/external-get-data-for-signature-checkin';
import { ExternalGetDataForSignatureCheckin$Params } from '../fn/external/external-get-data-for-signature-checkin';
import { externalGetDataForSignatureCheckout } from '../fn/external/external-get-data-for-signature-checkout';
import { ExternalGetDataForSignatureCheckout$Params } from '../fn/external/external-get-data-for-signature-checkout';
import { externalGetPdfForSignature } from '../fn/external/external-get-pdf-for-signature';
import { ExternalGetPdfForSignature$Params } from '../fn/external/external-get-pdf-for-signature';
import { externalGetSingleAttachmentForSignature$Heic } from '../fn/external/external-get-single-attachment-for-signature-heic';
import { ExternalGetSingleAttachmentForSignature$Heic$Params } from '../fn/external/external-get-single-attachment-for-signature-heic';
import { externalGetSingleAttachmentForSignature$Heif } from '../fn/external/external-get-single-attachment-for-signature-heif';
import { ExternalGetSingleAttachmentForSignature$Heif$Params } from '../fn/external/external-get-single-attachment-for-signature-heif';
import { externalGetSingleAttachmentForSignature$Jpeg } from '../fn/external/external-get-single-attachment-for-signature-jpeg';
import { ExternalGetSingleAttachmentForSignature$Jpeg$Params } from '../fn/external/external-get-single-attachment-for-signature-jpeg';
import { externalGetSingleAttachmentForSignature$Pdf } from '../fn/external/external-get-single-attachment-for-signature-pdf';
import { ExternalGetSingleAttachmentForSignature$Pdf$Params } from '../fn/external/external-get-single-attachment-for-signature-pdf';
import { externalGetSingleAttachmentForSignature$Png } from '../fn/external/external-get-single-attachment-for-signature-png';
import { ExternalGetSingleAttachmentForSignature$Png$Params } from '../fn/external/external-get-single-attachment-for-signature-png';
import { externalGetSinglePdfForSignature } from '../fn/external/external-get-single-pdf-for-signature';
import { ExternalGetSinglePdfForSignature$Params } from '../fn/external/external-get-single-pdf-for-signature';
import { externalPatchSignaturePad } from '../fn/external/external-patch-signature-pad';
import { ExternalPatchSignaturePad$Params } from '../fn/external/external-patch-signature-pad';
import { getConsigneeSignatureFromSignaturePad } from '../fn/signature-pads/get-consignee-signature-from-signature-pad';
import { GetConsigneeSignatureFromSignaturePad$Params } from '../fn/signature-pads/get-consignee-signature-from-signature-pad';
import { getConsignorSignatureFromSignaturePad } from '../fn/signature-pads/get-consignor-signature-from-signature-pad';
import { GetConsignorSignatureFromSignaturePad$Params } from '../fn/signature-pads/get-consignor-signature-from-signature-pad';
import { postConsigneeSignaturePad } from '../fn/signature-pads/post-consignee-signature-pad';
import { PostConsigneeSignaturePad$Params } from '../fn/signature-pads/post-consignee-signature-pad';
import { postConsignorSignaturePad } from '../fn/signature-pads/post-consignor-signature-pad';
import { PostConsignorSignaturePad$Params } from '../fn/signature-pads/post-consignor-signature-pad';
import { SignaturePadBundlesCheckin } from '../models/signature-pad-bundles-checkin';
import { SignaturePadBundlesCheckout } from '../models/signature-pad-bundles-checkout';
import { Token64LengthType } from '../models/token-64-length-type';


/**
 * Endpoints to post and access signature pads
 */
@Injectable({ providedIn: 'root' })
export class SignaturePadsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postConsignorSignaturePad()` */
  static readonly PostConsignorSignaturePadPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/signature-pads/checkout';

  /**
   * Create signature pad for checkout.
   *
   * Create new signature pad for checkout.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsignorSignaturePad()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConsignorSignaturePad$Response(params: PostConsignorSignaturePad$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateSignaturePadResponse>> {
    return postConsignorSignaturePad(this.http, this.rootUrl, params, context);
  }

  /**
   * Create signature pad for checkout.
   *
   * Create new signature pad for checkout.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsignorSignaturePad$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConsignorSignaturePad(params: PostConsignorSignaturePad$Params, context?: HttpContext): Observable<CreateSignaturePadResponse> {
    return this.postConsignorSignaturePad$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateSignaturePadResponse>): CreateSignaturePadResponse => r.body)
    );
  }

  /** Path part for operation `postConsigneeSignaturePad()` */
  static readonly PostConsigneeSignaturePadPath = '/organization-sites/{organizationSiteKey}/consignee/checkins/{checkinKey}/signature-pads';

  /**
   * Create signature pad for checkin.
   *
   * Create new signature pad for checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeSignaturePad()` instead.
   *
   * This method doesn't expect any request body.
   */
  postConsigneeSignaturePad$Response(params: PostConsigneeSignaturePad$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateSignaturePadResponse>> {
    return postConsigneeSignaturePad(this.http, this.rootUrl, params, context);
  }

  /**
   * Create signature pad for checkin.
   *
   * Create new signature pad for checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeSignaturePad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postConsigneeSignaturePad(params: PostConsigneeSignaturePad$Params, context?: HttpContext): Observable<CreateSignaturePadResponse> {
    return this.postConsigneeSignaturePad$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateSignaturePadResponse>): CreateSignaturePadResponse => r.body)
    );
  }

  /** Path part for operation `getConsignorSignatureFromSignaturePad()` */
  static readonly GetConsignorSignatureFromSignaturePadPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/signature-pads/checkout/signature';

  /**
   * Get signature pad for checkout.
   *
   * Get a signature from a signature pad for checkout.
   * The token is the getToken from the signature pad creation request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsignorSignatureFromSignaturePad()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorSignatureFromSignaturePad$Response(params: GetConsignorSignatureFromSignaturePad$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsignorSignatureFromSignaturePad(this.http, this.rootUrl, params, context);
  }

  /**
   * Get signature pad for checkout.
   *
   * Get a signature from a signature pad for checkout.
   * The token is the getToken from the signature pad creation request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsignorSignatureFromSignaturePad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorSignatureFromSignaturePad(params: GetConsignorSignatureFromSignaturePad$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsignorSignatureFromSignaturePad$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeSignatureFromSignaturePad()` */
  static readonly GetConsigneeSignatureFromSignaturePadPath = '/organization-sites/{organizationSiteKey}/consignee/checkins/{checkinKey}/signature-pads/signature';

  /**
   * Get signature pad for checkin.
   *
   * Get a signature from a signature pad for checkin.
   * The token is the getToken from the signature pad creation request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeSignatureFromSignaturePad()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSignatureFromSignaturePad$Response(params: GetConsigneeSignatureFromSignaturePad$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeSignatureFromSignaturePad(this.http, this.rootUrl, params, context);
  }

  /**
   * Get signature pad for checkin.
   *
   * Get a signature from a signature pad for checkin.
   * The token is the getToken from the signature pad creation request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeSignatureFromSignaturePad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSignatureFromSignaturePad(params: GetConsigneeSignatureFromSignaturePad$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeSignatureFromSignaturePad$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalPatchSignaturePad()` */
  static readonly ExternalPatchSignaturePadPath = '/external/signature-pads/{signaturePadType}';

  /**
   * Update signature via carrier.
   *
   * This endpoint updates the signature stored on a signature pad, utilizing the carrierAccessToken obtained during the pad's setup.
   * The behavior of the response varies based on the type of signature pad specified:
   * 'checkin'-Type: As soon as the checkin process is finished, it returns a 204 status.
   * 'checkout'-Type: Returns an accessToken for the checkout process.
   * It grants access to either a wallet invite page or a gate page.
   * In both scenarios, if the required conditions are not met within 15 minutes, the server will return an error response.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalPatchSignaturePad()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  externalPatchSignaturePad$Response(params: ExternalPatchSignaturePad$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken': Token64LengthType;
}>> {
    return externalPatchSignaturePad(this.http, this.rootUrl, params, context);
  }

  /**
   * Update signature via carrier.
   *
   * This endpoint updates the signature stored on a signature pad, utilizing the carrierAccessToken obtained during the pad's setup.
   * The behavior of the response varies based on the type of signature pad specified:
   * 'checkin'-Type: As soon as the checkin process is finished, it returns a 204 status.
   * 'checkout'-Type: Returns an accessToken for the checkout process.
   * It grants access to either a wallet invite page or a gate page.
   * In both scenarios, if the required conditions are not met within 15 minutes, the server will return an error response.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalPatchSignaturePad$Response()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  externalPatchSignaturePad(params: ExternalPatchSignaturePad$Params, context?: HttpContext): Observable<{
'accessToken': Token64LengthType;
}> {
    return this.externalPatchSignaturePad$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken': Token64LengthType;
}>): {
'accessToken': Token64LengthType;
} => r.body)
    );
  }

  /** Path part for operation `externalGetDataForSignatureCheckout()` */
  static readonly ExternalGetDataForSignatureCheckoutPath = '/external/signature-pads/checkout/bundle-data';

  /**
   * Get bundle checkout data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkout. The token is the carrierAccessToken.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetDataForSignatureCheckout()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckout$Response(params: ExternalGetDataForSignatureCheckout$Params, context?: HttpContext): Observable<StrictHttpResponse<SignaturePadBundlesCheckout>> {
    return externalGetDataForSignatureCheckout(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle checkout data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkout. The token is the carrierAccessToken.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetDataForSignatureCheckout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckout(params: ExternalGetDataForSignatureCheckout$Params, context?: HttpContext): Observable<SignaturePadBundlesCheckout> {
    return this.externalGetDataForSignatureCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignaturePadBundlesCheckout>): SignaturePadBundlesCheckout => r.body)
    );
  }

  /** Path part for operation `externalGetDataForSignatureCheckin()` */
  static readonly ExternalGetDataForSignatureCheckinPath = '/external/signature-pads/checkin/bundle-data';

  /**
   * Get bundle checkin data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkin. The token is the carrierAccessToken.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetDataForSignatureCheckin()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckin$Response(params: ExternalGetDataForSignatureCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<SignaturePadBundlesCheckin>> {
    return externalGetDataForSignatureCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle checkin data for external carrier signature.
   *
   * Return all bundle data that is necessary for the carrier to sign
   * the checkin. The token is the carrierAccessToken.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetDataForSignatureCheckin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetDataForSignatureCheckin(params: ExternalGetDataForSignatureCheckin$Params, context?: HttpContext): Observable<SignaturePadBundlesCheckin> {
    return this.externalGetDataForSignatureCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignaturePadBundlesCheckin>): SignaturePadBundlesCheckin => r.body)
    );
  }

  /** Path part for operation `externalGetPdfForSignature()` */
  static readonly ExternalGetPdfForSignaturePath = '/external/signature-pads/{signaturePadType}/bundle-data/pdf';

  /**
   * Get all delivery note PDFs as one pdf for signature pad.
   *
   * Return PDF with all delivery note pdfs from the checkout
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetPdfForSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetPdfForSignature$Response(params: ExternalGetPdfForSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetPdfForSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all delivery note PDFs as one pdf for signature pad.
   *
   * Return PDF with all delivery note pdfs from the checkout
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetPdfForSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetPdfForSignature(params: ExternalGetPdfForSignature$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetPdfForSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetSinglePdfForSignature()` */
  static readonly ExternalGetSinglePdfForSignaturePath = '/external/signature-pads/checkin/delivery-note/{deliveryNoteKey}/pdf';

  /**
   * Get a delivery note PDF by deliveryNoteKey.
   *
   * Return a PDF for a deliveryNoteKey for the checkin with carrier access key
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSinglePdfForSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSinglePdfForSignature$Response(params: ExternalGetSinglePdfForSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSinglePdfForSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a delivery note PDF by deliveryNoteKey.
   *
   * Return a PDF for a deliveryNoteKey for the checkin with carrier access key
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSinglePdfForSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSinglePdfForSignature(params: ExternalGetSinglePdfForSignature$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSinglePdfForSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetSingleAttachmentForSignature()` */
  static readonly ExternalGetSingleAttachmentForSignaturePath = '/external/signature-pads/checkin/delivery-note/{deliveryNoteKey}/attachments/{attachmentKey}';

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Pdf$Response(params: ExternalGetSingleAttachmentForSignature$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Pdf(params: ExternalGetSingleAttachmentForSignature$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heic$Response(params: ExternalGetSingleAttachmentForSignature$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heic(params: ExternalGetSingleAttachmentForSignature$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heif$Response(params: ExternalGetSingleAttachmentForSignature$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heif(params: ExternalGetSingleAttachmentForSignature$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Jpeg$Response(params: ExternalGetSingleAttachmentForSignature$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Jpeg(params: ExternalGetSingleAttachmentForSignature$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Png$Response(params: ExternalGetSingleAttachmentForSignature$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Png(params: ExternalGetSingleAttachmentForSignature$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
