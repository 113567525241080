/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BinaryFileType } from '../../models/binary-file-type';
import { KeyType } from '../../models/key-type';

export interface GetAttachment$Png$Params {
  organizationSiteKey: KeyType;
  deliveryNoteBundleKey: KeyType;
  deliveryNoteKey: KeyType;
  attachmentKey: KeyType;
}

export function getAttachment$Png(http: HttpClient, rootUrl: string, params: GetAttachment$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
  const rb = new RequestBuilder(rootUrl, getAttachment$Png.PATH, 'get');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
    rb.path('deliveryNoteBundleKey', params.deliveryNoteBundleKey, {});
    rb.path('deliveryNoteKey', params.deliveryNoteKey, {});
    rb.path('attachmentKey', params.attachmentKey, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'image/png', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BinaryFileType>;
    })
  );
}

getAttachment$Png.PATH = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/attachments/{attachmentKey}';
