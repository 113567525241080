import { Pipe, PipeTransform } from '@angular/core';
import { SupplayChainArray } from 'api/models';

@Pipe({
    name: 'filterByName',
})
export class FilterByNamePipe implements PipeTransform {
    transform(items: SupplayChainArray, filterBy: string): SupplayChainArray {
        if (!items) return [];
        if (!filterBy) return items;
        filterBy = filterBy ? filterBy.toLocaleLowerCase() : '';
        return items.filter(item =>
            item.fullSiteName?.toLocaleLowerCase()?.includes(filterBy)
        );
    }
}
