import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatNativeDateModule,
} from '@angular/material/core';
import {
    MatLuxonDateModule,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from '@angular/material-luxon-adapter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {
    MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatTooltipDefaultOptions,
    MatTooltipModule,
} from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {
    MatPaginatorIntl,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from '@app/shared/material/custom-paginator-intl';

import { DomSanitizer } from '@angular/platform-browser';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const MAT_LUXON_DATE_FORMAT = {
    parse: {
        dateInput: 'dd.LL.yyyy', // this is how your date will be parsed from Input
    },
    display: {
        dateInput: 'dd.LL.yyyy', // this is how your date will get displayed on the Input
        monthYearLabel: 'LLLL yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'LLLL yyyy',
    },
};

const modules = [
    CommonModule,

    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    DragDropModule,
    ScrollingModule,
    NgxMatSelectSearchModule,
    MatLuxonDateModule,
];

export const customTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 100,
    hideDelay: 100,
    touchendHideDelay: 100,
    position: 'above',
};

@NgModule({
    imports: [modules],
    exports: [modules],
    declarations: [],
    providers: [
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },

        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: customTooltipDefaults,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMAT },
    ],
})
export class MaterialModule {
    svgIcons: Array<[string, string]> = [
        ['folder-create', 'assets/images/folder_create.svg'],
        ['folder-checkout', 'assets/images/folder_checkout.svg'],
        ['folder-checked', 'assets/images/folder_checked_t.svg'],
        ['direction', 'assets/images/direction_t.svg'],
        ['truck-outline', 'assets/images/truck.svg'],
        ['exit', 'assets/images/exit.svg'],
        ['add-deviations', 'assets/images/icon_abweichung_nachtragen.svg'],
        ['red-error', 'assets/images/error-red.svg'],
        ['carrier-exchange', 'assets/images/carrier-exchange.svg'],
    ];

    constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        this.svgIcons.forEach(([iconName, iconPath]) => {
            iconRegistry.addSvgIcon(
                iconName,
                sanitizer.bypassSecurityTrustResourceUrl(iconPath)
            );
        });
    }
}
