import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionCloseResult } from '@app/shared/models/components/question-close-result';
import { Questions } from '@app/shared/models/components/questions';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-question-dialog',
    templateUrl: './question-dialog.component.html',
    styleUrls: ['./question-dialog.component.scss'],
})
export class QuestionDialogComponent implements AfterViewInit {
    /** Possible results for closing the modal dialog. */
    modalResult = QuestionCloseResult;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            msg: string;
            iconClass: string;
            icon: string;
            headline: string;
            confirmText: string;
            cancelText: string;
            hideCancelButton?: boolean;
            refNumber: string;
        },
        private dialogRef: MatDialogRef<QuestionDialogComponent>,
        private cdr: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 1000);
    }

    /**
     * Closes the dialog and sets the clsoing result.
     * @param result
     */
    closeWithResult(result: QuestionCloseResult): void {
        this.dialogRef.close(result);
    }
}
