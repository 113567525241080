/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteLoadCarrier } from '../fn/load-carriers/delete-load-carrier';
import { DeleteLoadCarrier$Params } from '../fn/load-carriers/delete-load-carrier';
import { getConsigneeLoadCarriers } from '../fn/load-carriers/get-consignee-load-carriers';
import { GetConsigneeLoadCarriers$Params } from '../fn/load-carriers/get-consignee-load-carriers';
import { getLoadCarriers } from '../fn/load-carriers/get-load-carriers';
import { GetLoadCarriers$Params } from '../fn/load-carriers/get-load-carriers';
import { LoadCarrier } from '../models/load-carrier';
import { LoadCarriers } from '../models/load-carriers';
import { patchLoadCarrier } from '../fn/load-carriers/patch-load-carrier';
import { PatchLoadCarrier$Params } from '../fn/load-carriers/patch-load-carrier';
import { postConsigneeLoadCarrier } from '../fn/load-carriers/post-consignee-load-carrier';
import { PostConsigneeLoadCarrier$Params } from '../fn/load-carriers/post-consignee-load-carrier';
import { postLoadCarrier } from '../fn/load-carriers/post-load-carrier';
import { PostLoadCarrier$Params } from '../fn/load-carriers/post-load-carrier';
import { putConsigneeLoadCarriers } from '../fn/load-carriers/put-consignee-load-carriers';
import { PutConsigneeLoadCarriers$Params } from '../fn/load-carriers/put-consignee-load-carriers';
import { putLoadCarrier } from '../fn/load-carriers/put-load-carrier';
import { PutLoadCarrier$Params } from '../fn/load-carriers/put-load-carrier';


/**
 * Endpoints to manage load carriers
 */
@Injectable({ providedIn: 'root' })
export class LoadCarriersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getConsigneeLoadCarriers()` */
  static readonly GetConsigneeLoadCarriersPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carriers';

  /**
   * Get all load carriers of a delivery note.
   *
   * Returns all load carriers of a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarriers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarriers$Response(params: GetConsigneeLoadCarriers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LoadCarrier>>> {
    return getConsigneeLoadCarriers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all load carriers of a delivery note.
   *
   * Returns all load carriers of a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarriers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarriers(params: GetConsigneeLoadCarriers$Params, context?: HttpContext): Observable<Array<LoadCarrier>> {
    return this.getConsigneeLoadCarriers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LoadCarrier>>): Array<LoadCarrier> => r.body)
    );
  }

  /** Path part for operation `putConsigneeLoadCarriers()` */
  static readonly PutConsigneeLoadCarriersPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carriers';

  /**
   * Replace all load carriers in delivery note.
   *
   * Replace all load carriers in a delivery note with new ones.
   * The delivery note must be in a started checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putConsigneeLoadCarriers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsigneeLoadCarriers$Response(params: PutConsigneeLoadCarriers$Params, context?: HttpContext): Observable<StrictHttpResponse<LoadCarriers>> {
    return putConsigneeLoadCarriers(this.http, this.rootUrl, params, context);
  }

  /**
   * Replace all load carriers in delivery note.
   *
   * Replace all load carriers in a delivery note with new ones.
   * The delivery note must be in a started checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putConsigneeLoadCarriers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsigneeLoadCarriers(params: PutConsigneeLoadCarriers$Params, context?: HttpContext): Observable<LoadCarriers> {
    return this.putConsigneeLoadCarriers$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoadCarriers>): LoadCarriers => r.body)
    );
  }

  /** Path part for operation `postConsigneeLoadCarrier()` */
  static readonly PostConsigneeLoadCarrierPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carriers';

  /**
   * Add a new load carrier to delivery note.
   *
   * Add a new load carrier to a existing delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeLoadCarrier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConsigneeLoadCarrier$Response(params: PostConsigneeLoadCarrier$Params, context?: HttpContext): Observable<StrictHttpResponse<LoadCarrier>> {
    return postConsigneeLoadCarrier(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a new load carrier to delivery note.
   *
   * Add a new load carrier to a existing delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeLoadCarrier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConsigneeLoadCarrier(params: PostConsigneeLoadCarrier$Params, context?: HttpContext): Observable<LoadCarrier> {
    return this.postConsigneeLoadCarrier$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoadCarrier>): LoadCarrier => r.body)
    );
  }

  /** Path part for operation `getLoadCarriers()` */
  static readonly GetLoadCarriersPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/load-carriers';

  /**
   * Get all load carriers for a delivery note.
   *
   * Get all load carriers for a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoadCarriers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadCarriers$Response(params: GetLoadCarriers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LoadCarrier>>> {
    return getLoadCarriers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all load carriers for a delivery note.
   *
   * Get all load carriers for a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoadCarriers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadCarriers(params: GetLoadCarriers$Params, context?: HttpContext): Observable<Array<LoadCarrier>> {
    return this.getLoadCarriers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LoadCarrier>>): Array<LoadCarrier> => r.body)
    );
  }

  /** Path part for operation `putLoadCarrier()` */
  static readonly PutLoadCarrierPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/load-carriers';

  /**
   * Replace all load carriers with new ones.
   *
   * Replace all load carriers with new ones.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putLoadCarrier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putLoadCarrier$Response(params: PutLoadCarrier$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putLoadCarrier(this.http, this.rootUrl, params, context);
  }

  /**
   * Replace all load carriers with new ones.
   *
   * Replace all load carriers with new ones.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putLoadCarrier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putLoadCarrier(params: PutLoadCarrier$Params, context?: HttpContext): Observable<void> {
    return this.putLoadCarrier$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postLoadCarrier()` */
  static readonly PostLoadCarrierPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/load-carriers';

  /**
   * Add a load carrier to a delivery note.
   *
   * Add a load carrier to a delivery note.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLoadCarrier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLoadCarrier$Response(params: PostLoadCarrier$Params, context?: HttpContext): Observable<StrictHttpResponse<LoadCarrier>> {
    return postLoadCarrier(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a load carrier to a delivery note.
   *
   * Add a load carrier to a delivery note.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postLoadCarrier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLoadCarrier(params: PostLoadCarrier$Params, context?: HttpContext): Observable<LoadCarrier> {
    return this.postLoadCarrier$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoadCarrier>): LoadCarrier => r.body)
    );
  }

  /** Path part for operation `deleteLoadCarrier()` */
  static readonly DeleteLoadCarrierPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/load-carriers/{loadCarrierKey}';

  /**
   * Delete a load carrier from a delivery note.
   *
   * Delete a load carrier from a delivery note.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLoadCarrier()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLoadCarrier$Response(params: DeleteLoadCarrier$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteLoadCarrier(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a load carrier from a delivery note.
   *
   * Delete a load carrier from a delivery note.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLoadCarrier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLoadCarrier(params: DeleteLoadCarrier$Params, context?: HttpContext): Observable<void> {
    return this.deleteLoadCarrier$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchLoadCarrier()` */
  static readonly PatchLoadCarrierPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/load-carriers/{loadCarrierKey}';

  /**
   * Update a load carrier.
   *
   * Update a load carrier.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchLoadCarrier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchLoadCarrier$Response(params: PatchLoadCarrier$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchLoadCarrier(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a load carrier.
   *
   * Update a load carrier.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchLoadCarrier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchLoadCarrier(params: PatchLoadCarrier$Params, context?: HttpContext): Observable<void> {
    return this.patchLoadCarrier$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
