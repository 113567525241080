<app-modal-dialog
    [bodyTemplate]="bodyTemplate"
    [showCloseButton]="false"
    [footerTemplate]="footerTemplate">
    <ng-template #bodyTemplate>
        <div class="container-fluid">
            <h1
                class="dialog tw-text-center"
                [ngStyle]="{ 'font-size.px': data.headlineFontSize || 24 }">
                {{ data.headline | translate }}
            </h1>
            <h2
                *ngIf="data.msg !== 'ds'"
                class="font-16 tw-text-center"
                [ngStyle]="{ 'font-size.px': data.msgFontSize || 16 }">
                {{ data.msg | translate }}
            </h2>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <button
            *ngIf="data.showCloseButton"
            class="tw-w-full"
            id="button-bottom-no"
            tabindex="1"
            type="button"
            mat-flat-button
            color="primary"
            (click)="close()"
            [ngStyle]="{
                'font-size.px': data.buttonFontSize || 14,
                'height.px': data.buttonHeight || 36
            }">
            {{ 'GLOBAL.BUTTON.CLOSE' | translate }}
        </button>
    </ng-template>
</app-modal-dialog>
