import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { OrganizationSite } from 'api/models/organization-site';
import { SelfserviceService } from '../../selfservice.service';

@UntilDestroy()
@Component({
    selector: 'app-selfservice-header',
    templateUrl: './selfservice-header.component.html',
    styleUrls: ['./selfservice-header.component.scss'],
})
export class SelfserviceHeaderComponent implements OnInit {
    headerName: string = '';
    organizationSite: OrganizationSite | undefined;

    constructor(private selfserviceService: SelfserviceService) {}

    ngOnInit() {
        this.selfserviceService.organizationSite$
            .pipe(untilDestroyed(this))
            .subscribe(site => {
                this.organizationSite = site;
                if (this.organizationSite) {
                    this.headerName = site!.name;
                }
            });
    }
}
