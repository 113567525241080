/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteDiscrepancy } from '../fn/discrepancies/delete-discrepancy';
import { DeleteDiscrepancy$Params } from '../fn/discrepancies/delete-discrepancy';
import { DiscrepanciesChoices } from '../models/discrepancies-choices';
import { DiscrepanciesResponse } from '../models/discrepancies-response';
import { DiscrepancyResponse } from '../models/discrepancy-response';
import { getDiscrepanciesChoices } from '../fn/discrepancies/get-discrepancies-choices';
import { GetDiscrepanciesChoices$Params } from '../fn/discrepancies/get-discrepancies-choices';
import { getDiscrepanciesForDeliveryNoteCarrier } from '../fn/discrepancies/get-discrepancies-for-delivery-note-carrier';
import { GetDiscrepanciesForDeliveryNoteCarrier$Params } from '../fn/discrepancies/get-discrepancies-for-delivery-note-carrier';
import { getDiscrepanciesForDeliveryNoteConsignor } from '../fn/discrepancies/get-discrepancies-for-delivery-note-consignor';
import { GetDiscrepanciesForDeliveryNoteConsignor$Params } from '../fn/discrepancies/get-discrepancies-for-delivery-note-consignor';
import { getDiscrepanciesForDelviveryNote } from '../fn/discrepancies/get-discrepancies-for-delvivery-note';
import { GetDiscrepanciesForDelviveryNote$Params } from '../fn/discrepancies/get-discrepancies-for-delvivery-note';
import { GetDiscrepanciesResponse } from '../models/get-discrepancies-response';
import { getDiscrepancy } from '../fn/discrepancies/get-discrepancy';
import { GetDiscrepancy$Params } from '../fn/discrepancies/get-discrepancy';
import { patchDiscrepancy } from '../fn/discrepancies/patch-discrepancy';
import { PatchDiscrepancy$Params } from '../fn/discrepancies/patch-discrepancy';
import { postNewDiscrepancy } from '../fn/discrepancies/post-new-discrepancy';
import { PostNewDiscrepancy$Params } from '../fn/discrepancies/post-new-discrepancy';
import { putConsigneeDiscrepancies } from '../fn/discrepancies/put-consignee-discrepancies';
import { PutConsigneeDiscrepancies$Params } from '../fn/discrepancies/put-consignee-discrepancies';


/**
 * Endpoints to manage discrepancies which are registered during checkin
 */
@Injectable({ providedIn: 'root' })
export class DiscrepanciesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDiscrepanciesForDelviveryNote()` */
  static readonly GetDiscrepanciesForDelviveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancies';

  /**
   * Get all discrepancies for a delivery note.
   *
   * Get all discrepancies for a delivery note as consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscrepanciesForDelviveryNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesForDelviveryNote$Response(params: GetDiscrepanciesForDelviveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDiscrepanciesResponse>> {
    return getDiscrepanciesForDelviveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all discrepancies for a delivery note.
   *
   * Get all discrepancies for a delivery note as consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscrepanciesForDelviveryNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesForDelviveryNote(params: GetDiscrepanciesForDelviveryNote$Params, context?: HttpContext): Observable<GetDiscrepanciesResponse> {
    return this.getDiscrepanciesForDelviveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDiscrepanciesResponse>): GetDiscrepanciesResponse => r.body)
    );
  }

  /** Path part for operation `putConsigneeDiscrepancies()` */
  static readonly PutConsigneeDiscrepanciesPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancies';

  /**
   * Replace all discrepancies for a delivery note.
   *
   * Replace all discrepancies for a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putConsigneeDiscrepancies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsigneeDiscrepancies$Response(params: PutConsigneeDiscrepancies$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscrepanciesResponse>> {
    return putConsigneeDiscrepancies(this.http, this.rootUrl, params, context);
  }

  /**
   * Replace all discrepancies for a delivery note.
   *
   * Replace all discrepancies for a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putConsigneeDiscrepancies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsigneeDiscrepancies(params: PutConsigneeDiscrepancies$Params, context?: HttpContext): Observable<DiscrepanciesResponse> {
    return this.putConsigneeDiscrepancies$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscrepanciesResponse>): DiscrepanciesResponse => r.body)
    );
  }

  /** Path part for operation `postNewDiscrepancy()` */
  static readonly PostNewDiscrepancyPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancies';

  /**
   * Add a new discrepancy to a delivery note.
   *
   * Add a new discrepancy to a delivery note.
   * The delivery note must be in a started checkin
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postNewDiscrepancy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNewDiscrepancy$Response(params: PostNewDiscrepancy$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscrepancyResponse>> {
    return postNewDiscrepancy(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a new discrepancy to a delivery note.
   *
   * Add a new discrepancy to a delivery note.
   * The delivery note must be in a started checkin
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postNewDiscrepancy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNewDiscrepancy(params: PostNewDiscrepancy$Params, context?: HttpContext): Observable<DiscrepancyResponse> {
    return this.postNewDiscrepancy$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscrepancyResponse>): DiscrepancyResponse => r.body)
    );
  }

  /** Path part for operation `getDiscrepancy()` */
  static readonly GetDiscrepancyPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancies/{discrepancyKey}';

  /**
   * Get a discrepancy for a delivery note.
   *
   * Get a discrepancy for a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscrepancy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepancy$Response(params: GetDiscrepancy$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscrepancyResponse>> {
    return getDiscrepancy(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a discrepancy for a delivery note.
   *
   * Get a discrepancy for a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscrepancy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepancy(params: GetDiscrepancy$Params, context?: HttpContext): Observable<DiscrepancyResponse> {
    return this.getDiscrepancy$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscrepancyResponse>): DiscrepancyResponse => r.body)
    );
  }

  /** Path part for operation `deleteDiscrepancy()` */
  static readonly DeleteDiscrepancyPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancies/{discrepancyKey}';

  /**
   * Delete a discrepancy from a delivery note.
   *
   * Delete a discrepancy from a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDiscrepancy()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscrepancy$Response(params: DeleteDiscrepancy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteDiscrepancy(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a discrepancy from a delivery note.
   *
   * Delete a discrepancy from a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDiscrepancy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscrepancy(params: DeleteDiscrepancy$Params, context?: HttpContext): Observable<void> {
    return this.deleteDiscrepancy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchDiscrepancy()` */
  static readonly PatchDiscrepancyPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancies/{discrepancyKey}';

  /**
   * Update a discrepancy for a delivery note.
   *
   * Update a discrepancy for a delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDiscrepancy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDiscrepancy$Response(params: PatchDiscrepancy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchDiscrepancy(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a discrepancy for a delivery note.
   *
   * Update a discrepancy for a delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDiscrepancy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDiscrepancy(params: PatchDiscrepancy$Params, context?: HttpContext): Observable<void> {
    return this.patchDiscrepancy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDiscrepanciesForDeliveryNoteConsignor()` */
  static readonly GetDiscrepanciesForDeliveryNoteConsignorPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}/discrepancies';

  /**
   * Get all discrepancies for a delivery note.
   *
   * Get all discrepancies for a delivery note as consignor. For this, the delivery note bundle must be in status 'closed'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscrepanciesForDeliveryNoteConsignor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesForDeliveryNoteConsignor$Response(params: GetDiscrepanciesForDeliveryNoteConsignor$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDiscrepanciesResponse>> {
    return getDiscrepanciesForDeliveryNoteConsignor(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all discrepancies for a delivery note.
   *
   * Get all discrepancies for a delivery note as consignor. For this, the delivery note bundle must be in status 'closed'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscrepanciesForDeliveryNoteConsignor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesForDeliveryNoteConsignor(params: GetDiscrepanciesForDeliveryNoteConsignor$Params, context?: HttpContext): Observable<GetDiscrepanciesResponse> {
    return this.getDiscrepanciesForDeliveryNoteConsignor$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDiscrepanciesResponse>): GetDiscrepanciesResponse => r.body)
    );
  }

  /** Path part for operation `getDiscrepanciesForDeliveryNoteCarrier()` */
  static readonly GetDiscrepanciesForDeliveryNoteCarrierPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-notes/{deliveryNoteKey}/discrepancies';

  /**
   * Get all discrepancies for a delivery note.
   *
   * Get all discrepancies for a delivery note as carrier. For this, the delivery note bundle must be in status 'closed'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscrepanciesForDeliveryNoteCarrier()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesForDeliveryNoteCarrier$Response(params: GetDiscrepanciesForDeliveryNoteCarrier$Params, context?: HttpContext): Observable<StrictHttpResponse<GetDiscrepanciesResponse>> {
    return getDiscrepanciesForDeliveryNoteCarrier(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all discrepancies for a delivery note.
   *
   * Get all discrepancies for a delivery note as carrier. For this, the delivery note bundle must be in status 'closed'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscrepanciesForDeliveryNoteCarrier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesForDeliveryNoteCarrier(params: GetDiscrepanciesForDeliveryNoteCarrier$Params, context?: HttpContext): Observable<GetDiscrepanciesResponse> {
    return this.getDiscrepanciesForDeliveryNoteCarrier$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetDiscrepanciesResponse>): GetDiscrepanciesResponse => r.body)
    );
  }

  /** Path part for operation `getDiscrepanciesChoices()` */
  static readonly GetDiscrepanciesChoicesPath = '/discrepancies/choices';

  /**
   * Get all possible discrepancies reasons and units.
   *
   * Get all possible discrepancies reasons and units.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscrepanciesChoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesChoices$Response(params?: GetDiscrepanciesChoices$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscrepanciesChoices>> {
    return getDiscrepanciesChoices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all possible discrepancies reasons and units.
   *
   * Get all possible discrepancies reasons and units.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscrepanciesChoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscrepanciesChoices(params?: GetDiscrepanciesChoices$Params, context?: HttpContext): Observable<DiscrepanciesChoices> {
    return this.getDiscrepanciesChoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscrepanciesChoices>): DiscrepanciesChoices => r.body)
    );
  }

}
