import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { catchError, Observable, of, throwError } from 'rxjs';
import { QuotasService } from '../services/quotas.service';
import { ToastService } from '@app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
    dialogRef: any; // MatDialogRef<QuotasComponent> | undefined;
    private isToastServiceEnabled: boolean = true;

    constructor(
        private appConfig: AppConfig,
        private quotasService: QuotasService,
        private toastService: ToastService,
        private readonly injector: Injector
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (request.url.startsWith('apiURL')) {
            const requestUrl = request.url.replace('apiURL/', '');
            request = request.clone({
                url: `${this.appConfig.apiUrl}/${requestUrl}`,
            });
        }

        return next.handle(request).pipe(
            catchError((httpEvent: HttpErrorResponse) => {
                console.log('httpEvent', httpEvent);
                if (
                    httpEvent.status === 404 &&
                    httpEvent.error.errorMessage ===
                        'Self checkin config not found.'
                ) {
                    return throwError(httpEvent);
                }
                if (
                    httpEvent.status === 404 &&
                    httpEvent.error.errorMessage === 'Checkout not found.'
                ) {
                    const translateService =
                        this.injector.get(TranslateService);
                    this.showToast(
                        translateService.instant('GLOBAL.ERROR.UNSPECIFIED')
                    );
                    return throwError(httpEvent);
                }
                if (
                    httpEvent.status === 409 &&
                    httpEvent.error.errorMessage ===
                        'Delivery note ist not editable any more.'
                ) {
                    const translateService =
                        this.injector.get(TranslateService);
                    this.showToast(
                        translateService.instant(
                            'INCOMING-GOODS.CHECKIN.FINE-DISCREPANCY-CONFLICT-ERROR'
                        )
                    );
                    return throwError(httpEvent);
                }
                if (
                    (httpEvent.status === 403 &&
                        httpEvent.error.errorMessage ===
                            'Delivery note not accessible.') ||
                    httpEvent.error.errorMessage ===
                        'Delivery note bundle not found.'
                ) {
                    const translateService =
                        this.injector.get(TranslateService);
                    this.showToast(
                        translateService.instant('GLOBAL.ERROR.UNSPECIFIED')
                    );
                    return throwError(httpEvent);
                }
                if (httpEvent.headers.has('x-request-id')) {
                    const requestId = httpEvent.headers.get('x-request-id');
                    console.log(
                        `%cHttp Error Response:
                    %cStatus: ${httpEvent.status}
                    Request ID: ${requestId}
                    URL: ${httpEvent.url}
                    Error Response: ${JSON.stringify(httpEvent.error)}`,
                        'color:cyan; font-weight:bold;',
                        'font-weight:normal;'
                    );

                    if (
                        requestId &&
                        !httpEvent.url?.includes('/me') &&
                        httpEvent.status !== 404
                    ) {
                        const translateService =
                            this.injector.get(TranslateService);
                        this.showToast(
                            requestId,
                            translateService.instant('ERROR.REQUEST-ID')
                        );
                    }
                }

                try {
                    if (
                        !httpEvent?.error?.errorMessage
                            ?.toLowerCase()
                            .includes('2FA') &&
                        (httpEvent?.error?.errorMessage
                            ?.toLowerCase()
                            .includes('quotas') ||
                            httpEvent?.error?.errorMessage
                                ?.toLowerCase()
                                .includes('reached') ||
                            (typeof httpEvent.error === 'string' &&
                                !httpEvent.error
                                    ?.toLowerCase()
                                    ?.includes('2FA') &&
                                (httpEvent.error
                                    ?.toLowerCase()
                                    ?.includes('quotas') ||
                                    httpEvent.error
                                        ?.toLowerCase()
                                        ?.includes('reached'))))
                    ) {
                        this.quotasService.showQuotasDialog();
                    }
                } catch (error) {}
                return throwError(httpEvent);
            })
        );
    }

    public disableToastService() {
        this.isToastServiceEnabled = false;
    }

    public enableToastService() {
        this.isToastServiceEnabled = true;
    }

    private showToast(message: string, title?: string) {
        if (this.isToastServiceEnabled) {
            this.toastService.error(message, title);
        }
    }
}
