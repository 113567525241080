import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/app.config';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
    constructor(
        private appConfig: AppConfig,
        private router: Router
    ) {}

    ngOnInit() {
        this.check();
    }

    check() {
        if (this.appConfig.maintenance) {
            setTimeout(async () => {
                await this.appConfig.load();
                this.check();
            }, 5000);
        } else {
            this.router.navigate(['/']);
        }
    }
}
