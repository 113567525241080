<div class="split-view-wrapper">
    <app-left-splitview></app-left-splitview>

    <div
        class="right-col tw-justify-content-center tw-flex tw-flex-col tw-items-center tw-justify-center">
        <div class="mat-headline-5">
            {{ 'MAIL-VERIFIED.TITLE' | translate }}
        </div>

        <button
            mat-flat-button
            color="primary"
            class="tw-mt-48"
            [routerLink]="['/login']">
            {{ 'MAIL-VERIFIED.TO-LOGIN' | translate }}
        </button>
    </div>
</div>
