<div class="tw-grid tw-grid-cols-12 tw-bg-gray-100">
    <div
        class="tw-border-r-1 tw-col-span-1 tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-gray-300 tw-bg-white"></div>
    <div class="tw-col-span-11 tw-grid tw-grid-cols-12">
        <div class="tw-col-span-1 tw-bg-white"></div>
        <div class="tw-col-span-6 tw-pr-5 tw-pt-2 tw-pl-3">
            <span class="bold">{{
                'ADMIN-PANEL.USERS.COLUMN-NAMES.PLACES' | translate
            }}</span>
        </div>
        <div class="tw-col-span-4 tw-pr-5 tw-pt-2 tw-pl-3">
            <span class="bold">{{
                'ADMIN-PANEL.USERS.COLUMN-NAMES.ROLES' | translate
            }}</span>
        </div>
    </div>
</div>
<div
    class="tw-grid tw-grid-cols-12 tw-bg-gray-100"
    *ngFor="let site of user?.organizationSites; let i = index">
    <div
        class="tw-border-r-1 tw-col-span-1 tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-gray-300 tw-bg-white"></div>
    <div class="tw-col-span-11 tw-grid tw-grid-cols-12">
        <div class="tw-col-span-1 tw-grid tw-grid-rows-2 tw-bg-white tw-pt-2">
            <div></div>
            <div
                class="tw-border-t-1 tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-solid tw-border-gray-300"></div>
        </div>
        <div
            class="tw-col-span-6 tw-flex tw-flex-col tw-justify-center tw-pr-5 tw-pt-2 tw-pl-3 tw-pb-1.5">
            <span class="tw-flex tw-whitespace-normal !tw-break-all">{{
                site.name
            }}</span>
        </div>
        <div
            class="tw-col-span-4 tw-flex tw-flex-col tw-flex-wrap tw-justify-center tw-pr-5 tw-pt-2 tw-pl-3 tw-pb-1.5">
            <span *ngFor="let role of site.roles">{{ role | translate }}</span>
        </div>
    </div>
</div>
