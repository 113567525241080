import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/app.config';

@Injectable({ providedIn: 'root' })
export class MaintenanceGuard {
    constructor(
        private router: Router,
        private appConfig: AppConfig
    ) {}

    canActivate() {
        if (this.appConfig.maintenance) {
            this.router.navigate(['/maintenance']);
            return false;
        }

        return true;
    }
}
