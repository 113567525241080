import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { RoutingEndpoints } from '@app/shared/models/routing/routing-endpoints';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        public sessionService: SessionService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const sessionExists = this.sessionService.sessionExists();

        if (!sessionExists) {
            this.router.navigate([RoutingEndpoints.login], {
                queryParams: { folderId: route.queryParams.folderId },
            });
            return false;
        }

        try {
            const session = this.sessionService.getSession();
            if (!session.termsOfService.accepted) {
                if (
                    session.termsOfService.dateAcceptUntil <
                    new Date().getTime()
                ) {
                    this.router.navigate(['/settings/tos']);
                    return false;
                }
            }
        } catch (error) {
            return true;
        }

        return true;
    }
}
