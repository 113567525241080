/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { CheckinsForDriverUpdate } from '../models/checkins-for-driver-update';
import { ConsigneeCheckinResponse } from '../models/consignee-checkin-response';
import { ConsigneeLookupDeliveryNoteResponse } from '../models/consignee-lookup-delivery-note-response';
import { getConsigneeBundlesForCheckin } from '../fn/delivery-note-bundles/get-consignee-bundles-for-checkin';
import { GetConsigneeBundlesForCheckin$Params } from '../fn/delivery-note-bundles/get-consignee-bundles-for-checkin';
import { getConsigneeCheckin } from '../fn/checkins/get-consignee-checkin';
import { GetConsigneeCheckin$Params } from '../fn/checkins/get-consignee-checkin';
import { getConsigneeCheckinLookup } from '../fn/checkins/get-consignee-checkin-lookup';
import { GetConsigneeCheckinLookup$Params } from '../fn/checkins/get-consignee-checkin-lookup';
import { GetConsigneeDeliveryNoteBundlesCheckinResponse } from '../models/get-consignee-delivery-note-bundles-checkin-response';
import { getConsigneeDeliveryNoteCombinedPdf } from '../fn/consignee/get-consignee-delivery-note-combined-pdf';
import { GetConsigneeDeliveryNoteCombinedPdf$Params } from '../fn/consignee/get-consignee-delivery-note-combined-pdf';
import { getConsigneeDeliveryNotesForToken } from '../fn/checkins/get-consignee-delivery-notes-for-token';
import { GetConsigneeDeliveryNotesForToken } from '../models/get-consignee-delivery-notes-for-token';
import { GetConsigneeDeliveryNotesForToken$Params } from '../fn/checkins/get-consignee-delivery-notes-for-token';
import { patchConsigneeCheckin } from '../fn/checkins/patch-consignee-checkin';
import { PatchConsigneeCheckin$Params } from '../fn/checkins/patch-consignee-checkin';
import { patchConsigneeSelfCheckin } from '../fn/checkins/patch-consignee-self-checkin';
import { PatchConsigneeSelfCheckin$Params } from '../fn/checkins/patch-consignee-self-checkin';
import { postConsigneeCheckinDeliveryNotes } from '../fn/checkins/post-consignee-checkin-delivery-notes';
import { PostConsigneeCheckinDeliveryNotes$Params } from '../fn/checkins/post-consignee-checkin-delivery-notes';
import { putConsignerDriverDetails } from '../fn/checkins/put-consigner-driver-details';
import { PutConsignerDriverDetails$Params } from '../fn/checkins/put-consigner-driver-details';


/**
 * Endpoints
 */
@Injectable({ providedIn: 'root' })
export class CheckinsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postConsigneeCheckinDeliveryNotes()` */
  static readonly PostConsigneeCheckinDeliveryNotesPath = '/organization-sites/{organizationSiteKey}/consignee/checkins';

  /**
   * Start a new checkin with delivery notes.
   *
   * Start a new checkin with delivery notes.
   * All delivery notes must not be in a finished checkin.
   * If a delivery note is in another checkin with status 'started'
   * the delivery note is moved to the new checkin.
   * The driver information is copied to the new checkin.
   * The driver information from the most recent started checkin is used.
   * After the checkin was started it is possible
   * to add discrepancies, load carriers and associated files.
   * The checkin has the status 'started' -> 'signing' -> 'finished'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeCheckinDeliveryNotes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConsigneeCheckinDeliveryNotes$Response(params: PostConsigneeCheckinDeliveryNotes$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeCheckinResponse>> {
    return postConsigneeCheckinDeliveryNotes(this.http, this.rootUrl, params, context);
  }

  /**
   * Start a new checkin with delivery notes.
   *
   * Start a new checkin with delivery notes.
   * All delivery notes must not be in a finished checkin.
   * If a delivery note is in another checkin with status 'started'
   * the delivery note is moved to the new checkin.
   * The driver information is copied to the new checkin.
   * The driver information from the most recent started checkin is used.
   * After the checkin was started it is possible
   * to add discrepancies, load carriers and associated files.
   * The checkin has the status 'started' -> 'signing' -> 'finished'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeCheckinDeliveryNotes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConsigneeCheckinDeliveryNotes(params: PostConsigneeCheckinDeliveryNotes$Params, context?: HttpContext): Observable<ConsigneeCheckinResponse> {
    return this.postConsigneeCheckinDeliveryNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeCheckinResponse>): ConsigneeCheckinResponse => r.body)
    );
  }

  /** Path part for operation `getConsigneeCheckin()` */
  static readonly GetConsigneeCheckinPath = '/organization-sites/{organizationSiteKey}/consignee/checkins/{checkinKey}';

  /**
   * Get a checkin document.
   *
   * Get a checkin document with delivery notes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeCheckin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeCheckin$Response(params: GetConsigneeCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeCheckinResponse>> {
    return getConsigneeCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a checkin document.
   *
   * Get a checkin document with delivery notes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeCheckin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeCheckin(params: GetConsigneeCheckin$Params, context?: HttpContext): Observable<ConsigneeCheckinResponse> {
    return this.getConsigneeCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeCheckinResponse>): ConsigneeCheckinResponse => r.body)
    );
  }

  /** Path part for operation `patchConsigneeCheckin()` */
  static readonly PatchConsigneeCheckinPath = '/organization-sites/{organizationSiteKey}/consignee/checkins/{checkinKey}';

  /**
   * Finish a checkin with signatures.
   *
   * Finish a checkin by sending the signatures of the consignee and
   * driver. The signatures must be pngs in base64 format.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchConsigneeCheckin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchConsigneeCheckin$Response(params: PatchConsigneeCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchConsigneeCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish a checkin with signatures.
   *
   * Finish a checkin by sending the signatures of the consignee and
   * driver. The signatures must be pngs in base64 format.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchConsigneeCheckin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchConsigneeCheckin(params: PatchConsigneeCheckin$Params, context?: HttpContext): Observable<void> {
    return this.patchConsigneeCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchConsigneeSelfCheckin()` */
  static readonly PatchConsigneeSelfCheckinPath = '/organization-sites/{organizationSiteKey}/consignee/checkins/{checkinKey}/consignee-signature';

  /**
   * Sign a checkin as consignee, make it ready for the self checkin of the driver.
   *
   * Sending the signature of the consignee. The signature must be a png in base64 format.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchConsigneeSelfCheckin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchConsigneeSelfCheckin$Response(params: PatchConsigneeSelfCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchConsigneeSelfCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Sign a checkin as consignee, make it ready for the self checkin of the driver.
   *
   * Sending the signature of the consignee. The signature must be a png in base64 format.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchConsigneeSelfCheckin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchConsigneeSelfCheckin(params: PatchConsigneeSelfCheckin$Params, context?: HttpContext): Observable<void> {
    return this.patchConsigneeSelfCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `putConsignerDriverDetails()` */
  static readonly PutConsignerDriverDetailsPath = '/organization-sites/{organizationSiteKey}/consignee/checkins/{checkinKey}/driver-details';

  /**
   * Update driver information.
   *
   * Update driver information of the relevant consignee delivery note bundle.
   * Returns 400 http status if any wrong input params are sent. Returns 200 when
   * the updated is successfully.
   * The checkin must be in status 'started'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putConsignerDriverDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsignerDriverDetails$Response(params: PutConsignerDriverDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckinsForDriverUpdate>> {
    return putConsignerDriverDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Update driver information.
   *
   * Update driver information of the relevant consignee delivery note bundle.
   * Returns 400 http status if any wrong input params are sent. Returns 200 when
   * the updated is successfully.
   * The checkin must be in status 'started'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putConsignerDriverDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putConsignerDriverDetails(params: PutConsignerDriverDetails$Params, context?: HttpContext): Observable<CheckinsForDriverUpdate> {
    return this.putConsignerDriverDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckinsForDriverUpdate>): CheckinsForDriverUpdate => r.body)
    );
  }

  /** Path part for operation `getConsigneeCheckinLookup()` */
  static readonly GetConsigneeCheckinLookupPath = '/organization-sites/{organizationSiteKey}/consignee/checkins/lookup';

  /**
   * Get all checkin documents associated with the specified delivery notes.
   *
   * By providing a list of delivery note keys, this endpoint returns all
   * checkin documents associated with those delivery notes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeCheckinLookup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getConsigneeCheckinLookup$Response(params: GetConsigneeCheckinLookup$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeLookupDeliveryNoteResponse>> {
    return getConsigneeCheckinLookup(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all checkin documents associated with the specified delivery notes.
   *
   * By providing a list of delivery note keys, this endpoint returns all
   * checkin documents associated with those delivery notes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeCheckinLookup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getConsigneeCheckinLookup(params: GetConsigneeCheckinLookup$Params, context?: HttpContext): Observable<ConsigneeLookupDeliveryNoteResponse> {
    return this.getConsigneeCheckinLookup$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeLookupDeliveryNoteResponse>): ConsigneeLookupDeliveryNoteResponse => r.body)
    );
  }

  /** Path part for operation `getConsigneeBundlesForCheckin()` */
  static readonly GetConsigneeBundlesForCheckinPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-note-bundles/checkin';

  /**
   * Get bundles via token for checkin.
   *
   * Get delivery note bundles for a checkin via access token
   * from a wallet.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeBundlesForCheckin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundlesForCheckin$Response(params: GetConsigneeBundlesForCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeDeliveryNoteBundlesCheckinResponse>> {
    return getConsigneeBundlesForCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundles via token for checkin.
   *
   * Get delivery note bundles for a checkin via access token
   * from a wallet.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeBundlesForCheckin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundlesForCheckin(params: GetConsigneeBundlesForCheckin$Params, context?: HttpContext): Observable<GetConsigneeDeliveryNoteBundlesCheckinResponse> {
    return this.getConsigneeBundlesForCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeDeliveryNoteBundlesCheckinResponse>): GetConsigneeDeliveryNoteBundlesCheckinResponse => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNotesForToken()` */
  static readonly GetConsigneeDeliveryNotesForTokenPath = '/organization-sites/consignee/delivery-notes/for-checkin';

  /**
   * get delivery note keys from wallet for checkin.
   *
   * Get delivery notes for a checkin.
   * The token from the checkout wallet or from the tour wallet.
   * Returns list of delivery note keys and their organization site key
   * that can be used to create a checkin.
   * The requesting user must have the permission
   * consignee_delivery_notes_lookup_with_token on all organization sites.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNotesForToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotesForToken$Response(params: GetConsigneeDeliveryNotesForToken$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeDeliveryNotesForToken>> {
    return getConsigneeDeliveryNotesForToken(this.http, this.rootUrl, params, context);
  }

  /**
   * get delivery note keys from wallet for checkin.
   *
   * Get delivery notes for a checkin.
   * The token from the checkout wallet or from the tour wallet.
   * Returns list of delivery note keys and their organization site key
   * that can be used to create a checkin.
   * The requesting user must have the permission
   * consignee_delivery_notes_lookup_with_token on all organization sites.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNotesForToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotesForToken(params: GetConsigneeDeliveryNotesForToken$Params, context?: HttpContext): Observable<GetConsigneeDeliveryNotesForToken> {
    return this.getConsigneeDeliveryNotesForToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeDeliveryNotesForToken>): GetConsigneeDeliveryNotesForToken => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNoteCombinedPdf()` */
  static readonly GetConsigneeDeliveryNoteCombinedPdfPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNoteCombinedPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteCombinedPdf$Response(params: GetConsigneeDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDeliveryNoteCombinedPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNoteCombinedPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteCombinedPdf(params: GetConsigneeDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDeliveryNoteCombinedPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
