/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { deleteSelfCheckinToken } from '../fn/administration-self-service-checkin/delete-self-checkin-token';
import { DeleteSelfCheckinToken$Params } from '../fn/administration-self-service-checkin/delete-self-checkin-token';
import { getConsigneeSelfCheckinLegalDocument } from '../fn/pdf/get-consignee-self-checkin-legal-document';
import { GetConsigneeSelfCheckinLegalDocument$Params } from '../fn/pdf/get-consignee-self-checkin-legal-document';
import { getSelfCheckinTokens } from '../fn/administration-self-service-checkin/get-self-checkin-tokens';
import { GetSelfCheckinTokens$Params } from '../fn/administration-self-service-checkin/get-self-checkin-tokens';
import { getSelfServiceConfigCheckin } from '../fn/administration-self-service-checkin/get-self-service-config-checkin';
import { GetSelfServiceConfigCheckin$Params } from '../fn/administration-self-service-checkin/get-self-service-config-checkin';
import { postGenerateSelfCheckinToken } from '../fn/administration-self-service-checkin/post-generate-self-checkin-token';
import { PostGenerateSelfCheckinToken$Params } from '../fn/administration-self-service-checkin/post-generate-self-checkin-token';
import { putConsigneeSelfCheckinLegalDocument } from '../fn/administration-self-service-checkin/put-consignee-self-checkin-legal-document';
import { PutConsigneeSelfCheckinLegalDocument$Params } from '../fn/administration-self-service-checkin/put-consignee-self-checkin-legal-document';
import { putSelfServiceConfigCheckin } from '../fn/administration-self-service-checkin/put-self-service-config-checkin';
import { PutSelfServiceConfigCheckin$Params } from '../fn/administration-self-service-checkin/put-self-service-config-checkin';
import { SelfCheckinTokenResponse } from '../models/self-checkin-token-response';
import { SelfCheckinTokensResponse } from '../models/self-checkin-tokens-response';
import { SelfServiceConfigCheckinResponse } from '../models/self-service-config-checkin-response';
import { SelfServiceConfigCheckinWithTokensResponse } from '../models/self-service-config-checkin-with-tokens-response';

@Injectable({ providedIn: 'root' })
export class AdministrationSelfServiceCheckinService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSelfServiceConfigCheckin()` */
  static readonly GetSelfServiceConfigCheckinPath = '/administration/organization-sites/{organizationSiteKey}/self-checkin';

  /**
   * Get the self service configuration during the checkin process for an organization site.
   *
   * Allows users to retrieve the current self service configuration during the checkin process.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelfServiceConfigCheckin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfServiceConfigCheckin$Response(params: GetSelfServiceConfigCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfServiceConfigCheckinWithTokensResponse>> {
    return getSelfServiceConfigCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the self service configuration during the checkin process for an organization site.
   *
   * Allows users to retrieve the current self service configuration during the checkin process.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelfServiceConfigCheckin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfServiceConfigCheckin(params: GetSelfServiceConfigCheckin$Params, context?: HttpContext): Observable<SelfServiceConfigCheckinWithTokensResponse> {
    return this.getSelfServiceConfigCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfServiceConfigCheckinWithTokensResponse>): SelfServiceConfigCheckinWithTokensResponse => r.body)
    );
  }

  /** Path part for operation `putSelfServiceConfigCheckin()` */
  static readonly PutSelfServiceConfigCheckinPath = '/administration/organization-sites/{organizationSiteKey}/self-checkin';

  /**
   * Customize the display of the self service configuration during the checkin process for an organization site.
   *
   * This endpoint provides the option to specify which self-service
   * checkin information should be presented during the check-in process
   * for an organization site.
   *
   * Set the notificationEmailAddress to the email address where notifications should be sent.
   *
   * Set the scanner flag to true or false to enable or disable the scanner.
   *
   * Specify the selfCheckinData elements to be displayed during the
   * checkin process and omit the ones you don't want to display,
   * e.g.: 'selfCheckinData': [ "NAME", "PAGER_NUMBER", "REFERENCE_NUMBER"].
   * Here, the name, pager number, and reference number will be displayed,
   * whereas the license plate number, phone number, waiting number, booking number
   * and time slot booking number will not be displayed. You can choose from the
   * following elements:
   * "NAME", "LICENSE_PLATE_NUMBER", "PHONE_NUMBER", "PAGER_NUMBER",
   * "REFERENCE_NUMBER", "WAITING_NUMBER", "BOOKING_NUMBER",
   * "TIME_SLOT_BOOKING_NUMBER".
   * However, the maximum number of elements selected at the same time must not exceed 5.
   *
   * Duplicates in the selfCheckinData array are not allowed and will result in an error.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSelfServiceConfigCheckin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSelfServiceConfigCheckin$Response(params: PutSelfServiceConfigCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfServiceConfigCheckinResponse>> {
    return putSelfServiceConfigCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Customize the display of the self service configuration during the checkin process for an organization site.
   *
   * This endpoint provides the option to specify which self-service
   * checkin information should be presented during the check-in process
   * for an organization site.
   *
   * Set the notificationEmailAddress to the email address where notifications should be sent.
   *
   * Set the scanner flag to true or false to enable or disable the scanner.
   *
   * Specify the selfCheckinData elements to be displayed during the
   * checkin process and omit the ones you don't want to display,
   * e.g.: 'selfCheckinData': [ "NAME", "PAGER_NUMBER", "REFERENCE_NUMBER"].
   * Here, the name, pager number, and reference number will be displayed,
   * whereas the license plate number, phone number, waiting number, booking number
   * and time slot booking number will not be displayed. You can choose from the
   * following elements:
   * "NAME", "LICENSE_PLATE_NUMBER", "PHONE_NUMBER", "PAGER_NUMBER",
   * "REFERENCE_NUMBER", "WAITING_NUMBER", "BOOKING_NUMBER",
   * "TIME_SLOT_BOOKING_NUMBER".
   * However, the maximum number of elements selected at the same time must not exceed 5.
   *
   * Duplicates in the selfCheckinData array are not allowed and will result in an error.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putSelfServiceConfigCheckin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSelfServiceConfigCheckin(params: PutSelfServiceConfigCheckin$Params, context?: HttpContext): Observable<SelfServiceConfigCheckinResponse> {
    return this.putSelfServiceConfigCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfServiceConfigCheckinResponse>): SelfServiceConfigCheckinResponse => r.body)
    );
  }

  /** Path part for operation `getSelfCheckinTokens()` */
  static readonly GetSelfCheckinTokensPath = '/administration/organization-sites/{organizationSiteKey}/self-checkin-tokens';

  /**
   * Get all self-checkin tokens for an organization site.
   *
   * This is an admin endpoint. The user must be an organization admin or site admin.
   * The endpoint retrieves all self-checkin tokens for the organization site.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelfCheckinTokens()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfCheckinTokens$Response(params: GetSelfCheckinTokens$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfCheckinTokensResponse>> {
    return getSelfCheckinTokens(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all self-checkin tokens for an organization site.
   *
   * This is an admin endpoint. The user must be an organization admin or site admin.
   * The endpoint retrieves all self-checkin tokens for the organization site.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelfCheckinTokens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfCheckinTokens(params: GetSelfCheckinTokens$Params, context?: HttpContext): Observable<SelfCheckinTokensResponse> {
    return this.getSelfCheckinTokens$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfCheckinTokensResponse>): SelfCheckinTokensResponse => r.body)
    );
  }

  /** Path part for operation `postGenerateSelfCheckinToken()` */
  static readonly PostGenerateSelfCheckinTokenPath = '/administration/organization-sites/{organizationSiteKey}/self-checkin-tokens';

  /**
   * Creates a self service checkin token for self-checkin on an organization site.
   *
   * This is an admin endpoint. The user must be an organization admin or site admin.
   * The endpoint creates an token, this token can be used as a
   * dlssessionId for all endpoints with 'self_checkin' role set as
   * permission on the organization site.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postGenerateSelfCheckinToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGenerateSelfCheckinToken$Response(params: PostGenerateSelfCheckinToken$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfCheckinTokenResponse>> {
    return postGenerateSelfCheckinToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a self service checkin token for self-checkin on an organization site.
   *
   * This is an admin endpoint. The user must be an organization admin or site admin.
   * The endpoint creates an token, this token can be used as a
   * dlssessionId for all endpoints with 'self_checkin' role set as
   * permission on the organization site.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postGenerateSelfCheckinToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGenerateSelfCheckinToken(params: PostGenerateSelfCheckinToken$Params, context?: HttpContext): Observable<SelfCheckinTokenResponse> {
    return this.postGenerateSelfCheckinToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfCheckinTokenResponse>): SelfCheckinTokenResponse => r.body)
    );
  }

  /** Path part for operation `deleteSelfCheckinToken()` */
  static readonly DeleteSelfCheckinTokenPath = '/administration/organization-sites/{organizationSiteKey}/self-checkin-tokens';

  /**
   * Delete a self-checkin token for an organization site.
   *
   * This is an admin endpoint. The user must be an organization admin or site admin.
   * The endpoint deletes a self-checkin token for the organization site.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSelfCheckinToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSelfCheckinToken$Response(params: DeleteSelfCheckinToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSelfCheckinToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a self-checkin token for an organization site.
   *
   * This is an admin endpoint. The user must be an organization admin or site admin.
   * The endpoint deletes a self-checkin token for the organization site.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSelfCheckinToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSelfCheckinToken(params: DeleteSelfCheckinToken$Params, context?: HttpContext): Observable<void> {
    return this.deleteSelfCheckinToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `putConsigneeSelfCheckinLegalDocument()` */
  static readonly PutConsigneeSelfCheckinLegalDocumentPath = '/administration/organization-sites/{organizationSiteKey}/self-checkin/legal-document/{fileName}';

  /**
   * Add a legal document to the organization site.
   *
   * Add a legal document to the organization site which will be
   * displayed during the self-checkin process.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putConsigneeSelfCheckinLegalDocument()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  putConsigneeSelfCheckinLegalDocument$Response(params: PutConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putConsigneeSelfCheckinLegalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a legal document to the organization site.
   *
   * Add a legal document to the organization site which will be
   * displayed during the self-checkin process.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putConsigneeSelfCheckinLegalDocument$Response()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  putConsigneeSelfCheckinLegalDocument(params: PutConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<void> {
    return this.putConsigneeSelfCheckinLegalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConsigneeSelfCheckinLegalDocument()` */
  static readonly GetConsigneeSelfCheckinLegalDocumentPath = '/organization-sites/{organizationSiteKey}/self-checkin/legal-document';

  /**
   * Get the legal PDF document.
   *
   * Retrieve the legal PDF document associated with the organization
   * site. The file name is returned in the content-disposition section
   * of the header.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeSelfCheckinLegalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSelfCheckinLegalDocument$Response(params: GetConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeSelfCheckinLegalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the legal PDF document.
   *
   * Retrieve the legal PDF document associated with the organization
   * site. The file name is returned in the content-disposition section
   * of the header.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeSelfCheckinLegalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSelfCheckinLegalDocument(params: GetConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeSelfCheckinLegalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
