/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { ConsigneeAttachmentResponse } from '../models/consignee-attachment-response';
import { deletePalletTicket } from '../fn/pallet-tickets/delete-pallet-ticket';
import { DeletePalletTicket$Params } from '../fn/pallet-tickets/delete-pallet-ticket';
import { GetConsigneeAttachmentsResponse } from '../models/get-consignee-attachments-response';
import { getPalletTicket } from '../fn/pallet-tickets/get-pallet-ticket';
import { GetPalletTicket$Params } from '../fn/pallet-tickets/get-pallet-ticket';
import { getPalletTicketsForDeliveryNote } from '../fn/pallet-tickets/get-pallet-tickets-for-delivery-note';
import { GetPalletTicketsForDeliveryNote$Params } from '../fn/pallet-tickets/get-pallet-tickets-for-delivery-note';
import { postPalletTicketToDeliveryNote } from '../fn/pallet-tickets/post-pallet-ticket-to-delivery-note';
import { PostPalletTicketToDeliveryNote$Params } from '../fn/pallet-tickets/post-pallet-ticket-to-delivery-note';

@Injectable({ providedIn: 'root' })
export class PalletTicketsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postPalletTicketToDeliveryNote()` */
  static readonly PostPalletTicketToDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pallet-tickets/file/{fileName}';

  /**
   * Add a new PalletTicket to a existing delivery note.
   *
   * Add a new PalletTicket to a existing delivery note.
   * The delivery note must be in a started checkin.
   * Accepted Formats are only pdfs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPalletTicketToDeliveryNote()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  postPalletTicketToDeliveryNote$Response(params: PostPalletTicketToDeliveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postPalletTicketToDeliveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a new PalletTicket to a existing delivery note.
   *
   * Add a new PalletTicket to a existing delivery note.
   * The delivery note must be in a started checkin.
   * Accepted Formats are only pdfs.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postPalletTicketToDeliveryNote$Response()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  postPalletTicketToDeliveryNote(params: PostPalletTicketToDeliveryNote$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postPalletTicketToDeliveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /** Path part for operation `getPalletTicket()` */
  static readonly GetPalletTicketPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pallet-tickets/{attachmentKey}';

  /**
   * Get a single PalletTicket for a delivery note.
   *
   * Get a single PalletTicket for a delivery note by organizationSiteKey,
   * deliveryNoteKey and palletTicketKey.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPalletTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletTicket$Response(params: GetPalletTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getPalletTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a single PalletTicket for a delivery note.
   *
   * Get a single PalletTicket for a delivery note by organizationSiteKey,
   * deliveryNoteKey and palletTicketKey.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPalletTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletTicket(params: GetPalletTicket$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getPalletTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `deletePalletTicket()` */
  static readonly DeletePalletTicketPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pallet-tickets/{attachmentKey}';

  /**
   * Delete a PalletTicket from a delivery note.
   *
   * Delete a PalletTicket from a delivery note.
   * The delivery note bundle must be in status 'sent'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePalletTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePalletTicket$Response(params: DeletePalletTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePalletTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a PalletTicket from a delivery note.
   *
   * Delete a PalletTicket from a delivery note.
   * The delivery note bundle must be in status 'sent'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePalletTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePalletTicket(params: DeletePalletTicket$Params, context?: HttpContext): Observable<void> {
    return this.deletePalletTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getPalletTicketsForDeliveryNote()` */
  static readonly GetPalletTicketsForDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pallet-tickets';

  /**
   * Get a list of pallet tickets for a single delivery note.
   *
   * Get a list of pallet tickets for a single delivery note by organizationSiteKey
   * and deliveryNoteKey.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPalletTicketsForDeliveryNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletTicketsForDeliveryNote$Response(params: GetPalletTicketsForDeliveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeAttachmentsResponse>> {
    return getPalletTicketsForDeliveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of pallet tickets for a single delivery note.
   *
   * Get a list of pallet tickets for a single delivery note by organizationSiteKey
   * and deliveryNoteKey.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPalletTicketsForDeliveryNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletTicketsForDeliveryNote(params: GetPalletTicketsForDeliveryNote$Params, context?: HttpContext): Observable<GetConsigneeAttachmentsResponse> {
    return this.getPalletTicketsForDeliveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeAttachmentsResponse>): GetConsigneeAttachmentsResponse => r.body)
    );
  }

}
