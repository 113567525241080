/* tslint:disable */
/* eslint-disable */
export enum SelfCheckinData {
  Name = 'NAME',
  LicensePlateNumber = 'LICENSE_PLATE_NUMBER',
  PhoneNumber = 'PHONE_NUMBER',
  PagerNumber = 'PAGER_NUMBER',
  ReferenceNumber = 'REFERENCE_NUMBER',
  WaitingNumber = 'WAITING_NUMBER',
  BookingNumber = 'BOOKING_NUMBER',
  TimeSlotBookingNumber = 'TIME_SLOT_BOOKING_NUMBER'
}
