/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { ConsigneeCheckinResponse } from '../models/consignee-checkin-response';
import { DeliveryNoteBundleOrTourArray } from '../models/delivery-note-bundle-or-tour-array';
import { getBundlesAndTours } from '../fn/self-service-checkins/get-bundles-and-tours';
import { GetBundlesAndTours$Params } from '../fn/self-service-checkins/get-bundles-and-tours';
import { getConsigneeDeliveryNoteFromPool } from '../fn/delivery-notes/get-consignee-delivery-note-from-pool';
import { GetConsigneeDeliveryNoteFromPool$Params } from '../fn/delivery-notes/get-consignee-delivery-note-from-pool';
import { getConsigneeDiscrepancyImageAttachment$Heic } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heic';
import { GetConsigneeDiscrepancyImageAttachment$Heic$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heic';
import { getConsigneeDiscrepancyImageAttachment$Heif } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heif';
import { GetConsigneeDiscrepancyImageAttachment$Heif$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heif';
import { getConsigneeDiscrepancyImageAttachment$Jpeg } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-jpeg';
import { GetConsigneeDiscrepancyImageAttachment$Jpeg$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-jpeg';
import { getConsigneeDiscrepancyImageAttachment$Pdf } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-pdf';
import { GetConsigneeDiscrepancyImageAttachment$Pdf$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-pdf';
import { getConsigneeDiscrepancyImageAttachment$Png } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-png';
import { GetConsigneeDiscrepancyImageAttachment$Png$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-png';
import { getConsigneeDiscrepancyReceiptAttachment$Heic } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heic';
import { GetConsigneeDiscrepancyReceiptAttachment$Heic$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heic';
import { getConsigneeDiscrepancyReceiptAttachment$Heif } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heif';
import { GetConsigneeDiscrepancyReceiptAttachment$Heif$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-heif';
import { getConsigneeDiscrepancyReceiptAttachment$Jpeg } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-jpeg';
import { GetConsigneeDiscrepancyReceiptAttachment$Jpeg$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-jpeg';
import { getConsigneeDiscrepancyReceiptAttachment$Pdf } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-pdf';
import { GetConsigneeDiscrepancyReceiptAttachment$Pdf$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-pdf';
import { getConsigneeDiscrepancyReceiptAttachment$Png } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-png';
import { GetConsigneeDiscrepancyReceiptAttachment$Png$Params } from '../fn/receipts/get-consignee-discrepancy-receipt-attachment-png';
import { getConsigneeLoadCarrierFile$Heic } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heic';
import { GetConsigneeLoadCarrierFile$Heic$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heic';
import { getConsigneeLoadCarrierFile$Heif } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heif';
import { GetConsigneeLoadCarrierFile$Heif$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heif';
import { getConsigneeLoadCarrierFile$Jpeg } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-jpeg';
import { GetConsigneeLoadCarrierFile$Jpeg$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-jpeg';
import { getConsigneeLoadCarrierFile$Pdf } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-pdf';
import { GetConsigneeLoadCarrierFile$Pdf$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-pdf';
import { getConsigneeLoadCarrierFile$Png } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-png';
import { GetConsigneeLoadCarrierFile$Png$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-png';
import { getConsigneeSelfCheckinLegalDocument } from '../fn/pdf/get-consignee-self-checkin-legal-document';
import { GetConsigneeSelfCheckinLegalDocument$Params } from '../fn/pdf/get-consignee-self-checkin-legal-document';
import { getPalletTicket } from '../fn/pallet-tickets/get-pallet-ticket';
import { GetPalletTicket$Params } from '../fn/pallet-tickets/get-pallet-ticket';
import { getSelfCheckinConfigAsSelfCheckin } from '../fn/self-service-checkins/get-self-checkin-config-as-self-checkin';
import { GetSelfCheckinConfigAsSelfCheckin$Params } from '../fn/self-service-checkins/get-self-checkin-config-as-self-checkin';
import { getSelfCheckinInformation } from '../fn/self-service-checkins/get-self-checkin-information';
import { GetSelfCheckinInformation$Params } from '../fn/self-service-checkins/get-self-checkin-information';
import { patchConsigneeSelfServiceCheckinFinish } from '../fn/self-service-checkins/patch-consignee-self-service-checkin-finish';
import { PatchConsigneeSelfServiceCheckinFinish$Params } from '../fn/self-service-checkins/patch-consignee-self-service-checkin-finish';
import { postSelfCheckinData } from '../fn/self-service-checkins/post-self-checkin-data';
import { PostSelfCheckinData$Params } from '../fn/self-service-checkins/post-self-checkin-data';
import { SelfCheckinConfigAsSelfCheckin } from '../models/self-checkin-config-as-self-checkin';
import { SelfCheckinInformation } from '../models/self-checkin-information';


/**
 * Endpoints for the self service checkin by the driver
 */
@Injectable({ providedIn: 'root' })
export class SelfServiceCheckinsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getConsigneeDiscrepancyImageAttachment()` */
  static readonly GetConsigneeDiscrepancyImageAttachmentPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancy-images/{attachmentKey}';

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Pdf$Response(params: GetConsigneeDiscrepancyImageAttachment$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Pdf(params: GetConsigneeDiscrepancyImageAttachment$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heic$Response(params: GetConsigneeDiscrepancyImageAttachment$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heic(params: GetConsigneeDiscrepancyImageAttachment$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heif$Response(params: GetConsigneeDiscrepancyImageAttachment$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heif(params: GetConsigneeDiscrepancyImageAttachment$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Jpeg$Response(params: GetConsigneeDiscrepancyImageAttachment$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Jpeg(params: GetConsigneeDiscrepancyImageAttachment$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Png$Response(params: GetConsigneeDiscrepancyImageAttachment$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Png(params: GetConsigneeDiscrepancyImageAttachment$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeLoadCarrierFile()` */
  static readonly GetConsigneeLoadCarrierFilePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carrier-files/{attachmentKey}';

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Pdf$Response(params: GetConsigneeLoadCarrierFile$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Pdf(params: GetConsigneeLoadCarrierFile$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heic$Response(params: GetConsigneeLoadCarrierFile$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heic(params: GetConsigneeLoadCarrierFile$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heif$Response(params: GetConsigneeLoadCarrierFile$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heif(params: GetConsigneeLoadCarrierFile$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Jpeg$Response(params: GetConsigneeLoadCarrierFile$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Jpeg(params: GetConsigneeLoadCarrierFile$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Png$Response(params: GetConsigneeLoadCarrierFile$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Png(params: GetConsigneeLoadCarrierFile$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeDiscrepancyReceiptAttachment()` */
  static readonly GetConsigneeDiscrepancyReceiptAttachmentPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/receipts/{attachmentKey}';

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Pdf$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Pdf(params: GetConsigneeDiscrepancyReceiptAttachment$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heic$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heic(params: GetConsigneeDiscrepancyReceiptAttachment$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heif$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Heif(params: GetConsigneeDiscrepancyReceiptAttachment$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Jpeg$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Jpeg(params: GetConsigneeDiscrepancyReceiptAttachment$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyReceiptAttachment$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Png$Response(params: GetConsigneeDiscrepancyReceiptAttachment$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyReceiptAttachment$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a receipts file.
   *
   * get a single receipt for a delivery note for consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyReceiptAttachment$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyReceiptAttachment$Png(params: GetConsigneeDiscrepancyReceiptAttachment$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyReceiptAttachment$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getPalletTicket()` */
  static readonly GetPalletTicketPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pallet-tickets/{attachmentKey}';

  /**
   * Get a single PalletTicket for a delivery note.
   *
   * Get a single PalletTicket for a delivery note by organizationSiteKey,
   * deliveryNoteKey and palletTicketKey.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPalletTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletTicket$Response(params: GetPalletTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getPalletTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a single PalletTicket for a delivery note.
   *
   * Get a single PalletTicket for a delivery note by organizationSiteKey,
   * deliveryNoteKey and palletTicketKey.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPalletTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletTicket(params: GetPalletTicket$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getPalletTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `postSelfCheckinData()` */
  static readonly PostSelfCheckinDataPath = '/organization-sites/{organizationSiteKey}/self-checkins/checkins';

  /**
   * Allow drivers to create a new checkin at the self service terminal.
   *
   * When a driver scans the QR code at a self-service terminal for the
   * first time, necessary information such as the driver's name, phone
   * number, and reference number must be collected. If there's no
   * existing check-in document in the collection, a new will be
   * created. However, if a check-in document already exists, a new one
   * will still be generated, but it's crucial to note that the driver
   * information from the previous checkin will be transferred
   * (i.e., driverName, driverNumberPlate).
   * When a new check-in is created, the the newly generated check-in key
   * should be used. Using the old checkin key will lead to an error,
   * as the old check-in is no longer considered valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSelfCheckinData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSelfCheckinData$Response(params: PostSelfCheckinData$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeCheckinResponse>> {
    return postSelfCheckinData(this.http, this.rootUrl, params, context);
  }

  /**
   * Allow drivers to create a new checkin at the self service terminal.
   *
   * When a driver scans the QR code at a self-service terminal for the
   * first time, necessary information such as the driver's name, phone
   * number, and reference number must be collected. If there's no
   * existing check-in document in the collection, a new will be
   * created. However, if a check-in document already exists, a new one
   * will still be generated, but it's crucial to note that the driver
   * information from the previous checkin will be transferred
   * (i.e., driverName, driverNumberPlate).
   * When a new check-in is created, the the newly generated check-in key
   * should be used. Using the old checkin key will lead to an error,
   * as the old check-in is no longer considered valid.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postSelfCheckinData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSelfCheckinData(params: PostSelfCheckinData$Params, context?: HttpContext): Observable<ConsigneeCheckinResponse> {
    return this.postSelfCheckinData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeCheckinResponse>): ConsigneeCheckinResponse => r.body)
    );
  }

  /** Path part for operation `patchConsigneeSelfServiceCheckinFinish()` */
  static readonly PatchConsigneeSelfServiceCheckinFinishPath = '/organization-sites/{organizationSiteKey}/self-checkin/checkins/{checkinKey}';

  /**
   * Finish a self service checkin with signature.
   *
   * Finish a self service checkin by sending the signatures of the
   * driver. The signature must be a png in base64 format.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchConsigneeSelfServiceCheckinFinish()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchConsigneeSelfServiceCheckinFinish$Response(params: PatchConsigneeSelfServiceCheckinFinish$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchConsigneeSelfServiceCheckinFinish(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish a self service checkin with signature.
   *
   * Finish a self service checkin by sending the signatures of the
   * driver. The signature must be a png in base64 format.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchConsigneeSelfServiceCheckinFinish$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchConsigneeSelfServiceCheckinFinish(params: PatchConsigneeSelfServiceCheckinFinish$Params, context?: HttpContext): Observable<void> {
    return this.patchConsigneeSelfServiceCheckinFinish$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNoteFromPool()` */
  static readonly GetConsigneeDeliveryNoteFromPoolPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF as consignee.
   *
   * Get a delivery note PDF from the consignee delivery note pool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNoteFromPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteFromPool$Response(params: GetConsigneeDeliveryNoteFromPool$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDeliveryNoteFromPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF as consignee.
   *
   * Get a delivery note PDF from the consignee delivery note pool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNoteFromPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteFromPool(params: GetConsigneeDeliveryNoteFromPool$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDeliveryNoteFromPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getSelfCheckinInformation()` */
  static readonly GetSelfCheckinInformationPath = '/organization-sites/{organizationSiteKey}/self-checkin/information';

  /**
   * Get detailed information about a self checkin.
   *
   * Get detailed information about a self checkin by tourKey xor
   * bundleKey xor checkinToken. It returns especially the state
   * of the self checkin and the associated delivery notes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelfCheckinInformation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfCheckinInformation$Response(params: GetSelfCheckinInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfCheckinInformation>> {
    return getSelfCheckinInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get detailed information about a self checkin.
   *
   * Get detailed information about a self checkin by tourKey xor
   * bundleKey xor checkinToken. It returns especially the state
   * of the self checkin and the associated delivery notes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelfCheckinInformation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfCheckinInformation(params: GetSelfCheckinInformation$Params, context?: HttpContext): Observable<SelfCheckinInformation> {
    return this.getSelfCheckinInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfCheckinInformation>): SelfCheckinInformation => r.body)
    );
  }

  /** Path part for operation `getSelfCheckinConfigAsSelfCheckin()` */
  static readonly GetSelfCheckinConfigAsSelfCheckinPath = '/organization-sites/{organizationSiteKey}/self-checkin/config';

  /**
   * Get the config of a self checkin.
   *
   * Get the configuration of a self checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelfCheckinConfigAsSelfCheckin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfCheckinConfigAsSelfCheckin$Response(params: GetSelfCheckinConfigAsSelfCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfCheckinConfigAsSelfCheckin>> {
    return getSelfCheckinConfigAsSelfCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the config of a self checkin.
   *
   * Get the configuration of a self checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelfCheckinConfigAsSelfCheckin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfCheckinConfigAsSelfCheckin(params: GetSelfCheckinConfigAsSelfCheckin$Params, context?: HttpContext): Observable<SelfCheckinConfigAsSelfCheckin> {
    return this.getSelfCheckinConfigAsSelfCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfCheckinConfigAsSelfCheckin>): SelfCheckinConfigAsSelfCheckin => r.body)
    );
  }

  /** Path part for operation `getBundlesAndTours()` */
  static readonly GetBundlesAndToursPath = '/organization-sites/{organizationSiteKey}/consignee/bundlesAndTours/{lastFourBundleOrTourNumbers}';

  /**
   * Get the bundle or tour by last four places of number.
   *
   * Get the bundles or/and tours by the last four places of the bundleNumber or tourNumber.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBundlesAndTours()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundlesAndTours$Response(params: GetBundlesAndTours$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryNoteBundleOrTourArray>> {
    return getBundlesAndTours(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the bundle or tour by last four places of number.
   *
   * Get the bundles or/and tours by the last four places of the bundleNumber or tourNumber.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBundlesAndTours$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundlesAndTours(params: GetBundlesAndTours$Params, context?: HttpContext): Observable<DeliveryNoteBundleOrTourArray> {
    return this.getBundlesAndTours$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryNoteBundleOrTourArray>): DeliveryNoteBundleOrTourArray => r.body)
    );
  }

  /** Path part for operation `getConsigneeSelfCheckinLegalDocument()` */
  static readonly GetConsigneeSelfCheckinLegalDocumentPath = '/organization-sites/{organizationSiteKey}/self-checkin/legal-document';

  /**
   * Get the legal PDF document.
   *
   * Retrieve the legal PDF document associated with the organization
   * site. The file name is returned in the content-disposition section
   * of the header.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeSelfCheckinLegalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSelfCheckinLegalDocument$Response(params: GetConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeSelfCheckinLegalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the legal PDF document.
   *
   * Retrieve the legal PDF document associated with the organization
   * site. The file name is returned in the content-disposition section
   * of the header.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeSelfCheckinLegalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSelfCheckinLegalDocument(params: GetConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeSelfCheckinLegalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
