<mat-form-field
    appearance="fill"
    class="search">
    <!-- <span matPrefix class="tele-icons-outlined icon-color-primary">q</span> -->
    <mat-label>{{
        _keysForFilteringObj.length === 1
            ? (_keysForFilteringObj[0].label | translate)
            : ('OUTGOING-GOODS.SEARCH' | translate)
    }}</mat-label>
    <!-- <mat-label *ngIf="isStatus">{{ 'STATUS.STATE' | translate }}</mat-label> -->

    <mat-icon
        matPrefix
        *ngIf="!textFilter"
        fontSet="material-symbols-outlined"
        >search</mat-icon
    >

    <mat-icon
        *ngIf="textFilter"
        matPrefix
        (click)="resetFilter()"
        fontSet="material-symbols-outlined"
        >delete</mat-icon
    >

    <input
        aria-label="tableSearch"
        #filterSearch
        matInput
        id="filter-search"
        name="filter-search"
        [(ngModel)]="textFilter"
        (ngModelChange)="applyFilter($event)"
        [disabled]="ofContext"
        autocomplete="off" />

    <mat-icon
        aria-label="expand-filter"
        matSuffix
        [matMenuTriggerFor]="filterKeysMenu"
        fontSet="material-symbols-outlined"
        >expand_more</mat-icon
    >
</mat-form-field>
<mat-checkbox
    aria-label="show-closed-label"
    *ngIf="hasCheckBoxFilter && !ofContext"
    value="folderId"
    [(ngModel)]="checkboxFilter"
    (ngModelChange)="applyFilter()">
    <span *ngIf="showClosedLabel">{{ showClosedLabel | translate }}</span>
    <span *ngIf="!showClosedLabel && !isTours">
        {{ 'OUTGOING-GOODS.SHOW-CLOSED' | translate }}
    </span>
    <span *ngIf="!showClosedLabel && isTours">
        {{ 'SPEDITEUR.TABLE.SHOW-CLOSED' | translate }}
    </span>
</mat-checkbox>
<mat-menu
    #filterKeysMenu="matMenu"
    xPosition="before">
    <div (click)="$event.stopPropagation()">
        <div class="d-flex justify-content-between">
            <div class="menu-title">
                {{ 'SHARED.TABLE-FILTERED-BY' | translate }}
            </div>
            <div
                class="text-right pe-1 has-pointer"
                (click)="closeFilterMenu()">
                <mat-icon
                    aria-label="close"
                    class="icon-display"
                    fontSet="material-symbols-outlined"
                    >close</mat-icon
                >
            </div>
        </div>
        <mat-radio-group
            aria-labelledby="sorted-by"
            class="sorting-radio-group"
            [(ngModel)]="filterBy">
            <ng-container *ngFor="let item of keysForFilteringObj">
                <mat-radio-button
                    aria-label="filter-button"
                    *ngIf="
                        item.keyName !== 'status' &&
                        item.keyName !== 'deliveryNoteBundleStatus' &&
                        item.keyName !== 'discrepancies' &&
                        item.keyName !== 'palletTickets'
                    "
                    class="sorting-radio-button"
                    [value]="item.keyName"
                    (change)="onFilterChanges(item)"
                    color="primary">
                    <div (click)="$event.stopPropagation()">
                        {{ item.label | translate }}
                    </div>
                </mat-radio-button>

                <mat-radio-button
                    aria-label="sortByDiscrepancies"
                    *ngIf="item.keyName === 'discrepancies'"
                    class="sorting-radio-button"
                    [matMenuTriggerFor]="filterByDiscrepancies">
                    <div (click)="$event.stopPropagation()">
                        {{ item.label | translate }}
                    </div>
                </mat-radio-button>

                <mat-radio-button
                    aria-label="sortByPalletTicket"
                    *ngIf="item.keyName === 'palletTickets'"
                    class="sorting-radio-button"
                    [matMenuTriggerFor]="filterByPallets">
                    <div (click)="$event.stopPropagation()">
                        {{ item.label | translate }}
                    </div>
                </mat-radio-button>
            </ng-container>

            <mat-radio-button
                aria-label="sort-by-status"
                class="sorting-radio-button"
                aria-labelledby="sorted-by"
                [matMenuTriggerFor]="filterByStatus">
                <div (click)="$event.stopPropagation()">
                    {{
                        'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.STATE'
                            | translate
                    }}
                </div>
            </mat-radio-button>
        </mat-radio-group>

        <div
            class="action-bar"
            *ngIf="filterBy">
            <button
                mat-button
                (click)="resetFilter()">
                <span>Reset</span>
            </button>
        </div>
    </div>
</mat-menu>

<mat-menu #filterByStatus="matMenu">
    <div>
        <mat-radio-group
            class="sorting-radio-group"
            [(ngModel)]="filterBy">
            <mat-radio-button
                *ngFor="let status of statuses"
                aria-label="status-button"
                class="sorting-radio-button"
                [value]="status.keyName"
                (change)="onStatusFilterChanges(status)"
                color="primary">
                <div (click)="$event.stopPropagation()">
                    {{ status.label | translate }}
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</mat-menu>

<mat-menu #filterByDiscrepancies="matMenu">
    <div>
        <mat-radio-group
            class="sorting-radio-group"
            [(ngModel)]="filterBy">
            <mat-radio-button
                *ngFor="let bool of boolStatus"
                aria-label="discrepancy-button"
                [value]="bool.keyName"
                (change)="onDiscrepanciesFilterChanges(bool)"
                color="primary">
                <div (click)="$event.stopPropagation()">
                    {{ bool.label | translate }}
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</mat-menu>

<mat-menu #filterByPallets="matMenu">
    <div>
        <mat-radio-group
            class="sorting-radio-group"
            [(ngModel)]="filterBy">
            <mat-radio-button
                *ngFor="let bool of boolStatus"
                aria-label="pallets-button"
                class="sorting-radio-button"
                [value]="bool.keyName"
                (change)="onPalletFilterChanges(bool)"
                color="primary">
                <div (click)="$event.stopPropagation()">
                    {{ bool.label | translate }}
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</mat-menu>
