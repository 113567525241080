<div class="tw-flex tw-h-full tw-flex-col tw-overflow-y-hidden">
    <div class="tw-flex tw-items-center tw-justify-between">
        <div
            class="mat-title"
            *ngIf="showText">
            {{ 'GENERAL.DELIVERY' | translate }}
        </div>
        <div *ngIf="!showText"></div>

        <div>
            <button
                mat-icon-button
                mat-dialog-close>
                <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
            </button>
        </div>
    </div>

    <ngx-extended-pdf-viewer
        *ngIf="pdfSrcData"
        [src]="pdfSrcData"
        [height]="'100%'"
        [showToolbar]="false"
        [showBorders]="false">
    </ngx-extended-pdf-viewer>
</div>
