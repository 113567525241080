import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AppConfig } from './app.config';
import { Customer } from './core/enums/customer.enum';
import { EventsService } from './core/services/event.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    showHeader: boolean = true;
    isSelfService: boolean = false;
    @ViewChild('contentArea') contentArea: ElementRef;

    private retryCount = 0;
    private maxRetries = 100;
    private retryDelay = 300;

    constructor(
        private renderer: Renderer2,
        private translate: TranslateService,
        private router: Router,
        private appConfig: AppConfig,
        private eventsService: EventsService
    ) {}

    ngOnInit(): void {
        this.eventsService
            .subscribe('user-idle:show')
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                Swal.fire({
                    title: this.translate.instant('GLOBAL.IDLE.ACTIVE'),
                    text: this.translate.instant('GLOBAL.IDLE.STILL-ACTIVE'),
                    icon: 'question',
                    showCancelButton: false,
                    confirmButtonColor: '#002c6c',
                    confirmButtonText:
                        this.translate.instant('GLOBAL.IDLE.YES'),
                    allowOutsideClick: false,
                }).then(result => {
                    location.reload();
                });
            });

        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                const showNoHeader = [
                    '/login',
                    '/forgot-password',
                    '/check-email',
                    '/set-new-password',
                    '/maintenance',
                    '/external/reset-user-password',
                    '/selfservice',
                ];

                this.showHeader = !showNoHeader.includes(
                    event.urlAfterRedirects
                );
                this.isSelfService =
                    event.urlAfterRedirects.startsWith('/selfservice');
                if (!this.isSelfService) {
                    sessionStorage.removeItem('reloadRoute');
                }
                const reloadRoute = sessionStorage.getItem('reloadRoute');
                const currentUrl = this.router.url;
                if (reloadRoute) {
                    sessionStorage.removeItem('reloadRoute');
                    if (
                        reloadRoute.startsWith('/selfservice') &&
                        currentUrl.startsWith('/selfservice')
                    ) {
                        this.router.navigateByUrl('/selfservice');
                        this.retryAdjustContentHeight();
                    }
                }
            }
        });

        if (this.appConfig.customer === Customer.mercedes) {
            this.translate.setDefaultLang('mercedes');
            this.translate.use('mercedes');
        } else {
            this.translate.setDefaultLang(environment.defaultLang);
            this.translate.use(environment.defaultLang);
        }
    }

    ngAfterViewInit() {
        if (this.isSelfService) {
            this.adjustContentHeight();
        }
    }

    @HostListener('window:resize')
    onResize() {
        if (this.isSelfService) {
            this.adjustContentHeight();
        }
    }

    @HostListener('window:beforeunload')
    onBeforeUnload() {
        const currentUrl = this.router.url;
        if (currentUrl.startsWith('/selfservice')) {
            sessionStorage.setItem('reloadRoute', currentUrl);
        } else {
            sessionStorage.removeItem('reloadRoute');
        }
    }

    private adjustContentHeight() {
        const windowHeight = window.innerHeight;
        const adjustedHeight = windowHeight;
        this.renderer.setStyle(
            this.contentArea.nativeElement,
            'height',
            `${adjustedHeight}px`
        );
    }

    private retryAdjustContentHeight() {
        if (this.isSelfService) {
            if (this.retryCount < this.maxRetries) {
                setTimeout(() => {
                    if (this.contentArea && this.contentArea.nativeElement) {
                        this.adjustContentHeight();
                    } else {
                        this.retryCount++;
                        this.retryAdjustContentHeight();
                    }
                }, this.retryDelay);
            }
        }
    }
}
