/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EventsArray } from '../../models/events-array';
import { EventType } from '../../models/event-type';
import { KeyType } from '../../models/key-type';
import { SortType } from '../../models/sort-type';
import { TimestampType } from '../../models/timestamp-type';

export interface GetEvents$Params {
  organizationKey: KeyType;
  sort?: SortType;
  gt?: TimestampType;
  lt?: TimestampType;
  siteKeys: Array<KeyType>;
  eventTypes?: Array<EventType>;
  limit?: number;
}

export function getEvents(http: HttpClient, rootUrl: string, params: GetEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<EventsArray>> {
  const rb = new RequestBuilder(rootUrl, getEvents.PATH, 'get');
  if (params) {
    rb.path('organizationKey', params.organizationKey, {});
    rb.query('sort', params.sort, {});
    rb.query('gt', params.gt, {});
    rb.query('lt', params.lt, {});
    rb.query('siteKeys', params.siteKeys, {});
    rb.query('eventTypes', params.eventTypes, {});
    rb.query('limit', params.limit, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EventsArray>;
    })
  );
}

getEvents.PATH = '/organizations/{organizationKey}/events';
