import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { NotificationsService } from '../../services/notifications.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsArray, UserRoles } from 'api/models';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { UsersService } from 'api/services';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-overview-buttons',
    templateUrl: './overview-buttons.component.html',
    styleUrls: ['./overview-buttons.component.scss'],
})
export class OverviewButtonsComponent implements OnInit {
    @Output()
    selectedView: EventEmitter<string> = new EventEmitter<string>();
    @Input() viewType = 'folder';
    newNotifications: boolean = false;

    constructor(
        private notificationService: NotificationsService,
        private sessionService: SessionService,
        private usersService: UsersService
    ) {}
    eventsArray: EventsArray | null;
    hasSelfServiceAccess = false;

    allowedRoles = [
        UserRoles.Carrier,
        UserRoles.Consignee,
        UserRoles.Consignor,
        UserRoles.OrganizationAdmin,
        UserRoles.SiteAdmin,
        UserRoles.SelfCheckin,
    ];

    async ngOnInit(): Promise<void> {
        await this.hasSelfService();
        if (this.hasSelfServiceAccess) {
            this.notificationService.events$
                .pipe(untilDestroyed(this))
                .subscribe(events => {
                    this.eventsArray = events;
                });
            this.notificationService.newNotifications$
                .pipe(untilDestroyed(this))
                .subscribe(newNotifications => {
                    this.newNotifications = newNotifications;
                });
        }
    }

    /**
     * Changes the view type based on the button toggle change event.
     * If the 'notifications' view is selected, it sets new notifications to false.
     * @param event The button toggle change event.
     */
    changeView(event: MatButtonToggleChange): void {
        this.selectedView.emit(event.value);
        this.viewType = event.value;
        if (event.value === 'notifications') {
            this.notificationService.setNewNotificationsToFalse();
        }
    }

    /**
     * Checks if the user has self-service check-in access.
     * Retrieves the organization sites and filters them based on user roles.
     * Sets the `hasSelfServiceAccess` property based on the presence of self-checkin enabled sites.
     */
    async hasSelfService() {
        const hasSelfCheckin = this.sessionService
            .getMyOrganizationSites()
            .filter(allSite =>
                allSite.roles!.some((role: UserRoles) =>
                    this.allowedRoles.includes(role)
                )
            )
            .some(allSite => allSite.selfCheckin);
        this.hasSelfServiceAccess = hasSelfCheckin;
    }
}
