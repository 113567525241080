import { Component } from '@angular/core';

@Component({
    selector: 'app-legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.scss'],
})
export class LegalComponent {
    constructor() {}

    back(): void {
        window.history.back();
    }
}
