/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { ConsigneeAttachmentResponse } from '../models/consignee-attachment-response';
import { deleteConsigneeLoadCarrierFile } from '../fn/consignee-load-carrier-files/delete-consignee-load-carrier-file';
import { DeleteConsigneeLoadCarrierFile$Params } from '../fn/consignee-load-carrier-files/delete-consignee-load-carrier-file';
import { GetConsigneeAttachmentsResponse } from '../models/get-consignee-attachments-response';
import { getConsigneeLoadCarrierFile$Heic } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heic';
import { GetConsigneeLoadCarrierFile$Heic$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heic';
import { getConsigneeLoadCarrierFile$Heif } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heif';
import { GetConsigneeLoadCarrierFile$Heif$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-heif';
import { getConsigneeLoadCarrierFile$Jpeg } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-jpeg';
import { GetConsigneeLoadCarrierFile$Jpeg$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-jpeg';
import { getConsigneeLoadCarrierFile$Pdf } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-pdf';
import { GetConsigneeLoadCarrierFile$Pdf$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-pdf';
import { getConsigneeLoadCarrierFile$Png } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-png';
import { GetConsigneeLoadCarrierFile$Png$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-file-png';
import { getConsigneeLoadCarrierFilesMetadata } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-files-metadata';
import { GetConsigneeLoadCarrierFilesMetadata$Params } from '../fn/consignee-load-carrier-files/get-consignee-load-carrier-files-metadata';
import { postConsigneeLoadCarrierFileToDeliveryNote$Heic } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-heic';
import { PostConsigneeLoadCarrierFileToDeliveryNote$Heic$Params } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-heic';
import { postConsigneeLoadCarrierFileToDeliveryNote$Heif } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-heif';
import { PostConsigneeLoadCarrierFileToDeliveryNote$Heif$Params } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-heif';
import { postConsigneeLoadCarrierFileToDeliveryNote$Jpeg } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-jpeg';
import { PostConsigneeLoadCarrierFileToDeliveryNote$Jpeg$Params } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-jpeg';
import { postConsigneeLoadCarrierFileToDeliveryNote$Png } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-png';
import { PostConsigneeLoadCarrierFileToDeliveryNote$Png$Params } from '../fn/consignee-load-carrier-files/post-consignee-load-carrier-file-to-delivery-note-png';

@Injectable({ providedIn: 'root' })
export class ConsigneeLoadCarrierFilesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postConsigneeLoadCarrierFileToDeliveryNote()` */
  static readonly PostConsigneeLoadCarrierFileToDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carrier-files/file/{fileName}';

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeLoadCarrierFileToDeliveryNote$Heic()` instead.
   *
   * This method sends `image/heic` and handles request body of type `image/heic`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Heic$Response(params: PostConsigneeLoadCarrierFileToDeliveryNote$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeLoadCarrierFileToDeliveryNote$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeLoadCarrierFileToDeliveryNote$Heic$Response()` instead.
   *
   * This method sends `image/heic` and handles request body of type `image/heic`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Heic(params: PostConsigneeLoadCarrierFileToDeliveryNote$Heic$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeLoadCarrierFileToDeliveryNote$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeLoadCarrierFileToDeliveryNote$Heif()` instead.
   *
   * This method sends `image/heif` and handles request body of type `image/heif`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Heif$Response(params: PostConsigneeLoadCarrierFileToDeliveryNote$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeLoadCarrierFileToDeliveryNote$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeLoadCarrierFileToDeliveryNote$Heif$Response()` instead.
   *
   * This method sends `image/heif` and handles request body of type `image/heif`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Heif(params: PostConsigneeLoadCarrierFileToDeliveryNote$Heif$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeLoadCarrierFileToDeliveryNote$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeLoadCarrierFileToDeliveryNote$Jpeg()` instead.
   *
   * This method sends `image/jpeg` and handles request body of type `image/jpeg`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Jpeg$Response(params: PostConsigneeLoadCarrierFileToDeliveryNote$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeLoadCarrierFileToDeliveryNote$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeLoadCarrierFileToDeliveryNote$Jpeg$Response()` instead.
   *
   * This method sends `image/jpeg` and handles request body of type `image/jpeg`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Jpeg(params: PostConsigneeLoadCarrierFileToDeliveryNote$Jpeg$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeLoadCarrierFileToDeliveryNote$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeLoadCarrierFileToDeliveryNote$Png()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Png$Response(params: PostConsigneeLoadCarrierFileToDeliveryNote$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeLoadCarrierFileToDeliveryNote$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an file for a load carrier exchange.
   *
   * Add a new file attachment for load carrier exchange to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are png, jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeLoadCarrierFileToDeliveryNote$Png$Response()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  postConsigneeLoadCarrierFileToDeliveryNote$Png(params: PostConsigneeLoadCarrierFileToDeliveryNote$Png$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeLoadCarrierFileToDeliveryNote$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /** Path part for operation `getConsigneeLoadCarrierFile()` */
  static readonly GetConsigneeLoadCarrierFilePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carrier-files/{attachmentKey}';

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Pdf$Response(params: GetConsigneeLoadCarrierFile$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Pdf(params: GetConsigneeLoadCarrierFile$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heic$Response(params: GetConsigneeLoadCarrierFile$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heic(params: GetConsigneeLoadCarrierFile$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heif$Response(params: GetConsigneeLoadCarrierFile$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Heif(params: GetConsigneeLoadCarrierFile$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Jpeg$Response(params: GetConsigneeLoadCarrierFile$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Jpeg(params: GetConsigneeLoadCarrierFile$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFile$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Png$Response(params: GetConsigneeLoadCarrierFile$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeLoadCarrierFile$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an load carrier file forr a delivery note.
   *
   * Retrieving a single load carrier file for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFile$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFile$Png(params: GetConsigneeLoadCarrierFile$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeLoadCarrierFile$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `deleteConsigneeLoadCarrierFile()` */
  static readonly DeleteConsigneeLoadCarrierFilePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carrier-files/{attachmentKey}';

  /**
   * Delete an file for a load carrier.
   *
   * Delete a load carrier attachment for the consignee from a delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConsigneeLoadCarrierFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConsigneeLoadCarrierFile$Response(params: DeleteConsigneeLoadCarrierFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteConsigneeLoadCarrierFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an file for a load carrier.
   *
   * Delete a load carrier attachment for the consignee from a delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConsigneeLoadCarrierFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConsigneeLoadCarrierFile(params: DeleteConsigneeLoadCarrierFile$Params, context?: HttpContext): Observable<void> {
    return this.deleteConsigneeLoadCarrierFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConsigneeLoadCarrierFilesMetadata()` */
  static readonly GetConsigneeLoadCarrierFilesMetadataPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/load-carrier-files';

  /**
   * Get all load carrier files for a Delvivery Note.
   *
   * Get a list of metadata for load carrier files that are attached to a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeLoadCarrierFilesMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFilesMetadata$Response(params: GetConsigneeLoadCarrierFilesMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeAttachmentsResponse>> {
    return getConsigneeLoadCarrierFilesMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all load carrier files for a Delvivery Note.
   *
   * Get a list of metadata for load carrier files that are attached to a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeLoadCarrierFilesMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeLoadCarrierFilesMetadata(params: GetConsigneeLoadCarrierFilesMetadata$Params, context?: HttpContext): Observable<GetConsigneeAttachmentsResponse> {
    return this.getConsigneeLoadCarrierFilesMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeAttachmentsResponse>): GetConsigneeAttachmentsResponse => r.body)
    );
  }

}
