import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ServiceInterceptor implements HttpInterceptor {
    constructor(private readonly sessionService: SessionService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const sessionId = this.sessionService.getSessionId();
        let headers = null;

        if (sessionId) {
            headers = req.headers.set('dlssessionid', sessionId);
        }

        if (headers) {
            // Clone the request to add the new header.
            const authReq = req.clone({ headers });
            // Pass on the cloned request instead of the original request.
            return next.handle(authReq);
        }

        return next.handle(req);
    }
}
