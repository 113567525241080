import { Injectable } from '@angular/core';
import {
    EventsService,
    OrganizationSitesService,
    UsersService,
} from 'api/services';
import {
    ConsigneeService,
    DeliveryNoteBundleWithOrgSiteKey,
} from '@app/consignee/services/consignee.service';
import { BehaviorSubject, timer, switchMap, catchError, of } from 'rxjs';
import { lastValueFrom } from 'rxjs';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import {
    EventType,
    EventsArray,
    OrganizationSite,
    SortType,
    TimestampType,
    UserRoles,
} from 'api/models';
import { register } from 'ts-node';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { ApiInterceptor } from '@app/core/interceptors/api.interceptor';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private eventsSubject = new BehaviorSubject<EventsArray | null>(null);
    public events$ = this.eventsSubject.asObservable();

    private newNotificationsSubject: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public newNotifications$ = this.newNotificationsSubject.asObservable();

    private hasErrorAllrdyReceived = false;

    allowedRoles = [
        UserRoles.Carrier,
        UserRoles.Consignee,
        UserRoles.Consignor,
        UserRoles.OrganizationAdmin,
        UserRoles.SiteAdmin,
        UserRoles.SelfCheckin,
    ];

    constructor(
        private eventsService: EventsService,
        private sessionService: SessionService,
        private storageService: StorageService,
        private apiInjector: ApiInterceptor
    ) {
        this.pollEvents();
    }

    /**
     * Sets the notification setting to either enable or disable notifications.
     * @param setNotification A boolean value indicating whether to enable or disable notifications.
     */
    setNotificationSetting(setNotification: boolean) {
        const userKey = this.sessionService.getSession()._key;
        this.storageService.setItem(
            'receiveNotifications' + '_' + userKey,
            setNotification
        );
    }

    /**
     * Retrieves the current notification setting.
     * @returns A boolean value indicating whether notifications are enabled or disabled.
     */
    getNotificationSetting(): boolean {
        const userKey = this.sessionService.getSession()._key;
        const receiveNotifications = this.storageService.getItem(
            'receiveNotifications' + '_' + userKey
        );

        if (
            receiveNotifications === 'true' ||
            receiveNotifications === 'false'
        ) {
            const isNotificationEnabled = JSON.parse(receiveNotifications);
            return isNotificationEnabled;
        } else {
            return true;
        }
    }

    /**
     * Polls events from the events service at regular intervals and updates the eventsSubject.
     * If new events are found, updates the newNotificationsSubject based on the notification setting.
     */
    private async pollEvents(): Promise<void> {
        const orgKey = this.sessionService.getSession().organizations[0]._key;
        let gt: number = new Date().getTime() - 8 * 60 * 60 * 1000;

        const siteKeys = this.sessionService
            .getMyOrganizationSites()
            .filter(allSite =>
                allSite.roles!.some((role: UserRoles) =>
                    this.allowedRoles.includes(role)
                )
            )
            .filter(allSite => allSite.selfCheckin)
            .map(allSite => allSite._key);
        const eventTypes: Array<EventType> = [
            EventType.SelfCheckinCheckinFinished,
            EventType.SelfCheckinRegistrationCompleted,
            EventType.SelfCheckinDriverWaiting,
        ];
        const limit = 50;
        if (!siteKeys || siteKeys.length === 0) {
            return;
        }
        let initialLoadDone = false;

        const params = {
            organizationKey: orgKey,
            sort: 'asc' as SortType,
            gt,
            siteKeys,
            eventTypes,
            limit,
        };

        const fetchEvents = async () => {
            try {
                if (this.hasErrorAllrdyReceived) {
                    this.apiInjector.disableToastService();
                }
                const eventsResponse = await lastValueFrom(
                    this.eventsService.getEvents(params)
                );
                if (this.hasErrorAllrdyReceived) {
                    this.apiInjector.enableToastService();
                }
                const currentEvents = this.eventsSubject.value || [];

                const newEvents = eventsResponse.filter(
                    event =>
                        !currentEvents.some(
                            currentEvent =>
                                currentEvent.eventKey === event.eventKey
                        )
                );

                if (newEvents.length > 0) {
                    this.eventsSubject.next([...currentEvents, ...newEvents]);
                    const receiveNotifications: boolean =
                        this.getNotificationSetting();
                    if (receiveNotifications) {
                        this.newNotificationsSubject.next(true);
                    }

                    gt = Math.max(
                        ...[...currentEvents, ...newEvents].map(event =>
                            new Date(event.dateCreated).getTime()
                        ),
                        gt
                    );
                    params.gt = gt;

                    if (!initialLoadDone) {
                        initialLoadDone = true;
                        params.sort = 'desc' as SortType;
                    }
                } else if (!initialLoadDone) {
                    initialLoadDone = true;
                    gt = new Date().getTime() - 60 * 1000;
                    params.gt = gt;
                } else {
                    gt = new Date().getTime() - 60 * 1000;
                    params.gt = gt;
                }
                if (eventsResponse) {
                    this.hasErrorAllrdyReceived = false;
                }
            } catch (error) {
                this.hasErrorAllrdyReceived = true;
                if (this.hasErrorAllrdyReceived) {
                    this.apiInjector.enableToastService();
                }
                console.error('Failed to fetch events:', error);
                this.eventsSubject.next(null);
                throw error;
            }
        };

        this.apiInjector.enableToastService();

        while (!initialLoadDone) {
            try {
                await fetchEvents();
            } catch {
                await new Promise(resolve => setTimeout(resolve, 10000));
            }
        }

        timer(0, 5000).subscribe(async () => {
            const hasSelfCheckin = this.sessionService
                .getMyOrganizationSites()
                .filter(allSite =>
                    allSite.roles!.some((role: UserRoles) =>
                        this.allowedRoles.includes(role)
                    )
                )
                .some(allSite => allSite.selfCheckin);
            if (hasSelfCheckin) {
                await fetchEvents();
            }
        });
    }

    /**
     * Sets the new notifications indicator to false.
     */
    setNewNotificationsToFalse() {
        this.newNotificationsSubject.next(false);
    }
}
