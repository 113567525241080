/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { KeyType } from '../../models/key-type';
import { NameTypeNullAble } from '../../models/name-type-null-able';
import { SelfCheckinInformation } from '../../models/self-checkin-information';
import { Token64LengthType } from '../../models/token-64-length-type';

export interface GetSelfCheckinInformation$Params {
  organizationSiteKey: KeyType;
  checkinToken?: Token64LengthType;
  tourNumber?: NameTypeNullAble;
  bundleNumber?: NameTypeNullAble;
}

export function getSelfCheckinInformation(http: HttpClient, rootUrl: string, params: GetSelfCheckinInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfCheckinInformation>> {
  const rb = new RequestBuilder(rootUrl, getSelfCheckinInformation.PATH, 'get');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
    rb.query('checkinToken', params.checkinToken, {});
    rb.query('tourNumber', params.tourNumber, {});
    rb.query('bundleNumber', params.bundleNumber, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SelfCheckinInformation>;
    })
  );
}

getSelfCheckinInformation.PATH = '/organization-sites/{organizationSiteKey}/self-checkin/information';
