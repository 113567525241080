import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertDeliveryNoteNumber',
})
export class DeliveryNoteNumberPipe implements PipeTransform {
    transform(fileName: string): string {
        if (fileName) {
            return fileName.split('.')[0];
        }
        return fileName;
    }

    transformPdf(fileName: string): string {
        if (fileName) {
            return fileName.split('.pdf')[0];
        }
        return fileName;
    }
}
