<footer
    class="fixedFooter tw-z-10 tw-fixed tw-bottom-0 tw-left-0"
    *ngIf="footerVisibility">
    <div class="footer">
        <div class="navigation">
            <div class="navitems">
                <div
                    class="navitem"
                    *ngFor="let footerLink of footerLinks">
                    <a
                        [href]="footerLink.link"
                        target="_blank"
                        >{{ footerLink.translationKey | translate }}</a
                    >
                </div>
            </div>
        </div>
        <div
            class="copyright"
            [matTooltip]="versionString"
            matTooltipClass="tw-whitespace-pre-line tw-max-w-[320px]">
            <div class="navitem">GS1 Germany - powered by T-Systems</div>
        </div>
    </div>
</footer>
