<div class="tw-flex tw-justify-between">
    <h1 mat-dialog-title>
        {{ 'SPEDITEUR.TOUR-PLANNING.NAME-OPTIONAL' | translate }}
    </h1>
</div>

<div class="tw-mt-5 tw-flex">
    <mat-form-field appearance="fill">
        <mat-label>{{
            'SPEDITEUR.TOUR-PLANNING.TOUR-NAME' | translate
        }}</mat-label>
        <input
            aria-label="tourNameInput"
            matInput
            [(ngModel)]="name" />
    </mat-form-field>
</div>

<div
    mat-dialog-actions
    align="end"
    class="tw-mt-3">
    <button
        mat-button
        (click)="skip()">
        {{ 'SPEDITEUR.TOUR-PLANNING.SKIP' | translate }}
    </button>
    <button
        *ngIf="isTourEdit"
        mat-raised-button
        color="primary"
        (click)="saveAndClose()"
        cdkFocusInitial
        [disabled]="isEditTourConfirmButtonDisabled()">
        {{ 'OUTGOING-GOODS.EDIT-NOTE-DIALOG.ACTION' | translate }}
    </button>
    <button
        *ngIf="!isTourEdit"
        mat-raised-button
        color="primary"
        (click)="saveAndClose()"
        cdkFocusInitial
        [disabled]="name === ''">
        {{ 'SPEDITEUR.TOUR-PLANNING.CONFIRM-NAME' | translate }}
    </button>
</div>
