import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IncomingData {
    pdfSrc: Blob;
}

@Component({
    selector: 'app-show-pdf-dialog',
    templateUrl: './show-pdf-dialog.component.html',
    styleUrls: ['./show-pdf-dialog.component.scss'],
})
export class ShowPdfDialogComponent implements OnInit {
    pdfSrcData: Blob;
    showText: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialogRef: MatDialogRef<ShowPdfDialogComponent>
    ) {}

    ngOnInit(): void {
        if (this.data) {
            console.log(this.data.data);
            this.pdfSrcData = this.data.data;
            if (this.data.showText !== undefined) {
                this.showText = this.data.showText;
            }
        }
    }
}
