/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EventsArray } from '../models/events-array';
import { getEvents } from '../fn/events/get-events';
import { GetEvents$Params } from '../fn/events/get-events';
import { triggerEvent } from '../fn/events/trigger-event';
import { TriggerEvent$Params } from '../fn/events/trigger-event';
import { TriggerEventResponse } from '../models/trigger-event-response';

@Injectable({ providedIn: 'root' })
export class EventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEvents()` */
  static readonly GetEventsPath = '/organizations/{organizationKey}/events';

  /**
   * Get Event.
   *
   * Get event from the events log. The filtering parameters are optional and work on the dateCreated field of the event.
   * The default sort order is descending. With gt and lt you can get all events greater than or less than a specific date.
   * With siteKeys you can filter events by site. With eventTypes you can filter events by type.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents$Response(params: GetEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<EventsArray>> {
    return getEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Event.
   *
   * Get event from the events log. The filtering parameters are optional and work on the dateCreated field of the event.
   * The default sort order is descending. With gt and lt you can get all events greater than or less than a specific date.
   * With siteKeys you can filter events by site. With eventTypes you can filter events by type.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents(params: GetEvents$Params, context?: HttpContext): Observable<EventsArray> {
    return this.getEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventsArray>): EventsArray => r.body)
    );
  }

  /** Path part for operation `triggerEvent()` */
  static readonly TriggerEventPath = '/organization-sites/{organizationSiteKey}/events';

  /**
   * Trigger a event manually.
   *
   * Trigger a event manually with the given payload.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerEvent$Response(params: TriggerEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<TriggerEventResponse>> {
    return triggerEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger a event manually.
   *
   * Trigger a event manually with the given payload.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `triggerEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerEvent(params: TriggerEvent$Params, context?: HttpContext): Observable<TriggerEventResponse> {
    return this.triggerEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<TriggerEventResponse>): TriggerEventResponse => r.body)
    );
  }

}
