/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AdministrationUserDetails } from '../models/administration-user-details';
import { AdministrationUsersInformation } from '../models/administration-users-information';
import { ApiUsers } from '../models/api-users';
import { archiveAdministrationOrganizationSite } from '../fn/administration/archive-administration-organization-site';
import { ArchiveAdministrationOrganizationSite$Params } from '../fn/administration/archive-administration-organization-site';
import { createApiKeyForOrganizationSitesWithRoles } from '../fn/administration/create-api-key-for-organization-sites-with-roles';
import { CreateApiKeyForOrganizationSitesWithRoles$Params } from '../fn/administration/create-api-key-for-organization-sites-with-roles';
import { deleteAdministrationRemoteAccessToSite } from '../fn/administration/delete-administration-remote-access-to-site';
import { DeleteAdministrationRemoteAccessToSite$Params } from '../fn/administration/delete-administration-remote-access-to-site';
import { getAdministrationLoadCarriersReportsCsv } from '../fn/administration/get-administration-load-carriers-reports-csv';
import { GetAdministrationLoadCarriersReportsCsv$Params } from '../fn/administration/get-administration-load-carriers-reports-csv';
import { getAdministrationOrganizationSites } from '../fn/administration/get-administration-organization-sites';
import { GetAdministrationOrganizationSites$Params } from '../fn/administration/get-administration-organization-sites';
import { getAdministrationOrganizationSiteUser } from '../fn/administration/get-administration-organization-site-user';
import { GetAdministrationOrganizationSiteUser$Params } from '../fn/administration/get-administration-organization-site-user';
import { getAdministrationOrganizationSiteUsers } from '../fn/administration/get-administration-organization-site-users';
import { GetAdministrationOrganizationSiteUsers$Params } from '../fn/administration/get-administration-organization-site-users';
import { getAdministrationOrganizationUser } from '../fn/administration/get-administration-organization-user';
import { GetAdministrationOrganizationUser$Params } from '../fn/administration/get-administration-organization-user';
import { getAdministrationOrganizationUsers } from '../fn/administration/get-administration-organization-users';
import { GetAdministrationOrganizationUsers$Params } from '../fn/administration/get-administration-organization-users';
import { getAdministrationRemoteAccesses } from '../fn/administration/get-administration-remote-accesses';
import { GetAdministrationRemoteAccesses$Params } from '../fn/administration/get-administration-remote-accesses';
import { getApiKeysForOrganization } from '../fn/administration/get-api-keys-for-organization';
import { GetApiKeysForOrganization$Params } from '../fn/administration/get-api-keys-for-organization';
import { OrganizationSite } from '../models/organization-site';
import { patchAdministrationOrganizationSite } from '../fn/administration/patch-administration-organization-site';
import { PatchAdministrationOrganizationSite$Params } from '../fn/administration/patch-administration-organization-site';
import { postAdministrationOrganizationSite } from '../fn/administration/post-administration-organization-site';
import { PostAdministrationOrganizationSite$Params } from '../fn/administration/post-administration-organization-site';
import { postAdministrationOrganizationSitesUser } from '../fn/administration/post-administration-organization-sites-user';
import { PostAdministrationOrganizationSitesUser$Params } from '../fn/administration/post-administration-organization-sites-user';
import { postAdministrationOrganizationUser } from '../fn/administration/post-administration-organization-user';
import { PostAdministrationOrganizationUser$Params } from '../fn/administration/post-administration-organization-user';
import { postAdministrationRemoteAccessToSite } from '../fn/administration/post-administration-remote-access-to-site';
import { PostAdministrationRemoteAccessToSite$Params } from '../fn/administration/post-administration-remote-access-to-site';
import { PostApiUserResponse } from '../models/post-api-user-response';
import { putAdministrationOrganizationSitesUser } from '../fn/administration/put-administration-organization-sites-user';
import { PutAdministrationOrganizationSitesUser$Params } from '../fn/administration/put-administration-organization-sites-user';
import { putAdministrationOrganizationUser } from '../fn/administration/put-administration-organization-user';
import { PutAdministrationOrganizationUser$Params } from '../fn/administration/put-administration-organization-user';
import { putApiKeyForOrganizationWithRoles } from '../fn/administration/put-api-key-for-organization-with-roles';
import { PutApiKeyForOrganizationWithRoles$Params } from '../fn/administration/put-api-key-for-organization-with-roles';
import { RemoteAccessOrganizationSites } from '../models/remote-access-organization-sites';


/**
 * Endpoints to handle organization administration funcitons
 */
@Injectable({ providedIn: 'root' })
export class AdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createApiKeyForOrganizationSitesWithRoles()` */
  static readonly CreateApiKeyForOrganizationSitesWithRolesPath = '/administration/organizations/{organizationKey}/api-users-keys';

  /**
   * creates a API key.
   *
   * Create a new api user and add this api user to the organization and the given organization sites
   * and equip the new api user with permissions according to the given roles for each site.
   * The requesting user must have access to the organization the api new user is added.
   * Returns a sessionId that can't received a second time.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiKeyForOrganizationSitesWithRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiKeyForOrganizationSitesWithRoles$Response(params: CreateApiKeyForOrganizationSitesWithRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<PostApiUserResponse>> {
    return createApiKeyForOrganizationSitesWithRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * creates a API key.
   *
   * Create a new api user and add this api user to the organization and the given organization sites
   * and equip the new api user with permissions according to the given roles for each site.
   * The requesting user must have access to the organization the api new user is added.
   * Returns a sessionId that can't received a second time.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApiKeyForOrganizationSitesWithRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiKeyForOrganizationSitesWithRoles(params: CreateApiKeyForOrganizationSitesWithRoles$Params, context?: HttpContext): Observable<PostApiUserResponse> {
    return this.createApiKeyForOrganizationSitesWithRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostApiUserResponse>): PostApiUserResponse => r.body)
    );
  }

  /** Path part for operation `getApiKeysForOrganization()` */
  static readonly GetApiKeysForOrganizationPath = '/administration/organizations/{organizationKey}/api-keys';

  /**
   * Get list of all API keys for organization.
   *
   * Get a list of all API keys for the given organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiKeysForOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiKeysForOrganization$Response(params: GetApiKeysForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiUsers>> {
    return getApiKeysForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of all API keys for organization.
   *
   * Get a list of all API keys for the given organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiKeysForOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiKeysForOrganization(params: GetApiKeysForOrganization$Params, context?: HttpContext): Observable<ApiUsers> {
    return this.getApiKeysForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiUsers>): ApiUsers => r.body)
    );
  }

  /** Path part for operation `putApiKeyForOrganizationWithRoles()` */
  static readonly PutApiKeyForOrganizationWithRolesPath = '/administration/organizations/{organizationKey}/api-users-keys/{userKey}';

  /**
   * Set new permissions for API key.
   *
   * New permissions are set based on the given roles and organization sites.
   * You can also edit the API key user properties.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putApiKeyForOrganizationWithRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApiKeyForOrganizationWithRoles$Response(params: PutApiKeyForOrganizationWithRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putApiKeyForOrganizationWithRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Set new permissions for API key.
   *
   * New permissions are set based on the given roles and organization sites.
   * You can also edit the API key user properties.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putApiKeyForOrganizationWithRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApiKeyForOrganizationWithRoles(params: PutApiKeyForOrganizationWithRoles$Params, context?: HttpContext): Observable<void> {
    return this.putApiKeyForOrganizationWithRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAdministrationLoadCarriersReportsCsv()` */
  static readonly GetAdministrationLoadCarriersReportsCsvPath = '/administration/organization-sites/{organizationSiteKey}/load-carriers/reports/csv';

  /**
   * Get load carriers report as csv for provided organization site.
   *
   * Get report as CSV for all load carrier exchanges for all delivery notes
   * at the provied organization site.
   * Constrain: from <= timestamp < to, from and to must have millisecond
   * resolution. Allowed time range is 35 days.
   * All times are in UTC.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdministrationLoadCarriersReportsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationLoadCarriersReportsCsv$Response(params: GetAdministrationLoadCarriersReportsCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getAdministrationLoadCarriersReportsCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * Get load carriers report as csv for provided organization site.
   *
   * Get report as CSV for all load carrier exchanges for all delivery notes
   * at the provied organization site.
   * Constrain: from <= timestamp < to, from and to must have millisecond
   * resolution. Allowed time range is 35 days.
   * All times are in UTC.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdministrationLoadCarriersReportsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationLoadCarriersReportsCsv(params: GetAdministrationLoadCarriersReportsCsv$Params, context?: HttpContext): Observable<string> {
    return this.getAdministrationLoadCarriersReportsCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAdministrationOrganizationSites()` */
  static readonly GetAdministrationOrganizationSitesPath = '/administration/organizations/{organizationKey}/organization-sites';

  /**
   * Get all organization sites of the organization.
   *
   * Get all organization sites of the organization.
   * Also returns archived sites and the date when the site was archived.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdministrationOrganizationSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationSites$Response(params: GetAdministrationOrganizationSites$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationSite>>> {
    return getAdministrationOrganizationSites(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all organization sites of the organization.
   *
   * Get all organization sites of the organization.
   * Also returns archived sites and the date when the site was archived.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdministrationOrganizationSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationSites(params: GetAdministrationOrganizationSites$Params, context?: HttpContext): Observable<Array<OrganizationSite>> {
    return this.getAdministrationOrganizationSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationSite>>): Array<OrganizationSite> => r.body)
    );
  }

  /** Path part for operation `postAdministrationOrganizationSite()` */
  static readonly PostAdministrationOrganizationSitePath = '/administration/organizations/{organizationKey}/organization-sites';

  /**
   * Create new organization site.
   *
   * Create a new organization site, which belongs to {organizationKey}
   * All organization admins (which have the role 'organization_admin')
   * gain the roles organization_admin and site_admin
   * on the new create organization site
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAdministrationOrganizationSite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationOrganizationSite$Response(params: PostAdministrationOrganizationSite$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationSite>> {
    return postAdministrationOrganizationSite(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new organization site.
   *
   * Create a new organization site, which belongs to {organizationKey}
   * All organization admins (which have the role 'organization_admin')
   * gain the roles organization_admin and site_admin
   * on the new create organization site
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAdministrationOrganizationSite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationOrganizationSite(params: PostAdministrationOrganizationSite$Params, context?: HttpContext): Observable<OrganizationSite> {
    return this.postAdministrationOrganizationSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationSite>): OrganizationSite => r.body)
    );
  }

  /** Path part for operation `archiveAdministrationOrganizationSite()` */
  static readonly ArchiveAdministrationOrganizationSitePath = '/administration/organization-sites/{organizationSiteKey}';

  /**
   * Archive organization site.
   *
   * Archives an organization site. After this request, the site is marked as archived.
   * Only read operations are possible after archive.
   * TODO: A delete job will delete the site, if the site is no longer needed in supply chains and other.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archiveAdministrationOrganizationSite()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveAdministrationOrganizationSite$Response(params: ArchiveAdministrationOrganizationSite$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationSite>> {
    return archiveAdministrationOrganizationSite(this.http, this.rootUrl, params, context);
  }

  /**
   * Archive organization site.
   *
   * Archives an organization site. After this request, the site is marked as archived.
   * Only read operations are possible after archive.
   * TODO: A delete job will delete the site, if the site is no longer needed in supply chains and other.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `archiveAdministrationOrganizationSite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveAdministrationOrganizationSite(params: ArchiveAdministrationOrganizationSite$Params, context?: HttpContext): Observable<OrganizationSite> {
    return this.archiveAdministrationOrganizationSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationSite>): OrganizationSite => r.body)
    );
  }

  /** Path part for operation `patchAdministrationOrganizationSite()` */
  static readonly PatchAdministrationOrganizationSitePath = '/administration/organization-sites/{organizationSiteKey}';

  /**
   * Modify organization site.
   *
   * Modify an existing organization site.
   * Currently all properties are required.
   * In a later update all properties become optional.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAdministrationOrganizationSite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAdministrationOrganizationSite$Response(params: PatchAdministrationOrganizationSite$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationSite>> {
    return patchAdministrationOrganizationSite(this.http, this.rootUrl, params, context);
  }

  /**
   * Modify organization site.
   *
   * Modify an existing organization site.
   * Currently all properties are required.
   * In a later update all properties become optional.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAdministrationOrganizationSite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAdministrationOrganizationSite(params: PatchAdministrationOrganizationSite$Params, context?: HttpContext): Observable<OrganizationSite> {
    return this.patchAdministrationOrganizationSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationSite>): OrganizationSite => r.body)
    );
  }

  /** Path part for operation `getAdministrationRemoteAccesses()` */
  static readonly GetAdministrationRemoteAccessesPath = '/administration/organization-sites/{organizationSiteKey}/remote-accesses';

  /**
   * Get all remote site accesses.
   *
   * Get all remote site accesses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdministrationRemoteAccesses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationRemoteAccesses$Response(params: GetAdministrationRemoteAccesses$Params, context?: HttpContext): Observable<StrictHttpResponse<RemoteAccessOrganizationSites>> {
    return getAdministrationRemoteAccesses(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all remote site accesses.
   *
   * Get all remote site accesses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdministrationRemoteAccesses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationRemoteAccesses(params: GetAdministrationRemoteAccesses$Params, context?: HttpContext): Observable<RemoteAccessOrganizationSites> {
    return this.getAdministrationRemoteAccesses$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoteAccessOrganizationSites>): RemoteAccessOrganizationSites => r.body)
    );
  }

  /** Path part for operation `postAdministrationRemoteAccessToSite()` */
  static readonly PostAdministrationRemoteAccessToSitePath = '/administration/organization-sites/{organizationSiteKey}/remote-accesses';

  /**
   * share remote access to another site.
   *
   * Share remote access to another site. The users of the remote site
   * can have the remote_* roles. The shared organization site must have
   * enabled the flag allowRemoteAccess.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAdministrationRemoteAccessToSite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationRemoteAccessToSite$Response(params: PostAdministrationRemoteAccessToSite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postAdministrationRemoteAccessToSite(this.http, this.rootUrl, params, context);
  }

  /**
   * share remote access to another site.
   *
   * Share remote access to another site. The users of the remote site
   * can have the remote_* roles. The shared organization site must have
   * enabled the flag allowRemoteAccess.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAdministrationRemoteAccessToSite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationRemoteAccessToSite(params: PostAdministrationRemoteAccessToSite$Params, context?: HttpContext): Observable<void> {
    return this.postAdministrationRemoteAccessToSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAdministrationRemoteAccessToSite()` */
  static readonly DeleteAdministrationRemoteAccessToSitePath = '/administration/organization-sites/{organizationSiteKey}/remote-accesses/{targetOrganizationSiteKey}';

  /**
   * remove the remote access of an organization site.
   *
   * Remove the remote access of an organization site.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdministrationRemoteAccessToSite()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdministrationRemoteAccessToSite$Response(params: DeleteAdministrationRemoteAccessToSite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAdministrationRemoteAccessToSite(this.http, this.rootUrl, params, context);
  }

  /**
   * remove the remote access of an organization site.
   *
   * Remove the remote access of an organization site.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdministrationRemoteAccessToSite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdministrationRemoteAccessToSite(params: DeleteAdministrationRemoteAccessToSite$Params, context?: HttpContext): Observable<void> {
    return this.deleteAdministrationRemoteAccessToSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAdministrationOrganizationUsers()` */
  static readonly GetAdministrationOrganizationUsersPath = '/administration/organizations/{organizationKey}/users';

  /**
   * Get organization users information.
   *
   * Return all users of an organization. The organization must
   * belong to the calling user.
   * Users Permissions are returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdministrationOrganizationUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationUsers$Response(params: GetAdministrationOrganizationUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministrationUsersInformation>> {
    return getAdministrationOrganizationUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get organization users information.
   *
   * Return all users of an organization. The organization must
   * belong to the calling user.
   * Users Permissions are returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdministrationOrganizationUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationUsers(params: GetAdministrationOrganizationUsers$Params, context?: HttpContext): Observable<AdministrationUsersInformation> {
    return this.getAdministrationOrganizationUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministrationUsersInformation>): AdministrationUsersInformation => r.body)
    );
  }

  /** Path part for operation `postAdministrationOrganizationUser()` */
  static readonly PostAdministrationOrganizationUserPath = '/administration/organizations/{organizationKey}/users';

  /**
   * Create new user.
   *
   * Create a new user and add this user to the organization and the given organization sites
   * and equip the new user with permissions according to the given roles.
   * The requesting user must have access to the organization the new user is added.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAdministrationOrganizationUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationOrganizationUser$Response(params: PostAdministrationOrganizationUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postAdministrationOrganizationUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new user.
   *
   * Create a new user and add this user to the organization and the given organization sites
   * and equip the new user with permissions according to the given roles.
   * The requesting user must have access to the organization the new user is added.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAdministrationOrganizationUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationOrganizationUser(params: PostAdministrationOrganizationUser$Params, context?: HttpContext): Observable<void> {
    return this.postAdministrationOrganizationUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAdministrationOrganizationUser()` */
  static readonly GetAdministrationOrganizationUserPath = '/administration/organizations/{organizationKey}/users/{userKey}';

  /**
   * Get information details for one organization user.
   *
   * Return one user of an organization. The organization must
   * belong to the calling user.
   * Users, Org and Sites Permissions are returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdministrationOrganizationUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationUser$Response(params: GetAdministrationOrganizationUser$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministrationUserDetails>> {
    return getAdministrationOrganizationUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Get information details for one organization user.
   *
   * Return one user of an organization. The organization must
   * belong to the calling user.
   * Users, Org and Sites Permissions are returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdministrationOrganizationUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationUser(params: GetAdministrationOrganizationUser$Params, context?: HttpContext): Observable<AdministrationUserDetails> {
    return this.getAdministrationOrganizationUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministrationUserDetails>): AdministrationUserDetails => r.body)
    );
  }

  /** Path part for operation `putAdministrationOrganizationUser()` */
  static readonly PutAdministrationOrganizationUserPath = '/administration/organizations/{organizationKey}/users/{userKey}';

  /**
   * Edit user with new site roles/permissions.
   *
   * Edit a existing user and add this user to the given organization sites
   * and equip the new user with permissions according to the given roles.
   * The requesting user must have access to the organization the user is added.
   * All previously set permissions and linked sites are removed.
   * All sites and roles to be set must be passed.
   * The optional submitted password is ignored.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAdministrationOrganizationUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAdministrationOrganizationUser$Response(params: PutAdministrationOrganizationUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putAdministrationOrganizationUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit user with new site roles/permissions.
   *
   * Edit a existing user and add this user to the given organization sites
   * and equip the new user with permissions according to the given roles.
   * The requesting user must have access to the organization the user is added.
   * All previously set permissions and linked sites are removed.
   * All sites and roles to be set must be passed.
   * The optional submitted password is ignored.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putAdministrationOrganizationUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAdministrationOrganizationUser(params: PutAdministrationOrganizationUser$Params, context?: HttpContext): Observable<void> {
    return this.putAdministrationOrganizationUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAdministrationOrganizationSiteUsers()` */
  static readonly GetAdministrationOrganizationSiteUsersPath = '/administration/organization-sites/{organizationSiteKey}/users';

  /**
   * Get all users with roles of a orgnaization site.
   *
   * Get all assigned users from organization site with
   * all roles for the organization site.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdministrationOrganizationSiteUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationSiteUsers$Response(params: GetAdministrationOrganizationSiteUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministrationUsersInformation>> {
    return getAdministrationOrganizationSiteUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all users with roles of a orgnaization site.
   *
   * Get all assigned users from organization site with
   * all roles for the organization site.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdministrationOrganizationSiteUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationSiteUsers(params: GetAdministrationOrganizationSiteUsers$Params, context?: HttpContext): Observable<AdministrationUsersInformation> {
    return this.getAdministrationOrganizationSiteUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministrationUsersInformation>): AdministrationUsersInformation => r.body)
    );
  }

  /** Path part for operation `postAdministrationOrganizationSitesUser()` */
  static readonly PostAdministrationOrganizationSitesUserPath = '/administration/organization-sites/users';

  /**
   * Create new user for organization sites.
   *
   * Create a new user and add this user to the organization for the specified organization sites
   * and equip the new user with the rights according to the specified roles.
   * The requesting user must have access to the organization sites to which the new user is to be added.
   * The new user can have only the roles carrier, consignee, consignor, site_admin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAdministrationOrganizationSitesUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationOrganizationSitesUser$Response(params: PostAdministrationOrganizationSitesUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postAdministrationOrganizationSitesUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new user for organization sites.
   *
   * Create a new user and add this user to the organization for the specified organization sites
   * and equip the new user with the rights according to the specified roles.
   * The requesting user must have access to the organization sites to which the new user is to be added.
   * The new user can have only the roles carrier, consignee, consignor, site_admin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAdministrationOrganizationSitesUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministrationOrganizationSitesUser(params: PostAdministrationOrganizationSitesUser$Params, context?: HttpContext): Observable<void> {
    return this.postAdministrationOrganizationSitesUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `putAdministrationOrganizationSitesUser()` */
  static readonly PutAdministrationOrganizationSitesUserPath = '/administration/organization-sites/users/{userKey}';

  /**
   * Edit user with new site roles/permissions.
   *
   * Edit a existing user and add this user to the given organization sites
   * and equip the new user with permissions according to the given roles.
   * The requesting user must have access to the organization sites the user is added.
   * All previously set permissions and linked sites are removed.
   * All sites and roles to be set must be passed.
   * The optional submitted password is ignored.
   * The user can have only the roles carrier, consignee, consignor, site_admin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAdministrationOrganizationSitesUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAdministrationOrganizationSitesUser$Response(params: PutAdministrationOrganizationSitesUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putAdministrationOrganizationSitesUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit user with new site roles/permissions.
   *
   * Edit a existing user and add this user to the given organization sites
   * and equip the new user with permissions according to the given roles.
   * The requesting user must have access to the organization sites the user is added.
   * All previously set permissions and linked sites are removed.
   * All sites and roles to be set must be passed.
   * The optional submitted password is ignored.
   * The user can have only the roles carrier, consignee, consignor, site_admin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putAdministrationOrganizationSitesUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAdministrationOrganizationSitesUser(params: PutAdministrationOrganizationSitesUser$Params, context?: HttpContext): Observable<void> {
    return this.putAdministrationOrganizationSitesUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAdministrationOrganizationSiteUser()` */
  static readonly GetAdministrationOrganizationSiteUserPath = '/administration/organization-sites/{organizationSiteKey}/users/{userKey}';

  /**
   * Get information details for one organization site user.
   *
   * Return one user of an organization site. The organization site must
   * belong to the calling user.
   * Permissions are returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdministrationOrganizationSiteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationSiteUser$Response(params: GetAdministrationOrganizationSiteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministrationUserDetails>> {
    return getAdministrationOrganizationSiteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Get information details for one organization site user.
   *
   * Return one user of an organization site. The organization site must
   * belong to the calling user.
   * Permissions are returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdministrationOrganizationSiteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdministrationOrganizationSiteUser(params: GetAdministrationOrganizationSiteUser$Params, context?: HttpContext): Observable<AdministrationUserDetails> {
    return this.getAdministrationOrganizationSiteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministrationUserDetails>): AdministrationUserDetails => r.body)
    );
  }

}
