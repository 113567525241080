/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CarrierDeliveryNoteBundlesOverview } from '../../models/carrier-delivery-note-bundles-overview';
import { KeyType } from '../../models/key-type';

export interface GetCarrierBundles$Params {
  organizationSiteKey: KeyType;
}

export function getCarrierBundles(http: HttpClient, rootUrl: string, params: GetCarrierBundles$Params, context?: HttpContext): Observable<StrictHttpResponse<CarrierDeliveryNoteBundlesOverview>> {
  const rb = new RequestBuilder(rootUrl, getCarrierBundles.PATH, 'get');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CarrierDeliveryNoteBundlesOverview>;
    })
  );
}

getCarrierBundles.PATH = '/organization-sites/{organizationSiteKey}/carrier/delivery-note-bundles';
