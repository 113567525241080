/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { externalTestStringLength } from '../fn/debug/external-test-string-length';
import { ExternalTestStringLength$Params } from '../fn/debug/external-test-string-length';
import { NameType } from '../models/name-type';

@Injectable({ providedIn: 'root' })
export class DebugService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalTestStringLength()` */
  static readonly ExternalTestStringLengthPath = '/external/test-string-length';

  /**
   * DEBUG - test accepted string length.
   *
   * Test accepted string length.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalTestStringLength()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalTestStringLength$Response(params: ExternalTestStringLength$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'codePoints': number;
'message': NameType;
}>> {
    return externalTestStringLength(this.http, this.rootUrl, params, context);
  }

  /**
   * DEBUG - test accepted string length.
   *
   * Test accepted string length.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalTestStringLength$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalTestStringLength(params: ExternalTestStringLength$Params, context?: HttpContext): Observable<{
'codePoints': number;
'message': NameType;
}> {
    return this.externalTestStringLength$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'codePoints': number;
'message': NameType;
}>): {
'codePoints': number;
'message': NameType;
} => r.body)
    );
  }

}
