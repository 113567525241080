import { Injectable } from '@angular/core';
import { DeliveryNoteBundleWithOrgSiteKey } from '@app/consignor/services/consignor.service';
import { DeliveryNoteInPool } from 'api/models';

@Injectable({
    providedIn: 'root',
})
export class CheckoutBundleService {
    selection: DeliveryNoteBundleWithOrgSiteKey[];

    private newBundleSelection: DeliveryNoteInPool[];

    constructor() {}

    public get getNewBundleSelection() {
        return this.newBundleSelection;
    }

    public setNewBundleSelection(selection: DeliveryNoteInPool[]) {
        this.newBundleSelection = selection;
    }
}
