import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CarrierOperations } from '@app/shared/models/business-model/carrier-operations';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { environment } from '@env/environment';
import { LoadCarrierExchangeDto } from '@app/shared/models/service-model/load-carrier-exchange-dto';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GetLoadCarrierTemplatesResponse } from '../../../../api/models/get-load-carrier-templates-response';
import { lastValueFrom } from 'rxjs';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { LoadCarrierTemplatesService } from '../../../../api/services/load-carrier-templates.service';
import { LoadCarrierTemplate } from '../../../../api/models/load-carrier-template';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-carrier-exchange',
    templateUrl: './carrier-exchange.component.html',
    styleUrls: ['./carrier-exchange.component.scss'],
})
export class CarrierExchangeComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    /** Flag for showing the signing pads */
    @Input() readyToSign: boolean;
    /** Flag which shows the first 2 rows for carrier exchanges at opening */
    @Input() carrierExchangeInitialOpen: boolean = false;
    /** Flag for showing the sign button to send the folder */
    @Input() readyToSave: boolean;

    @Input() nameOfControl: string;

    @Input() loadCarriers: LoadCarrierExchangeDto[];

    @ViewChild('focusdriver') focusDriver: ElementRef;
    /** Flag if carrier exchange input fields should be shown */
    loadCarrier: boolean = false;
    /** List of all carrier types */
    allCarrierTypes: LoadCarrierTemplate[];
    /** Enum for the carrier operations */
    carrierOperations = CarrierOperations;
    /** context of app */
    env = environment;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private storageService: StorageService,
        private sessionService: SessionService,
        private loadCarrierTemplatesService: LoadCarrierTemplatesService
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.getAllCarrierTypes();
        setTimeout(() => this.pushExistingLoadCarries(), 250);
    }

    pushExistingLoadCarries(): void {
        if (this.loadCarriers && this.loadCarriers.length > 0) {
            this.carrierExchangesFormArray.clear();
            this.loadCarriers.forEach((item: LoadCarrierExchangeDto) => {
                const operation: any = item.operation;
                this.carrierExchangesFormArray.push(
                    this.createCarrierExchange(operation, item)
                );
            });
            this.loadCarrier = this.loadCarriers.length > 0;
        } else if (this.carrierExchangeInitialOpen) {
            this.addLoadCarrier();
        }
    }

    /**
     * Gets all carrier types.
     */
    async getAllCarrierTypes() {
        const orgKey = this.sessionService.getSession().organizations[0]._key;
        try {
            const response: GetLoadCarrierTemplatesResponse =
                await lastValueFrom(
                    this.loadCarrierTemplatesService.getLoadCarrierTemplates({
                        organizationKey: orgKey,
                    })
                );
            this.storageService.setItem('allCarrierTypes', response);
            this.allCarrierTypes = response;
        } catch (error) {
            this.allCarrierTypes =
                this.storageService.getItemAsObject('allCarrierTypes');
        }
        /*this.allCarrierTypes =
            this.storageService.getItemAsObject('allCarrierTypes');*/
    }

    /**
     * Initializes the form parts for the carrier exchange
     * @private
     */
    private initForm(): void {
        this.form.addControl(this.nameOfControl, this.formBuilder.array([]));
        this.form.addControl(
            'driverName',
            new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(3),
            ])
        );
        this.form.addControl(
            'driverLicensePlate',
            new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(3),
            ])
        );
    }

    /**
     * Adds the initial state to the form array
     */
    addLoadCarrier(): void {
        if (!this.loadCarrier) {
            this.carrierExchangesFormArray.clear();
            this.addCarrierExchange(CarrierOperations.ADOPTION);
            this.addCarrierExchange(CarrierOperations.DELIVERY);
            this.loadCarrier = true;
        }
    }

    /**
     * Adds the new carrier exchange input fields to the form array
     */
    addCarrierExchange(carrierExchangeOp?: CarrierOperations): void {
        this.carrierExchangesFormArray.push(
            this.createCarrierExchange(carrierExchangeOp)
        );
    }

    /**
     * Removes the carrier exchange input fields from the form array
     * @param index
     */
    removeCarrierExchange(index: number): void {
        this.carrierExchangesFormArray.removeAt(index);
        this.loadCarrier = this.carrierExchangesFormArray.length !== 0;
    }

    /**
     * Creates a new group of carrier exchange input fields.
     */
    private createCarrierExchange(
        carrierExchangeOp?: CarrierOperations,
        obj?: LoadCarrierExchangeDto
    ): UntypedFormGroup {
        return this.formBuilder.group({
            templateKey: [obj?.templateKey || '', Validators.required],
            operation: [carrierExchangeOp, Validators.required],
            quantity: [obj?.quantity, [Validators.required, Validators.min(0)]],
            damaged: [false],
        });
    }

    /**
     * Returns the form array of the carrier exchanges.
     */
    get carrierExchangesFormArray(): UntypedFormArray {
        return this.form.get(this.nameOfControl) as UntypedFormArray;
    }
}
