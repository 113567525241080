<div
    *ngIf="showLanguages"
    class="tw-flex tw-w-full tw-justify-center"
    style="max-height: 200px">
    <div
        class="tw-flex tw-w-20 tw-items-center tw-justify-center tw-text-white">
        <button
            color="primary"
            (click)="movePrev()"
            class="tw-h-full tw-w-full">
            ❮
        </button>
    </div>
    <div
        class="no-scrollbar tw-mx-2 tw-flex tw-items-center tw-gap-4 tw-overflow-x-auto"
        #languageContainer>
        <div
            *ngFor="let language of availableLanguages"
            class="tw-flex">
            <a
                (click)="changeLanguage(language.code)"
                class="tw-block">
                <img
                    class="tw-border-2 tw-border-solid tw-border-black"
                    src="./assets/images/flags/{{ language.code }}.svg"
                    width="150px" />
            </a>
        </div>
    </div>
    <div
        class="tw-flex tw-w-20 tw-items-center tw-justify-center tw-text-white">
        <button
            color="primary"
            (click)="moveNext()"
            class="tw-h-full tw-w-full">
            ❯
        </button>
    </div>
</div>
