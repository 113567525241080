<div class="tw-flex tw-items-center tw-justify-between">
    <div
        *ngIf="!headerTemplate"
        class="tw-flex-column tw-flex">
        <h2
            *ngIf="title"
            mat-dialog-title>
            {{ title }}
        </h2>
        <h3 *ngIf="subtitle">{{ subtitle }}</h3>
    </div>

    <div *ngIf="headerTemplate">
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>

    <button
        *ngIf="showCloseButton"
        mat-icon-button
        mat-dialog-close>
        <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <ng-container *ngIf="bodyTemplate">
        <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <ng-container *ngIf="footerTemplate">
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </ng-container>

    <button
        mat-raised-button
        mat-dialog-close
        (click)="close()"
        *ngIf="showCloseButton">
        Schließen
    </button>
</mat-dialog-actions>
