import { registerLocaleData } from '@angular/common';
import {
    HttpClient,
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler,
    forwardRef,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core/core.module';
import { MaterialModule } from '@app/shared/material/material.module';
import { WINDOW, windowFactory } from '@app/shared/services/window.token';
import { environment } from '@env/environment';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateCompiler,
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiModule } from 'api/api.module';
import { NgHttpCachingConfig } from 'ng-http-caching';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { lastValueFrom } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig, initConfig } from './app.config';
import { QuotasComponent } from './core/components/quotas/quotas.component';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { ServiceInterceptor } from './core/interceptors/service-interceptor';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';
import { SelfserviceMainModule } from './external/pages/selfservice/selfservice-main.module';
import { ToastrModule } from 'ngx-toastr';

// your config...
const ngHttpCachingConfig: NgHttpCachingConfig = {
    lifetime: 1000 * 10, // cache expire after 10 seconds
};

registerLocaleData(localeDe);
registerLocaleData(localeEn);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
export function loadTranslations(translateService: TranslateService) {
    return () => lastValueFrom(translateService.use('de'));
}

class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return 'Missing translation for key: ' + params.key;
    }
}

@NgModule({
    declarations: [AppComponent, QuotasComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    imports: [
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
        }),
        ReactiveFormsModule,
        FormlyModule.forRoot(),
        FormlyBootstrapModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgxSpinnerModule,
        CoreModule,
        MaterialModule,
        SelfserviceMainModule,
        StoreModule.forRoot({}, {}),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            // compiler configuration
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        ApiModule.forRoot({ rootUrl: 'apiURL' }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: loadTranslations,
            deps: [TranslateService],
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'de' },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true, disableClose: false },
        },
        { provide: WINDOW, useFactory: windowFactory },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServiceInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: forwardRef(() => ApiInterceptor),
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    constructor() {}
}
