<div class="tw-flex tw-h-full tw-flex-col tw-justify-start">
    <div class="tw-flex tw-justify-between">
        <h1 mat-dialog-title>Standorte Auswählen</h1>

        <button
            mat-icon-button
            mat-dialog-close>
            <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
        </button>
    </div>

    <div class="tw-flex tw-grow tw-flex-col">
        <table
            mat-table
            matTableResponsive
            [dataSource]="dataSource">
            <ng-container matColumnDef="select">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="!tw-px-3">
                    <mat-checkbox
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="
                            selection.hasValue() && !isAllSelected()
                        ">
                    </mat-checkbox>
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    class="!tw-px-3">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    {{ 'USER-SITES.COLUMNS.NAME' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let site"
                    class="!tw-pr-11">
                    {{ site.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="!tw-pr-11">
                    {{ 'USER-SITES.COLUMNS.ADDRESS' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let site"
                    class="!tw-pr-11">
                    {{ siteAdress(site) }}
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>

            <tr
                mat-row
                *matRowDef="let user; columns: displayedColumns"></tr>
        </table>
    </div>

    <div
        mat-dialog-actions
        class="tw-self-end">
        <button
            mat-button
            mat-dialog-close>
            {{ 'USER-SITES.ACTIONS.CANCEL' | translate }}
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="confirm()"
            cdkFocusInitial>
            {{ 'USER-SITES.ACTIONS.CONFIRM' | translate }}
        </button>
    </div>
</div>
