import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ApiInterceptor } from '@app/core/interceptors/api.interceptor';
import { ToastService } from '@app/core/services/toast.service';
import { SelfserviceService } from '@app/external/pages/selfservice/selfservice.service';
import { ShowPdfDialogComponent } from '@app/shared/components/show-pdf-dialog/show-pdf-dialog.component';
import { IVersion } from '@app/shared/models/version.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-selfservice-footer',
    templateUrl: './selfservice-footer.component.html',
    styleUrls: ['./selfservice-footer.component.scss'],
})
export class SelfserviceFooterComponent implements OnInit {
    versionString: string;
    @Input() footerVisibility: boolean = true;

    selfServiceFooterLinks = [
        {
            translationKey: 'SELFSERVICE.FOOTER.LEGAL',
        },
    ];

    constructor(
        private router: Router,
        private http: HttpClient,
        private spinner: NgxSpinnerService,
        private pdfService: PdfService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private selfServiceService: SelfserviceService,
        private apiInjector: ApiInterceptor,
        private toastService: ToastService
    ) {}

    async ngOnInit() {
        this.observeRoutes();
        try {
            const version = await lastValueFrom(
                this.http.get<IVersion>(
                    `assets/version.json?v=${new Date().getTime()}`
                )
            );
            if (version) {
                let versionString = '';
                if (version.tag) {
                    versionString += `Tag:\n${version.tag}\n`;
                }
                if (version.branch) {
                    versionString += `Branch:\n${version.branch}\n`;
                }
                if (version.commit) {
                    versionString += `Commit ID:\n${version.commit}`;
                }
                this.versionString = versionString;
            }
        } catch (error) {}
    }

    private observeRoutes(): void {
        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                const showNoHeader = [
                    '/login',
                    '/forgot-password',
                    '/check-email',
                    '/set-new-password',
                ];
                this.footerVisibility = !showNoHeader.includes(
                    event.urlAfterRedirects
                );
            }
        });
    }

    /**
     * @param legalNote
     * opens modal dialog with pdf preview
     */
    async viewLegalNote() {
        this.apiInjector.disableToastService();
        let blobData: Blob | undefined;
        await this.spinner.show();
        try {
            const organizationSite = await firstValueFrom(
                this.selfServiceService.organizationSite$
            );
            const organizationSiteKey = organizationSite?._key;
            if (!organizationSiteKey) {
                throw new Error('Organization site key is not available');
            }

            blobData = await lastValueFrom(
                this.pdfService.getConsigneeSelfCheckinLegalDocument({
                    organizationSiteKey: organizationSiteKey,
                })
            );

            await this.spinner.hide();

            this.dialog.open(ShowPdfDialogComponent, {
                minWidth: '80%',
                height: '95%',
                disableClose: true,
                data: {
                    data: blobData,
                    showText: false,
                },
            });
        } catch (error) {
            this.spinner.hide();
            this.apiInjector.enableToastService();
            this.toastService.error(
                this.translateService.instant('ERROR.NO-LEGAL-NOTE')
            );
        }
    }
}
