/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { LoginResponse } from '../models/login-response';
import { postLogin } from '../fn/authentication/post-login';
import { PostLogin$Params } from '../fn/authentication/post-login';
import { postLogin2FaRequest } from '../fn/authentication/post-login-2-fa-request';
import { PostLogin2FaRequest$Params } from '../fn/authentication/post-login-2-fa-request';
import { postLogout } from '../fn/authentication/post-logout';
import { PostLogout$Params } from '../fn/authentication/post-logout';


/**
 * Endpoints to login and logout
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postLogin()` */
  static readonly PostLoginPath = '/authentication/login';

  /**
   * Log in.
   *
   * Log in user and get initial user, organization and organization site
   * data with permissions.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLogin$Response(params: PostLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return postLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * Log in.
   *
   * Log in user and get initial user, organization and organization site
   * data with permissions.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLogin(params: PostLogin$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.postLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `postLogin2FaRequest()` */
  static readonly PostLogin2FaRequestPath = '/authentication/loginTwoFARequest';

  /**
   * request 2fa for log in.
   *
   * Request login of user by email and send an email with 2fa code to login the user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLogin2FaRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLogin2FaRequest$Response(params: PostLogin2FaRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postLogin2FaRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * request 2fa for log in.
   *
   * Request login of user by email and send an email with 2fa code to login the user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postLogin2FaRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLogin2FaRequest(params: PostLogin2FaRequest$Params, context?: HttpContext): Observable<void> {
    return this.postLogin2FaRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postLogout()` */
  static readonly PostLogoutPath = '/authentication/logout';

  /**
   * Log out.
   *
   * Log out the user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLogout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLogout$Response(params: PostLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * Log out.
   *
   * Log out the user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postLogout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLogout(params: PostLogout$Params, context?: HttpContext): Observable<void> {
    return this.postLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
