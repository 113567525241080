<div
    class="container d-flex justify-content-center align-content-center flex-column">
    <h2 class="mat-dialog-title text-center">
        {{ 'FEEDBACK.TITLE' | translate }}
    </h2>
    <h4 class="text-center">
        {{ 'FEEDBACK.DESCRIPTION' | translate }}
    </h4>
    <mat-dialog-content class="mat-typography">
        <div class="d-flex justify-content-evenly rating">
            <form
                id="checkout-folder-form"
                [formGroup]="ratingForm">
                <div class="text-center mb-2">
                    <mat-icon
                        [ngStyle]="{ color: rating === 1 ? '#c034eb' : '' }"
                        fontSet="material-symbols-outlined"
                        (click)="onClick(1)"
                        >sentiment_dissatisfied</mat-icon
                    >

                    <mat-icon
                        [ngStyle]="{ color: rating === 2 ? '#ff6759' : '' }"
                        fontSet="material-symbols-outlined"
                        (click)="onClick(2)"
                        >sentiment_neutral</mat-icon
                    >

                    <mat-icon
                        [ngStyle]="{ color: rating === 3 ? '#ffb300' : '' }"
                        fontSet="material-symbols-outlined"
                        (click)="onClick(3)"
                        >sentiment_satisfied</mat-icon
                    >

                    <mat-icon
                        [ngStyle]="{ color: rating === 4 ? '#34eb37' : '' }"
                        fontSet="material-symbols-outlined"
                        (click)="onClick(4)"
                        >sentiment_very_satisfied</mat-icon
                    >
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'FEEDBACK.FREETEXT' | translate }}</mat-label
                    >
                    <textarea
                        [placeholder]="'FEEDBACK.PLACEHOLDER' | translate"
                        matInput
                        formControlName="text"
                        maxlength="500"
                        cdkAutosizeMinRows="3"
                        cdkTextareaAutosize
                        #feedback></textarea>
                    <mat-hint
                        [class.danger]="
                            feedback.maxLength - feedback.value.length < 10
                        "
                        align="end">
                        {{ feedback.value?.length || 0 }}/{{
                            feedback.maxLength
                        }}
                    </mat-hint>
                </mat-form-field>
                <div
                    class="rating-alert"
                    *ngIf="rating === 1">
                    {{ 'FEEDBACK.RATING-1' | translate }}
                </div>
            </form>
        </div>

        <div class="mt-2 mb-2">
            <mat-checkbox
                (change)="onHideRating(hideRating)"
                [(ngModel)]="hideRating"
                [ngModelOptions]="{ standalone: true }">
                {{ 'FEEDBACK.NOT-SHOW-AGAIN' | translate }}
            </mat-checkbox>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button
            [disabled]="ratingForm.invalid"
            mat-raised-button
            color="primary"
            (click)="onSave()">
            {{ 'FEEDBACK.SAVE' | translate }}
        </button>
        <button
            mat-raised-button
            aria-label="close-feedback-button"
            (click)="onCancel()">
            {{ 'FEEDBACK.CLOSE' | translate }}
        </button>
    </mat-dialog-actions>
</div>
