/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { ConsigneeAttachmentResponse } from '../models/consignee-attachment-response';
import { deleteConsigneeDiscrepancyImage } from '../fn/discrepancy-images/delete-consignee-discrepancy-image';
import { DeleteConsigneeDiscrepancyImage$Params } from '../fn/discrepancy-images/delete-consignee-discrepancy-image';
import { GetConsigneeAttachmentsResponse } from '../models/get-consignee-attachments-response';
import { getConsigneeDiscrepancyImageAttachment$Heic } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heic';
import { GetConsigneeDiscrepancyImageAttachment$Heic$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heic';
import { getConsigneeDiscrepancyImageAttachment$Heif } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heif';
import { GetConsigneeDiscrepancyImageAttachment$Heif$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-heif';
import { getConsigneeDiscrepancyImageAttachment$Jpeg } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-jpeg';
import { GetConsigneeDiscrepancyImageAttachment$Jpeg$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-jpeg';
import { getConsigneeDiscrepancyImageAttachment$Pdf } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-pdf';
import { GetConsigneeDiscrepancyImageAttachment$Pdf$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-pdf';
import { getConsigneeDiscrepancyImageAttachment$Png } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-png';
import { GetConsigneeDiscrepancyImageAttachment$Png$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-image-attachment-png';
import { getConsigneeDiscrepancyImagesMetadata } from '../fn/discrepancy-images/get-consignee-discrepancy-images-metadata';
import { GetConsigneeDiscrepancyImagesMetadata$Params } from '../fn/discrepancy-images/get-consignee-discrepancy-images-metadata';
import { postConsigneeDiscrepancyImageToDeliveryNote$Heic } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-heic';
import { PostConsigneeDiscrepancyImageToDeliveryNote$Heic$Params } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-heic';
import { postConsigneeDiscrepancyImageToDeliveryNote$Heif } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-heif';
import { PostConsigneeDiscrepancyImageToDeliveryNote$Heif$Params } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-heif';
import { postConsigneeDiscrepancyImageToDeliveryNote$Jpeg } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-jpeg';
import { PostConsigneeDiscrepancyImageToDeliveryNote$Jpeg$Params } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-jpeg';
import { postConsigneeDiscrepancyImageToDeliveryNote$Png } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-png';
import { PostConsigneeDiscrepancyImageToDeliveryNote$Png$Params } from '../fn/discrepancy-images/post-consignee-discrepancy-image-to-delivery-note-png';

@Injectable({ providedIn: 'root' })
export class DiscrepancyImagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postConsigneeDiscrepancyImageToDeliveryNote()` */
  static readonly PostConsigneeDiscrepancyImageToDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancy-images/file/{fileName}';

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeDiscrepancyImageToDeliveryNote$Heic()` instead.
   *
   * This method sends `image/heic` and handles request body of type `image/heic`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Heic$Response(params: PostConsigneeDiscrepancyImageToDeliveryNote$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeDiscrepancyImageToDeliveryNote$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeDiscrepancyImageToDeliveryNote$Heic$Response()` instead.
   *
   * This method sends `image/heic` and handles request body of type `image/heic`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Heic(params: PostConsigneeDiscrepancyImageToDeliveryNote$Heic$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeDiscrepancyImageToDeliveryNote$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeDiscrepancyImageToDeliveryNote$Heif()` instead.
   *
   * This method sends `image/heif` and handles request body of type `image/heif`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Heif$Response(params: PostConsigneeDiscrepancyImageToDeliveryNote$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeDiscrepancyImageToDeliveryNote$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeDiscrepancyImageToDeliveryNote$Heif$Response()` instead.
   *
   * This method sends `image/heif` and handles request body of type `image/heif`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Heif(params: PostConsigneeDiscrepancyImageToDeliveryNote$Heif$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeDiscrepancyImageToDeliveryNote$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeDiscrepancyImageToDeliveryNote$Jpeg()` instead.
   *
   * This method sends `image/jpeg` and handles request body of type `image/jpeg`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Jpeg$Response(params: PostConsigneeDiscrepancyImageToDeliveryNote$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeDiscrepancyImageToDeliveryNote$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeDiscrepancyImageToDeliveryNote$Jpeg$Response()` instead.
   *
   * This method sends `image/jpeg` and handles request body of type `image/jpeg`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Jpeg(params: PostConsigneeDiscrepancyImageToDeliveryNote$Jpeg$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeDiscrepancyImageToDeliveryNote$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConsigneeDiscrepancyImageToDeliveryNote$Png()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Png$Response(params: PostConsigneeDiscrepancyImageToDeliveryNote$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postConsigneeDiscrepancyImageToDeliveryNote$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Add an image for a discrepancy.
   *
   * Add a new image attachment to a existing delivery note for the consignee
   * The delivery note must be in a started checkin.
   * Accepted Formats are jpg, png, heic, heif
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postConsigneeDiscrepancyImageToDeliveryNote$Png$Response()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  postConsigneeDiscrepancyImageToDeliveryNote$Png(params: PostConsigneeDiscrepancyImageToDeliveryNote$Png$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postConsigneeDiscrepancyImageToDeliveryNote$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /** Path part for operation `getConsigneeDiscrepancyImageAttachment()` */
  static readonly GetConsigneeDiscrepancyImageAttachmentPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancy-images/{attachmentKey}';

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Pdf$Response(params: GetConsigneeDiscrepancyImageAttachment$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Pdf(params: GetConsigneeDiscrepancyImageAttachment$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heic$Response(params: GetConsigneeDiscrepancyImageAttachment$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heic(params: GetConsigneeDiscrepancyImageAttachment$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heif$Response(params: GetConsigneeDiscrepancyImageAttachment$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Heif(params: GetConsigneeDiscrepancyImageAttachment$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Jpeg$Response(params: GetConsigneeDiscrepancyImageAttachment$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Jpeg(params: GetConsigneeDiscrepancyImageAttachment$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImageAttachment$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Png$Response(params: GetConsigneeDiscrepancyImageAttachment$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDiscrepancyImageAttachment$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an image for a discrepancy.
   *
   * Retrieving a single discrepancy image for a specific delivery note as a consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImageAttachment$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImageAttachment$Png(params: GetConsigneeDiscrepancyImageAttachment$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDiscrepancyImageAttachment$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `deleteConsigneeDiscrepancyImage()` */
  static readonly DeleteConsigneeDiscrepancyImagePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancy-images/{attachmentKey}';

  /**
   * Delete an image for a discrepancy.
   *
   * Delete a discrepancy image attachment for the consignee from a delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConsigneeDiscrepancyImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConsigneeDiscrepancyImage$Response(params: DeleteConsigneeDiscrepancyImage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteConsigneeDiscrepancyImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an image for a discrepancy.
   *
   * Delete a discrepancy image attachment for the consignee from a delivery note.
   * The delivery note must be in a started checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConsigneeDiscrepancyImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConsigneeDiscrepancyImage(params: DeleteConsigneeDiscrepancyImage$Params, context?: HttpContext): Observable<void> {
    return this.deleteConsigneeDiscrepancyImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConsigneeDiscrepancyImagesMetadata()` */
  static readonly GetConsigneeDiscrepancyImagesMetadataPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/discrepancy-images';

  /**
   * Get all image for a discrepancy for a Delvivery Note.
   *
   * get a list of attachments for a single delivery note for the consignee
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDiscrepancyImagesMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImagesMetadata$Response(params: GetConsigneeDiscrepancyImagesMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeAttachmentsResponse>> {
    return getConsigneeDiscrepancyImagesMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all image for a discrepancy for a Delvivery Note.
   *
   * get a list of attachments for a single delivery note for the consignee
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDiscrepancyImagesMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDiscrepancyImagesMetadata(params: GetConsigneeDiscrepancyImagesMetadata$Params, context?: HttpContext): Observable<GetConsigneeAttachmentsResponse> {
    return this.getConsigneeDiscrepancyImagesMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeAttachmentsResponse>): GetConsigneeAttachmentsResponse => r.body)
    );
  }

}
