<mat-button-toggle-group
    aria-label="type of view"
    [value]="viewType"
    (change)="changeView($event)">
    <mat-button-toggle value="folder">{{
        'OUTGOING-GOODS.TOGGLER.FOLDERS' | translate
    }}</mat-button-toggle>
    <mat-button-toggle
        value="tours"
        aria-label="toursView"
        >{{ 'OUTGOING-GOODS.TOGGLER.TOURS' | translate }}</mat-button-toggle
    >
    <mat-button-toggle
        value="deliverySlip"
        aria-label="deliveryNotesView"
        >{{
            'OUTGOING-GOODS.TOGGLER.DELIVERY-NOTES' | translate
        }}</mat-button-toggle
    >
    <mat-button-toggle
        *ngIf="hasSelfServiceAccess"
        value="notifications"
        aria-label="notificationsView"
        class="tw-center-items tw-flex tw-flex-col"
        >{{ 'OUTGOING-GOODS.TOGGLER.NOTIFICATIONS' | translate }}
        <mat-icon
            *ngIf="newNotifications"
            color="primary"
            fontSet="material-symbols-outlined"
            class="bell-shake-animation tw-m-0 !tw-mr-0 tw-mb-1 tw-self-center tw-text-center tw-align-middle"
            >notifications_active</mat-icon
        ></mat-button-toggle
    >
</mat-button-toggle-group>
