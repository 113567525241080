import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { LoginOrganizationSite } from '../../../../api/models/login-organization-site';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-filter-user-sites',
    templateUrl: './filter-user-sites.component.html',
    styleUrls: ['./filter-user-sites.component.scss'],
})
export class FilterUserSitesComponent implements OnInit {
    dataSource = new MatTableDataSource<LoginOrganizationSite>();
    selection = new SelectionModel<LoginOrganizationSite>(true, []);

    displayedColumns = ['select', 'name', 'address'];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: {
            sites: LoginOrganizationSite[];
            currentlySelectedSites: string[];
        },
        private dialogRef: MatDialogRef<FilterUserSitesComponent>
    ) {}

    ngOnInit(): void {
        this.dataSource.data = this.data.sites;
        if (this.data.currentlySelectedSites) {
            const selectedOrgSites: LoginOrganizationSite[] = [];
            this.data.currentlySelectedSites.forEach(site => {
                const foundSite = this.data.sites.find(
                    element => element._key === site
                );
                if (foundSite) {
                    selectedOrgSites.push(foundSite);
                }
            });
            this.selection.select(...selectedOrgSites);
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    confirm() {
        this.dialogRef.close(this.selection.selected);
    }

    siteAdress(site: LoginOrganizationSite) {
        if (site.address) {
            return site.address;
        } else if (site.location) {
            return `${site.location.street}, ${site.location.zipCode} ${site.location.city}`;
        } else {
            return '';
        }
    }
}
