<app-modal-dialog
    [bodyTemplate]="bodyTemplate"
    [showCloseButton]="false"
    [footerTemplate]="footerTemplate">
    <ng-template #bodyTemplate>
        <div class="container-fluid">
            <h5 class="dialog">
                <mat-icon
                    class="icon-color-primary me-2"
                    fontSet="material-symbols-outlined"
                    >{{ data.icon }}</mat-icon
                >

                {{ data.headline }}
            </h5>
            <p class="font-16 tw-mt-5">
                {{ data.msg }}
            </p>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <button
            tabindex="-1"
            type="button"
            mat-stroked-button
            (click)="closeWithResult('selfClose')">
            {{ 'CONFIRMATION-DIALOG.BUTTON-BACK' | translate }}
        </button>
        <button
            id="button-bottom-no"
            tabindex="1"
            type="button"
            mat-flat-button
            color="primary"
            (click)="closeWithResult('save')">
            Okay
        </button>
    </ng-template>
</app-modal-dialog>
