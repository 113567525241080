import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '../enums/customer.enum';

@Injectable({
    providedIn: 'root',
})
export class CustomTranslateService {
    public currentLang: string = 'de';

    constructor(
        private translate: TranslateService,
        private appConfig: AppConfig
    ) {
        this.init();
    }

    init() {
        if (this.appConfig.customer === Customer.mercedes) {
            this.currentLang = 'de';
            return;
        }
        this.currentLang = this.translate.currentLang;
    }
}
