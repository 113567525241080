/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { ConsigneeAttachmentResponse } from '../models/consignee-attachment-response';
import { GetConsigneeAttachmentsResponse } from '../models/get-consignee-attachments-response';
import { getFineDiscrepanciesForDelviveryNote } from '../fn/fine-discrepancies/get-fine-discrepancies-for-delvivery-note';
import { GetFineDiscrepanciesForDelviveryNote$Params } from '../fn/fine-discrepancies/get-fine-discrepancies-for-delvivery-note';
import { getFineDiscrepancy$Heic } from '../fn/fine-discrepancies/get-fine-discrepancy-heic';
import { GetFineDiscrepancy$Heic$Params } from '../fn/fine-discrepancies/get-fine-discrepancy-heic';
import { getFineDiscrepancy$Heif } from '../fn/fine-discrepancies/get-fine-discrepancy-heif';
import { GetFineDiscrepancy$Heif$Params } from '../fn/fine-discrepancies/get-fine-discrepancy-heif';
import { getFineDiscrepancy$Jpeg } from '../fn/fine-discrepancies/get-fine-discrepancy-jpeg';
import { GetFineDiscrepancy$Jpeg$Params } from '../fn/fine-discrepancies/get-fine-discrepancy-jpeg';
import { getFineDiscrepancy$Pdf } from '../fn/fine-discrepancies/get-fine-discrepancy-pdf';
import { GetFineDiscrepancy$Pdf$Params } from '../fn/fine-discrepancies/get-fine-discrepancy-pdf';
import { getFineDiscrepancy$Png } from '../fn/fine-discrepancies/get-fine-discrepancy-png';
import { GetFineDiscrepancy$Png$Params } from '../fn/fine-discrepancies/get-fine-discrepancy-png';
import { postNewFineDiscrepancy } from '../fn/fine-discrepancies/post-new-fine-discrepancy';
import { PostNewFineDiscrepancy$Params } from '../fn/fine-discrepancies/post-new-fine-discrepancy';

@Injectable({ providedIn: 'root' })
export class FineDiscrepanciesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postNewFineDiscrepancy()` */
  static readonly PostNewFineDiscrepancyPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/fine-discrepancies/file/{fileName}';

  /**
   * create new fine discrepancy for a delivery note.
   *
   * Create a new fine discrepancy for a delivery note.
   * The delivery note must be in a started checkin.
   * The only accepted format is pdf.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postNewFineDiscrepancy()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  postNewFineDiscrepancy$Response(params: PostNewFineDiscrepancy$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeAttachmentResponse>> {
    return postNewFineDiscrepancy(this.http, this.rootUrl, params, context);
  }

  /**
   * create new fine discrepancy for a delivery note.
   *
   * Create a new fine discrepancy for a delivery note.
   * The delivery note must be in a started checkin.
   * The only accepted format is pdf.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postNewFineDiscrepancy$Response()` instead.
   *
   * This method sends `application/pdf` and handles request body of type `application/pdf`.
   */
  postNewFineDiscrepancy(params: PostNewFineDiscrepancy$Params, context?: HttpContext): Observable<ConsigneeAttachmentResponse> {
    return this.postNewFineDiscrepancy$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeAttachmentResponse>): ConsigneeAttachmentResponse => r.body)
    );
  }

  /** Path part for operation `getFineDiscrepancy()` */
  static readonly GetFineDiscrepancyPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/fine-discrepancies/{discrepancyKey}';

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFineDiscrepancy$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Pdf$Response(params: GetFineDiscrepancy$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getFineDiscrepancy$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFineDiscrepancy$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Pdf(params: GetFineDiscrepancy$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getFineDiscrepancy$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFineDiscrepancy$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Heic$Response(params: GetFineDiscrepancy$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getFineDiscrepancy$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFineDiscrepancy$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Heic(params: GetFineDiscrepancy$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getFineDiscrepancy$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFineDiscrepancy$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Heif$Response(params: GetFineDiscrepancy$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getFineDiscrepancy$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFineDiscrepancy$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Heif(params: GetFineDiscrepancy$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getFineDiscrepancy$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFineDiscrepancy$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Jpeg$Response(params: GetFineDiscrepancy$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getFineDiscrepancy$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFineDiscrepancy$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Jpeg(params: GetFineDiscrepancy$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getFineDiscrepancy$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFineDiscrepancy$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Png$Response(params: GetFineDiscrepancy$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getFineDiscrepancy$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * get a fine discrepancy for a delivery note.
   *
   * Get a single fine discrepancy for a delivery note as a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFineDiscrepancy$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepancy$Png(params: GetFineDiscrepancy$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getFineDiscrepancy$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getFineDiscrepanciesForDelviveryNote()` */
  static readonly GetFineDiscrepanciesForDelviveryNotePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/fine-discrepancies';

  /**
   * get all fine discrepancies for a delivery note.
   *
   * Get all meta data for a fine discrepancies for a single delivery note as a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFineDiscrepanciesForDelviveryNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepanciesForDelviveryNote$Response(params: GetFineDiscrepanciesForDelviveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeAttachmentsResponse>> {
    return getFineDiscrepanciesForDelviveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * get all fine discrepancies for a delivery note.
   *
   * Get all meta data for a fine discrepancies for a single delivery note as a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFineDiscrepanciesForDelviveryNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFineDiscrepanciesForDelviveryNote(params: GetFineDiscrepanciesForDelviveryNote$Params, context?: HttpContext): Observable<GetConsigneeAttachmentsResponse> {
    return this.getFineDiscrepanciesForDelviveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeAttachmentsResponse>): GetConsigneeAttachmentsResponse => r.body)
    );
  }

}
