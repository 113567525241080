<div class="tw-flex tw-justify-between">
    <h1
        mat-dialog-title
        translate>
        OUTGOING-GOODS.COLUMN-FILTERS-DIALOG.TITLE
    </h1>

    <button
        mat-icon-button
        mat-dialog-close>
        <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
</div>

<div *ngFor="let col of columnData">
    <mat-checkbox
        *ngIf="!isTours?.valueOf()"
        [(ngModel)]="col.shouldDisplay"
        [disabled]="!!col.disabled">
        <span *ngIf="col.name !== '_key' || !keyLabel; else displayKey">{{
            'OUTGOING-GOODS.COLUMN-FILTERS.' + col.name | translate
        }}</span>
        <ng-template #displayKey>
            {{ keyLabel || '' | translate }}
        </ng-template>
    </mat-checkbox>

    <mat-checkbox
        *ngIf="isTours?.valueOf()"
        [(ngModel)]="col.shouldDisplay"
        [disabled]="!!col.disabled">
        <span *ngIf="col.name !== '_key' || !keyLabel; else displayKey">{{
            'SPEDITEUR.TABLE.COLUMN-FILTERS.' + col.name | translate
        }}</span>
        <ng-template #displayKey>
            {{ keyLabel || '' | translate }}
        </ng-template>
    </mat-checkbox>
</div>

<div
    *ngIf="!amountOfChecked"
    class="tw-mt-3 tw-text-red-500"
    translate>
    OUTGOING-GOODS.COLUMN-FILTERS-DIALOG.SELECT-ONE-ROW
</div>

<div
    mat-dialog-actions
    align="end">
    <button
        mat-button
        mat-dialog-close>
        {{ 'GENERAL.CANCEL' | translate }}
    </button>
    <button
        mat-button
        (click)="reset()">
        {{ 'GENERAL.RESET' | translate }}
    </button>
    <button
        mat-raised-button
        color="primary"
        (click)="closeDialog()"
        [disabled]="!amountOfChecked">
        {{ 'GENERAL.CONFIRM' | translate }}
    </button>
</div>
