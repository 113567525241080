import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Check {
    hasMinLength?: boolean;
    hasNumber?: boolean;
    hasCapitalCase?: boolean;
    hasSmallCase?: boolean;
    hasSpecialCharacters?: boolean;
}

@Component({
    selector: 'app-password-check',
    templateUrl: './password-check.component.html',
})
export class PasswordCheckComponent {
    _password = '';
    @Input()
    set password(password: string) {
        if (password != undefined) {
            this._password = password;
            this.passwordValidation();
        }
    }

    get password() {
        return this._password;
    }

    @Output() checkEv = new EventEmitter<Check>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onCorrect = new EventEmitter<boolean>();

    correct = false;
    check: Check | any = {};

    checks: { name: string; translationKey: string }[] = [
        {
            name: 'hasMinLength',
            translationKey: 'GENERAL.PASSWORD_CHECK.MIN_LENGTH',
        },
        {
            name: 'hasNumber',
            translationKey: 'GENERAL.PASSWORD_CHECK.HAS_NUMBER',
        },
        {
            name: 'hasCapitalCase',
            translationKey: 'GENERAL.PASSWORD_CHECK.HAS_CAPITAL_CASE',
        },
        {
            name: 'hasSmallCase',
            translationKey: 'GENERAL.PASSWORD_CHECK.HAS_SMALL_CASE',
        },
        {
            name: 'hasSpecialCharacters',
            translationKey: 'GENERAL.PASSWORD_CHECK.HAS_SPECIAL_CHARACTERS',
        },
        {
            name: 'hasNoRestrictedCharacters',
            translationKey:
                'GENERAL.PASSWORD_CHECK.HAS_NO_RESTRICTED_CHARACTERS',
        },
    ];

    constructor() {}

    passwordValidation() {
        const password = this._password;
        let allCorrect = true;
        const checks:
            | {
                  hasMinLength?: boolean;
                  hasNumber?: boolean;
                  hasCapitalCase?: boolean;
                  hasSmallCase?: boolean;
                  hasSpecialCharacters?: boolean;
              }
            | any = {};

        if (password.match(/.{12,}/)) {
            checks.hasMinLength = true;
        } else {
            checks.hasMinLength = false;
        }

        if (password.match(/[0-9]/)) {
            checks.hasNumber = true;
        } else {
            checks.hasNumber = false;
        }

        if (password.match(/[A-Z]/)) {
            checks.hasCapitalCase = true;
        } else {
            checks.hasCapitalCase = false;
        }

        if (password.match(/[a-z]/)) {
            checks.hasSmallCase = true;
        } else {
            checks.hasSmallCase = false;
        }

        if (password.match(/[!@#$%^&*)(+=._-]+/)) {
            checks.hasSpecialCharacters = true;
        } else {
            checks.hasSpecialCharacters = false;
        }

        if (password.match(/^\S*$/)) {
            checks.hasNoRestrictedCharacters = true;
        } else {
            checks.hasNoRestrictedCharacters = false;
        }

        Object.keys(checks).forEach(key => {
            if (!checks[key]) {
                allCorrect = false;
            }
        });

        Object.assign(this.check, checks);

        setTimeout(() => {
            this.correct = allCorrect;
            this.onCorrect.emit(this.correct);
        });
    }
}
