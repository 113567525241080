import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { ChangeEmailComponent } from '@app/user/pages/user-settings/change-email/change-email.component';
import { ChangePasswordComponent } from '@app/user/pages/user-settings/change-password/change-password.component';
import { ChangeUsernameComponent } from '@app/user/pages/user-settings/change-username/change-username.component';
import { UserSettingsComponent } from '@app/user/pages/user-settings/user-settings.component';
import { GlobalFooterComponent } from './components/global-footer/global-footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LeftSplitviewComponent } from './components/left-splitview/left-splitview.component';
import { SetNewPasswordComponent } from './components/set-new-password/set-new-password.component';
import { UnauthFooterComponent } from './components/unauth-footer/unauth-footer.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { EmailGotVerifiedComponent } from './pages/email-got-verified/email-got-verified.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LegalComponent } from './pages/legal/legal.component';
import { TwofaVerificationDialogComponent } from '@app/core/pages/landing/twofa-verification-dialog/twofa-verification-dialog.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordCheckModule } from '@app/core/components/password-check/password-check.module';
import { SelfserviceFooterComponent } from './components/selfservice-footer/selfservice-footer.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [
        TwofaVerificationDialogComponent,
        HeaderComponent,
        UserProfileComponent,
        ChangePasswordComponent,
        GlobalFooterComponent,
        SelfserviceFooterComponent,
        LegalComponent,
        UnauthFooterComponent,
        ForgotPasswordComponent,
        PasswordResetComponent,
        LeftSplitviewComponent,
        UserSettingsComponent,
        ChangeUsernameComponent,
        ChangeEmailComponent,
        EmailGotVerifiedComponent,
        SetNewPasswordComponent,
        LandingComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatFormFieldModule,
        MatDialogModule,
        PasswordCheckModule,
    ],
    exports: [
        HeaderComponent,
        GlobalFooterComponent,
        SelfserviceFooterComponent,
        UnauthFooterComponent,
        LegalComponent,
    ],
})
export class CoreModule {}
