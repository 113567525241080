/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetFeedbackResponseArray } from '../models/get-feedback-response-array';
import { getFeedbacks } from '../fn/feedback/get-feedbacks';
import { GetFeedbacks$Params } from '../fn/feedback/get-feedbacks';
import { postFeedback } from '../fn/feedback/post-feedback';
import { PostFeedback$Params } from '../fn/feedback/post-feedback';
import { putLastTimeUserGaveFeedbackIntoUser } from '../fn/feedback/put-last-time-user-gave-feedback-into-user';
import { PutLastTimeUserGaveFeedbackIntoUser$Params } from '../fn/feedback/put-last-time-user-gave-feedback-into-user';

@Injectable({ providedIn: 'root' })
export class FeedbackService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postFeedback()` */
  static readonly PostFeedbackPath = '/feedbacks';

  /**
   * Post new feedback.
   *
   * Post a new feedback element to the database.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFeedback()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFeedback$Response(params: PostFeedback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postFeedback(this.http, this.rootUrl, params, context);
  }

  /**
   * Post new feedback.
   *
   * Post a new feedback element to the database.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postFeedback$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFeedback(params: PostFeedback$Params, context?: HttpContext): Observable<void> {
    return this.postFeedback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `putLastTimeUserGaveFeedbackIntoUser()` */
  static readonly PutLastTimeUserGaveFeedbackIntoUserPath = '/me/last-feedback-to-now';

  /**
   * Set last time of feedback to now into user.
   *
   * Set last time of feedback to now into user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putLastTimeUserGaveFeedbackIntoUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  putLastTimeUserGaveFeedbackIntoUser$Response(params?: PutLastTimeUserGaveFeedbackIntoUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putLastTimeUserGaveFeedbackIntoUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Set last time of feedback to now into user.
   *
   * Set last time of feedback to now into user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putLastTimeUserGaveFeedbackIntoUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putLastTimeUserGaveFeedbackIntoUser(params?: PutLastTimeUserGaveFeedbackIntoUser$Params, context?: HttpContext): Observable<void> {
    return this.putLastTimeUserGaveFeedbackIntoUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getFeedbacks()` */
  static readonly GetFeedbacksPath = '/service-provider/feedbacks';

  /**
   * Get a list with all feedbacks.
   *
   * Get a list with all feedbacks.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeedbacks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedbacks$Response(params?: GetFeedbacks$Params, context?: HttpContext): Observable<StrictHttpResponse<GetFeedbackResponseArray>> {
    return getFeedbacks(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list with all feedbacks.
   *
   * Get a list with all feedbacks.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeedbacks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedbacks(params?: GetFeedbacks$Params, context?: HttpContext): Observable<GetFeedbackResponseArray> {
    return this.getFeedbacks$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetFeedbackResponseArray>): GetFeedbackResponseArray => r.body)
    );
  }

}
