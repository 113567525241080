import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '@env/environment';
import { Customer } from './core/enums/customer.enum';
import { SessionService } from './core/services/client-services/session-service/session.service';

interface Config {
    apiUrl: string;
    customer?: Customer;
    twoFactorEnabled?: boolean | string;
    maintenance?: boolean | string;
    languageSwitcher?: boolean;
}
export interface IAppConfig {
    apiUrl: string;
    load: () => Promise<void>;
}

@Injectable({
    providedIn: 'root',
})
export class AppConfig implements IAppConfig {
    public apiUrl: string;
    public customer?: Customer;
    public twoFactorEnabled?: boolean;
    public maintenance?: boolean;
    public languageSwitcher?: boolean = false;

    constructor(
        private readonly http: HttpClient,
        private sessionService: SessionService
    ) {}

    public async load(): Promise<void> {
        const config = await lastValueFrom(
            this.http.get<Config>(
                `assets/config.json?v=${new Date().getTime()}`
            )
        );

        if (config.apiUrl === '${API_URL}') {
            config.apiUrl = environment.apiUrl;
        }
        this.apiUrl = config.apiUrl;

        if (config.twoFactorEnabled === '${TWO_FACTOR_ENABLED}') {
            config.twoFactorEnabled = environment.twoFactorEnabled;
        } else {
            config.twoFactorEnabled =
                config.twoFactorEnabled == 'true' ? true : false;
        }

        this.twoFactorEnabled = config.twoFactorEnabled;

        if (config.languageSwitcher) {
            config.languageSwitcher = environment.languageSwitcher;
        }

        this.languageSwitcher = config.languageSwitcher;

        if (config.maintenance === '${MAINTENANCE}') {
            config.maintenance = environment.maintenance;
        } else {
            config.maintenance = config.maintenance == 'true' ? true : false;
        }

        this.maintenance = config.maintenance;

        if (this.sessionService.getSessionId()) {
            await this.sessionService.loadUserFromApi();
        }
        return;
    }
}
export function initConfig(config: AppConfig): () => Promise<void> {
    return () => config.load();
}
