/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { KeyType } from '../../models/key-type';
import { OrganizationSite } from '../../models/organization-site';
import { PostPatchOrganizationSiteRequest } from '../../models/post-patch-organization-site-request';

export interface PostAdministrationOrganizationSite$Params {
  organizationKey: KeyType;
      body: PostPatchOrganizationSiteRequest
}

export function postAdministrationOrganizationSite(http: HttpClient, rootUrl: string, params: PostAdministrationOrganizationSite$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationSite>> {
  const rb = new RequestBuilder(rootUrl, postAdministrationOrganizationSite.PATH, 'post');
  if (params) {
    rb.path('organizationKey', params.organizationKey, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OrganizationSite>;
    })
  );
}

postAdministrationOrganizationSite.PATH = '/administration/organizations/{organizationKey}/organization-sites';
