/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AttachmentResponse } from '../../models/attachment-response';
import { BinaryFileType } from '../../models/binary-file-type';
import { KeyType } from '../../models/key-type';
import { NameType } from '../../models/name-type';

export interface PostAttachmentToDeliveryNote$Heic$Params {
  organizationSiteKey: KeyType;
  deliveryNoteBundleKey: KeyType;
  deliveryNoteKey: KeyType;
  fileName: NameType;
      body: BinaryFileType
}

export function postAttachmentToDeliveryNote$Heic(http: HttpClient, rootUrl: string, params: PostAttachmentToDeliveryNote$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentResponse>> {
  const rb = new RequestBuilder(rootUrl, postAttachmentToDeliveryNote$Heic.PATH, 'post');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
    rb.path('deliveryNoteBundleKey', params.deliveryNoteBundleKey, {});
    rb.path('deliveryNoteKey', params.deliveryNoteKey, {});
    rb.path('fileName', params.fileName, {});
    rb.body(params.body, 'image/heic');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AttachmentResponse>;
    })
  );
}

postAttachmentToDeliveryNote$Heic.PATH = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/attachments/file/{fileName}';
