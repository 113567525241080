import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomTranslateService } from '@app/core/services/translate.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-wallet',
    templateUrl: './wallet.component.html',
    styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent {
    @Input() showQrCode?: boolean | undefined = true;
    @Input() qrCodeValue?: string | undefined;
    @Input() qrHref?: string | undefined;
    @Input() date?: number | undefined;
    @Input() mapNr?: string | undefined;
    @Input() tourNr?: string | undefined;

    @Input() consigneeName?: string | undefined;
    @Input() carrierName?: string | undefined;
    @Input() consignorName?: string | undefined;

    @Output()
    qrClick: EventEmitter<void> = new EventEmitter<void>();

    currentLanguageCode: string | undefined;

    constructor(private customTranslateService: CustomTranslateService) {
        this.currentLanguageCode = this.customTranslateService.currentLang;
    }
}
