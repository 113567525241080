import { Component, Inject, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast.service';
import {
    deliveryNoteNumberValidator,
    orderNumberValidator,
} from '@app/shared/form-validators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryNoteInPool } from 'api/models';
import { DeliveryNotesService } from 'api/services';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-edit-note-dialog',
    templateUrl: './edit-note-dialog.component.html',
    styleUrls: ['./edit-note-dialog.component.scss'],
})
export class EditNoteDialogComponent implements OnInit {
    loading = false;

    patchDeliveryNoteForm: UntypedFormGroup;

    minDate: Date = new Date(
        new Date().setFullYear(new Date().getFullYear() - 10)
    );
    touchedDate = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { note: DeliveryNoteInPool; organizationSiteKey: string },
        private deliveryNoteService: DeliveryNotesService,
        private toastr: ToastService,
        private dialogRef: MatDialogRef<EditNoteDialogComponent>,
        private translate: TranslateService,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit(): void {
        this.patchDeliveryNoteForm = this.formBuilder.group({
            fileName: [this.data.note.fileName],
            number: [
                this.data.note.number,
                [
                    Validators.required,
                    deliveryNoteNumberValidator(),
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
            dateAnnounced: [
                this.data.note.dateAnnounced
                    ? new Date(this.data.note.dateAnnounced)
                    : null,
            ],
            orderNumber: [
                this.data.note.orderNumber || '',
                [
                    orderNumberValidator(),
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
        });

        this.patchDeliveryNoteForm.markAllAsTouched();
    }

    async saveChanges(): Promise<void> {
        this.loading = true;

        const dateAnnounced = this.patchDeliveryNoteForm.value.dateAnnounced
            ? this.patchDeliveryNoteForm.value.dateAnnounced.valueOf()
            : 0;

        try {
            await lastValueFrom(
                this.deliveryNoteService.patchDeliveryNoteMetadata({
                    organizationSiteKey: this.data.organizationSiteKey,
                    deliveryNoteKey: this.data.note._key,
                    body: {
                        ...this.patchDeliveryNoteForm.value,
                        dateAnnounced,
                    },
                })
            );
            this.toastr.success(
                this.translate.instant(
                    'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.NOTE-UPDATED'
                )
            );

            const updatedNote = this.data.note;
            updatedNote.fileName = this.patchDeliveryNoteForm.value.fileName;
            updatedNote.number = this.patchDeliveryNoteForm.value.number;
            updatedNote.dateAnnounced =
                this.patchDeliveryNoteForm.value.dateAnnounced;
            updatedNote.orderNumber =
                this.patchDeliveryNoteForm.value.orderNumber;

            this.dialogRef.close(updatedNote);
        } catch (error: any) {
            this.toastr.error(error);
        } finally {
            setTimeout(() => (this.loading = false), 300);
        }
    }

    /**
     * Gets all form controls of the login form.
     */
    get formControls(): { [p: string]: AbstractControl } {
        return this.patchDeliveryNoteForm.controls;
    }
}
