import { Component, OnInit } from '@angular/core';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { DialogService } from '@ngneat/dialog';
import { GetMeQuotasResponse } from 'api/models';

@Component({
    selector: 'app-quotas',
    templateUrl: './quotas.component.html',
    styleUrls: ['./quotas.component.scss'],
})
export class QuotasComponent implements OnInit {
    public quotas: GetMeQuotasResponse | undefined;
    constructor(
        public sessionService: SessionService,
        public dialog: DialogService
    ) {}

    async ngOnInit() {
        this.quotas = await this.sessionService.getQuotas(true);
        console.log(this.quotas);
    }
}
