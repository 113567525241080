/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttachmentResponse } from '../models/attachment-response';
import { BinaryFileType } from '../models/binary-file-type';
import { deleteAttachment } from '../fn/attachments/delete-attachment';
import { DeleteAttachment$Params } from '../fn/attachments/delete-attachment';
import { getAttachment$Heic } from '../fn/attachments/get-attachment-heic';
import { GetAttachment$Heic$Params } from '../fn/attachments/get-attachment-heic';
import { getAttachment$Heif } from '../fn/attachments/get-attachment-heif';
import { GetAttachment$Heif$Params } from '../fn/attachments/get-attachment-heif';
import { getAttachment$Jpeg } from '../fn/attachments/get-attachment-jpeg';
import { GetAttachment$Jpeg$Params } from '../fn/attachments/get-attachment-jpeg';
import { getAttachment$Pdf } from '../fn/attachments/get-attachment-pdf';
import { GetAttachment$Pdf$Params } from '../fn/attachments/get-attachment-pdf';
import { getAttachment$Png } from '../fn/attachments/get-attachment-png';
import { GetAttachment$Png$Params } from '../fn/attachments/get-attachment-png';
import { getAttachmentsForDeliveryNote } from '../fn/attachments/get-attachments-for-delivery-note';
import { GetAttachmentsForDeliveryNote$Params } from '../fn/attachments/get-attachments-for-delivery-note';
import { GetAttachmentsResponse } from '../models/get-attachments-response';
import { postAttachmentToDeliveryNote$Heic } from '../fn/attachments/post-attachment-to-delivery-note-heic';
import { PostAttachmentToDeliveryNote$Heic$Params } from '../fn/attachments/post-attachment-to-delivery-note-heic';
import { postAttachmentToDeliveryNote$Heif } from '../fn/attachments/post-attachment-to-delivery-note-heif';
import { PostAttachmentToDeliveryNote$Heif$Params } from '../fn/attachments/post-attachment-to-delivery-note-heif';
import { postAttachmentToDeliveryNote$Jpeg } from '../fn/attachments/post-attachment-to-delivery-note-jpeg';
import { PostAttachmentToDeliveryNote$Jpeg$Params } from '../fn/attachments/post-attachment-to-delivery-note-jpeg';
import { postAttachmentToDeliveryNote$Png } from '../fn/attachments/post-attachment-to-delivery-note-png';
import { PostAttachmentToDeliveryNote$Png$Params } from '../fn/attachments/post-attachment-to-delivery-note-png';


/**
 * Endpoints to handle attachments to load carriers
 */
@Injectable({ providedIn: 'root' })
export class AttachmentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postAttachmentToDeliveryNote()` */
  static readonly PostAttachmentToDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/attachments/file/{fileName}';

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAttachmentToDeliveryNote$Heic()` instead.
   *
   * This method sends `image/heic` and handles request body of type `image/heic`.
   */
  postAttachmentToDeliveryNote$Heic$Response(params: PostAttachmentToDeliveryNote$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentResponse>> {
    return postAttachmentToDeliveryNote$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAttachmentToDeliveryNote$Heic$Response()` instead.
   *
   * This method sends `image/heic` and handles request body of type `image/heic`.
   */
  postAttachmentToDeliveryNote$Heic(params: PostAttachmentToDeliveryNote$Heic$Params, context?: HttpContext): Observable<AttachmentResponse> {
    return this.postAttachmentToDeliveryNote$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentResponse>): AttachmentResponse => r.body)
    );
  }

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAttachmentToDeliveryNote$Heif()` instead.
   *
   * This method sends `image/heif` and handles request body of type `image/heif`.
   */
  postAttachmentToDeliveryNote$Heif$Response(params: PostAttachmentToDeliveryNote$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentResponse>> {
    return postAttachmentToDeliveryNote$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAttachmentToDeliveryNote$Heif$Response()` instead.
   *
   * This method sends `image/heif` and handles request body of type `image/heif`.
   */
  postAttachmentToDeliveryNote$Heif(params: PostAttachmentToDeliveryNote$Heif$Params, context?: HttpContext): Observable<AttachmentResponse> {
    return this.postAttachmentToDeliveryNote$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentResponse>): AttachmentResponse => r.body)
    );
  }

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAttachmentToDeliveryNote$Jpeg()` instead.
   *
   * This method sends `image/jpeg` and handles request body of type `image/jpeg`.
   */
  postAttachmentToDeliveryNote$Jpeg$Response(params: PostAttachmentToDeliveryNote$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentResponse>> {
    return postAttachmentToDeliveryNote$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAttachmentToDeliveryNote$Jpeg$Response()` instead.
   *
   * This method sends `image/jpeg` and handles request body of type `image/jpeg`.
   */
  postAttachmentToDeliveryNote$Jpeg(params: PostAttachmentToDeliveryNote$Jpeg$Params, context?: HttpContext): Observable<AttachmentResponse> {
    return this.postAttachmentToDeliveryNote$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentResponse>): AttachmentResponse => r.body)
    );
  }

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAttachmentToDeliveryNote$Png()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  postAttachmentToDeliveryNote$Png$Response(params: PostAttachmentToDeliveryNote$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentResponse>> {
    return postAttachmentToDeliveryNote$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Add attachment to open delivery note.
   *
   * Add a new attachment to a existing delivery note.
   * The delivery note bundle must be in status 'open'.
   * Accepted Formats are jpg, png, heic and heif.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAttachmentToDeliveryNote$Png$Response()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  postAttachmentToDeliveryNote$Png(params: PostAttachmentToDeliveryNote$Png$Params, context?: HttpContext): Observable<AttachmentResponse> {
    return this.postAttachmentToDeliveryNote$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentResponse>): AttachmentResponse => r.body)
    );
  }

  /** Path part for operation `getAttachment()` */
  static readonly GetAttachmentPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/attachments/{attachmentKey}';

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Pdf$Response(params: GetAttachment$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getAttachment$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Pdf(params: GetAttachment$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getAttachment$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Heic$Response(params: GetAttachment$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getAttachment$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Heic(params: GetAttachment$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getAttachment$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Heif$Response(params: GetAttachment$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getAttachment$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Heif(params: GetAttachment$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getAttachment$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Jpeg$Response(params: GetAttachment$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getAttachment$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Jpeg(params: GetAttachment$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getAttachment$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Png$Response(params: GetAttachment$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getAttachment$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one attachment of delivery note.
   *
   * Get a single attachment of a delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Png(params: GetAttachment$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getAttachment$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `deleteAttachment()` */
  static readonly DeleteAttachmentPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/attachments/{attachmentKey}';

  /**
   * Delete attachment from delivery note.
   *
   * Delete a attachment from a delivery note.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment$Response(params: DeleteAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete attachment from delivery note.
   *
   * Delete a attachment from a delivery note.
   * The delivery note bundle must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment(params: DeleteAttachment$Params, context?: HttpContext): Observable<void> {
    return this.deleteAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAttachmentsForDeliveryNote()` */
  static readonly GetAttachmentsForDeliveryNotePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/attachments';

  /**
   * All attachments of a delivery note.
   *
   * Get a list of attachments of single delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachmentsForDeliveryNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentsForDeliveryNote$Response(params: GetAttachmentsForDeliveryNote$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAttachmentsResponse>> {
    return getAttachmentsForDeliveryNote(this.http, this.rootUrl, params, context);
  }

  /**
   * All attachments of a delivery note.
   *
   * Get a list of attachments of single delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachmentsForDeliveryNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentsForDeliveryNote(params: GetAttachmentsForDeliveryNote$Params, context?: HttpContext): Observable<GetAttachmentsResponse> {
    return this.getAttachmentsForDeliveryNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAttachmentsResponse>): GetAttachmentsResponse => r.body)
    );
  }

}
