<div [formGroup]="form">
    <!-- Ladungsträger -->
    <div
        class="row mb-3"
        *ngIf="!loadCarrier && !readyToSign">
        <div class="col-md-12 align-left">
            <button
                (click)="addLoadCarrier()"
                mat-raised-button
                color="accent">
                {{ 'CARRIER.EXCHANGE.TITLE' | translate }}
            </button>
        </div>
    </div>

    <ng-container *ngIf="loadCarrier">
        <h4 class="mt-2 bg-secondary text-light p-2">Ladungsträgertausch</h4>
        <div
            [formArrayName]="nameOfControl"
            *ngFor="
                let carrierExchangeControl of carrierExchangesFormArray.controls;
                let i = index
            ">
            <div
                [formGroupName]="i"
                class="row align-items-center">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Ladungsträger</mat-label>
                        <mat-select
                            id="select-carrier"
                            formControlName="loadCarrierTypeId">
                            <mat-option
                                *ngFor="let carrier of allCarrierTypes"
                                [value]="carrier._key">
                                {{ carrier.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Anzahl</mat-label>
                        <input
                            matInput
                            id="input-count-exchange-{{ i }}"
                            type="number"
                            min="0"
                            oninput="this.value = Math.abs(this.value)"
                            formControlName="quantity"
                            [autocomplete]="env.production ? 'off' : 'auto'" />
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <div
                        class="mat-field-container"
                        class="fix-button-position">
                        <mat-radio-group
                            formControlName="operation"
                            required>
                            <mat-radio-button
                                [value]="carrierOperations.ADOPTION">
                                {{ carrierOperations.ADOPTION | titlecase }}
                            </mat-radio-button>
                            <mat-radio-button
                                [value]="carrierOperations.DELIVERY">
                                {{ carrierOperations.DELIVERY | titlecase }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div
                    class="col-md-1"
                    *ngIf="!readyToSign && !readyToSave">
                    <div class="actions">
                        <div
                            matTooltip="Weitere Ladungsträger"
                            *ngIf="
                                i + 1 ===
                                carrierExchangesFormArray.controls.length
                            "
                            (click)="addCarrierExchange()"
                            id="add-carrier-exchange-button-{{ i }}"
                            class="rounded-button">
                            <mat-icon fontSet="material-symbols-outlined"
                                >add</mat-icon
                            >
                        </div>
                        <div
                            matTooltip="Ladungsträger entfernen"
                            (click)="removeCarrierExchange(i)"
                            class="rounded-button">
                            <mat-icon fontSet="material-symbols-outlined"
                                >remove</mat-icon
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Fahrerdaten -->
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="fill">
                <mat-label>Name Fahrer</mat-label>
                <input
                    matInput
                    id="inout-driver-name"
                    formControlName="driverName"
                    #focusdriver
                    [autocomplete]="env.production ? 'off' : 'auto'" />
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="fill">
                <mat-label>Kennzeichen</mat-label>
                <input
                    matInput
                    id="input-licenseplate"
                    formControlName="driverLicensePlate"
                    [autocomplete]="env.production ? 'off' : 'auto'" />
            </mat-form-field>
        </div>
    </div>
</div>
