<div class="wrapper">
    <div id="Nutzungsbedingungen"></div>
    <div class="mt-5">&nbsp;</div>
    <div class="container spacer-top">
        <div class="row">
            <div class="col-12">
                <h2>
                    Projekt &bdquo;Digitaler Lieferschein&ldquo;
                    Nutzungsbedingungen
                </h2>
                <p><strong>Pr&auml;ambel:</strong></p>
                <p>
                    Aus dem Themenkreis des BVL.digital "Digitalisierung in der
                    Transportlogistik" entstand Ende 2019 die Absicht, das Thema
                    "Digitaler Lieferschein" gemeinsam zu bearbeiten und einer
                    L&ouml;sung zuzuf&uuml;hren. Seite Mitte 2020
                    unterst&uuml;tzt GS1 Germany GmbH, im folgenden GS1 Germany,
                    das Vorhaben und hat es in einem &uuml;bergreifenden Projekt
                    mit Versendern, Logistikern und H&auml;ndlern
                    geb&uuml;ndelt.
                </p>
                <p>
                    Ziel des Projektes ist es, durch die Einf&uuml;hrung eines
                    Digitalen Lieferscheins sowohl Kosten zu reduzieren als auch
                    einen wesentlichen Beitrag zur Nachhaltigkeit zu leisten.
                </p>
                <p>
                    In einem n&auml;chsten Schritt soll nun mit
                    ausgew&auml;hlten Partnern die grunds&auml;tzliche
                    Machbarkeit dieses Konzeptes in einem Proof of Concept PoC)
                    &uuml;berpr&uuml;ft werden. Dabei soll sowohl der Prozess
                    als auch die minimale, technische Umsetzung (MVP) getestet
                    werden. Mit dem PoC soll erprobt werden wie der
                    papierbasierte Lieferschein durch einen digitalen
                    maschinenlesbaren Lieferschein &uuml;ber die Prozesskette
                    hinweg abgel&ouml;st werden kann. Aufbauend auf die
                    Ergebnisse soll im Anschluss &uuml;ber die weiteren Schritte
                    entschieden werden.
                </p>
                <p>
                    Dies vorangestellt, vereinbaren GS1 Germany und der Partner
                    nachfolgende Nutzungsbedingungen f&uuml;r die Zusammenarbeit
                    im Projekt &bdquo;Digitaler Lieferschein&ldquo;:
                </p>
                <h2>1. Gegenstand der Vereinbarung</h2>
                <ul>
                    <li>
                        Der Gegenstand der Vereinbarung ist die Zusammenarbeit
                        in dem Pilotprojekt &bdquo;Digitaler
                        Lieferschein&ldquo;.
                    </li>
                    <li>
                        Ziel der Zusammenarbeit ist es, innerhalb des
                        Pilotprojektes M&ouml;glichkeiten des Einsatzes von
                        digitalen Lieferscheinen bei Versendern, Logistikern und
                        H&auml;ndlern, ggf. aber auch weiteren Unternehmen, zu
                        untersuchen und hieraus Erkenntnisse zu
                    </li>
                    <li>
                        Der Partner erh&auml;lt das nicht ausschlie&szlig;liche,
                        auf die Vertragslaufzeit beschr&auml;nkte Recht, auf den
                        Webdienst und die Webapp des Projekts &bdquo;Digitaler
                        Lieferschein&ldquo;
                    </li>
                </ul>
                <h2>2. Durchf&uuml;hrung der Arbeiten/ Projektkoordination</h2>
                <ul>
                    <li>
                        Die Partner erkl&auml;ren sich zur aktiven Teilnahme an
                        dem Pilotprojekt
                    </li>
                    <li>
                        Die Partner werden fachlich qualifizierte Mitarbeiter
                        f&uuml;r die Mitarbeit in dem Pilotprojekt zur
                        Verf&uuml;gung
                    </li>
                    <li>
                        Jeder Partner wird einen f&uuml;r seine Mitarbeit
                        zust&auml;ndigen zentralen Ansprechpartner benennen (mit
                        Adresse, Rufnummer und E-Mail).
                    </li>
                    <li>Die Projektkoordination obliegt der GS1</li>
                </ul>
                <h2>3. Technische Zusammenarbeit</h2>
                <ul>
                    <li>
                        Technisch wird die Zusammenarbeit der Prozessteilnehmer
                        durch einen cloudbasierten Webdienst bei T- Systems
                        erfolgen. Ber&uuml;cksichtig werden dabei die 3 Parteien
                        Versender, Empf&auml;nger und Logistiker
                        (Spediteur/Fahrer).
                    </li>
                    <li>
                        Weiter wird eine Webapp mit den Rollen
                        &bdquo;Versender", ,,Empf&auml;nger" und
                        &bdquo;Logistiker" zur Verf&uuml;gung
                    </li>
                    <li>
                        Der Partner ist, soweit nicht in dieser Vereinbarung
                        gestattet, nur berechtigt, Daten zu seinen Lieferungen
                        Die Bearbeitung und das Herunterladen von Daten ist nur
                        im Rahmen der ihnen zugeteilten Zugriffsrechte und nur
                        zu eigenen unternehmerischen Zwecken gestattet. Der
                        Handel mit Daten ist nicht gestattet. Die Weitergabe der
                        Daten an Dritte au&szlig;erhalb des Partnerkreises ist
                        nur mit Zustimmung der GS1 Germany zul&auml;ssig. Der
                        Partner ist nicht berechtigt, &Auml;nderungen an der
                        Plattform vorzunehmen.
                    </li>
                    <li>
                        Die Daten werden in der so genannten Open Telekom Cloud
                        Die Serverinfrastruktur steht in Deutschland.
                    </li>
                </ul>
                <h2>4. Rechte und Arbeitsergebnisse</h2>
                <ul>
                    <li>
                        Alle bereits bestehenden geistigen Eigentumsrechte an
                        der Unternehmenstechnologie sowie Rechte an Patenten,
                        Urheberrechten, Marken und Gesch&auml;ftsgeheimnissen an
                        der Unternehmenstechnologie verbleiben bei dem Partner
                        und/oder seien
                    </li>
                    <li>
                        Als Arbeitsergebnisse werden alle Ergebnisse
                        einschlie&szlig;lich der erstellten Berichte und
                        Unterlagen bezeichnet, die von den Partnern bei
                        Erforschung von M&ouml;glichkeiten des Einsatzes des
                        digitalen Lieferscheins bei Versendern, H&auml;ndlern
                        und Logistikunternehmen sowie ggf. auch weiteren
                        Unternehmen erzielt werden, soweit diese nicht unter
                        Ziffer 1 fallen.
                    </li>
                    <li>
                        Arbeitsergebnisse geh&ouml;ren ausschlie&szlig;lich GS1
                        Germany. Die Partner r&auml;umen GS1 Germany an den bei
                        der Durchf&uuml;hrung des Pilotprojektes entstandenen
                        Arbeitsergebnissen gem&auml;&szlig; der Ziffern 4.2 ff.,
                        ein zeitlich und r&auml;umlich unbegrenztes,
                        ausschlie&szlig;liches, &uuml;bertragbares und
                        unentgeltliches Nutzungs- und Verwertungsrecht Die
                        Nutzungseinr&auml;umung gilt &uuml;ber die Laufzeit
                        dieser Vereinbarung hinaus.
                    </li>
                </ul>
                <h2>5. Kosten</h2>
                <p>
                    Jeder Partner tr&auml;gt die ihm im Rahmen der
                    Durchf&uuml;hrung dieser Vereinbarung entstehenden Kosten
                    selbst.
                </p>
                <h2>6. Pflichten und Obliegenheiten des Partners</h2>
                <p>
                    Der Partner wird alle Pflichten und Obliegenheiten
                    erf&uuml;llen, die zur Abwicklung der Vereinbarung
                    erforderlich sind. Hierzu z&auml;hlen insbesondere:
                </p>
                <ol>
                    <li>
                        Der Partner pr&uuml;ft eigenverantwortlich die
                        Einhaltung aller f&uuml;r ihn im Zusammenhang mit der
                        Nutzung der Leistung relevanten und anwendbaren
                        rechtlichen Vorschriften, Gesetze, Ver- ordnungen und
                        branchenspezifischen Bestimmungen und stellt deren
                        Einhaltung
                    </li>
                    <li>
                        Der Partner verpflichtet sich, seine Daten und
                        Informationen vor der Eingabe auf Viren und sonstige
                        sch&auml;dliche Komponenten zu pr&uuml;fen und hierzu
                        dem Stand der Technik entsprechende Virenschutzprogramme
                    </li>
                    <li>
                        Der Partner wird die ihm bzw. den Nutzern zugeordneten
                        Nutzungs- und Zugangsberechti- gungen geheim halten, vor
                        dem Zugriff durch Dritte sch&uuml;tzen und nicht an
                        unberechtigte Nutzer Diese Daten sind durch geeignete
                        und &uuml;bliche Ma&szlig;nahmen zu sch&uuml;tzen. Der
                        Partner wird GS1 Germany GmbH unverz&uuml;glich
                        unterrichten, wenn der Verdacht besteht, dass die
                        Zugangsdaten und/oder Kennw&ouml;rter nicht berechtigten
                        Personen bekannt geworden sein k&ouml;nnten.
                    </li>
                    <li>
                        Der Partner wird eine nach DSGVO erforderliche
                        Einwilligung des jeweils Betroffenen einho- len, soweit
                        er bei Nutzung der Plattform personenbezogene Daten
                        erhebt, verarbeitet oder nutzt und kein gesetzlicher
                        Erlaubnistatbestand
                    </li>
                    <li>
                        Der Partner wird vor der Versendung von Daten und
                        Informationen diese auf Viren pr&uuml;fen und dem Stand
                        der Technik entsprechende Virenschutzprogramme
                    </li>
                    <li>
                        Der Partner wird, wenn er zur Erzeugung von
                        Anwendungsdaten mit Hilfe der Webapp Daten
                        &uuml;bermittelt, diese regelm&auml;&szlig;ig und der
                        Bedeutung der Daten entsprechend sichern und eigene
                        Sicherungskopien erstellen, um bei Verlust der Daten und
                        Informationen die Rekonstruktion derselben zu
                        erm&ouml;glichen.
                    </li>
                    <li>
                        Der Partner erkl&auml;rt sich mit dem Schriftwechsel per
                        E-Mail einverstanden und wird stets eine aktuelle
                        E-Mail-Adresse hinterlegen. Dem Partner ist bekannt,
                        dass f&uuml;r die Leistungserbrin- gung wesentliche
                        Informationen, wie Zugangsdaten und/oder Informationen
                        zu &Auml;nderungen der Leistungen, ausschlie&szlig;lich
                        per E-Mail versendet
                    </li>
                </ol>
                <h2>7. Datensicherheit, Datenschutz</h2>
                <ul>
                    <li>
                        Die Partner werden die jeweils anwendbaren, insbesondere
                        die in Deutschland geltenden datenschutzrechtlichen
                        Bestimmungen
                    </li>
                    <li>
                        GS1 Germany wird personenbezogene Daten nur in dem
                        Umfang erheben und nutzen, wie es die Durchf&uuml;hrung
                        dieser Vereinbarung erfordert. Der Partner stimmt der
                        Erhebung und Nutzung solcher Daten in diesem Umfang zu.
                        Im Rahmen der zugeteilten Rechte bestimmt der Partner,
                        welchen Unternehmen seine Daten zug&auml;nglich gemacht
                        zum Herunterladen bereitgestellt werden.
                    </li>
                    <li>
                        Die Verpflichtungen nach Abs. 1 bis 2 bestehen, solange
                        Anwendungsdaten im Einflussbereich von GS1 Germany
                        liegen, auch &uuml;ber das Ende der Vereinbarung
                    </li>
                </ul>
                <ul>
                    <li>
                        Die Parteien schlie&szlig;en nach Ma&szlig;gabe von
                        &sect; 28 DSGVO die als
                        <strong>Anhang 1 </strong>beigef&uuml;gte Vereinbarung
                        &uuml;ber die
                    </li>
                </ul>
                <h2>8. Geheimhaltung</h2>
                <ul>
                    <li>
                        Die Parteien werden ausdr&uuml;cklich als vertraulich
                        bezeichneten Informationen und solche Informationen,
                        deren Vertraulichkeit sich aus den Umst&auml;nden der
                        &Uuml;berlassung eindeutig ergibt, als vertraulich
                    </li>
                </ul>
                <p>
                    Als vertraulich sind insbesondere die Webapp L&ouml;sung und
                    deren Funktionalit&auml;ten zu betrachten.
                </p>
                <p>
                    Keine vertraulich zu behandelnde Information liegt vor,
                    soweit der die Information empfangende Partei nachweist,
                    dass sie
                </p>
                <ul>
                    <li>
                        ihm vor dem Empfangsdatum bekannt oder allgemein
                        zug&auml;nglich war;
                    </li>
                    <li>
                        der &Ouml;ffentlichkeit vor dem Empfangsdatum bekannt
                        oder allgemein zug&auml;nglich war;
                    </li>
                    <li>
                        der &Ouml;ffentlichkeit nach dem Empfangsdatum bekannt
                        oder allgemein zug&auml;nglich wurde, ohne dass der
                        informationsempfangende Partei hierf&uuml;r
                        verantwortlich ist;
                    </li>
                    <li>
                        ihm nach dem Empfangsdatum von einem Dritten
                        rechtm&auml;&szlig;ig und ohne
                        Geheimhaltungsverpflichtungen bekannt gemacht wurden;
                    </li>
                    <li>
                        auf Grund einer Anordnung staatlicher Beh&ouml;rden oder
                        Gerichte oder wegen zwingender gesetzlicher Vorschriften
                        offengelegt werden m&uuml;ssen.
                    </li>
                </ul>
                <ul>
                    <li>
                        Die Parteien werden &uuml;ber alle vertraulichen
                        Informationen, die ihnen im Rahmen dieser Vereinbarung
                        zur Kenntnis gelangt sind, Stillschweigen bewahren bzw.
                        diese nur im vorher schriftlich hergestellten
                        Einvernehmen des jeweils anderen Partei Dritten
                        gegen&uuml;ber &ndash; gleich zu welchem Zweck &ndash;
                    </li>
                    <li>
                        Die Verpflichtungen nach Abs. 2 bestehen auch &uuml;ber
                        das Ende der Vereinbarung hinaus auf unbestimmte Zeit,
                        und zwar so lange, wie ein Ausnahmetatbestand nach 1
                        nicht nachgewiesen ist.
                    </li>
                </ul>
                <h2>9. Gew&auml;hrleistung und Haftung</h2>
                <ul>
                    <li>
                        Die Partner werden die von ihnen im Rahmen des
                        Pilotprojektes &uuml;bernommenen Arbeiten
                        sachgem&auml;&szlig; und nach bestem Wissen unter
                        Ber&uuml;cksichtigung des neuesten Standes von
                        Wissenschaft und Technik ausf&uuml;hren.
                    </li>
                    <li>
                        Anspr&uuml;che der Partner gegeneinander und
                        gegen&uuml;ber GS1 Germany auf Ersatz von Sch&auml;den
                        sind ausgeschlossen, soweit sie nicht auf Vorsatz oder
                        grober Fahrl&auml;ssigkeit beruhen. Dies gilt auch
                        f&uuml;r mittelbare Sch&auml;den.
                    </li>
                    <li>
                        Da es sich um ein Pilotprojekt handelt, kann eine
                        Haftung f&uuml;r die Verf&uuml;gbarkeit der webbasierten
                        Cloud der Webapp nicht &uuml;bernommen werden. Den
                        Parteien ist es bewusst, dass es sich um ein
                        Pilotprojekt handelt, und dass die papierbasierten
                        Lieferscheine mitgef&uuml;hrt werden m&uuml;ssen.
                    </li>
                </ul>
                <h2>10. Laufzeit, K&uuml;ndigung</h2>
                <p>
                    Die Vereinbarung beginnt mit der Zustimmung des Partners zu
                    diesen Nutzungsbedingungen und wird auf unbestimmte Zeit
                    geschlossen und endet sp&auml;testens am 30.09.2022.
                </p>
                <p>
                    Unabh&auml;ngig davon kann die Vereinbarung mit einer Frist
                    von 2 Wochen gek&uuml;ndigt werden.
                </p>
                <p>
                    Das Recht zur au&szlig;erordentlichen K&uuml;ndigung dieser
                    Vereinbarung aus wichtigem Grund bleibt unber&uuml;hrt.
                </p>
                <h2>11. Schlussbestimmungen</h2>
                <ul>
                    <li>
                        Diese Vereinbarung unterliegt deutschem Recht unter
                        Ausschluss des UN-Kaufrechts (CISG).
                    </li>
                    <li>
                        Erf&uuml;llungsort und Gerichtsstand f&uuml;r
                        s&auml;mtliche sich aus dieser Vereinbarung ergebenden
                        bzw. mit dieser Vereinbarung zusammenh&auml;ngenden
                        Streitigkeiten ist K&ouml;ln.
                    </li>
                    <li>
                        Sollten einzelne Bestimmungen dieser Vereinbarung
                        unwirksam sein, soll hierdurch die G&uuml;ltigkeit der
                        &uuml;brigen Vereinbarung nicht ber&uuml;hrt werden. Die
                        Vereinbarung ist in diesem Fall durch eine schriftlich
                        zu vereinbarende Regelung zu erg&auml;nzen, die dem mit
                        der unwirksamen Bestimmung verfolgten wirtschaftlichen
                        Zweck in rechtlich zul&auml;ssiger Weise am
                        n&auml;chsten Dasselbe gilt, wenn sich nach Abschluss
                        eine L&uuml;cke dieser Vereinbarung herausstellen
                        sollte.
                    </li>
                </ul>
                <h2>Anlage 1 zu den Nutzungsbedingungen</h2>
                <p>
                    Vertrag &uuml;ber die Verarbeitung personenbezogener Daten
                </p>
                <p>- nachfolgend &bdquo;AVV-Vertrag&ldquo; genannt -</p>
                <p>zwischen Partner</p>
                <p>
                    - nachfolgend &bdquo;Verantwortlichen&ldquo; genannt &ndash;
                </p>
                <p>und</p>
                <p>GS1 Germany GmbH Maarweg 133</p>
                <p>50825 K&ouml;ln</p>
                <p>- nachfolgend &bdquo;Auftragsverarbeiter&ldquo; genannt -</p>
                <p>
                    - gemeinsam nachfolgend einzeln oder gemeinsam auch
                    &bdquo;Parteien&ldquo; genannt -
                </p>
                <h2>&sect; 1 Begriffsbestimmungen</h2>
                <p>Im Sinne dieses AVV-Vertrags bezeichnet der Ausdruck</p>
                <ul>
                    <li>
                        &bdquo;<strong>Auftragsverarbeiter&ldquo;</strong>&ldquo;
                        eine nat&uuml;rliche oder juristische Person,
                        Beh&ouml;rde, Einrichtung oder andere Stelle, die
                        personenbezogene Daten im Auftrag des Verantwortlichen
                        verarbeitet.
                    </li>
                </ul>
                <p>
                    &bdquo;Auftragsverarbeiter&ldquo; ist die im Vorstehenden
                    als &bdquo;Auftragsverarbeiter&ldquo; bezeichnete
                    Vertragspartei.
                </p>
                <ul>
                    <li>
                        <strong>&bdquo;Dritter&ldquo; </strong>eine
                        nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
                        Einrichtung oder andere Stelle, au&szlig;er der
                        betroffenen Person, dem Verantwortlichen, dem
                        Auftragsverarbeiter und den Personen, die unter der
                        unmittelbaren Verantwortung des Verantwortlichen oder
                        des Auftragsverarbeiters befugt sind, die
                        personenbezogenen Daten zu
                    </li>
                </ul>
                <ul>
                    <li>
                        &bdquo;<strong>Hauptvertrag</strong>&ldquo; den in 2
                        n&auml;her gekennzeichneten Dienstleistungs- oder
                        Kooperationsvertrag.
                    </li>
                </ul>
                <ul>
                    <li>
                        &bdquo;<strong>Verantwortlicher</strong>&ldquo; die
                        nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
                        Einrichtung oder andere Stelle, die allein oder
                        gemeinsam mit anderen &uuml;ber die Zwecke und Mittel
                        der Verarbeitung von personenbezogenen Daten
                    </li>
                </ul>
                <p>
                    &bdquo;Verantwortlicher&ldquo; ist die im Vorstehenden als
                    &bdquo;Verantwortlichen&ldquo; bezeichnete Vertragspartei,
                    die hier in diesem AVV-Vertrag allein &uuml;ber die Zwecke
                    und Mittel der Verarbeitung von personenbezogenen Daten
                    entscheidet.
                </p>
                <ul>
                    <li>
                        &bdquo;<strong>Verarbeitung&ldquo; </strong>jeden mit
                        oder ohne Hilfe automatisierter Verfahren
                        ausgef&uuml;hrten Vorgang oder jede solche Vorgangsreihe
                        im Zusammenhang mit personenbezogenen Daten wie das
                        Erheben, das Erfassen, die Organisation, das Ordnen, die
                        Speicherung, die Anpassung oder Ver&auml;nderung, das
                        Auslesen, das Abfragen, die Verwendung, die Offenlegung
                        durch &Uuml;bermittlung, Verbreitung oder eine andere
                        Form der Bereitstellung, den Abgleich oder die
                        Verkn&uuml;pfung, die Einschr&auml;nkung, das
                        L&ouml;schen oder die
                    </li>
                </ul>
                <ul>
                    <li>
                        &bdquo;<strong>personenbezogene Daten</strong>&ldquo;
                        alle Informationen, die sich auf eine identifizierte
                        oder identifizierbare nat&uuml;rliche Person (im
                        Folgenden &bdquo;betroffene Person&ldquo;) Als
                        identifizierbar wird eine nat&uuml;rliche Person
                        angesehen, die direkt oder indirekt, insbesondere
                        mittels Zuordnung zu einer Kennung wie einem Namen, zu
                        einer Kennnummer, zu Standortdaten, zu einer
                        Online-Kennung oder zu einem oder mehreren besonderen
                        Merkmalen, die Ausdruck der physischen, physiologischen,
                        genetischen, psychischen, wirtschaftlichen, kulturellen
                        oder sozialen Identit&auml;t dieser nat&uuml;rlichen
                        Person sind, identifiziert werden kann.
                    </li>
                </ul>
                <ul>
                    <li>
                        &bdquo;<strong
                            >weiterer Auftragsverarbeiter oder
                            Unterauftragsverarbeiter</strong
                        >&ldquo; den Vertragspartner des Auftragsverarbeiters,
                        der von diesem mit der Durchf&uuml;hrung bestimmter
                        Verarbeitungsaktivit&auml;ten f&uuml;r den
                        Verantwortlichen beauftragt
                    </li>
                </ul>
                <ul>
                    <li>
                        &bdquo;<strong>Sub-Unterauftragsverarbeiter</strong>&ldquo;
                        den Vereinbarungspartner des weiteren
                        Auftragsverarbeiters oder Unterauftragsverarbeiters, der
                        von Letzterem mit der Durchf&uuml;hrung bestimmter
                        Verarbeitungsaktivit&auml;ten im Regelungsbereich dieses
                        AVV-Vertrags beauftragt
                    </li>
                </ul>
                <h2>&sect; 2 Gegenstand des Vertrags, Rechtsgrundlage</h2>
                <ul>
                    <li>
                        <strong>[Rechtsgrundlage] </strong>Die Rechtsgrundlagen
                        dieser Vereinbarung sind die Bestimmungen der EU-
                        Datenschutzgrundverordnung (EU DSGVO)
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Gegenstand des Vertrags] </strong>Gegenstand
                        des Vertrags ist die Verarbeitung personenbezogener
                        Daten durch den Auftragsverarbeiter f&uuml;r den
                        Verantwortlichen in dessen Auftrag und nach dessen
                        Weisung im Zusammenhang mit der Nutzung der zeitlich
                        begrenzten Anwendung des proof-of-concept digitaler
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Konkretisierung der Verarbeitung] </strong>Aus
                        dem Hauptvertrag ergeben sich Gegenstand und Dauer des
                        Auftrags, Art und Zweck der Verarbeitung, Art der
                        personenbezogenen Daten sowie die Kategorien der
                        betroffenen Personen. in Verbindung mit
                        <strong>Annex 1</strong>. Der Verantwortliche
                        gew&auml;hrt dem Auftragsverarbeiter Zugriff auf
                        personenbezogene Daten des Verantwortlichen wie in
                        <strong>Annex 1 </strong>
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Wartung, Pr&uuml;fung] </strong>Erbringt der
                        Auftragsverarbeiter f&uuml;r den Verantwortlichen
                        Leistungen im Bereich Wartung / Fernwartung /
                        IT-Fehleranalyse, bei denen ein Zugriff auf
                        personenbezogene Daten des Verantwortlichen zwar nicht
                        bezweckt ist, aber nicht ausgeschlossen werden kann,
                        gilt diese Vereinbarung Etwaige diesbez&uuml;gliche
                        Detaillierungen der Verarbeitung legen die
                        Vertragsparteien in <strong>Annex 1 </strong>fest.
                    </li>
                </ul>
                <h2>&sect; 3 Rechte und Pflichten des Verantwortlichen</h2>
                <ul>
                    <li>
                        <strong
                            >[Zul&auml;ssigkeit der Datenverarbeitung] </strong
                        >F&uuml;r die Beurteilung der Zul&auml;ssigkeit der
                        Datenverarbeitung sowie f&uuml;r die Wahrung der Rechte
                        der Betroffenen ist allein der Verantwortliche
                        verantwortlich. Der Verantwortliche wird in seinem
                        Verantwortungsbereich daf&uuml;r Sorge tragen, dass die
                        gesetzlich notwendigen Voraussetzungen (z.B. durch
                        Einholung von Einwilligungserkl&auml;rungen) geschaffen
                        werden, damit der Auftragsverarbeiter die vereinbarten
                        Leistungen auch insoweit rechtsverletzungsfrei erbringen
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Weisungen] </strong>Der Auftragsverarbeiter
                        wird personenbezogene Daten nur auf dokumentierte
                        Weisung des Verantwortlichen &mdash; auch in Bezug auf
                        die &Uuml;bermittlung personenbezogener Daten an ein
                        Drittland oder eine internationale Organisation &mdash;
                        verarbeiten, sofern er nicht durch das Recht der Union
                        oder der Mitgliedstaaten, dem der Auftragsverarbeiter
                        unterliegt, hierzu verpflichtet ist. In einem solchen
                        Fall teilt der Auftragsverarbeiter dem Verantwortlichen
                        diese rechtlichen Anforderungen vor der Verarbeitung
                        mit, sofern das betreffende Recht eine solche Mitteilung
                        nicht wegen eines wichtigen &ouml;ffentlichen Interesses
                    </li>
                </ul>
                <p>
                    Alle Weisungen werden schriftlich oder per E-Mail erteilt.
                    Der Auftragsverarbeiter informiert den Verantwortlichen
                    unverz&uuml;glich, falls er der Auffassung ist, dass eine
                    Weisung gegen die geltenden rechtlichen Bestimmungen
                    verst&ouml;&szlig;t. Der Auftragsverarbeiter ist berechtigt,
                    die Durchf&uuml;hrung einer solchen Weisung solange
                    auszusetzen, bis diese durch den Aufraggeber best&auml;tigt
                    oder ge&auml;ndert wird.
                </p>
                <ul>
                    <li>
                        <strong>[Ausgleich Mehrleistung] </strong>Soweit im
                        Hauptvertrag Vereinbarungen zu Leistungs&auml;nderungen
                        getroffen wurden, gehen diese den Regelungen in diesem
                        Absatz Soweit keine Vereinbarung zu
                        Leistungs&auml;nderungen im Hauptvertrag getroffen
                        wurden, werden Weisungen und Ma&szlig;nahmen, die eine
                        Abweichung zu den in diesem AVV-Vertrag oder im
                        Hauptvertrag festgelegten Leistungen darstellen, als
                        Antrag auf Leistungs&auml;nderung behandelt. Weisungen
                        und Ma&szlig;nahmen, die &uuml;ber die vertraglich
                        vereinbarten Leistungen hinausgehen, sind &ndash;soweit
                        nicht ausdr&uuml;cklich anders vereinbart- bei
                        Mehraufwand f&uuml;r den Auftragsverarbeiter gesondert
                        zu verg&uuml;ten. Die Vertragsparteien werden sich in
                        diesem Fall &uuml;ber eine angemessene Verg&uuml;tung
                        gesondert verst&auml;ndigen.
                    </li>
                </ul>
                <p>
                    Soweit nicht ausdr&uuml;cklich anders vereinbart, werden
                    Unterst&uuml;tzungsleistungen des Auftragsverarbeiters nach
                </p>
                <ul>
                    <li>
                        3 (5), (6) und &sect; 4 (4), (5) (7), (8, dort Satz 2),
                        (9), (10, dort Satz 2), (11) dieser Vereinbarung
                        gesondert verg&uuml;tet. Die Vertragsparteien werden
                        sich bei Bedarf &uuml;ber Inhalt und Umfang etwaiger
                        Unterst&uuml;tzungsleistungen des Auftragsverarbeiters
                        sowie &uuml;ber eine angemessene Verg&uuml;tung
                        gesondert verst&auml;ndigen.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong
                            >[Nachweis durch den Auftragsverarbeiter] </strong
                        >Der Auftragsverarbeiter kann, unbenommen der Regelung
                        in 3 (5), die hinreichende Umsetzung der Pflichten aus
                        diesem AVV-Vertrag, insbesondere der technisch-
                        organisatorischen Ma&szlig;nahmen (&sect; 5) und
                        Ma&szlig;nahmen, die nicht nur den konkreten Auftrag
                        betreffen, durch folgende Nachweise belegen:
                    </li>
                </ul>
                <ul>
                    <li>die Einhaltung genehmigter Verhaltensregeln,</li>
                </ul>
                <ul>
                    <li>
                        die Zertifizierung nach einem genehmigten
                        Zertifizierungsverfahren,
                    </li>
                    <li>
                        aktuelle Testate, Berichte oder Berichtsausz&uuml;ge
                        unabh&auml;ngiger Instanzen (z.B.
                        Wirtschaftspr&uuml;fer, Revision),
                    </li>
                    <li>
                        eine geeignete Zertifizierung durch IT-Sicherheits- oder
                        Datenschutzaudit,
                    </li>
                    <li>Eigenerkl&auml;rung des</li>
                </ul>
                <ul>
                    <li>
                        <strong>[&Uuml;berpr&uuml;fungen, Inspektionen] </strong
                        >Der Verantwortliche kann die Einhaltung der
                        Vorschriften &uuml;ber den Datenschutz und der in diesem
                        AVV-Vertrag niedergelegten Pflichten durch die Einholung
                        von Ausk&uuml;nften und Abfrage der unter &sect; 3 Abs.
                        4 angef&uuml;hrten Nachweise beim Auftragsverarbeiter in
                        Hinblick auf die ihn betreffende Verarbeitung
                        kontrollieren. Der Verantwortliche wird vorrangig
                        pr&uuml;fen, ob die in Satz 1 dieses Absatzes
                        einger&auml;umte M&ouml;glichkeit der
                        &Uuml;berpr&uuml;fung ausreicht. Der Verantwortliche
                        kann dar&uuml;ber hinaus die Einhaltung der Vorschriften
                        &uuml;ber den Datenschutz vor Ort kontrollieren. Der
                        Verantwortliche kann die Kontrollen selbst
                        durchf&uuml;hren oder durch einen von ihm beauftragten
                        Dritten auf seine Kosten durchf&uuml;hren Vom
                        Verantwortlichen mit der Kontrolle betraute Personen
                        oder Dritte sind mit Beauftragung nachweislich zur
                        Wahrung der Vertraulichkeit zu verpflichten. Die vom
                        Verantwortlichen mit der Kontrolle betrauten Personen
                        oder Dritte werden dem Auftragsverarbeiter in
                        angemessener Form vorangek&uuml;ndigt und in die Lage
                        versetzt, ihre Legitimation zur Durchf&uuml;hrung der
                        Kontrollen nachzuweisen. Dritte im Sinne dieses Absatzes
                        d&uuml;rfen keine Vertreter von Wettbewerbern des
                        Auftragsverarbeiters sein. Der Verantwortliche wird
                        Kontrollen mit einer angemessenen Frist ank&uuml;ndigen
                        und bei deren Durchf&uuml;hrung auf
                        Gesch&auml;ftsbetrieb und Betriebsablauf R&uuml;cksicht
                        nehmen.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong
                            >[Unterst&uuml;tzung durch den Verantwortlichen] </strong
                        >Der Verantwortliche wird in Hinblick auf die ihn
                        betreffende Verarbeitung den Auftragsverarbeiter bei
                        Verdacht auf Datenschutzverletzungen und/oder anderen
                        Unregelm&auml;&szlig;igkeiten bei der Verarbeitung der
                        personenbezogenen Daten unverz&uuml;glich und
                        vollst&auml;ndig Der Verantwortliche wird in Hinblick
                        auf die ihn betreffende Verarbeitung den
                        Auftragsverarbeiter bei der Pr&uuml;fung m&ouml;glicher
                        Verst&ouml;&szlig;e und bei Abwehr von Anspr&uuml;chen
                        Betroffener oder Dritten sowie bei der Abwehr von
                        Sanktionen durch Aufsichtsbeh&ouml;rden zeitnah und
                        umf&auml;nglich unterst&uuml;tzen.
                    </li>
                </ul>
                <h2>&sect; 4 Rechte und Pflichten des Auftragsverarbeiters</h2>
                <ul>
                    <li>
                        <strong>[Datenverarbeitung] </strong>Der
                        Auftragsverarbeiter verarbeitet die personenbezogenen
                        Daten ausschlie&szlig;lich im Rahmen des getroffenen
                        Vertrags und nach Weisung des Verantwortlichen
                        entsprechend der Regelung des
                    </li>
                    <li>
                        3 Abs. 2. Der Auftragsverarbeiter verwendet die
                        personenbezogenen Daten f&uuml;r keine anderen Zwecke
                        und wird die ihm &uuml;berlassenen personenbezogenen
                        Daten nicht an unberechtigte Dritte weitergeben. Kopien
                        und Duplikate werden ohne vorherige Einwilligung des
                        Verantwortlichen nicht erstellt. Hiervon ausgenommen
                        sind Sicherheitskopien zur Gew&auml;hrleistung einer
                        ordnungsgem&auml;&szlig;en Datenverarbeitung.
                    </li>
                </ul>
                <p>
                    Der Auftragsverarbeiter gew&auml;hrleistet, dass die mit der
                    Verarbeitung der personenbezogenen Daten des
                    Verantwortlichen befassten Mitarbeiter und andere f&uuml;r
                    den Auftragsverarbeiter t&auml;tigen Personen diese
                    personenbezogenen Daten nur auf Grundlage der Weisung des
                    Verantwortlichen verarbeiten, es sei denn, sie sind nach dem
                    Recht der Union oder der Mitgliedstaaten zur Verarbeitung
                    verpflichtet.
                </p>
                <ul>
                    <li>
                        <strong>[Datenschutzbeauftragter] </strong>Der
                        Auftragsverarbeiter gew&auml;hrleistet, einen
                        unabh&auml;ngigen, fachkundigen und zuverl&auml;ssigen
                        Datenschutzbeauftragten bestellt zu haben, sofern dies
                        von dem anwendbaren Recht der Europ&auml;ischen Union
                        oder des Mitgliedsstaates, dem der Auftragsverarbeiter
                        unterliegt, gefordert wird.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong
                            >[R&auml;umliche Beschr&auml;nkungen; Vollmacht] </strong
                        >Den Ort der Datenverarbeitung legen die Parteien in
                        <strong>Annex 1 </strong>vor der Datenverarbeitung fest.
                        &Auml;nderungen des Orts der Datenverarbeitung werden
                        die Parteien bei Bedarf unter Beachtung der in dieser
                        Vereinbarung festgelegten Form Die Regelungen des &sect;
                        7 bleiben hiervon unber&uuml;hrt.
                    </li>
                </ul>
                <p>
                    Eine Datenverarbeitung in sogenannten Drittl&auml;ndern (d.
                    h. L&auml;ndern, die keine Mitgliedstaaten der
                    Europ&auml;ischen Union oder des Europ&auml;ischen
                    Wirtschaftsraumes (EWR) sind und &uuml;ber kein angemessenes
                    Datenschutzniveau verf&uuml;gen), wird unter
                    Ber&uuml;cksichtigung der einschl&auml;gigen geltenden
                    rechtlichen Bestimmungen der Europ&auml;ischen Union
                    vorgenommen.
                </p>
                <p>
                    Etwaige Einschr&auml;nkungen bei der Wahl der
                    Gestaltungsm&ouml;glichkeiten der Daten&uuml;bermittlung
                    nach Ma&szlig;gabe der einschl&auml;gigen geltenden
                    rechtlichen Bestimmungen zur Verarbeitung in
                    Drittl&auml;ndern werden die Parteien in
                    <strong>Annex 1 </strong>festlegen. Der Verantwortliche wird
                    die Wahl der Gestaltung der Daten&uuml;bermittlung durch den
                    Auftragsverarbeiter nicht unbillig einschr&auml;nken und im
                    erforderlichen Umfang mitwirken.
                </p>
                <p>
                    Der Auftragsverarbeiter bzw. seine genehmigten
                    Unterauftragsverarbeiter (&sect; 7) werden bei einer nach
                    Annex
                </p>
                <p>
                    1 zugelassenen Verwendung der EU-Standardvertragsklauseln
                    diese im Namen und im Auftrag des Verantwortlichen
                    abschlie&szlig;en. Die entsprechende Vertretungsvollmacht
                    hierf&uuml;r wird hiermit durch den Verantwortlichen
                    erteilt.
                </p>
                <ul>
                    <li>
                        <strong
                            >[Unterst&uuml;tzung bei Pflichten des
                            Verantwortlichen] </strong
                        >Der Auftragsverarbeiter wird &ndash; im vertraglich
                        vereinbarten Umfang unter Ber&uuml;cksichtigung der Art
                        der Verarbeitung und der ihm zur Verf&uuml;gung
                        stehenden Informationen - den Verantwortlichen bei der
                        Einhaltung seiner ihm nach den geltenden rechtlichen
                        Bestimmungen obliegenden Pflichten unterst&uuml;tzen.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong
                            >[Unterst&uuml;tzung bei &Uuml;berpr&uuml;fung und
                            Auskunftsbegehren] </strong
                        >Ist der Verantwortliche gegen&uuml;ber einer
                        staatlichen Stelle oder einer Person verpflichtet,
                        Ausk&uuml;nfte &uuml;ber die Verarbeitung von
                        personenbezogenen Daten zu geben, so wird der
                        Auftragsverarbeiter den Verantwortlichen darin
                        unterst&uuml;tzen, diese Ausk&uuml;nfte zu erteilen,
                        sofern diese Ausk&uuml;nfte die Datenverarbeitung
                        gem&auml;&szlig; diesem AVV-Vertrag
                    </li>
                </ul>
                <p>
                    Der Auftragsverarbeiter wird den Verantwortlichen
                    &ndash;soweit rechtlich zul&auml;ssig- &uuml;ber an ihn als
                    Auftragsverarbeiter gerichtete Mitteilungen der
                    Aufsichtsbeh&ouml;rden (z. B. Anfragen, Benachrichtigung
                    &uuml;ber Ma&szlig;nahmen oder Auflagen) in Verbindung mit
                    der Verarbeitung von personenbezogenen Daten nach diesem
                    AVV-Vertrag informieren. Soweit rechtlich zul&auml;ssig wird
                    der Auftragsverarbeiter Ausk&uuml;nfte an Dritte, auch an
                    Aufsichtsbeh&ouml;rden, nur nach schriftlicher Zustimmung
                    durch und in Abstimmung mit dem Verantwortlichen erteilen.
                </p>
                <ul>
                    <li>
                        <strong>[Meldung von Zwischenf&auml;llen] </strong>Der
                        Auftragsverarbeiter meldet eine Verletzung des Schutzes
                        der personenbezogenen Daten des Verantwortlichen an
                        diesen unverz&uuml;glich, nachdem ihm diese Verletzung
                        bekannt geworden
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Nachweis und Dokumentation] </strong>Die
                        Vertragsparteien unterst&uuml;tzen sich gegenseitig beim
                        Nachweis und der Dokumentation der ihnen obliegenden
                        Rechenschaftspflicht im Hinblick auf die Grunds&auml;tze
                        ordnungsgem&auml;&szlig;er
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong
                            >[Verzeichnis von im Auftrag durchgef&uuml;hrten
                            T&auml;tigkeiten der Verarbeitung] </strong
                        >Der Auftragsverarbeiter f&uuml;hrt nach Ma&szlig;gabe
                        der einschl&auml;gigen geltenden rechtlichen
                        Bestimmungen, denen der Auftragsverarbeiter unterliegt,
                        ein Verzeichnis zu allen Kategorien von im Auftrag des
                        Verantwortlichen durchgef&uuml;hrten T&auml;tigkeiten
                        der Der Auftragsverarbeiter unterst&uuml;tzt den
                        Verantwortlichen auf Anfrage und stellt dem
                        Verantwortlichen die f&uuml;r die F&uuml;hrung seines
                        Verzeichnisses von Verarbeitungst&auml;tigkeiten
                        notwendigen Angaben zur Verf&uuml;gung, soweit diese
                        Angaben im vertraglich umschriebenen Verantwortungs- und
                        Leistungsbereich als Auftragsverarbeiter liegen und der
                        Verantwortliche keinen anderen Zugang zu diesen
                        Informationen hat.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Datenschutz-Folgenabsch&auml;tzung] </strong
                        >Falls der Verantwortliche eine
                        Datenschutzfolgenabsch&auml;tzung durchf&uuml;hrt
                        und/oder eine Konsultation der Aufsichtsbeh&ouml;rde
                        nach einer Datenschutzfolgenabsch&auml;tzung
                        beabsichtigt, werden sich die Vertragsparteien bei
                        Bedarf &uuml;ber Inhalt und Umfang etwaiger
                        Unterst&uuml;tzungsleistungen des Auftragsverarbeiters
                    </li>
                </ul>
                <p>
                    (10)<strong>[Aus&uuml;bung von Betroffenenrechten] </strong
                    >Abh&auml;ngig von der Art der Verarbeitung wird der
                    Auftragsverarbeiter den Verantwortlichen bei dessen Pflicht
                    zur Beantwortung von Antr&auml;gen auf Wahrnehmung der
                    Betroffenenrechte nach M&ouml;glichkeit mit geeigneten
                    technischen und organisatorischen Ma&szlig;nahmen
                    unterst&uuml;tzen. Bei Bedarf werden sich die
                    Vertragsparteien &uuml;ber Inhalt und Umfang etwaiger
                    Unterst&uuml;tzungsleistungen des Auftragsverarbeiters
                    abstimmen.
                </p>
                <p>
                    Soweit sich ein Betroffener zwecks Geltendmachung eines
                    Betroffenenrechts unmittelbar an den Auftragsverarbeiter
                    wendet, leitet der Auftragsverarbeiter die Anfragen des
                    Betroffenen zeitnah an den Verantwortlichen weiter.
                </p>
                <p>
                    (11)<strong>[&Uuml;bergabe von Speichermedien] </strong
                    >Soweit sich Speichermedien im Besitz des Verantwortlichen
                    befinden, wird der Verantwortliche vor einer etwaig
                    vorgesehenen &Uuml;bergabe (z.B. zur Pr&uuml;fung oder
                    Abwicklung von Gew&auml;hrleistungsanspr&uuml;chen) an den
                    Auftragsverarbeiter oder dessen Unter-Auftragsverarbeiter
                    alle personenbezogenen Daten &ndash;soweit nicht anders
                    vereinbart- l&ouml;schen.
                </p>
                <p>
                    (12)<strong>[Abschluss der vertraglichen Arbeiten] </strong
                    >Nicht mehr ben&ouml;tigte Unterlagen mit personenbezogenen
                    Daten und Dateien, mit Ausnahme der aufgrund gesetzlicher
                    Verpflichtung des Auftragsverarbeiters weiter vorzuhaltenden
                    personenbezogenen Daten, werden &ndash;soweit nicht im
                    Hauptvertrag und dessen Anlagen und Anh&auml;nge bereits
                    geregelt und soweit nicht anders vereinbart- an den
                    Verantwortlichen zur&uuml;ckgegeben oder vernichtet bzw.
                    gel&ouml;scht. Gleiches gilt f&uuml;r Test- und
                    Ausschussmaterial.
                </p>
                <p>
                    (13)<strong
                        >[R&uuml;ckgabe oder L&ouml;schung von personenbezogenen
                        Daten] </strong
                    >Sofern die Vertragsparteien eine ausdr&uuml;ckliche
                    Vereinbarung zur R&uuml;ckgabe und L&ouml;schung von
                    personenbezogenen Daten bzw. Datentr&auml;gern getroffen
                    haben, geht diese Vereinbarung den Regelungen in diesem
                    Absatz vor.
                </p>
                <p>
                    Soweit die Vertragsparteien keine ausdr&uuml;ckliche
                    Vereinbarung zur R&uuml;ckgabe von personenbezogenen Daten
                    bzw. Datentr&auml;gern des Verantwortlichen getroffen haben
                    kann der Auftragsverarbeiter personenbezogene Daten bzw.
                    Datentr&auml;ger des Verantwortlichen zur&uuml;ckgeben. Wenn
                    der Verantwortliche seiner R&uuml;cknahmepflicht nicht
                    nachkommt, steht es dem Auftragsverarbeiter frei, die
                    personenbezogenen Daten bzw. Datentr&auml;ger auf Kosten des
                    Verantwortlichen zu l&ouml;schen/vernichten.
                </p>
                <p>
                    Der Verantwortliche kann w&auml;hrend des Bestehens des
                    Vertragsverh&auml;ltnisses oder mit Vertragsende schriftlich
                    die personenbezogenen Daten, die nicht gem&auml;&szlig;
                    &sect; 4 Abs. (12) vernichtet bzw. gel&ouml;scht sind,
                    heraus verlangen und dem Auftragsverarbeiter einen Zeitpunkt
                    (l&auml;ngstens bis Vertragsende) f&uuml;r die Herausgabe
                    nennen. Die Vertragsparteien werden sich nach
                    Herausgabeverlangen auf die weiteren Modalit&auml;ten der
                    Herausgabe (wie z.B. Format) verst&auml;ndigen. Das
                    Herausgabeverlangen muss dem Auftragsverarbeiter einen Monat
                    vor dem vom Verantwortlichen benannten Zeitpunkt bzw. ein
                    Monat vor Vertragsende zugegangen sein.
                </p>
                <h2>
                    &sect; 5 Technische und organisatorische
                    Sicherheitsma&szlig;nahmen
                </h2>
                <ul>
                    <li>
                        <strong
                            >[Technisch organisatorische Ma&szlig;nahmen] </strong
                        >Der Verantwortliche und der Auftragsverarbeiter werden
                        geeignete technische und organisatorische
                        Ma&szlig;nahmen treffen, um ein, dem Risiko angemessenes
                        Schutzniveau zu gew&auml;hrleisten.
                    </li>
                </ul>
                <p>
                    Die derzeit umzusetzenden Ma&szlig;nahmen des
                    Auftragsverarbeiters sind in
                    <strong>Annex 2 </strong>beschrieben und werden als
                    geeignete Ma&szlig;nahmen vereinbart. Der Verantwortliche
                    hat die technischen und organisatorischen Ma&szlig;nahmen im
                    Zusammenhang mit etwaigen weiteren Ma&szlig;nahmen in
                    Hinblick auf ein angemessenes Schutzniveau bewertet. Etwaige
                    Weiterentwicklungen erfolgen nach Ma&szlig;gabe von &sect; 5
                    Abs. 2.
                </p>
                <ul>
                    <li>
                        <strong>[Weiterentwicklung] </strong>Die technischen und
                        organisatorischen Ma&szlig;nahmen k&ouml;nnen im Laufe
                        des Vertragsverh&auml;ltnisses der technischen und
                        organisatorischen Weiterentwicklung angepasst werden,
                        soweit das vertraglich vereinbarte Schutzniveau nicht
                        unterschritten
                    </li>
                </ul>
                <p>
                    Die Sicherheit der Verarbeitung und die Angemessenheit des
                    Schutzniveaus wird der Verantwortliche regelm&auml;&szlig;ig
                    pr&uuml;fen und dem Auftragsverarbeiter etwaigen
                    Anpassungsbedarf unverz&uuml;glich mitteilen. Der
                    Verantwortliche wird dem Auftragsverarbeiter hierzu alle
                    erforderlichen Informationen zur Verf&uuml;gung stellen. Der
                    Auftragsverarbeiter seinerseits kontrolliert
                    regelm&auml;&szlig;ig die internen Prozesse sowie die
                    technischen und organisatorischen Ma&szlig;nahmen, um zu
                    gew&auml;hrleisten, dass die Verarbeitung in seinem
                    Verantwortungsbereich im Einklang mit den Anforderungen der
                    EU DSGVO erfolgt und der Schutz der Rechte der betroffenen
                    Person gew&auml;hrleistet wird. Der Verantwortliche ersetzt
                    dem Auftragsverarbeiter, soweit nicht ausdr&uuml;cklich
                    anderweitig vereinbart, den durch die Anpassung der
                    Schutzma&szlig;nahmen an den technischen Fortschritt
                    entstehenden Mehraufwand. Zum finanziellen Ausgleich
                    f&uuml;r den Auftragsverarbeiter f&uuml;hren solche
                    Ma&szlig;nahmen, die auf Wunsch des Verantwortlichen den
                    technischen Fortschritt &uuml;ber das vereinbarte
                    Schutzniveau hinaus ber&uuml;cksichtigen sollen.
                </p>
                <ul>
                    <li>
                        <strong>[&Uuml;berpr&uuml;fung und Nachweis] </strong
                        >F&uuml;r die &Uuml;berpr&uuml;fungs- und
                        Nachweism&ouml;glichkeiten gelten 3 Abs. 4 und &sect; 3
                        Abs. 5.
                    </li>
                </ul>
                <h2>&sect; 6 Vertraulichkeit</h2>
                <ul>
                    <li>
                        <strong>[Vertraulichkeit] </strong>Der
                        Auftragsverarbeiter wird im Zusammenhang mit der hier
                        vereinbarten Verarbeitung personenbezogener Daten die
                        Vertraulichkeit Er wird die zur Verarbeitung der
                        personenbezogenen Daten befugten Personen zur
                        Vertraulichkeit verpflichten, soweit diese nicht bereits
                        einer angemessenen gesetzlichen Verschwiegenheitspflicht
                        unterliegen.
                    </li>
                </ul>
                <p>
                    Vereinbarungen im Hauptvertrag zur Wahrung der
                    Vertraulichkeit und zum Schutz von nicht personenbezogenen
                    Daten bleiben unber&uuml;hrt. Soweit im Hauptvertrag hierzu
                    keine Vereinbarung getroffen wurden, verpflichten sich beide
                    Parteien, alle nicht allgemein offenkundigen Informationen
                    aus dem Bereich der anderen Partei, die ihnen durch die
                    Gesch&auml;ftsbeziehung bekannt werden, geheim zu halten und
                    nicht f&uuml;r eigene Zwecke au&szlig;erhalb dieses
                    Vertrages oder Zwecke Dritter zu verwenden.
                </p>
                <ul>
                    <li>
                        <strong>[Pflichten beteiligter Personen] </strong>Der
                        Auftragsverarbeiter wird Personen, die Zugang zu
                        personenbezogenen Daten haben, mit den f&uuml;r sie
                        ma&szlig;geblichen Datenschutzvorgaben und Weisungen
                        dieser Vereinbarung im Voraus vertraut
                    </li>
                </ul>
                <h2>&sect; 7 Unterauftragsverarbeiter</h2>
                <ul>
                    <li>
                        <strong>[Genehmigung] </strong>Der Auftragsverarbeiter
                        darf zur Erf&uuml;llung der in diesem Vertrag
                        beschriebenen Aufgaben gem&auml;&szlig; den
                        nachfolgenden Bedingungen weitere Auftragsverarbeiter
                        (Unterauftragsverarbeiter und Sub-
                        Unterauftragsverarbeiter) einsetzen
                        <strong>(Allgemeine schriftliche Genehmigung)</strong>.
                    </li>
                </ul>
                <p>
                    Nicht als Unterauftragsverh&auml;ltnisse im Sinne dieser
                    Regelung sind solche Auftr&auml;ge zu verstehen, die der
                    Auftragsverarbeiter bei Dritten als Nebenleistung zur
                    Unterst&uuml;tzung bei der Auftragsdurchf&uuml;hrung erteilt
                    und die keine Auftragsverarbeitungsleistung f&uuml;r den
                    Verantwortlichen beinhalten.
                </p>
                <p>
                    F&uuml;r die in <strong>Annex 3 </strong>aufgef&uuml;hrten
                    Unterauftragsverarbeiter und die dort genannten
                    Aufgabenbereiche gilt die gesonderte Genehmigung des
                    Verantwortlichen als erteilt
                    <strong>(Gesonderte Genehmigung)</strong>.
                </p>
                <ul>
                    <li>
                        <strong>[Information bei &Auml;nderungen] </strong>Der
                        Auftragsverarbeiter informiert den Verantwortlichen
                        schriftlich oder per E-Mail, &uuml;ber jede
                        beabsichtigte &Auml;nderung in Bezug auf die
                        Hinzuziehung oder die Ersetzung weiterer
                        Auftragsverarbeiter (Unterauftragsverarbeiter und
                        Sub-Unterauftragsverarbeiter), wodurch der
                        Verantwortliche die M&ouml;glichkeit erh&auml;lt, gegen
                        derartige &Auml;nderungen binnen 14 Tagen nach Zugang
                        der Information beim Verantwortlichen Einspruch zu
                        erheben. Die Vertragsparteien werden sich bei Bedarf
                        &uuml;ber Art und Weise, hinzutretender oder
                        alternativer M&ouml;glichkeiten der Information
                        &uuml;ber den k&uuml;nftigen Einsatz oder
                        &Auml;nderungen beim Einsatz weiterer
                        Unterauftragsverarbeiter und
                        Sub-Unterauftragsverarbeiter verst&auml;ndigen. Dies
                        kann B. die Vorhaltung und den Abruf einer Listung der
                        Unterauftragsverarbeiter und Sub-
                        Unterauftragsverarbeiter einschlie&szlig;en. Der
                        Verantwortliche wird die Genehmigung zur Einbindung
                        weiterer Unterauftragsverarbeiter und
                        Sub-Unterauftragsverarbeiter nicht ohne wichtigen Grund
                        verweigern.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong
                            >[K&uuml;ndigungsrecht des Auftragsverarbeiter] </strong
                        >Dem Auftragsverarbeiter steht ein
                        au&szlig;erordentliches K&uuml;ndigungsrecht des
                        Hauptvertrages nach Ma&szlig;gabe des Hauptvertrages
                        &ndash; oder f&uuml;r den Fall, dass ein solches
                        K&uuml;ndigungsrecht im Hauptvertrag nicht
                        einger&auml;umt wurde, ein au&szlig;erordentliches
                        K&uuml;ndigungsrecht von 4 Wochen zum Monatsende - zu,
                        wenn nach Auffassung des Auftragsverarbeiters der
                        Verantwortliche die Einbindung des
                        Unterauftragsverarbeiters und/oder
                        Sub-Unterauftragsverarbeiters ohne wichtigen Grund
                        verweigert oder dem Auftragsverarbeiter eine
                        Leistungserbringung ohne den abgelehnten
                        Unterauftragsverarbeiter und/oder
                        Sub-Unterauftragsverarbeiters nicht m&ouml;glich ist.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Auswahl, Back-to-Back-Vereinbarung] </strong
                        >Der Auftragsverarbeiter wird Unterauftragsverarbeiter
                        ausw&auml;hlen, die hinreichende Garantien daf&uuml;r
                        bieten, dass die vereinbarten geeigneten technischen und
                        organisatorischen Ma&szlig;nahmen so durchgef&uuml;hrt
                        werden, dass die Verarbeitung entsprechend den
                        Anforderungen der einschl&auml;gigen geltenden
                        rechtlichen Bestimmungen erfolgt. Der
                        Auftragsverarbeiter wird mit Unterauftragsverarbeitern
                        vertragliche Vereinbarungen treffen, die den
                        vertraglichen Regelungen diesem Vertrag inhaltlich
                        entsprechen. Der Auftragsverarbeiter wird mit dem
                        Unterauftragsverarbeiter die technischen und
                        organisatorischen Ma&szlig;nahmen festlegen und die
                        Einhaltung der vereinbarten technischen und
                        organisatorischen Ma&szlig;nahmen regelm&auml;&szlig;ig
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Sub-Unterauftragsverarbeiter] </strong>Die
                        Beauftragung von Sub-Unterauftragsverarbeitern durch den
                        Auftragsverarbeiter ist nach Ma&szlig;gabe der 7 Abs.
                        (1) bis Abs. (4) zul&auml;ssig.
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong
                            >[R&auml;umliche Beschr&auml;nkungen; Vollmacht] </strong
                        >Die Regelungen des 4 (3) bleiben unber&uuml;hrt.
                    </li>
                </ul>
                <h2>&sect; 8 Vertragsdauer; K&uuml;ndigung</h2>
                <p>
                    Diese Vereinbarung gilt f&uuml;r die Dauer der
                    tats&auml;chlichen Leistungserbringung durch den
                    Auftragsverarbeiter. Dies gilt unabh&auml;ngig von der
                    Laufzeit etwaiger anderer Vertr&auml;ge (insbesondere des
                    Hauptvertrags), die die Parteien ebenfalls bzgl. der
                    Erbringung der vereinbarten Leistungen abgeschlossen haben.
                </p>
                <h2>&sect; 9 Ansprechpartner</h2>
                <ul>
                    <li>Ansprechpartner beim Auftragsverarbeiter ist:</li>
                </ul>
                <p>Ansprechpartner: Frau Oxana Reiss Tel. 0170 7658719</p>
                <p>
                    E-Mail:
                    <a href="mailto:oxana.reiss@gs1.de"
                        >oxana.reiss&#64;gs1.de</a
                    >
                </p>
                <ul>
                    <li>
                        Datenschutzbeauftragter des Auftragsverarbeiters ist:
                    </li>
                </ul>
                <p>
                    Datenschutzbeauftragter: Frau Claudia Happel E-Mail:
                    <a href="mailto:datenschutz@gs1-germany.de"
                        >datenschutz&#64;gs1-germany.de</a
                    >
                </p>
                <p>Fax: +49 221 94714-990</p>
                <h2>&sect; 10 Haftung und Freistellung</h2>
                <ul>
                    <li>
                        <strong
                            >[Verantwortungsbereich des Verantwortlichen] </strong
                        >Der Verantwortliche gew&auml;hrleistet in seinem
                        Verantwortungsbereich die Umsetzung der sich aus den
                        einschl&auml;gigen geltenden rechtlichen Bestimmungen
                        ergebenden Pflichten bei der Verarbeitung
                        personenbezogener
                    </li>
                </ul>
                <ul>
                    <li>
                        <strong>[Haftung] </strong>Die Haftungsregelung des
                        Hauptvertrages gilt f&uuml;r diese Vereinbarung zur
                        Auftragsverarbeitung, soweit nicht eine
                        Haftungsbeschr&auml;nkung nach Ma&szlig;gabe der jeweils
                        einschl&auml;gigen geltenden rechtlichen Bestimmungen
                        zugunsten des Auftragsverarbeiters
                    </li>
                </ul>
                <h2>&sect; 11 Sonstiges</h2>
                <p>
                    (1)<strong>[G&uuml;ltigkeit des Vertrags] </strong>Von der
                    Ung&uuml;ltigkeit einer Bestimmung dieses AVV-Vertrags
                    bleibt die G&uuml;ltigkeit der &uuml;brigen Bestimmungen
                    unber&uuml;hrt. Sollte sich eine Bestimmung als unwirksam
                    erweisen, werden die Parteien diese durch eine neue
                    ersetzen, die dem von den Parteien Gewollten am
                    n&auml;chsten kommt.
                </p>
                <p>
                    (2)<strong>[&Auml;nderungen des Vertrags] </strong
                    >S&auml;mtliche &Auml;nderungen dieses AVV-Vertrags sowie
                    Nebenabreden bed&uuml;rfen der Schriftform
                    (einschlie&szlig;lich in elektronischer Form). Dies gilt
                    auch f&uuml;r das Abbedingen dieser Schriftformklausel
                    selbst.
                </p>
                <p>
                    (3)<strong>[Gesch&auml;ftsbedingungen] </strong>Es besteht
                    zwischen den Parteien Einigkeit dar&uuml;ber, dass die
                    &bdquo;Allgemeinen Gesch&auml;ftsbedingungen&ldquo; des
                    Verantwortlichen auf diesem AVV-Vertrag keine Anwendung
                    finden.
                </p>
                <p>
                    (4)<strong>[Gerichtsstand] </strong>Der alleinige
                    Gerichtsstand f&uuml;r alle Streitigkeiten aus und im
                    Zusammenhang mit diesem AVV-Vertrag ist in
                    <strong>Annex 1 </strong>festgelegt. Dieser gilt
                    vorbehaltlich eines etwaigen ausschlie&szlig;lich
                    gesetzlichen Gerichtsstandes.
                </p>
                <p>
                    (5)<strong>[Geltendes Recht] </strong>Es gelten die
                    Regelungen in &sect; 2 (1) und Annex 1.
                </p>
                <p>
                    (6)<strong>[Vorrangregelung] </strong>Bei Widerspr&uuml;chen
                    zwischen den Bestimmungen dieses Vertrags und Bestimmungen
                    sonstiger Vereinbarungen, insbesondere des Hauptvertrags,
                    sind die Bestimmungen dieses AVV-Vertrags ma&szlig;gebend.
                    Im &Uuml;brigen bleiben die Bestimmungen des Hauptvertrags
                    unber&uuml;hrt und gelten f&uuml;r diesen AVV- Vertrag
                    entsprechend.
                </p>
                <h2>Annexe:</h2>
                <p>
                    Nachstehende Annexe sind feste Bestandteile dieser
                    Vereinbarung:
                </p>
                <p>Annex 1: Einzelheiten der Datenverarbeitung</p>
                <p>
                    Annex 2: Technische und organisatorische
                    Sicherheitsma&szlig;nahmen Annex 3: Genehmigte
                    Unterauftragsverarbeiter
                </p>
                <h2><u>Annex 1</u></h2>
                <p>
                    <strong><u>Einzelheiten der Datenverarbeitung</u></strong>
                </p>
                <p>&nbsp;</p>
                <ol>
                    <li>
                        <strong
                            >Kategorien von Verarbeitungen, zu verarbeitende </strong
                        >personenbezogene
                        <strong>Daten/betroffene</strong>
                    </li>
                </ol>
                <p>
                    personenbezogene <strong>Daten; Art des Zugriffs:</strong>
                </p>
                <p>&nbsp;</p>
                <ol>
                    <li>
                        Angaben zu &bdquo;Kategorien von Verarbeitungen&ldquo;
                    </li>
                </ol>
                <p>&nbsp;Cloud Speicherdienst</p>
                <ol>
                    <li>Kategorien betroffener Personen:</li>
                </ol>
                <p>
                    Es werden Lieferscheindaten von den involvierten Firmen im
                    Logistikprozess verarbeitet.
                </p>
                <p>&nbsp;Lieferanten</p>
                <p>&nbsp;Handelsvertreter</p>
                <ol>
                    <li>Betroffene personenbezogene Daten:</li>
                </ol>
                <p>
                    Auf den Lieferscheinen finden sich Lieferdaten und
                    betriebliche Kontaktdaten von Mitarbeitern der involvierten
                    Firmen.
                </p>
                <p>&nbsp;Name Titel</p>
                <p>&nbsp;Anschrift</p>
                <p>&nbsp;Kontaktdaten (z. B. Telefon, E-Mail) Kundennummer</p>
                <ol>
                    <li>
                        Besondere Kategorien von personenbezogenen Daten (z.B.
                        Art. 9 DSGVO (m&uuml;ssen
                        <em>hier detailliert angegeben werden)</em>:
                    </li>
                </ol>
                <p>- keine -</p>
                <ol>
                    <li>Zugriff auf personenbezogene Daten</li>
                </ol>
                <p>
                    Versender stellen Lieferscheine im Rahmen des Lieferprozess
                    bereit. Die Lieferscheine werden &uuml;ber eine
                    Web-Applikation hochgeladen und in der Cloud abgelegt. Diese
                    Lieferscheine beinhaltet betriebliche Kontaktdaten von
                    Mitarbeitern der involvierten Firmen.
                </p>
                <p>
                    Der Verantwortliche stellt dem Auftragsverarbeiter die
                    personenbezogenen Daten bereit, erm&ouml;glicht ihm Zugriff
                    auf die personenbezogenen Daten oder erlaubt ihm, die
                    personenbezogenen Daten zu erheben und zwar wie nachfolgend
                    beschrieben:
                </p>
                <p>
                    &nbsp;&Uuml;bermittlung durch den Verantwortlichen &uuml;ber
                    gesicherte Verbindung:
                </p>
                <p>
                    Daten werden &uuml;ber eine sichere
                    Transportverschl&uuml;sselung in die Cloud hochgeladen.
                </p>
                <h2>2. Leistungen, Verarbeitungszweck:</h2>
                <p>
                    Die Lieferscheine werden als PDF-Dokumente durch die
                    Lieferanten hochgeladen und durch die T- Systems Applikation
                    in der Cloud abgelegt. Die Lieferscheine werden im Laufe des
                    Lieferprozesses durch die T-Systems Applikation aktualisiert
                    Warenpr&uuml;fung im Wareneingang, Quittieren durch die
                    Logistiker etc.). Personenbezogene Daten befinden sich auf
                    den Lieferscheinen werden von T-Systems aber nicht gesondert
                    erfasst oder ausgewertet.
                </p>
                <p>
                    Zugriff zu den Lieferscheinen haben &uuml;ber die T-Systems
                    Applikation Mitarbeiter der beteiligten Unternehmen in den
                    entsprechenden Lieferketten (Versender, Empf&auml;nger,
                    Spediteur, Fahrer, ggf. Kontrolleur (Polizei, Zoll)).
                </p>
                <h2>3. Verarbeitungsort:</h2>
                <p>
                    Die Verarbeitung der Daten findet in Deutschland, den
                    Niederlanden, Ungarn und der Slowakei statt.
                </p>
                <h2>
                    4. Anforderungen an die Auftragsverarbeitung in
                    Drittl&auml;nder:
                </h2>
                <p>
                    Alle Daten werden in der europ&auml;ischen Union
                    verarbeitet.
                </p>
                <h2>5. Gerichtsstand:</h2>
                <p>
                    Gerichtsstand f&uuml;r alle Streitigkeiten aus oder im
                    Zusammenhang mit diesem Vertrag ist K&ouml;ln.
                </p>
                <h2><u>Annex 2</u></h2>
                <p>
                    <strong
                        ><u
                            >Technische und organisatorische
                            Sicherheitsma&szlig;nahmen</u
                        ></strong
                    >
                </p>
                <p>
                    F&uuml;r die beauftragte Erhebung und / oder Verarbeitung
                    von personenbezogenen Daten werden folgende Ma&szlig;nahmen
                    vereinbart:
                </p>
                <p>TOMs DSGVO DTAG</p>
                <p>als Auftragsverarbeit</p>
                <p>
                    <strong><u>Annex 3</u></strong>
                </p>
                <p>
                    <strong><u>Genehmigte Unterauftragsverarbeiter</u></strong>
                </p>
                <p>
                    <strong
                        ><u
                            >Angaben zu Unterauftragsverarbeitern / Leistungen /
                            Verarbeitungsorte</u
                        ></strong
                    >
                </p>
                <p>&nbsp;</p>
                <p><strong>Gesonderte Genehmigung</strong></p>
                <p>
                    Der Auftragsverarbeiter beabsichtigt, die folgenden
                    Unterauftragsverarbeiter f&uuml;r die folgenden Leistungen
                </p>
                <p>/ an den folgenden Verarbeitungsorten einzusetzen:</p>
                <p>Telekom Deutschland GmbH Hohe Str. 80</p>
                <p>44139 Dortmund Verarbeitungsort: Deutschland</p>
                <p>T-Systems International GmbH</p>
                <p>
                    60528 Frankfurt am Main, Hahnstra&szlig;e 43d Services:
                    Systemintegrator Verarbeitungsort: Deutschland
                </p>
                <p>
                    Deutsche Telekom Systems Solution Hungary Kft. H-1097
                    Budapest, Toth Kalman u 2/B
                </p>
                <p>
                    Services: Operation, 2nd Level Support Verarbeitungsort:
                    Ungarn
                </p>
                <p>
                    Deutsche Telekom IT &amp; Telecommunications Hungary Kft.
                    H-1097 Budapest, Toth Kalman u 2/B
                </p>
                <p>Services: 2nd Level Support Verarbeitungsort: Ungarn</p>
                <p>
                    Deutsche Telekom IT GmbH 53227 Bonn, Landgrabenweg 151
                    Services: MyWorkplace Verarbeitungsort: Deutschland
                </p>
                <p>Deutsche Telekom AG</p>
                <p>53113 Bonn, Friedrich-Ebert-Allee 140</p>
                <p>Services: Cloud Provider Verarbeitungsort: Deutschland</p>
                <p>
                    Deutsche Telekom Systems Solutions Slovakia s.r.o. 040 01
                    Kosice, Zriedlova 13
                </p>
                <p>Services: Cloud Provider Verarbeitungsort: Slowakei</p>
                <p>
                    T-Systems Multimedia Solution GmbH 01129 Dresden, Riesaer
                    Str. 5 Services: Cloud Provider Verarbeitungsort:
                    Deutschland
                </p>
                <p>
                    T-Systems On Site Services GmbH 13509 Berlin, Holzhauser
                    Str. 4-8 Services: Cloud Provider Verarbeitungsort:
                    Deutschland
                </p>
                <p>Operational Services GmbH &amp; Co. KG</p>
                <p>
                    60549 Frankfurt am Main, Frankfurt Airport Center,
                    Geb&auml;ude 234, HBK25 Services: Cloud Provider
                </p>
                <p>Verarbeitungsort: Deutschland</p>
                <p>
                    Deutsche Telekom Individual Solutions &amp; Products GmbH
                    53113 Bonn, Friedrich-Ebert-Allee 70
                </p>
                <p>
                    Services: 1st &amp; 1,5 Level Support, Hardware-Wartung und
                    Aufbau Verarbeitungsort: Deutschland, Niederlande
                </p>
                <p>
                    GULP Solutions Services GmbH &amp; Co. KG 50667 K&ouml;ln,
                    Breite Str. 137-139
                </p>
                <p>Services: Servicedesk Verarbeitungsort: Deutschland</p>
                <p>
                    Eingesetzt von Deutsche Telekom Individual Solutions &amp;
                    Products GmbH
                </p>
                <p>
                    I.T.E.N.O.S. International Telecom Network Operation
                    Services GmbH 53119 Bonn, Lievelingsweg 125
                </p>
                <p>
                    Services: Hardware-Wartung und Aufbau Verarbeitungsort:
                    Deutschland
                </p>
                <p>
                    Eingesetzt von Deutsche Telekom Individual Solutions &amp;
                    Products GmbH
                </p>
                <p>
                    Deutsche Telekom Security GmbH 53113 Bonn, Bonner Talweg 100
                    Services: Cloud Provider Verarbeitungsort: Deutschland
                </p>
            </div>
        </div>
    </div>

    <div id="Datenschutz"></div>
    <div class="container spacer-top">
        <div class="row">
            <div class="col-12">
                <h2>Datenschutzerkl&auml;rung</h2>
                <p>
                    Wir freuen uns &uuml;ber Ihren Besuch auf unserer
                    Internetseite und Ihrem Interesse an unserem Unternehmen.
                    Der Schutz Ihrer personenbezogenen Daten ist uns ein
                    wichtiges Anliegen. Nachstehend informieren wir Sie
                    gem&auml;&szlig; Art. 12, 13, 14 und 21 der
                    Datenschutzgrundverordnung (DSGVO) &uuml;ber den Umgang mit
                    Ihren personenbezogenen Daten bei der Nutzung unserer
                    Website
                    <a href="http://www.digitaler-lieferschein.de/"
                        >www.digitaler-lieferschein.de </a
                    >und bei der Begr&uuml;ndung, Durchf&uuml;hrung und
                    Beendigung eines Vertrages oder einer Veranstaltung mit uns.
                </p>
                <p>
                    Personenbezogene Daten sind Einzelangaben &uuml;ber
                    pers&ouml;nliche oder sachliche Verh&auml;ltnisse einer
                    bestimmten oder bestimmbaren nat&uuml;rlichen Person.
                    Darunter fallen Informationen wie der b&uuml;rgerliche Name,
                    die Anschrift, die Telefonnummer und das Geburtsdatum.
                </p>
                <h3>Inhaltsverzeichnis</h3>
                <p></p>
                <ol>
                    <li><strong>Verantwortlicher</strong></li>
                </ol>
                <p></p>
                <ol>
                    <li>
                        <strong
                            >Zwecke und Rechtsgrundlagen der
                            Datenverarbeitung</strong
                        >
                    </li>
                </ol>
                <p></p>
                <ol>
                    <li>
                        <strong>Informatorische Nutzung der Website</strong>
                    </li>
                    <li>
                        <strong
                            >Aktive Nutzung der Website und Vertragsabschluss,
                            Datenverarbeitung au&szlig;erhalb der
                            Website</strong
                        >
                    </li>
                    <li>
                        <strong
                            >Datenverarbeitung w&auml;hrend der
                            Vertragsdurchf&uuml;hrung</strong
                        >
                    </li>
                    <li><strong>Einhaltung rechtlicher Vorgaben</strong></li>
                    <li><strong>Rechtsdurchsetzung</strong></li>
                </ol>
                <p></p>
                <ul>
                    <li><strong>Kategorien von Empf&auml;ngern</strong></li>
                </ul>
                <p></p>
                <ol>
                    <li><strong>Drittlandtransfer</strong></li>
                </ol>
                <p></p>
                <ol>
                    <li><strong>Dauer der Speicherung</strong></li>
                </ol>
                <p></p>
                <ol>
                    <li>
                        <strong>Informatorische Nutzung der Website</strong>
                    </li>
                    <li>
                        <strong
                            >Aktive Nutzung der Website sowie Kontaktanfragen,
                            Vertragsabschl&uuml;sse und Anmeldung zu
                            Veranstaltungen au&szlig;erhalb der Website</strong
                        >
                    </li>
                </ol>
                <p></p>
                <ol>
                    <li><strong>Ihre Rechte als betroffenen Person</strong></li>
                </ol>
                <p></p>
                <ul>
                    <li>
                        <strong
                            >Umfang Ihrer Pflichten zur Bereitstellung von
                            Daten</strong
                        >
                    </li>
                </ul>
                <p></p>
                <ul>
                    <li>
                        <strong
                            >Automatisierte Entscheidungsfindung / Profiling
                            Information &uuml;ber Ihr Widerspruchsrecht 21
                            DSGVO</strong
                        >
                    </li>
                </ul>
                <h1>I. Verantwortlicher</h1>
                <p></p>
                <p>
                    <em
                        >Verantwortlicher im Sinne der
                        Datenschutz-Grundverordnung</em
                    >
                </p>
                <p>
                    GS1 Germany GmbH Stolberger Stra&szlig;e 108 a 50933
                    K&ouml;ln
                </p>
                <p>
                    E-Mail:
                    <a href="mailto:info@gs1-germany.de"
                        >info&#64;gs1-germany.de</a
                    >
                    Fax: +49 221 94714-990
                </p>
                <p><em>Kontaktdaten unseres Datenschutzbeauftragten:</em></p>
                <p>Claudia Happel Maarweg 133</p>
                <p>50825 K&ouml;ln</p>
                <p>
                    E-Mail:
                    <a href="mailto:datenschutz@gs1-germany.de"
                        >datenschutz&#64;gs1-germany.de</a
                    >
                    Fax: +49 221 94714-990
                </p>
                <h1>II. Zwecke und Rechtsgrundlagen der Datenverarbeitung</h1>
                <p></p>
                <ol>
                    <li>
                        <strong>Informatorische Nutzung der Website</strong>
                    </li>
                </ol>
                <p></p>
                <p>
                    Sie k&ouml;nnen unsere Website besuchen, ohne Angaben zu
                    Ihrer Person zu machen. Wenn Sie unsere Website lediglich
                    informatorisch nutzen, sich also nicht anmelden,
                    registrieren, eine Bestellung abgeben oder uns sonst
                    Informationen zu Ihrer Person &uuml;bermitteln, verarbeiten
                    wir keine personenbezogenen Daten, mit Ausnahme der Daten,
                    die Ihr Browser &uuml;bermittelt, um Ihnen den Besuch der
                    Webseite zu erm&ouml;glichen sowie Informationen, die uns im
                    Rahmen von eingesetzten Cookies zur statistischen Analyse
                    der Nutzung unserer Website &uuml;bermittelt werden.
                </p>
                <ol>
                    <li>Technische Bereitstellung der Website</li>
                </ol>
                <ol>
                    <li><strong>Zweck</strong></li>
                </ol>
                <p></p>
                <p>
                    Zum Zweck der technischen Bereitstellung der Website, ist es
                    erforderlich, dass wir bestimmte, automatisch
                    &uuml;bermittelte Informationen von Ihnen verarbeiten, damit
                    Ihr Browser unsere Website anzeigen und Sie die Website
                    nutzen k&ouml;nnen. Diese Informationen werden bei jedem
                    Aufruf unserer Internetseite automatisch erfasst und in
                    unseren Serverlogfiles gespeichert. Diese Informationen
                    beziehen sich auf das Computersystem des aufrufenden
                    Rechners. Folgende Informationen werden dabei erhoben:
                </p>
                <ul>
                    <li>Host,</li>
                    <li>IP-Adresse des Nutzers,</li>
                    <li>(ggfs.) auth name,</li>
                    <li>Datum und Uhrzeit des Zugriffs,</li>
                    <li>Methode des Zugriffs (Get/Post),</li>
                    <li>Request,</li>
                    <li>Protokoll (z.B. http),</li>
                    <li>Status (z.B. Fehlermeldungen),</li>
                    <li>abgerufene Datenmenge,</li>
                    <li>Referrer,</li>
                    <li>Browser und Betriebssystem des</li>
                </ul>
                <p>
                    Ferner setzen wir Cookies ein, um Ihnen unsere Webseite zur
                    Nutzung zur Verf&uuml;gung zu stellen. Bei Cookies handelt
                    es sich um Textdateien, die im Internetbrowser bzw. vom
                    Internetbrowser bei dem Aufruf einer Website auf Ihrem
                    Computersystem gespeichert werden. Ein Cookie enth&auml;lt
                    eine charakteristische Zeichenfolge, die eine eindeutige
                    Identifizierung des Browsers beim erneuten Aufrufen der
                    Website erm&ouml;glicht. Wir setzen diese Cookies
                    ausschlie&szlig;lich dazu ein, Ihnen unsere Webseite mit
                    ihren technischen Funktionen zur Verf&uuml;gung zu stellen.
                    Einige Funktionen unserer Website k&ouml;nnen ohne den
                    Einsatz von Cookies nicht angeboten werden. Wir setzen
                    Cookies mit verschiedenen Speicherdauern ein. Wir greifen u.
                    a. auf sog. Session Cookies zur&uuml;ck, die mit dem
                    Verlassen der Website gel&ouml;scht werden. Andere Cookies
                    werden l&auml;nger gespeichert. So werden z. B. Log-In
                    Cookies, die Ihre Zugangsberechtigung speichern, automatisch
                    nach einem Zeitraum von 24 Stunden gel&ouml;scht und Cookies
                    f&uuml;r unser Consent-Management (d. h. hinsichtlich Ihrer
                    Entscheidungen f&uuml;r oder gegen den Einsatz von Cookies)
                    werden bis zu einem Jahr gespeichert.
                </p>
                <ol>
                    <li><strong>Rechtsgrundlage</strong></li>
                </ol>
                <p></p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur technischen
                    Bereitstellung unserer Website auf Grundlage der folgenden
                    Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        zur Erf&uuml;llung eines Vertrags oder zur
                        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                        gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO, soweit Sie
                        unsere Website besuchen, um sich &uuml;ber unsere
                        Produkte oder Dienstleistungen und unsere
                        Veranstaltungen zu informieren; und
                    </li>
                    <li>
                        f&uuml;r den Einsatz des Consent-Managements zur
                        Erf&uuml;llung einer rechtlichen Verpflichtung, der wir
                        als Verantwortlicher unterliegen gem. Art. 6 Abs. 1 lit.
                        c Die rechtliche Verpflichtung liegt in Ihrer
                        Information &uuml;ber von uns eingesetzte Cookies sowie
                        der Einholung und Dokumentation Ihrer Einwilligung zur
                        Datenverarbeitung; und
                    </li>
                    <li>
                        zur Wahrung unserer berechtigten Interessen
                        gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO, um Ihnen
                        die Website technisch zur Verf&uuml;gung stellen zu
                        k&ouml;nnen. Unser berechtigtes Interesse besteht dabei
                        darin, Ihnen eine ansprechende, technisch
                        funktionierende und benutzerfreundliche Website zur
                        Verf&uuml;gung stellen zu k&ouml;nnen sowie um
                        Ma&szlig;nahmen zum Schutz unserer Website vor
                        Cyberrisiken zu ergreifen und zu verhindern, dass von
                        unserer Website Cyberrisiken f&uuml;r Dritte
                    </li>
                </ul>
                <ol>
                    <li>
                        <strong
                            >Statistische Analyse der Nutzung der Website und
                            Reichweitenerh&ouml;hung sowie
                            Werbung/Tracking</strong
                        >
                    </li>
                </ol>
                <p></p>
                <ol>
                    <li><strong>Zweck</strong></li>
                </ol>
                <p></p>
                <p>
                    Zum Zweck der statistischen Analyse der Nutzung unserer
                    Website setzen wir verschiedene Anwendungen wie z. B. Google
                    Analytics und damit Cookies ein, die eine Analyse Ihres
                    Surfverhaltens erm&ouml;glichen. Dadurch k&ouml;nnen wir die
                    Qualit&auml;t unserer Website und ihrer Inhalte verbessern.
                    Wir erfahren, wie die Website genutzt wird und k&ouml;nnen
                    so unser Angebot stetig optimieren.
                </p>
                <p>
                    Die im Rahmen der statistischen Analyse unserer Website
                    erlangten Informationen werden nicht mit Ihren sonstigen, im
                    Rahmen der Website erfassten Daten zusammengef&uuml;hrt.
                </p>
                <p>
                    Dar&uuml;ber hinaus setzen wir zur Anzeige und Optimierung
                    von Werbung sowie ggf. damit verbundenem Tracking
                    Anwendungen wie z. B. Facebook Pixel und die mit der
                    jeweiligen Anwendung verbundenen Cookies ein.
                </p>
                <p><strong>Google Ads</strong></p>
                <p></p>
                <p>
                    Wir nutzen das Online-Werbeprogramm &bdquo;Google Ads&ldquo;
                    und im Rahmen von Google Ads das Conversion-Tracking sowie
                    das Google Dynamic Remarketing und Google Doubleclick. Das
                    Google Conversion Tracking ist ein Analysedienst der Google
                    Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irland
                    (Tochtergesellschaft der Google Inc., 1600 Amphitheatre
                    Parkway, Mountain View, CA 94043, USA;
                    &bdquo;Google&ldquo;). Wenn Sie auf eine von Google
                    geschaltete Anzeige klicken, wird ein Cookie f&uuml;r das
                    Conversion-Tracking auf Ihrem Rechner abgelegt. Diese
                    Cookies werden f&uuml;r die oben angegebene Speicherdauer
                    (siehe Tabelle) gespeichert, enthalten keine
                    personenbezogenen Daten und dienen somit nicht der
                    pers&ouml;nlichen Identifizierung. Beim Google Dynamic
                    Remarketing handelt es sich um eine Anwendung zur
                    Optimierung der an Sie ausgespielten Anzeigen, die auf der
                    Analyse Ihrer Interessen basiert, die mit Ihren von Google
                    gesammelten Daten (z. B. demografischen Merkmalen)
                    abgeglichen werden. DoubleClick dient dazu, Ihre Handlungen
                    auf der Webseite nach der Anzeige oder dem Klicken auf eine
                    der Anzeigen eines Anbieters zu registrieren und zu melden,
                    um die Wirksamkeit einer Werbung und der Anzeige
                    zielgerichteter Werbung zu messen.
                </p>
                <p>
                    Wenn Sie bestimmte Internetseiten unserer Website besuchen
                    und das Cookie noch nicht abgelaufen ist, k&ouml;nnen Google
                    und wir erkennen, dass Sie auf die Anzeige geklickt haben
                    und zu dieser Seite weitergeleitet wurden. Jeder Google
                    Ads-Kunde erh&auml;lt ein anderes Cookie.
                </p>
                <p>
                    Somit besteht keine M&ouml;glichkeit, dass Cookies &uuml;ber
                    die Websites von Ads-Kunden nachverfolgt werden k&ouml;nnen.
                </p>
                <p>
                    Die Informationen, die mithilfe des Conversion-Cookie
                    eingeholten werden, dienen dazu, Conversion-Statistiken
                    f&uuml;r Ads-Kunden zu erstellen, die sich f&uuml;r
                    Conversion-Tracking entschieden haben. Hierbei erfahren die
                    Kunden die Gesamtanzahl der Nutzer, die auf ihre Anzeige
                    geklickt haben und zu einer mit einem
                    Conversion-Tracking-Tag versehenen Seite weitergeleitet
                    wurden. Sie erhalten jedoch keine Informationen, mit denen
                    sich Nutzer pers&ouml;nlich identifizieren lassen.
                </p>
                <p>
                    Wenn Sie nicht am Tracking teilnehmen m&ouml;chten,
                    k&ouml;nnen Sie dieser Nutzung widersprechen, indem Sie die
                    Installation der Cookies durch eine entsprechende
                    Einstellung Ihrer Browser Software verhindern
                    (Deaktivierungsm&ouml;glichkeit). Sie werden sodann nicht in
                    die Conversion-Tracking Statistiken aufgenommen.
                    Dar&uuml;ber hinaus k&ouml;nnen Sie auch Ihre Einwilligung
                    zum Einsatz des Cookies verweigern bzw. widerrufen &uuml;ber
                    unser Consent- Management (s. o.). Weiterf&uuml;hrende
                    Informationen sowie die Datenschutzerkl&auml;rung von Google
                    finden Sie unter:
                </p>
                <p>
                    <a href="http://www.google.com/policies/technologies/ads/"
                        >http://www.google.com/policies/technologies/ads/</a
                    >
                    <a href="http://www.google.de/policies/privacy/"
                        >http://www.google.de/policies/privacy/</a
                    >
                </p>
                <p><strong>Google Tag Manager</strong></p>
                <p></p>
                <p>
                    Auf unserer Webseite benutzen wir den Google Tag Manager von
                    Google. Der Google Tag Manager ist eine L&ouml;sung, mit der
                    Vermarkter Webseiten-Tags &uuml;ber eine Oberfl&auml;che
                    verwalten k&ouml;nnen. Der Dienst Google Tag Manager selbst
                    (der die Tags implementiert) ist eine Cookie- lose Domain
                    und erfasst keine personenbezogenen Daten. Der Dienst Google
                    Tag Manager
                </p>
                <p>
                    sorgt f&uuml;r die Ausl&ouml;sung anderer Tags, die
                    ihrerseits unter Umst&auml;nden Daten erfassen. Google Tag
                    Manager greift nicht auf diese Daten zu. Wenn auf Domain-
                    oder Cookie-Ebene eine Deaktivierung vorgenommen wurde,
                    bleibt diese f&uuml;r alle Tracking-Tags bestehen, die mit
                    Google Tag Manager implementiert werden.
                </p>
                <p><strong>Google Analytics und Google Optimize</strong></p>
                <p></p>
                <p>
                    Auf unserer Website benutzen wir Google Analytics, einen
                    Webanalysedienst der Google Ireland Ltd., Gordon House,
                    Barrow Street, Dublin 4, Irland (Tochtergesellschaft der
                    Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
                    94043, USA; &bdquo;Google&ldquo;) sowie die Erweiterung
                    Google Optimize, mit der wir Webseiten-Optimierungen in
                    sogenannten A/B- Verfahren testen k&ouml;nnen. Google
                    Analytics verwendet sog. &bdquo;Cookies&ldquo;, Textdateien,
                    die auf Ihrem Computer gespeichert werden und die eine
                    Analyse der Benutzung der Website durch Sie
                    erm&ouml;glichen. Die durch das Cookie erzeugten
                    Informationen &uuml;ber Ihre Benutzung unserer Website
                    werden in der Regel an einen Server von Google in den USA
                    &uuml;bertragen und dort gespeichert. Im Falle der
                    Aktivierung der IP-Anonymisierung auf unserer Website, wird
                    Ihre IP-Adresse von Google jedoch innerhalb von
                    Mitgliedstaaten der Europ&auml;ischen Union oder in anderen
                    Vertragsstaaten des Abkommens &uuml;ber den
                    Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in
                    Ausnahmef&auml;llen wird die volle IP-Adresse an einen
                    Server von Google in den USA &uuml;bertragen und dort
                    gek&uuml;rzt. In unserem Auftrag wird Google diese
                    Informationen benutzen, um Ihre Nutzung der Website
                    auszuwerten, um Reports &uuml;ber die
                    Websiteaktivit&auml;ten zusammenzustellen und um weitere mit
                    der Websitenutzung und der Internetnutzung verbundene
                    Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu
                    erbringen. Die im Rahmen von Google Analytics von Ihrem
                    Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen
                    Daten von Google zusammengef&uuml;hrt.
                </p>
                <p>
                    Auf unserer Website verwenden wir Google Analytics mit der
                    Erweiterung
                </p>
                <p>
                    &bdquo;_anonymizeIp()&ldquo;. Dadurch werden IP-Adressen
                    gek&uuml;rzt weiterverarbeitet, eine direkte
                    Personenbeziehbarkeit kann damit ausgeschlossen werden.
                </p>
                <p>
                    Sie k&ouml;nnen die Speicherung der Cookies durch eine
                    entsprechende Einstellung Ihrer Browser- Software verhindern
                    oder Ihre Einwilligung zum Einsatz des Cookies verweigern
                    bzw. widerrufen &uuml;ber unser Consent-Management (s. o.);
                    wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht s&auml;mtliche Funktionen unserer
                    Website vollumf&auml;nglich werden nutzen k&ouml;nnen.
                </p>
                <p>
                    Sie k&ouml;nnen die Erfassung der durch das Cookie erzeugten
                    und auf Ihre Nutzung unserer Website bezogenen Daten (inkl.
                    Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
                    Daten durch Google verhindern, indem Sie das unter dem
                    folgenden Link verf&uuml;gbare
                </p>
                <p>
                    Browser-Plugin herunterladen und installieren:
                    <a href="http://tools.google.com/dlpage/gaoptout?hl=de"
                        >http://tools.google.com/dlpage/gaoptout?hl=de</a
                    >
                </p>
                <p>
                    Alternativ zum Browser-Plugin k&ouml;nnen Sie folgenden Link
                    klicken, um die Erfassung durch Google Analytics auf unser
                    Website zuk&uuml;nftig zu verhindern. Dabei wird ein
                    Opt-Out-Cookie auf Ihrem Endger&auml;t abgelegt.
                    L&ouml;schen Sie Ihre Cookies, m&uuml;ssen Sie den Link
                    erneut klicken.
                </p>
                <p>
                    <a href="https://www.gs1-germany.de/"
                        >Erfassung von Daten durch Google Analytics f&uuml;r
                        diese Webseite deaktivieren.</a
                    >
                </p>
                <p>
                    N&auml;here Informationen zu Nutzungsbedingungen und
                    Datenschutz von bzw. bei Google Analytics finden Sie unter
                    <a href="http://www.google.com/analytics/terms/de.html"
                        >http://www.google.com/analytics/terms/de.html </a
                    >bzw. unter
                    <a href="https://www.google.de/intl/de/policies/"
                        >https://www.google.de/intl/de/policies/</a
                    >
                </p>
                <p><strong>Hotjar</strong></p>
                <p>
                    Hotjar ist ein Dienst, der das Verhalten und das Feedback
                    von Nutzern auf Webseiten durch eine Kombination von
                    Analyse- und Feedback-Tools analysiert. Durch den Einsatz
                    von Hotjar ergibt sich f&uuml;r uns ein
                    &bdquo;Gesamtbild&ldquo; davon, wie die Endnutzererfahrung
                    und die Leistung der Webseite verbessert werden k&ouml;nnen.
                    Hierzu haben wir einen Tracking-Code integriert, der Daten
                    auf in Irland (EU) gelegene Server &uuml;bertr&auml;gt.
                </p>
                <p>
                    Der Tracking-Code kontaktiert die Server von Hotjar und
                    sendet ein Skript an den Computer oder das Endger&auml;t,
                    mit dem Sie auf unsere Webseite zugreifen. Das Skript
                    erfasst bestimmte Daten in Bezug auf die Interaktion des
                    Nutzers mit der Webseite. Diese Daten werden dann zur
                    Verarbeitung an die Server von Hotjar gesendet. N&auml;here
                    Einzelheiten hierzu k&ouml;nnen Sie unter
                    <a
                        href="https://help.hotjar.com/hc/en-us/sections/115003180467-Privacy-Security-and-Operations"
                        >Technical Information </a
                    >erfahren. Der alleinige Zweck dieser Datenerhebung besteht
                    darin, Ihre Nutzererfahrung auf unserer Webseite zu
                    verbessern. Hotjar-basierte Webseiten verwenden Cookies zur
                    Erhebung nicht personenbezogener Daten einschlie&szlig;lich
                    Standard- Internetprotokolldaten und Angaben zu
                    Verhaltensmustern beim Besuch einer Hotjar- basierten
                    Webseite. Dies erfolgt, damit wir Ihnen eine bessere
                    Nutzererfahrung bieten, Pr&auml;ferenzen erkennen,
                    technische Probleme diagnostizieren, Entwicklungen
                    analysieren und ganz allgemein eine Webseite optimieren
                    k&ouml;nnen. Es werden insbesondere die folgenden Daten
                    erhoben und verarbeitet:
                </p>
                <ul>
                    <li>
                        IP-Adresse des Endger&auml;ts (sie wird in
                        anonymisierter Form erhoben und gespeichert)
                    </li>
                    <li>
                        Aufl&ouml;sung des Bildschirms/Displays des
                        Endger&auml;tbildschirms;
                    </li>
                    <li>
                        Typ des Endger&auml;ts (individuelle
                        Endger&auml;te-Identifizierungsmerkmale, Betriebssystem,
                        und Browsertyp)
                    </li>
                    <li>geografischer Standort (nur Land)</li>
                    <li>
                        bevorzugte Sprache bei der Anzeige der Hotjar-basierten
                    </li>
                </ul>
                <p>
                    Folgende Informationen werden in Bezug auf die
                    Nutzerinteraktionen erhoben.
                </p>
                <ul>
                    <li>Mausereignisse (Bewegungen, Position und Klicks)</li>
                    <li></li>
                </ul>
                <p><strong>Protokolldaten</strong></p>
                <p>
                    Stichprobenartig zeichnen Hotjar-Server automatisch
                    Informationen auf, die von den Kunden- Webseiten erhoben
                    werden. U.a. werden folgende Daten erfasst:
                </p>
                <ul>
                    <li>verweisende URL und Domain</li>
                </ul>
                <ul>
                    <li>besuchte Seiten</li>
                    <li>geografischer Standort (nur Land)</li>
                    <li>bei der Anzeige der Webseite bevorzugte Sprache</li>
                    <li>
                        Datum und Zeit, wann auf die Seiten der Website
                        zugegriffen wurde
                    </li>
                </ul>
                <p><strong>Login</strong></p>
                <p>
                    Hotjar verwendet Cookies auch zur Aufzeichnung von
                    Login-Daten auf Ihrem Endger&auml;t oder Computer. Mittels
                    der Cookies l&auml;sst sich erkennen, ob ein bestimmtes
                    Endger&auml;t eine Hotjar- basierte Webseite bereits besucht
                    hat, sodass Sie die Login-Daten bei einem erneuten Besuch
                    derselben Hotjar-basierten Webseite nicht erneut eingeben
                    m&uuml;ssen. Die meisten Browser erm&ouml;glichen Ihnen die
                    Wahl, ob Sie Cookies zulassen wollen oder nicht. Wenn auf
                    Ihrem Computer keine Cookies gespeichert werden sollen,
                    stellen Sie bitte Ihre Browser- Pr&auml;ferenzen vor dem
                    Zugriff auf eine Hotjar-basierte Webseite so ein, dass alle
                    Cookies abgewiesen werden. Mehr Informationen &uuml;ber
                    Cookies und dar&uuml;ber, wie man &uuml;berpr&uuml;fen
                </p>
                <p>
                    kann, welche Cookies gespeichert werden, wie man sie
                    verwaltet und l&ouml;scht, finden Sie unter
                    <a
                        href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies"
                        >help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies.</a
                    >
                </p>
                <p>
                    Die jeweils aktuelle Fassung der Datenschutzerkl&auml;rung
                    von Hotjar ist abrufbar unter
                    <a href="http://www.hotjar.com/privacy"
                        >www.hotjar.com/privacy</a
                    >.
                </p>
                <p><strong>Microsoft Advertising</strong></p>
                <p></p>
                <p>
                    In unserem Internetauftritt setzen wir zur
                    Abschlussverfolgung Microsoft Advertising ein. Es handelt
                    sich hierbei um einen Dienst der Microsoft Corporation, One
                    Microsoft Way, Redmond, WA 98052 - 6399, USA, nachfolgend
                    nur &bdquo;Microsoft&ldquo; genannt, der die sog.
                </p>
                <p>Universelle Ereignisnachverfolgung (UEN) nutzt.</p>
                <p>
                    Klicken Sie auf eine von uns bei der Internetsuchmaschine
                    &bdquo;Bing&ldquo; geschaltete Anzeige, wird durch Microsoft
                    ein Cookie zur Trackingfunktionalit&auml;t &uuml;ber Ihren
                    Internet-Browser auf Ihrem Endger&auml;t gespeichert. Dieses
                    Tracking-Cookie wird f&uuml;r die oben angegebene
                    Speicherdauer (siehe Tabelle) gespeichert und dient nicht
                    Ihrer pers&ouml;nlichen Identifikation. Besuchen Sie
                    bestimmte Seiten unseres Internetauftritts und ist das
                    Cookie noch nicht abgelaufen, k&ouml;nnen sowohl Microsoft
                    als auch wir erkennen, dass Sie auf eine von uns bei Bing
                    platzierte Werbung geklickt und von dort aus auf unseren
                    Internetauftritt weitergeleitet worden sind.
                </p>
                <p>
                    Die durch das Tracking-Cookie gesammelten Informationen
                    nutzt Microsoft, um f&uuml;r uns Besuchsstatistiken zu
                    erstellen. Wir erhalten dadurch Informationen &uuml;ber die
                    Anzahl der Zugriffe auf die von uns bei Bing geschaltete
                    Werbung sowie &uuml;ber die Internetseiten unseres
                    Internetauftritts, auf die im Anschluss zugegriffen wurde.
                    Hierbei erhalten wir aber keine Informationen, aufgrund
                    derer wir Sie pers&ouml;nlich identifizieren k&ouml;nnten.
                </p>
                <p>
                    Zus&auml;tzlich kann Microsoft Ihr Nutzerverhalten
                    wom&ouml;glich &uuml;ber das sog. Cross-Device- Tracking
                    &uuml;ber mehrere Ihrer Endger&auml;te hinweg verfolgen.
                    Dadurch ist Microsoft in der Lage dazu, Ihnen
                    ger&auml;te&uuml;bergreifend personalisierte Werbung
                    einzublenden.
                </p>
                <p>
                    Sofern Sie mit dieser Verarbeitung nicht einverstanden sind,
                    haben Sie die M&ouml;glichkeit, die Installation der Cookies
                    durch die entsprechenden Einstellungen in Ihrem
                    Internet-Browser zu verhindern oder auch Ihre Einwilligung
                    zum Einsatz des Cookies zu verweigern bzw. zu widerrufen
                    &uuml;ber unser Consent-Management (s. o.).
                </p>
                <p>
                    Sollten Sie &uuml;ber ein Microsoft-Konto verf&uuml;gen,
                    k&ouml;nnen Sie zudem unter
                    <a
                        href="https://account.microsoft.com/privacy/ad-settings/signedout-settings/signedout"
                        >account.microsoft.com/privacy/ad-settings/signedout </a
                    >auch dort die Einstellungen f&uuml;r personalisierte
                    Werbung &auml;ndern.
                </p>
                <p>Ferner bietet Microsoft</p>
                <p>
                    unter
                    <a
                        href="https://help.ads.microsoft.com/%23apex/3/de/53056/2"
                        >https://help.ads.microsoft.com/#apex/3/de/53056/2 </a
                    >sowie
                </p>
                <p>
                    unter
                    <a
                        href="https://privacy.microsoft.com/de-de/privacystatement"
                        >privacy.microsoft.com/de-de/privacystatement </a
                    >weitere Informationen zu Microsoft Advertising sowie zur
                    Erhebung und Nutzung der Daten sowie zu Ihren Rechten und
                    M&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re an.
                </p>
                <p><strong>LinkedIn Insight Tag, LinkedIn Analytics</strong></p>
                <p></p>
                <p>
                    Unsere Website verwendet das Conversion Tool &bdquo;LinkedIn
                    Insight Tag&ldquo; sowie LinkedIn Analytics der LinkedIn
                    Ireland Unlimited Company. Diese Tools erstellen Cookies in
                    Ihrem Webbrowser, welche die Erfassung u.a. folgender Daten
                    erm&ouml;glicht: IP-Adresse, Ger&auml;te- und
                    Browsereigenschaften und Seitenereignisse (z.B.
                    Seitenabrufe). Diese Daten werden
                </p>
                <p>
                    verschl&uuml;sselt, innerhalb von sieben Tagen anonymisiert
                    und die anonymisierten Daten innerhalb der oben angegebenen
                    Speicherdauer (siehe Tabelle) gel&ouml;scht. LinkedIn teilt
                    keine personenbezogenen Daten mit GS1 Germany, sondern
                    bietet anonymisierte Berichte &uuml;ber die
                    Webseiten-Zielgruppe und die Anzeigeleistung an.
                    Erg&auml;nzend bietet LinkedIn &uuml;ber das Insight Tag die
                    M&ouml;glichkeit eines Retargetings. GS1 Germany kann mit
                    Hilfe dieser Daten zielgerichtete Werbung au&szlig;erhalb
                    seiner Website anzeigen, ohne dass Sie als Websitebesucher
                    dabei identifiziert werden. N&auml;here Informationen zum
                    Datenschutz bei LinkedIn k&ouml;nnen Sie den LinkedIn
                    Datenschutzhinweisen entnehmen
                    <a href="https://www.linkedin.com/legal/privacy-policy"
                        >https://www.linkedin.com/legal/privacy-policy.</a
                    >
                </p>
                <p>
                    Mitglieder von LinkedIn k&ouml;nnen die Nutzung ihrer
                    personenbezogenen Daten zu Werbezwecken in ihren
                    Kontoeinstellungen steuern, um das Insight-Tag auf unserer
                    Website zu deaktivieren (&bdquo;Opt-out&ldquo;) klicken Sie
                    hier (<a
                        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                        >https://www.linkedin.com/psettings/guest-</a
                    >
                    <a
                        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                        >controls/retargeting-opt-out</a
                    >). Wenn Sie bei LinkedIn eingeloggt sind, k&ouml;nnen die
                    Datenerhebung jederzeit unter folgendem Link deaktivieren:
                    <a
                        href="https://www.linkedin.com/psettings/enhanced-advertising"
                        >https://www.linkedin.com/psettings/enhanced-advertising.</a
                    >
                </p>
                <p>
                    Dar&uuml;ber hinaus k&ouml;nnen Sie auch Ihre Einwilligung
                    zum Einsatz des Cookies verweigern bzw. widerrufen &uuml;ber
                    unser Consent-Management (s. o.).
                </p>
                <p>
                    <strong
                        >Facebook-Anwendungen (Pixel, Custom Audiences,
                        Connect)</strong
                    >
                </p>
                <p></p>
                <p>
                    Auf unserer Website nutzen wir den sogenannten
                    &bdquo;Facebook-Pixel&ldquo; sowie die Anwendungen
                </p>
                <p>
                    &bdquo;Facebook Custom Audiences&ldquo; und &bdquo;Facebook
                    Connect&ldquo; des Unternehmens &bdquo;Facebook&ldquo;
                    (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
                    Harbour, Dublin 2 Ireland). Der
                </p>
                <p>
                    &bdquo;Facebook-Pixel&ldquo; dient dazu, die Besucher
                    unserer Webseite in bestimmte Zielgruppen einzuordnen, um
                    Ihnen auf Facebook, Instagram und im Facebook Werbenetzwerk
                    personalisierte, interessenbezogene Werbeanzeigen
                    (&bdquo;Ads&ldquo;) anzeigen lassen zu k&ouml;nnen. Die
                    erfassten Daten (z.B. IP-Adressen, Informationen zum
                    Webbrowser, den Standort der Website, Angeklickte Buttons,
                    ggf. Pixel-IDs und weitere Merkmale) sind nur im Rahmen der
                    Anzeige bestimmter Werbeanzeigen nutzbar und f&uuml;r uns
                    selbst nicht einsehbar. Mittels Facebook Custom Audiences
                    sind wir in der Lage, Ihre Aktivit&auml;ten auf unserer
                    Website nachzuverfolgen, wenn Sie z. B. &uuml;ber Anzeigen
                    von Facebook auf unsere Website gelangt sind. Dies dient der
                    Messung der Wirksamkeit einer Werbung, der Analyse des
                    Besucherverhaltens und der Anzeige zielgerichteter Werbung
                    f&uuml;r Sie (z. B. durch Zielgruppenbildung). Dazu werden
                    bei Besuch unserer Website von Facebook Cookies gesetzt. Das
                    Cookie erm&ouml;glicht in diesem Fall automatisch mittels
                    einer pseudonymen CookieID und auf Grundlage der von Ihnen
                    besuchten Seiten eine interessenbasierte Werbung. Die
                    erhobenen Daten sind f&uuml;r uns als Betreiber dieser
                    Website jedoch anonym, wir k&ouml;nnen daher keine
                    R&uuml;ckschl&uuml;sse auf die Identit&auml;t der Nutzer
                    ziehen. Dar&uuml;ber bieten wir zur einfacheren Anmeldung
                    bei uns die M&ouml;glichkeit, sich mittels Facebook-Connect
                    auf unserer Website &uuml;ber Ihr Facebook-Nutzerkonto
                    anzumelden.
                </p>
                <p>
                    Wenn Sie ein Facebook- und/oder Instagram-Konto besitzen und
                    angemeldet sind, wird der Besuch dieser Webseite Ihrem
                    Facebook- und/oder Instagram-Benutzerkonto zugeordnet.
                </p>
                <p>
                    N&auml;here Informationen zur Erhebung und Nutzung der Daten
                    durch Facebook, Ihre diesbez&uuml;glichen Rechte und
                    M&ouml;glichkeiten zum Schutze Ihrer Privatsph&auml;re
                    finden Sie in den Datenschutzhinweisen
                    (&bdquo;Privacy&ldquo;) auf der Internetseite von Facebook.
                </p>
                <p><strong>Walls.io</strong></p>
                <p>
                    Unsere Website verwendet Social Media Plugins bzw. Widgets
                    von Walls.io. Beim Aufruf dieser Plugins werden IP-Adresse
                    und Cookie-Informationen an Walls.io &uuml;bermittelt.
                    Walls.io wird von &bdquo;Walls.io GmbH&ldquo; in Wien,
                    &Ouml;sterreich betrieben.
                </p>
                <ol>
                    <li><strong>Rechtsgrundlage</strong></li>
                </ol>
                <p></p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    statistischen Analyse der Nutzung unserer Website auf der
                    folgenden Rechtsgrundlage:
                </p>
                <ul>
                    <li>
                        Mit Ihrer Einwilligung Art. 6 Abs. 1 lit. a DSGVO i. V.
                        m. &sect; 15 Abs. 3 TMG.
                    </li>
                </ul>
                <ol start="2">
                    <li>
                        <strong
                            >Aktive Nutzung der Website und Vertragsabschluss,
                            Datenverarbeitung au&szlig;erhalb der
                            Website</strong
                        >
                    </li>
                </ol>
                <p></p>
                <p>
                    Neben der rein informatorischen Nutzung unserer Website
                    k&ouml;nnen Sie unsere Website auch aktiv nutzen, um eines
                    unserer Produkte oder eine Dienstleistung zu bestellen, sich
                    f&uuml;r eine Veranstaltung anzumelden, sich f&uuml;r
                    unseren Newsletter zu registrieren oder um mit uns in
                    Kontakt zu treten. Zus&auml;tzlich zu der oben dargestellten
                    Verarbeitung Ihrer personenbezogenen Daten bei einer rein
                    informatorischen Nutzung verarbeiten wir dann auch weitere
                    personenbezogene Daten von Ihnen, die wir zur Abwicklung
                    Ihrer Bestellung bzw. zur Bearbeitung und Beantwortung Ihrer
                    Anfrage ben&ouml;tigen.
                </p>
                <p>
                    <strong
                        >Die nachfolgenden Ausf&uuml;hrungen gelten, mit
                        Ausnahme des Abschnitts 2.e &bdquo;Newsletter,
                        Werbeemails und Presseverteiler&ldquo;, auch f&uuml;r
                        den Fall, dass Sie &uuml;ber andere Kommunikationswege
                        (bspw. E-Mail, Telefon, pers&ouml;nlich) mit uns Kontakt
                        oder gesch&auml;ftliche Beziehungen aufnehmen wollen. In
                        besonderen F&auml;llen k&ouml;nnen wir Ihnen aber auch
                        gesonderte Datenschutzinformationen zur Verf&uuml;gung
                        stellen.</strong
                    >
                </p>
                <p></p>
                <ol>
                    <li><strong>Kontaktanfragen</strong></li>
                </ol>
                <p></p>
                <p>
                    Um Ihre Anfragen an uns, z.B. &uuml;ber das Kontaktformular
                    oder an unsere Emailadresse, bearbeiten und beantworten zu
                    k&ouml;nnen, verarbeiten wir die von Ihnen in diesem
                    Zusammenhang mitgeteilten personenbezogenen Daten von Ihnen.
                    Dazu z&auml;hlen in jedem Fall Ihr Name und Ihre E-Mail
                    Adresse, um Ihnen eine Antwort zukommen zu lassen, sowie die
                    sonstigen Informationen, die Sie uns im Rahmen Ihrer
                    Mitteilung zusenden.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Beantwortung von Nutzeranfragen auf den folgenden
                    Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        sofern Ihre Kontaktaufnahme im Rahmen eines Vertrages,
                        dessen Vertragspartei Sie sind, bzw. zur
                        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                        erfolgt, gem. Art. 6 Abs. 1 b DSGVO,
                    </li>
                    <li>
                        zur Wahrung unserer berechtigten Interessen
                        gem&auml;&szlig; 6 Abs. 1 lit. f DSGVO; unser
                        berechtigtes Interesse besteht in der sachgerechten
                        Beantwortung von Kontaktanfragen.
                    </li>
                </ul>
                <ol>
                    <li><strong>Vertragsabschluss</strong></li>
                </ol>
                <p></p>
                <p>
                    Wenn Sie im Rahmen unserer Website eines unserer
                    kostenpflichtigen oder kostenlosen Produkte oder
                    Dienstleistungen bestellen, verarbeiten wir Ihre
                    personenbezogenen Daten, um die Bestellung entgegenzunehmen,
                    abzuwickeln und Ihnen die bestellten Produkte oder
                    Dienstleistungen bereitstellen zu k&ouml;nnen. Dabei
                    verarbeiten wir die aus den jeweiligen
                </p>
                <p>
                    Eingabeformularen ersichtlichen Angaben (Freiwillige Angaben
                    sind mit dem Zusatz &bdquo;ggf.&ldquo; gekennzeichnet):
                </p>
                <ul>
                    <li>E-Mail-Adresse</li>
                    <li>Anrede</li>
                    <li>Position</li>
                    <li>Vor- und Nachname</li>
                    <li>Firma</li>
                    <li>Branche</li>
                    <li>Stra&szlig;e/Hausnummer</li>
                    <li>Postleitzahl</li>
                    <li>Stadt</li>
                    <li>Land</li>
                    <li>Telefon</li>
                </ul>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung auf der
                    folgenden Rechtsgrundlage:
                </p>
                <ul>
                    <li>
                        zur Erf&uuml;llung eines Vertrags oder zur
                        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                        gem&auml;&szlig; 6 Abs. 1 lit. b DSGVO.
                    </li>
                </ul>
                <ol>
                    <li><strong>Anmeldung zu Veranstaltungen</strong></li>
                </ol>
                <p></p>
                <p>
                    Wenn Sie sich im Rahmen unserer Website f&uuml;r eine
                    kostenpflichtige oder kostenlose physische oder virtuelle
                    Veranstaltung anmelden, verarbeiten wir Ihre
                    personenbezogenen Daten, um die Anmeldung entgegenzunehmen.
                    Dabei verarbeiten wir die aus den jeweiligen
                    Eingabeformularen ersichtlichen bzw. abgefragten Angaben
                    (Freiwillige Angaben sind mit dem Zusatz &bdquo;ggf.&ldquo;
                    gekennzeichnet):
                </p>
                <p>Angaben zur Firma</p>
                <ul>
                    <li>Firma</li>
                    <li>Stra&szlig;e</li>
                    <li>PO-Nr.</li>
                    <li>Postleitzahl</li>
                    <li>Stadt</li>
                    <li>Land</li>
                    <li>Branche Angaben zum Teilnehmer:</li>
                    <li>Anrede</li>
                    <li>Abteilung</li>
                    <li>Position</li>
                    <li>Vor- und Nachname</li>
                    <li>Titel</li>
                    <li>Telefon</li>
                    <li>E-Mail</li>
                </ul>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung der
                    Veranstaltung auf Basis der folgenden Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        zur Erf&uuml;llung eines Vertrags oder zur
                        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                        gem&auml;&szlig; 6 Abs. 1 lit. b DSGVO.
                    </li>
                </ul>
                <p>
                    Wenn wir Veranstaltungen virtuell durchf&uuml;hren, kann es
                    erforderlich sein, dass Sie sich bei einem
                    Drittanbieterdienst (z.B. virtuelle Konferenzdienste)
                    registrieren, um an der Veranstaltung teilnehmen zu
                    k&ouml;nnen. Dabei handelt es sich nicht um Dienste, die von
                    GS1 selbst bereitgestellt werden. Weder haben wir einen
                    Einfluss darauf, wie dieser Drittanbieter Ihre
                    personenbezogenen Daten verarbeitet, noch sind wir
                    daf&uuml;r verantwortlich. Wenn Sie wissen m&ouml;chten, wie
                    dieser Drittanbieter Ihre personenbezogenen Daten
                    verarbeitet, m&uuml;ssen Sie sich in den
                    Datenschutzerkl&auml;rungen des Drittanbieters entsprechend
                    informieren.
                </p>
                <p>
                    Die Veranstaltungen von GS1 Germany sowie die angebotenen
                    Kooperationsveranstaltungen dienen vorrangig dem Zweck der
                    Vernetzung sowie dem gegenseitigen Austausch mit den
                    wichtigsten Akteuren einer Branche, auch im Nachgang der
                    Veranstaltung. Eine solche Vernetzung kann nur erfolgen,
                    indem GS1 Germany anderen Teilnehmern - darunter auch die
                    teilnehmenden GS1 Germany Solution Partner &ndash; den Namen
                    der jeweiligen Teilnehmer, den Namen des Unternehmens,
                    f&uuml;r das die jeweiligen Teilnehmer t&auml;tig sind, die
                    Bezeichnung der jeweiligen Position sowie die Kontaktdaten
                    (E-Mail-Adresse) zug&auml;nglich machen. Dies erfolgt in
                    Form einer Ver&ouml;ffentlichung der Daten auf einer
                    Teilnehmerliste, die an alle Teilnehmer &uuml;bermittelt
                    wird.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung der
                    Veranstaltungen sowie zur Vernetzung bei und nach
                    Veranstaltungen auf Basis der folgenden Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        zur Wahrung unserer berechtigten Interessen von GS1
                        Germany oder eines Dritten, im Sinne von Art. 6 Abs. 1
                        S. 1 lit. f DSGVO. Das berechtigte Interesse von GS1
                        Germany ist darin begr&uuml;ndet, den Teilnehmern die
                        M&ouml;glichkeit der Kontaktaufnahme zu anderen
                        Teilnehmern bzw. durch andere Teilnehmer zu bieten.
                        Weiterhin m&ouml;chten wir f&uuml;r alle Teilnehmer die
                        bestm&ouml;glichen Voraussetzungen zur Vernetzung
                    </li>
                </ul>
                <ol>
                    <li><strong>Bonit&auml;tspr&uuml;fung</strong></li>
                </ol>
                <p></p>
                <p>
                    Sofern wir in Vorleistung treten, behalten wir uns vor, zur
                    Wahrung unserer berechtigten Interessen ggf. eine
                    Bonit&auml;tsauskunft auf der Basis
                    mathematisch-statistischer Verfahren bei folgendem(n)
                    Unternehmen einzuholen:
                </p>
                <p>
                    Creditreform Boniversum GmbH, Hellersbergstra&szlig;e 11,
                    41460 Neuss (<a href="http://www.boniversum.de/"
                        >www.boniversum.de</a
                    >) BID Bayerischer Inkasso Dienst AG, Weichengereuth 26,
                    96450 Coburg (<a href="http://www.bid-coburg.de/"
                        >www.bid-</a
                    >
                    <a href="http://www.bid-coburg.de/">coburg.de</a>)
                </p>
                <p>
                    Hierzu &uuml;bermitteln wir die zu einer
                    Bonit&auml;tspr&uuml;fung ben&ouml;tigten personenbezogenen
                    Daten und verwenden die erhaltenen Informationen &uuml;ber
                    die statistische Wahrscheinlichkeit eines Zahlungsausfalls.
                    Die Bonit&auml;tsauskunft kann Wahrscheinlichkeitswerte
                    (Score-Werte) beinhalten, die auf Basis wissenschaftlich
                    anerkannter mathematisch-statistischer Verfahren berechnet
                    werden. Dabei wird mittels einer Vielzahl von Merkmalen, wie
                    beispielsweise Einkommen, Anschriftendaten, Beruf,
                    Familienstand und bisherigem Zahlungsverhalten, auf das
                    zuk&uuml;nftige Zahlungsausfallrisiko des Kunden
                    geschlossen. Das Ergebnis wird in Form eines Zahlungswertes
                    (sog. Score) ausgedr&uuml;ckt. Die so erhaltenen
                    Informationen sind Basis unserer Entscheidung &uuml;ber die
                    Begr&uuml;ndung, Durchf&uuml;hrung oder Beendigung des
                    Vertragsverh&auml;ltnisses. Die Auswahlm&ouml;glichkeit
                    einer der angebotenen Zahlungsarten ist jedoch nicht
                    abh&auml;ngig von derartigen Informationen.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung des
                    Vertrages auf Basis der folgenden Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        zur Erf&uuml;llung eines Vertrags oder zur
                        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                        gem&auml;&szlig; 6 Abs. 1 lit. b DSGVO,
                    </li>
                    <li>
                        zur Wahrung unserer berechtigten Interessen
                        gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Unserer
                        Berechtigtes Interesse liegt in der finanziellen
                        Absicherung unserer Vorleistung, zur Vermeidung von
                        Betrug sowie zur Identit&auml;tsfeststellung. Ferner
                        bestehen die Berechtigten Interessen Dritter im Schutz
                        von Zahlungsausfall und der Vermeidung eines
                    </li>
                </ul>
                <ol>
                    <li>
                        <strong
                            >Newsletter, Werbeemails und Presseverteiler</strong
                        >
                    </li>
                </ol>
                <p></p>
                <p>
                    <strong
                        >Datenverarbeitung bei Anmeldung zum
                        E-Mail-Newsletter</strong
                    >
                </p>
                <p>
                    Wenn Sie sich zu unserem Newsletter anmelden, verarbeiten
                    wir die hierf&uuml;r erforderlichen oder gesondert von Ihnen
                    mitgeteilten Daten, um Ihnen regelm&auml;&szlig;ig unseren
                    E-Mail- Newsletter zuzusenden.
                </p>
                <p>
                    <strong
                        >Datenverarbeitung bei Anmeldung zum
                        Presseverteiler</strong
                    >
                </p>
                <p></p>
                <p>
                    Wenn Sie sich zu unserem Presseverteiler anmelden,
                    verarbeiten wir die hierf&uuml;r erforderlichen oder
                    gesondert von Ihnen mitgeteilten Daten, um Ihnen
                    regelm&auml;&szlig;ig unsere Presseinformationen zuzusenden.
                    Folgende Daten werden verarbeitet:
                </p>
                <p>
                    Dabei verarbeiten wir die aus den jeweiligen
                    Eingabeformularen ersichtlichen bzw. abgefragten Angaben
                    (Freiwillige Angaben sind mit dem Zusatz &bdquo;ggf.&ldquo;
                    gekennzeichnet):
                </p>
                <p>Angaben zur Redaktion</p>
                <ul>
                    <li>Verlag</li>
                    <li>Medium</li>
                    <li>Stra&szlig;e</li>
                    <li>PO-Nr.</li>
                    <li>Postleitzahl</li>
                    <li>Stadt</li>
                    <li>Land Angaben zum Teilnehmer:</li>
                    <li>Anrede</li>
                    <li>Ressort</li>
                    <li>Position</li>
                    <li>Vor- und Nachname</li>
                    <li>Titel</li>
                    <li>Telefon</li>
                    <li>E-Mail</li>
                </ul>
                <p><strong>Datenverwendung f&uuml;r E-Mail-Werbung</strong></p>
                <p>
                    Wenn wir Ihre E-Mail-Adresse im Zusammenhang mit dem
                    Vertragsschluss und der Bereitstellung unserer Produkte oder
                    Dienstleistung erhalten und Sie dem nicht widersprochen
                    haben, behalten wir uns vor, Ihnen regelm&auml;&szlig;ig
                    Angebote zu &auml;hnlichen
                </p>
                <p>
                    Produkten oder Dienstleistungen, aus unserem Angebot per
                    E-Mail zuzusenden. Sie k&ouml;nnen dieser Verwendung Ihrer
                    E-Mail-Adresse jederzeit durch eine Nachricht an die oben
                    angegebene Kontaktm&ouml;glichkeit oder &uuml;ber einen
                    daf&uuml;r vorgesehenen Link in der Werbemail widersprechen,
                    ohne dass hierf&uuml;r andere als die
                    &Uuml;bermittlungskosten nach den Basistarifen entstehen.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    &Uuml;bersendung unseres Newsletters auf Grundlage der
                    folgenden Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        f&uuml;r den Versand des Newsletters aufgrund Ihrer per
                        Double-Opt-In-Verfahren erteilten Einwilligung in die
                        Verarbeitung personenbezogener Daten gem&auml;&szlig;
                        Art. 6 Abs. 1 lit. a DSGVO; und
                    </li>
                    <li>
                        f&uuml;r die E-Mail-Werbung in den genannten F&auml;llen
                        zur Wahrung unserer berechtigten Interessen
                        gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO i.V.m.
                        &sect; 7 Abs. 3 UWG; unser berechtigtes Interesse
                        besteht in der Direktwerbung f&uuml;r unsere Produkte
                        und
                    </li>
                </ul>
                <ol start="3">
                    <li>
                        <strong
                            >Datenverarbeitung w&auml;hrend der
                            Vertragsdurchf&uuml;hrung</strong
                        >
                    </li>
                </ol>
                <p></p>
                <p>
                    W&auml;hrend der Vertragsdurchf&uuml;hrung verarbeiten wir
                    Ihre personenbezogenen Daten wie folgt:
                </p>
                <ol>
                    <li><strong>Leistungserbringung</strong></li>
                </ol>
                <p></p>
                <p>
                    Soweit dies im Rahmen der Erf&uuml;llung des Vertrags
                    erforderlich ist, verarbeiten wir Ihre personenbezogenen
                    Daten zur Leistungserbringung, um z.B. mit Ihnen in Kontakt
                    zu treten, um Termine abzustimmen, R&uuml;ckfragen zu
                    kl&auml;ren und Serviceleistungen zu erbringen.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten bei der zur
                    Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung des
                    Vertrages auf Grundlage der folgenden Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        zur Erf&uuml;llung eines Vertrags oder zur
                        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                        gem&auml;&szlig; 6 Abs. 1 lit. b DSGVO.
                    </li>
                </ul>
                <ol>
                    <li><strong>Zahlung</strong></li>
                </ol>
                <p></p>
                <p>
                    Zur Zahlungsabwicklung setzen wir Zahlungsdienstleister und
                    Banken ein.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung des
                    Vertrages auf Basis der folgenden Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        zur Erf&uuml;llung eines Vertrags oder zur
                        Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                        gem&auml;&szlig; 6 Abs. 1 lit. b DSGVO.
                    </li>
                </ul>
                <ol start="4">
                    <li><strong>Einhaltung rechtlicher Vorgaben</strong></li>
                </ol>
                <p></p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zudem, um
                    sonstige gesetzliche Pflichten, die uns im Zusammenhang mit
                    der Durchf&uuml;hrung des Vertrages treffen, zu
                    erf&uuml;llen. Hierzu z&auml;hlen insbesondere handels-,
                    gewerbe- oder steuerrechtliche Aufbewahrungsfristen.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Begr&uuml;ndung, Durchf&uuml;hrung und Beendigung des
                    Vertrages auf Basis der folgenden Rechtsgrundlagen:
                </p>
                <ul>
                    <li>
                        zur Erf&uuml;llung einer rechtlichen Verpflichtung, der
                        wir unterliegen gem&auml;&szlig; Art. 6 Abs. 1 c DSGVO
                        in Verbindung mit Handels-, Gewerbe, oder Steuerrecht,
                        soweit wir
                    </li>
                </ul>
                <p>
                    dazu verpflichtet sind, Ihre Daten aufzuzeichnen und
                    aufzubewahren sowie in Verbindung mit weiterer
                    einschl&auml;giger Vorschriften oder beh&ouml;rdlicher
                    Anordnungen (z. B. im Strafrecht).
                </p>
                <ol start="5">
                    <li><strong>Rechtsdurchsetzung</strong></li>
                </ol>
                <p></p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zudem, um
                    unsere Rechte geltend und unsere rechtlichen Anspr&uuml;che
                    durchsetzen zu k&ouml;nnen. Ebenfalls verarbeiten wir Ihre
                    personenbezogenen Daten, um uns gegen rechtliche
                    Anspr&uuml;che verteidigen zu k&ouml;nnen.
                </p>
                <p>
                    Schlie&szlig;lich verarbeiten wir Ihre personenbezogenen
                    Daten, soweit dies zur Abwehr oder Verfolgung von Straftaten
                    erforderlich ist.
                </p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten zur
                    Rechtsdurchsetzung:
                </p>
                <ul>
                    <li>
                        zur Wahrung unserer berechtigten Interessen
                        gem&auml;&szlig; 6 Abs. 1 lit. f DSGVO, soweit wir
                        rechtliche Anspr&uuml;che geltend machen oder uns bei
                        rechtlichen Streitigkeiten verteidigen oder wir
                        Straftaten verhindern oder aufkl&auml;ren.
                    </li>
                </ul>
                <ul>
                    <li><strong>Kategorien von Empf&auml;ngern</strong></li>
                </ul>
                <p></p>
                <p>
                    Zun&auml;chst erhalten nur unsere Mitarbeiter Kenntnis von
                    Ihren personenbezogenen Daten. Zudem teilen wir Ihre
                    personenbezogenen Daten, soweit die gesetzlich erlaubt oder
                    vorgeschrieben ist, anderen Empf&auml;ngern mit, die
                    f&uuml;r uns Dienstleistungen im Zusammenhang mit unserer
                    Website oder den oben beschriebenen Datenverarbeitungen
                    au&szlig;erhalb der Website erbringen. Wir beschr&auml;nken
                    die Weitergabe Ihrer personenbezogenen Daten dabei auf das
                    Notwendige, insbesondere um Ihre Bestellung abwickeln zu
                    k&ouml;nnen. Teilweise erhalten unsere Dienstleister Ihre
                    personenbezogenen Daten als Auftragsverarbeiter und sind
                    dann bei dem Umgang mit Ihren personenbezogenen Daten streng
                    an unsere Weisungen gebunden. Teilweise agieren die
                    Empf&auml;nger eigenst&auml;ndig mit Ihren Daten, die wir an
                    diese &uuml;bermitteln.
                </p>
                <p>
                    Nachfolgend nennen wir Ihnen die Kategorien der
                    Empf&auml;nger Ihrer personenbezogenen Daten:
                </p>
                <ul>
                    <li>
                        Zahlungsdienstleister und Banken, bei der Abwicklung der
                        Zahlung, sowie Auskunfteien zur Pr&uuml;fung der
                        Bonit&auml;t,
                    </li>
                    <li>
                        IT-Dienstleister bei der Administration und dem Hosting
                        unserer Website oder dem Betrieb unserer Apps,
                    </li>
                    <li>
                        IT-Dienstleister f&uuml;r den Versand von
                        Pressemitteilungen,
                    </li>
                    <li>
                        IT-Dienstleister, die Software bereitstellen, um
                        virtuelle Konferenzen, Besprechungen oder vergleichbare
                        Kommunikation durchzuf&uuml;hren,
                    </li>
                    <li>
                        Inkassounternehmen und Rechtsberater bei der
                        Geltendmachung unserer Anspr&uuml;che
                    </li>
                    <li>
                        andere Veranstaltungsteilnehmer, darunter auch die
                        teilnehmenden GS1 Germany Solution Partner, f&uuml;r die
                        M&ouml;glichkeit der Kontaktaufnahme zu anderen
                        Teilnehmern durch andere Teilnehmer,
                    </li>
                    <li>
                        &ouml;ffentliche Stellen und Institutionen soweit wir
                        rechtlich dazu verpflichtet
                    </li>
                </ul>
                <ol>
                    <li><strong>Drittlandtransfer</strong></li>
                </ol>
                <p></p>
                <p>
                    Im Rahmen des Einsatzes der Tools von Google &uuml;bertragen
                    wir u. a. Ihre gek&uuml;rzte IP-Adresse in die USA. Daneben
                    kann eine Daten&uuml;bermittlung au&szlig;erhalb der EU/des
                    EWR auch im Rahmen des Einsatzes der Tools von Microsoft,
                    LinkedIn, Hotjar sowie Facebook erfolgen.
                </p>
                <p>
                    Werden Dienstleister au&szlig;erhalb der EU/des EWR
                    Drittstaat eingesetzt und existiert f&uuml;r dieses Land
                    kein sog. &bdquo;Angemessenheitsbeschluss&ldquo; der
                    EU-Kommission, sind diese Dienstleister zus&auml;tzlich zu
                    schriftlichen Weisungen durch die Vereinbarung der EU-
                    Standardvertragsklauseln zur Einhaltung des
                    Datenschutzniveaus in Europa verpflichtet.
                </p>
                <p>
                    Leider besteht aufgrund der Gesetze von Nicht-EU-Staaten (z.
                    B. im Rahmen des sog. Cloud- Act in den USA) jedoch auch bei
                    Abschluss entsprechender Vereinbarungen und Regelwerken die
                    M&ouml;glichkeit, dass insbesondere staatliche Stellen auf
                    Ihre personenbezogenen Daten zugreifen, ohne dass wir oder
                    Sie dies erfahren oder verhindern, unterbinden oder
                    kontrollieren k&ouml;nnen. Aus diesen Gr&uuml;nden umfasst
                    Ihre Einwilligung z. B. zum Einsatz von Cookies (bspw.
                    f&uuml;r Google Analytics) auch den Zweck der
                    Daten&uuml;bermittlung in L&auml;nder au&szlig;erhalb der
                    EU. Weitere Ausk&uuml;nfte erhalten Sie von unserer/unserem
                    Datenschutzbeauftragten.
                </p>
                <p>
                    Im &Uuml;brigen &uuml;bertragen wir Ihre personenbezogenen
                    Daten nicht in L&auml;nder au&szlig;erhalb der EU bzw. des
                    EWR oder an internationale Organisationen.
                </p>
                <ol>
                    <li><strong>Dauer der Speicherung</strong></li>
                </ol>
                <p></p>
                <ol>
                    <li>
                        <strong>Informatorische Nutzung der Website</strong>
                    </li>
                </ol>
                <p></p>
                <p>
                    Bei der rein informatorischen Nutzung unserer Website
                    speichern wir Ihre personenbezogenen Daten auf unseren
                    Servern f&uuml;r die Dauer des Besuchs unserer Website.
                    Nachdem Sie unsere Website verlassen haben, werden Ihre
                    personenbezogenen Daten in der Regel unverz&uuml;glich
                    gel&ouml;scht. Serverlogfiles speichern wir jedoch f&uuml;r
                    die Dauer von 1 Monat.
                </p>
                <p>
                    Die Speicherdauern der von uns eingesetzten Cookies
                    k&ouml;nnen hiervon abweichen und bis zu 2 Jahre betragen.
                    Die genauen Angaben zu den Speicherdauern der von uns
                    verwendeten Cookies entnehmen Sie bitte der &Uuml;bersicht
                    zu Beginn dieser Datenschutzerkl&auml;rung bzw. unserem
                    Consent-Management/Cookie-Banner.
                </p>
                <p>
                    Sie haben jederzeit die M&ouml;glichkeit, installierte
                    Cookies selbst zu l&ouml;schen oder &uuml;ber unser Consent
                    Management/Cookie-Banner zu deaktivieren.
                </p>
                <ol start="2">
                    <li>
                        <strong
                            >Aktive Nutzung der Website sowie Kontaktanfragen,
                            Vertragsabschl&uuml;sse und Anmeldung zu
                            Veranstaltungen au&szlig;erhalb der Website,
                            Vertragsdurchf&uuml;hrung</strong
                        >
                    </li>
                </ol>
                <p></p>
                <p>
                    Bei einer aktiven Nutzung unserer Website sowie bei
                    Kontaktanfragen, Vertragsabschl&uuml;ssen und Anmeldungen zu
                    Veranstaltungen au&szlig;erhalb der Website und bei der
                    Vertragsdurchf&uuml;hrung speichern wir Ihre
                    personenbezogenen Daten zun&auml;chst solange, wie dies z.
                    B. f&uuml;r die Beantwortung Ihrer Anfrage, die
                    Vertragsdurchf&uuml;hrung oder die unsere
                    gesch&auml;ftlichen Beziehungen erforderlich ist. Das
                    schlie&szlig;t auch die Anbahnung eines Vertrages
                    (vorvertragliches Rechtsverh&auml;ltnis) und die Abwicklung
                    eines Vertrages mit ein.
                </p>
                <p>
                    Zus&auml;tzlich speichern wir Ihre personenbezogenen Daten
                    sodann bis zum Eintritt der Verj&auml;hrung etwaiger
                    rechtlicher Anspr&uuml;che aus der Beziehung mit Ihnen, um
                    sie gegebenenfalls als Beweismittel einzusetzen. Die
                    Verj&auml;hrungsfrist betr&auml;gt in der Regel zwischen 1
                    und 3 Jahren, kann aber auch bis zu 30 Jahre betragen.
                </p>
                <p>
                    Mit Eintritt der Verj&auml;hrung l&ouml;schen wir Ihre
                    personenbezogenen Daten, es sei denn, es liegt eine
                    gesetzliche Aufbewahrungspflicht, zum Beispiel aus dem
                    Handelsgesetzbuch (&sect;&sect; 238,
                </p>
                <p>
                    257 Abs. 4 HGB) oder aus der Abgabenordnung (&sect; 147 Abs.
                    3, 4 AO) vor. Diese Aufbewahrungspflichten k&ouml;nnen zwei
                    bis zehn Jahre betragen.
                </p>
                <ol>
                    <li><strong>Ihre Rechte als betroffenen Person</strong></li>
                </ol>
                <p></p>
                <p>
                    Ihnen stehen unter den gesetzlichen Voraussetzungen die
                    nachfolgenden Rechte als betroffene Person zu, die Sie uns
                    gegen&uuml;ber geltend machen k&ouml;nnen:
                </p>
                <p>
                    <strong>Auskunftsrecht</strong>: Sie sind jederzeit
                    berechtigt, im Rahmen von Art. 15 DSGVO von uns eine
                    Best&auml;tigung dar&uuml;ber zu verlangen, ob wir Sie
                    betreffende personenbezogene Daten verarbeiten; ist dies der
                    Fall, sind Sie im Rahmen von Art. 15 DSGVO ferner
                    berechtigt, Auskunft &uuml;ber diese personenbezogenen Daten
                    sowie bestimmte weitere Informationen (u.a.
                    Verarbeitungszwecke, Kategorien personenbezogener Daten,
                    Kategorien von Empf&auml;ngern, geplante Speicherdauer, ihre
                    Rechte, die Herkunft der Daten, den Einsatz einer
                    automatisierten Entscheidungsfindung und im Fall des
                    Drittlandtransfer die geeigneten Garantien) und eine Kopie
                    Ihrer Daten zu erhalten.
                </p>
                <p>
                    <strong>Recht auf Berichtigung</strong>: Sie sind
                    berechtigt, nach Art. 16 DSGVO von uns zu verlangen, dass
                    wir die &uuml;ber Sie gespeicherten personenbezogenen Daten
                    berichtigen, wenn diese unzutreffend oder fehlerhaft sind.
                </p>
                <p>
                    <strong>Recht auf L&ouml;schung</strong>: Sie sind
                    berechtigt, unter den Voraussetzungen von Art. 17 DSGVO von
                    uns zu verlangen, dass wir Sie betreffende personenbezogene
                    Daten unverz&uuml;glich l&ouml;schen. Das Recht auf
                    L&ouml;schung besteht u.a. nicht, wenn die Verarbeitung der
                    personenbezogenen Daten erforderlich ist f&uuml;r (i) die
                    Aus&uuml;bung des Rechts auf freie
                    Meinungs&auml;u&szlig;erung und Information, (ii) zur
                    Erf&uuml;llung einer rechtlichen Verpflichtung, der wir
                    unterliegen (z.B. gesetzliche Aufbewahrungspflichten) oder
                    (iii) zur Geltendmachung, Aus&uuml;bung oder Verteidigung
                    von Rechtsanspr&uuml;chen.
                </p>
                <p>
                    <strong
                        >Recht auf Einschr&auml;nkung der Verarbeitung</strong
                    >: Sie sind berechtigt, unter den Voraussetzungen von Art.
                    18 DSGVO von uns zu verlangen, dass wir die Verarbeitung
                    Ihrer personenbezogenen Daten einschr&auml;nken.
                </p>
                <p>
                    <strong>Recht auf Daten&uuml;bertragbarkeit</strong>: Sie
                    sind berechtigt, unter den Voraussetzungen von Art. 20 DSGVO
                    von uns zu verlangen, dass wir Ihnen die Sie betreffenden
                    personenbezogenen Daten, die Sie uns bereitgestellt haben,
                    in einem strukturierten, g&auml;ngigen und maschinenlesbaren
                    Format &uuml;bergeben.
                </p>
                <p>
                    <strong>Widerrufsrecht</strong>: Sie haben das Recht Ihre
                    erteilte Einwilligung in die Verarbeitung personenbezogener
                    Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu
                    widerrufen.
                </p>
                <p>
                    <strong>Widerspruchsrecht</strong>: Sie sind berechtigt,
                    unter den Voraussetzungen von Art. 21 DSGVO Widerspruch
                    gegen die Verarbeitung Ihrer personenbezogenen Daten
                    einzulegen, sodass wir die Verarbeitung Ihrer
                    personenbezogenen Daten beenden m&uuml;ssen. Das
                    Widerspruchsrecht besteht nur in den in Art. 21 DSGVO
                    vorgesehen Grenzen. Zudem k&ouml;nnen unsere Interessen
                    einer Beendigung der Verarbeitung entgegenstehen, sodass wir
                    trotz Ihres Widerspruchs berechtigt sind, Ihre
                    personenbezogenen Daten zu verarbeiten.
                </p>
                <p>
                    <strong
                        >Beschwerderecht bei einer Aufsichtsbeh&ouml;rde</strong
                    >: Sie sind berechtigt, unter den Voraussetzungen von Art.
                    77 DSGVO Beschwerde bei einer Aufsichtsbeh&ouml;rde,
                    insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts,
                    ihres Arbeitsplatzes oder des Orts des
                </p>
                <p>
                    mutma&szlig;lichen Versto&szlig;es, zu, wenn Sie der Ansicht
                    sind, dass die Verarbeitung der Sie betreffenden
                    personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t.
                    Das Beschwerderecht besteht unbeschadet eines anderweitigen
                    verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs.
                </p>
                <p>
                    Die f&uuml;r uns zust&auml;ndige Aufsichtsbeh&ouml;rde ist:
                </p>
                <p>
                    Landesbeauftragte f&uuml;r Datenschutz und
                    Informationsfreiheit Nordrhein- Westfalen
                </p>
                <p>Kavalleriestr. 2-4</p>
                <p>
                    40213 D&uuml;sseldorf Telefon:
                    <a href="tel://0211384240/">0211/38424-0</a> Fax:
                    0211/38424-10
                </p>
                <p>
                    E-Mail:
                    <a href="mailto:poststelle@ldi.nrw.de"
                        >poststelle &#64; ldi.nrw.de</a
                    >
                </p>
                <p>
                    <strong
                        >Wir empfehlen Ihnen allerdings, eine Beschwerde
                        zun&auml;chst immer an unseren Datenschutzbeauftragten
                        zu richten.</strong
                    >
                </p>
                <p></p>
                <p>
                    <strong
                        >Ihre Antr&auml;ge &uuml;ber die Aus&uuml;bung ihrer
                        Rechte sollten nach M&ouml;glichkeit schriftlich an die
                        oben angegebene Anschrift oder direkt an unseren
                        Datenschutzbeauftragten adressiert werden.</strong
                    >
                </p>
                <p></p>
                <ul>
                    <li>
                        <strong
                            >Umfang Ihrer Pflichten zur Bereitstellung von
                            Daten</strong
                        >
                    </li>
                </ul>
                <p></p>
                <p>
                    Grunds&auml;tzlich sind Sie nicht verpflichtet, uns Ihre
                    personenbezogenen Daten mitzuteilen. Wenn Sie dies jedoch
                    nicht tun, werden wir Ihnen unsere Website nicht zur
                    Verf&uuml;gung stellen, Ihre Anfragen an uns nicht
                    beantworten und mit Ihnen keinen Vertrag eingehen
                    k&ouml;nnen. Personenbezogene Daten, die wir nicht zwingend
                    f&uuml;r die oben genannten Verarbeitungszwecke
                    ben&ouml;tigen, sind durch ein &bdquo;ggf.&ldquo; oder ein
                    anderes Zeichen als freiwillige Angaben gekennzeichnet.
                </p>
                <ul>
                    <li>
                        <strong
                            >Automatisierte Entscheidungsfindung /
                            Profiling</strong
                        >
                    </li>
                </ul>
                <p></p>
                <p>
                    Wir setzen keine automatisierte Entscheidungsfindung oder
                    ein Profiling (eine automatisierte Analyse Ihrer
                    pers&ouml;nlichen Umst&auml;nde) ein.
                </p>
                <p>
                    <strong
                        >Information &uuml;ber Ihr Widerspruchsrecht Art. 21
                        DSGVO</strong
                    >
                </p>
                <p></p>
                <ol>
                    <li>
                        <strong
                            >Sie haben das Recht, jederzeit gegen die
                            Verarbeitung Ihrer Daten, die aufgrund von 6 Abs. 1
                            f DSGVO (Datenverarbeitung auf der Grundlage einer
                            Interessenabw&auml;gung) oder Art. 6 Abs. 1 e DSGVO
                            (Datenverarbeitung im &ouml;ffentlichen Interesse)
                            erfolgt, Widerspruch einzulegen, wenn daf&uuml;r
                            Gr&uuml;nde vorliegen, die sich aus Ihrer besonderen
                            Situation ergeben.</strong
                        >
                    </li>
                </ol>
                <p></p>
                <p>
                    Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen
                    Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen
                    zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die
                    Verarbeitung nachweisen, die Ihre Interessen, Rechte und
                    Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der
                    Geltendmachung, Aus&uuml;bung oder Verteidigung von
                    Rechtsanspr&uuml;chen.
                </p>
                <ol>
                    <li>
                        <strong
                            >Wir verarbeiten Ihre personenbezogenen Daten in
                            Einzelf&auml;llen auch, um Direktwerbung zu
                            betreiben. Sofern Sie keine Werbung erhalten
                            m&ouml;chten, haben Sie jederzeit das Recht,
                            Widerspruch dagegen Diesen Widerspruch werden
                            wir</strong
                        >
                    </li>
                </ol>
                <p>
                    <strong
                        >f&uuml;r die Zukunft beachten. Ihre Daten werden wir
                        nicht mehr f&uuml;r Zwecke der Direktwerbung
                        verarbeiten, wenn Sie der Verarbeitung f&uuml;r diese
                        Zwecke widersprechen.</strong
                    >
                </p>
                <p></p>
                <p>
                    Der Widerspruch kann formfrei erfolgen und sollte
                    m&ouml;glichst gerichtet werden an Stolberger Stra&szlig;e
                    108 a
                </p>
                <p>50933 K&ouml;ln</p>
                <p>
                    <a href="mailto:widerspruch@gs1-germany.de"
                        >widerspruch&#64;gs1-germany.de</a
                    >
                </p>
                <p>GS1 Germany GmbH</p>

                <p>
                    Diese Webseite verwendet Cookies und Local-Storage um eine
                    möglichst intuitive Bedienung zu ermöglichen. Es handelt
                    sich um notwendige funktionale Cookies.
                </p>
                <div></div>
            </div>
        </div>
    </div>

    <div id="Cookies"></div>

    <div class="container spacer-top">
        <div class="row">
            <div class="col-12">
                <h1>Cookie Übersicht:</h1>
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr class="table-active">
                                <td>Name</td>
                                <td>Anbieter</td>
                                <td>Zweck</td>
                                <td>Ablauf</td>
                            </tr>
                            <tr>
                                <td>BCSI-SCS-*</td>
                                <td>T-Systems International GmbH</td>
                                <td>Bluecoat Proxy Server</td>
                                <td>Nach Sessionende</td>
                            </tr>
                            <tr>
                                <td>CLOUDWAFSESTIME</td>
                                <td>T-Systems International GmbH</td>
                                <td>
                                    OTC Web-Appication-Firewall Session Cookie
                                </td>
                                <td>Nach Sessionende</td>
                            </tr>
                            <tr>
                                <td>CLOUDWAFSESID</td>
                                <td>T-Systems International GmbH</td>
                                <td>
                                    OTC Web-Appication-Firewall Session Cookie
                                </td>
                                <td>Nach Sessionende</td>
                            </tr>
                            <tr>
                                <td>Generierte 32stellige Hexadezimal-ID</td>
                                <td>T-Systems International GmbH</td>
                                <td>TC Loadbalancer Session Cookie</td>
                                <td>Nach Sessionende</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div></div>
                <h3>Local storage:</h3>
                <p>
                    Damit Sie den digitalen Lieferschein Ihren persönlichen
                    Bedürfnissen und Ihrer Nutzung anpassen können, verwenden
                    wir die sogenannte Local Storage Technik (auch „Lokale
                    Daten“ und „Lokaler Speicher“ genannt). Dabei werden Daten
                    lokal im Browserspeicher abgelegt. Nach Schließung der
                    Anwendung werden diese automatisch gelöscht. Auf die im
                    Local Storage gespeicherten Daten können Dritte nicht
                    zugreifen. Sie werden an Dritte nicht weitergegeben und auch
                    nicht zu Werbezwecken verwendet. Local-Storage-Inhalte
                    verwalten Sie im Browser über die Einstellungen zur
                    „Chronik“ bzw. zu „Lokalen Daten“, je nachdem welchen
                    Browser Sie verwenden
                </p>
                <table class="table">
                    <tbody>
                        <tr class="table-active">
                            <td>Name</td>
                            <td>Anbieter</td>
                            <td>Zweck</td>
                            <td>Ablauf</td>
                            <td>Typ</td>
                        </tr>
                        <tr>
                            <td>currentSession</td>
                            <td>T-Systems International GmbH</td>
                            <td>Nutzersession (Username, SessionId, Rolle)</td>
                            <td>Nach logout</td>
                            <td>https</td>
                        </tr>
                        <tr>
                            <td>hideInfo</td>
                            <td>T-Systems International GmbH</td>
                            <td>Informationstext auf Loginseite an/aus</td>
                            <td>forever</td>
                            <td>https</td>
                        </tr>
                        <tr>
                            <td>hideRating</td>
                            <td>T-Systems International GmbH</td>
                            <td>Feedback-Dialog an/aus</td>
                            <td>forever</td>
                            <td>https</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="container spacer-top">
        <div class="row">
            <div class="col-12">
                Hintergrundbild Login-Seite:
                <a
                    href="http://www.freepik.com"
                    target="_blank"
                    >Designed by rawpixel.com / Freepik</a
                >
            </div>
        </div>
    </div>
    <div class="spacer-top">&nbsp;</div>
</div>
