<div class="tw-flex tw-justify-end">
    <button
        mat-icon-button
        mat-dialog-close>
        <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
</div>

<div class="wrapper-container">
    <div class="wrapper-left-area">
        <app-wallet
            *ngIf="checkOutTour"
            [date]="checkOutTour.tour.dateCheckout"
            [tourNr]="checkOutTour.tour.tourNumber || tour?._key"
            [consigneeName]="checkOutTour.tour.consigneeName"
            [carrierName]="checkOutTour.tour.carrierName"
            [consignorName]="checkOutTour.tour.consignorName"
            [showQrCode]="true"
            [qrCodeValue]="linkQrCode"
            [qrHref]="linkQrCode"></app-wallet>

        <app-wallet
            *ngIf="tourData"
            [date]="tourData.dateCreated"
            [tourNr]="tourData.tourNumber || tourData._key"
            [consigneeName]="uniqueConsignees"
            [carrierName]="tourData.deliveryNotes[0].carrierName"
            [consignorName]="uniqueConsignors"
            [showQrCode]="true"
            [qrCodeValue]="linkQrCode"
            [qrHref]="linkQrCode"></app-wallet>
    </div>

    <div class="wrapper-right-area">
        <div class="content">
            <div *ngIf="isCheckout">
                <div class="headline">
                    {{ 'SEND-WALLET-INVITE.HEADLINE' | translate }}
                </div>
                <div class="subhead">
                    {{ 'SEND-WALLET-INVITE.SUBHEADLINE' | translate }}
                </div>
            </div>

            <div class="mt-5">
                <ng-container>
                    <form [formGroup]="invitationForm">
                        <div class="row">
                            <div>
                                <div class="headline">
                                    {{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.HEADLINE'
                                            | translate
                                    }}
                                </div>
                                <div class="subhead">
                                    {{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.SUBHEADLINE'
                                            | translate
                                    }}
                                </div>
                            </div>
                            <div class="mt-5">
                                <mat-form-field
                                    appearance="fill"
                                    class="tw-mb-0">
                                    <mat-label>{{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.INPUT-LABEL'
                                            | translate
                                    }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="email"
                                        type="email"
                                        placeholder="{{
                                            'SEND-WALLET-INVITE.TICKET-EMAIL.INPUT-PLACEHOLDER'
                                                | translate
                                        }}" />
                                    <mat-error
                                        *ngIf="
                                            invitationForm.controls.email.value
                                                .length &&
                                            invitationForm.controls.email
                                                .invalid
                                        ">
                                        {{
                                            'SEND-WALLET-INVITE.TICKET-EMAIL.INPUT-ERROR'
                                                | translate
                                        }}
                                    </mat-error>
                                </mat-form-field>

                                <button
                                    mat-raised-button
                                    color="primary"
                                    [disabled]="
                                        invitationForm.controls.email.value ===
                                            lastInvited ||
                                        loading ||
                                        invitationForm.invalid
                                    "
                                    [class.spinner]="loading"
                                    (click)="sendInvitation()">
                                    {{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.SEND-BUTTON'
                                            | translate
                                    }}
                                </button>

                                <div
                                    *ngIf="
                                        invitationForm.controls.email.value ===
                                        lastInvited
                                    ">
                                    {{ lastInvited }} -
                                    {{
                                        'SEND-WALLET-INVITE.TICKET-EMAIL.SENT-SUCCESSFULLY'
                                            | translate
                                    }}
                                </div>
                            </div>
                            <div class="mt-4">
                                <div
                                    class="subhead emphasize has-pointer d-flex align-items-center"
                                    (click)="onPrintQRCodeCanvas()">
                                    <mat-icon
                                        fontSet="material-symbols-outlined"
                                        >print</mat-icon
                                    >
                                    <span class="clickable-link">{{
                                        'SEND-WALLET-INVITE.PRINT-QR-TICKET'
                                            | translate
                                    }}</span>
                                </div>
                            </div>
                            <div class="mt-4">
                                <div
                                    class="subhead emphasize has-pointer d-flex align-items-center"
                                    (click)="onPrintAllDeliveryNotes()">
                                    <mat-icon
                                        fontSet="material-symbols-outlined"
                                        >print</mat-icon
                                    >
                                    <span class="clickable-link">{{
                                        'SEND-WALLET-INVITE.PRINT-ALL-DELIVERYNOTES'
                                            | translate
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</div>
