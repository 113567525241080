<h2
    mat-dialog-title
    class="tw-font-bold">
    {{ 'TWOFA.TITLE' | translate }}
</h2>

<mat-dialog-content class="tw-mt-2">
    <div class="tw-w-5/6">{{ 'TWOFA.TEXT' | translate }}</div>

    <form [formGroup]="oneTimeCodesForm">
        <mat-form-field
            appearance="fill"
            class="tw-mt-6 tw-mb-0">
            <mat-label>{{ 'TWOFA.INPUT-PLACEHOLDER' | translate }}</mat-label>
            <input
                formControlName="code"
                aria-label="one-time-code-input"
                matInput />
        </mat-form-field>
    </form>

    <div
        *ngIf="wrongCodeError"
        class="tw-text-red-500">
        {{ 'TWOFA.WRONG-CODE' | translate }}
    </div>

    <div class="tw-font-bold">
        {{ 'TWOFA.NEW-CODE-1' | translate }}&nbsp;
        <span class="tw-text-primaryBlue"
            >{{ timeLeft * 1000 | date: 'mm:ss' }}
            {{ 'TWOFA.NEW-CODE-2' | translate }}</span
        >
        &nbsp;{{ 'TWOFA.NEW-CODE-3' | translate }}
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        [disabled]="
            oneTimeCodesForm.invalid ||
            oneTimeCodesForm.controls.code.value.length !== codeLength
        "
        mat-raised-button
        aria-label="one-time-code-confirm"
        color="primary"
        (click)="submitTwofaCode()">
        {{ 'TWOFA.VERIFY' | translate }}
    </button>
</mat-dialog-actions>
