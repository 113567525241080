<div class="split-view-wrapper">
    <app-left-splitview></app-left-splitview>
    <div class="right-col d-flex justify-content-center">
        <div class="input-container">
            <h2>{{ 'FORGOT-PASSWORD.TITLE' | translate }}</h2>
            <h3>{{ 'FORGOT-PASSWORD.SUBTITLE' | translate }}</h3>
            <div class="login-form">
                <form [formGroup]="passwordResetForm">
                    <div class="mat-field-container">
                        <mat-form-field appearance="fill">
                            <mat-label>{{
                                'FORGOT-PASSWORD.LABEL-EMAIL' | translate
                            }}</mat-label>

                            <mat-icon
                                matPrefix
                                fontSet="material-symbols-outlined"
                                >mail</mat-icon
                            >

                            <input
                                matInput
                                formControlName="email" />
                            <mat-error *ngIf="f.email.hasError('pattern')">{{
                                'FORGOT-PASSWORD.EMAIL-REQUIRED' | translate
                            }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="text-center mt-2">
                        <button
                            class="login-btn"
                            type="button"
                            mat-raised-button
                            color="primary"
                            [disabled]="passwordResetForm.invalid"
                            (click)="onResetPassword()"
                            [class.spinner]="loading">
                            {{ 'FORGOT-PASSWORD.RESET-BUTTON' | translate }}
                        </button>
                    </div>
                    <div class="text-center -tw-mb-[80px]">
                        <div class="mt-5 mb-4">
                            <a
                                class="service-link"
                                [routerLink]="['/login']"
                                routerLinkActive="router-link-active"
                                >{{ 'LOGIN.LOGIN-BUTTON' | translate }}
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-unauth-footer page="forgot-password"></app-unauth-footer>
</div>
