<div
    class="tw-flex tw-w-10 tw-items-center tw-justify-center tw-pr-10"
    *ngIf="isVisible">
    <div class="tw-flex tw-w-full tw-justify-end">
        <p class="tw-w-8 tw-text-right">{{ remainingTime }}</p>
    </div>
    <button
        (click)="navigateToStart()"
        mat-icon-button>
        <mat-icon
            color="primary"
            fontSet="material-symbols-outlined"
            >cancel</mat-icon
        >
    </button>
</div>
