import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { QrCodeModule } from 'ng-qrcode';
import { WalletComponent } from './wallet.component';

@NgModule({
    declarations: [WalletComponent],
    imports: [CommonModule, TranslateModule, QrCodeModule, MatIconModule],
    exports: [WalletComponent],
})
export class WalletComponentModule {}
