import { Injectable } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuotasComponent } from '../components/quotas/quotas.component';
import { SessionService } from './client-services/session-service/session.service';

@Injectable({
    providedIn: 'root',
})
export class QuotasService {
    dialogRef: any;

    constructor(
        private dialog: DialogService,
        private spinner: NgxSpinnerService,
        private sessionService: SessionService
    ) {}

    async checkQuotas(
        type:
            | 'OrganizationSites'
            | 'Users'
            | 'DeliveryNotesPerMonth'
            | 'BundlesPerMonth'
            | 'CheckoutsPerMonth'
            | 'ToursPerMonth'
            | 'CheckinsPerMonth',
        showDialog: boolean
    ): Promise<boolean> {
        await this.spinner.show();
        const quotas: any = await this.sessionService.getQuotas(true);
        if (
            quotas?.usedQuotas[`used${type}`] >= quotas?.maxQuotas[`max${type}`]
        ) {
            if (showDialog) {
                this.showQuotasDialog();
            }
            await this.spinner.hide();
            return true;
        }
        await this.spinner.hide();
        return false;
    }

    showQuotasDialog() {
        this.dialogRef?.close();
        this.dialogRef = this.dialog.open(QuotasComponent, {
            size: 'md',
            closeButton: false,
            enableClose: false,
            minHeight: '100px',
        });
        this.dialogRef.afterClosed$.subscribe((result: any) => {
            this.dialogRef = undefined;
        });
    }
}
