/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { externalGetBundleWalletInviteData } from '../fn/external/external-get-bundle-wallet-invite-data';
import { ExternalGetBundleWalletInviteData$Params } from '../fn/external/external-get-bundle-wallet-invite-data';
import { externalGetTourWalletInviteData } from '../fn/external/external-get-tour-wallet-invite-data';
import { ExternalGetTourWalletInviteData$Params } from '../fn/external/external-get-tour-wallet-invite-data';
import { MultiBundleWalletInviteData } from '../models/multi-bundle-wallet-invite-data';
import { TourWalletInviteData } from '../models/tour-wallet-invite-data';


/**
 * Endpoints regarding the wallet invite page
 */
@Injectable({ providedIn: 'root' })
export class WalletInviteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalGetBundleWalletInviteData()` */
  static readonly ExternalGetBundleWalletInviteDataPath = '/external/bundle/wallet-invite';

  /**
   * Get data for wallet invite page.
   *
   * Returns all data that is necessary to build the wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetBundleWalletInviteData()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetBundleWalletInviteData$Response(params: ExternalGetBundleWalletInviteData$Params, context?: HttpContext): Observable<StrictHttpResponse<MultiBundleWalletInviteData>> {
    return externalGetBundleWalletInviteData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get data for wallet invite page.
   *
   * Returns all data that is necessary to build the wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetBundleWalletInviteData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetBundleWalletInviteData(params: ExternalGetBundleWalletInviteData$Params, context?: HttpContext): Observable<MultiBundleWalletInviteData> {
    return this.externalGetBundleWalletInviteData$Response(params, context).pipe(
      map((r: StrictHttpResponse<MultiBundleWalletInviteData>): MultiBundleWalletInviteData => r.body)
    );
  }

  /** Path part for operation `externalGetTourWalletInviteData()` */
  static readonly ExternalGetTourWalletInviteDataPath = '/external/tour/wallet-invite';

  /**
   * Get tour data for wallet invite page.
   *
   * Returns all data that is necessary to build the tour wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourWalletInviteData()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourWalletInviteData$Response(params: ExternalGetTourWalletInviteData$Params, context?: HttpContext): Observable<StrictHttpResponse<TourWalletInviteData>> {
    return externalGetTourWalletInviteData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tour data for wallet invite page.
   *
   * Returns all data that is necessary to build the tour wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourWalletInviteData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourWalletInviteData(params: ExternalGetTourWalletInviteData$Params, context?: HttpContext): Observable<TourWalletInviteData> {
    return this.externalGetTourWalletInviteData$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourWalletInviteData>): TourWalletInviteData => r.body)
    );
  }

}
