/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { changeUsersSignature } from '../fn/users/change-users-signature';
import { ChangeUsersSignature$Params } from '../fn/users/change-users-signature';
import { completeChangeEmailAddress } from '../fn/users/complete-change-email-address';
import { CompleteChangeEmailAddress$Params } from '../fn/users/complete-change-email-address';
import { completeChangePassword } from '../fn/users/complete-change-password';
import { CompleteChangePassword$Params } from '../fn/users/complete-change-password';
import { getAccessibleUserSites } from '../fn/users/get-accessible-user-sites';
import { GetAccessibleUserSites$Params } from '../fn/users/get-accessible-user-sites';
import { getMe } from '../fn/users/get-me';
import { GetMe$Params } from '../fn/users/get-me';
import { getMeQuotas } from '../fn/users/get-me-quotas';
import { GetMeQuotas$Params } from '../fn/users/get-me-quotas';
import { GetMeQuotasResponse } from '../models/get-me-quotas-response';
import { getMeSupplyChains } from '../fn/users/get-me-supply-chains';
import { GetMeSupplyChains$Params } from '../fn/users/get-me-supply-chains';
import { getUserSignature } from '../fn/users/get-user-signature';
import { GetUserSignature$Params } from '../fn/users/get-user-signature';
import { LoginResponse } from '../models/login-response';
import { MeSupplyChainsResponse } from '../models/me-supply-chains-response';
import { OrganizationSite } from '../models/organization-site';
import { patchBasicUser } from '../fn/users/patch-basic-user';
import { PatchBasicUser$Params } from '../fn/users/patch-basic-user';
import { postMeAcceptTermsOfService } from '../fn/users/post-me-accept-terms-of-service';
import { PostMeAcceptTermsOfService$Params } from '../fn/users/post-me-accept-terms-of-service';
import { postPublicCompleteResetUserPassword } from '../fn/public/post-public-complete-reset-user-password';
import { PostPublicCompleteResetUserPassword$Params } from '../fn/public/post-public-complete-reset-user-password';
import { putPublicResetUserPassword } from '../fn/public/put-public-reset-user-password';
import { PutPublicResetUserPassword$Params } from '../fn/public/put-public-reset-user-password';
import { requestChangeEmailAddress } from '../fn/users/request-change-email-address';
import { RequestChangeEmailAddress$Params } from '../fn/users/request-change-email-address';
import { requestChangePassword } from '../fn/users/request-change-password';
import { RequestChangePassword$Params } from '../fn/users/request-change-password';
import { TwoFaExpiry } from '../models/two-fa-expiry';
import { UserInformation } from '../models/user-information';


/**
 * Endpoints to manage users
 */
@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `putPublicResetUserPassword()` */
  static readonly PutPublicResetUserPasswordPath = '/public/reset-user-password';

  /**
   * Initiate user password reset.
   *
   * Send link to reset the password.
   * The link is valid for 15 minutes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPublicResetUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPublicResetUserPassword$Response(params: PutPublicResetUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putPublicResetUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Initiate user password reset.
   *
   * Send link to reset the password.
   * The link is valid for 15 minutes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putPublicResetUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPublicResetUserPassword(params: PutPublicResetUserPassword$Params, context?: HttpContext): Observable<void> {
    return this.putPublicResetUserPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postPublicCompleteResetUserPassword()` */
  static readonly PostPublicCompleteResetUserPasswordPath = '/public/complete-reset-user-password';

  /**
   * Complete user password reset.
   *
   * Reset the user password with the provided password and the token.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPublicCompleteResetUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPublicCompleteResetUserPassword$Response(params: PostPublicCompleteResetUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postPublicCompleteResetUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Complete user password reset.
   *
   * Reset the user password with the provided password and the token.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postPublicCompleteResetUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPublicCompleteResetUserPassword(params: PostPublicCompleteResetUserPassword$Params, context?: HttpContext): Observable<void> {
    return this.postPublicCompleteResetUserPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMe()` */
  static readonly GetMePath = '/me';

  /**
   * Get information of user the logged in user.
   *
   * Get user information of the logged in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(params?: GetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return getMe(this.http, this.rootUrl, params, context);
  }

  /**
   * Get information of user the logged in user.
   *
   * Get user information of the logged in user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(params?: GetMe$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.getMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `getAccessibleUserSites()` */
  static readonly GetAccessibleUserSitesPath = '/me/organization-sites';

  /**
   * Get organizations sites of the logged in user.
   *
   * Get accessible organization sites for the user
   * Returns also the allowRemoteAccess flag.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessibleUserSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessibleUserSites$Response(params?: GetAccessibleUserSites$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationSite>>> {
    return getAccessibleUserSites(this.http, this.rootUrl, params, context);
  }

  /**
   * Get organizations sites of the logged in user.
   *
   * Get accessible organization sites for the user
   * Returns also the allowRemoteAccess flag.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessibleUserSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessibleUserSites(params?: GetAccessibleUserSites$Params, context?: HttpContext): Observable<Array<OrganizationSite>> {
    return this.getAccessibleUserSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationSite>>): Array<OrganizationSite> => r.body)
    );
  }

  /** Path part for operation `patchBasicUser()` */
  static readonly PatchBasicUserPath = '/users';

  /**
   * Update user information.
   *
   * Patch basic user information, like fullName.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBasicUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBasicUser$Response(params: PatchBasicUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserInformation>> {
    return patchBasicUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Update user information.
   *
   * Patch basic user information, like fullName.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBasicUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBasicUser(params: PatchBasicUser$Params, context?: HttpContext): Observable<UserInformation> {
    return this.patchBasicUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInformation>): UserInformation => r.body)
    );
  }

  /** Path part for operation `requestChangePassword()` */
  static readonly RequestChangePasswordPath = '/users/request-change-password';

  /**
   * Request user password reset.
   *
   * Request the change of the password of the current user.
   * After this the request must be completed with a 2fa code in another request,
   * which will be sent to the users email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestChangePassword$Response(params: RequestChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<TwoFaExpiry>> {
    return requestChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Request user password reset.
   *
   * Request the change of the password of the current user.
   * After this the request must be completed with a 2fa code in another request,
   * which will be sent to the users email.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestChangePassword(params: RequestChangePassword$Params, context?: HttpContext): Observable<TwoFaExpiry> {
    return this.requestChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<TwoFaExpiry>): TwoFaExpiry => r.body)
    );
  }

  /** Path part for operation `completeChangePassword()` */
  static readonly CompleteChangePasswordPath = '/users/complete-change-password';

  /**
   * Complete the password reset.
   *
   * Completes the password request with the correct 2fa code given in the body.
   * The current session is closed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeChangePassword$Response(params: CompleteChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return completeChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Complete the password reset.
   *
   * Completes the password request with the correct 2fa code given in the body.
   * The current session is closed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeChangePassword(params: CompleteChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.completeChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `requestChangeEmailAddress()` */
  static readonly RequestChangeEmailAddressPath = '/users/request-change-email-address';

  /**
   * Request email address change.
   *
   * Request the change of the email address of the current user.
   * After this an 2fa code is send to the old email address and another
   * 2fa code is send to the new email address. Both 2fa codes are needed
   * to complete the email address change.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestChangeEmailAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestChangeEmailAddress$Response(params: RequestChangeEmailAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<TwoFaExpiry>> {
    return requestChangeEmailAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Request email address change.
   *
   * Request the change of the email address of the current user.
   * After this an 2fa code is send to the old email address and another
   * 2fa code is send to the new email address. Both 2fa codes are needed
   * to complete the email address change.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestChangeEmailAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestChangeEmailAddress(params: RequestChangeEmailAddress$Params, context?: HttpContext): Observable<TwoFaExpiry> {
    return this.requestChangeEmailAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<TwoFaExpiry>): TwoFaExpiry => r.body)
    );
  }

  /** Path part for operation `completeChangeEmailAddress()` */
  static readonly CompleteChangeEmailAddressPath = '/users/complete-change-email-address';

  /**
   * Complete email address change.
   *
   * Completes the email address change with both 2fa codes. One from the
   * old email address and one from the new email address.
   * The current session is closed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeChangeEmailAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeChangeEmailAddress$Response(params: CompleteChangeEmailAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return completeChangeEmailAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Complete email address change.
   *
   * Completes the email address change with both 2fa codes. One from the
   * old email address and one from the new email address.
   * The current session is closed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeChangeEmailAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeChangeEmailAddress(params: CompleteChangeEmailAddress$Params, context?: HttpContext): Observable<void> {
    return this.completeChangeEmailAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUserSignature()` */
  static readonly GetUserSignaturePath = '/users/signature';

  /**
   * Get consignee signature.
   *
   * Get the consignee users signature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSignature$Response(params?: GetUserSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getUserSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Get consignee signature.
   *
   * Get the consignee users signature.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSignature(params?: GetUserSignature$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getUserSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `changeUsersSignature()` */
  static readonly ChangeUsersSignaturePath = '/users/signature';

  /**
   * Update the consignee users signature.
   *
   * Set or update the consignee users signature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeUsersSignature()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  changeUsersSignature$Response(params: ChangeUsersSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeUsersSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the consignee users signature.
   *
   * Set or update the consignee users signature.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeUsersSignature$Response()` instead.
   *
   * This method sends `image/png` and handles request body of type `image/png`.
   */
  changeUsersSignature(params: ChangeUsersSignature$Params, context?: HttpContext): Observable<void> {
    return this.changeUsersSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postMeAcceptTermsOfService()` */
  static readonly PostMeAcceptTermsOfServicePath = '/me/terms-of-service';

  /**
   * Accept ToS.
   *
   * Accept latest version of terms of of service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMeAcceptTermsOfService()` instead.
   *
   * This method doesn't expect any request body.
   */
  postMeAcceptTermsOfService$Response(params?: PostMeAcceptTermsOfService$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postMeAcceptTermsOfService(this.http, this.rootUrl, params, context);
  }

  /**
   * Accept ToS.
   *
   * Accept latest version of terms of of service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postMeAcceptTermsOfService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postMeAcceptTermsOfService(params?: PostMeAcceptTermsOfService$Params, context?: HttpContext): Observable<void> {
    return this.postMeAcceptTermsOfService$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMeSupplyChains()` */
  static readonly GetMeSupplyChainsPath = '/me/supply-chains';

  /**
   * Get all weighted supply chains for the user.
   *
   * Get a weighted list of all supply chains for the logged in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeSupplyChains()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeSupplyChains$Response(params?: GetMeSupplyChains$Params, context?: HttpContext): Observable<StrictHttpResponse<MeSupplyChainsResponse>> {
    return getMeSupplyChains(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all weighted supply chains for the user.
   *
   * Get a weighted list of all supply chains for the logged in user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeSupplyChains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeSupplyChains(params?: GetMeSupplyChains$Params, context?: HttpContext): Observable<MeSupplyChainsResponse> {
    return this.getMeSupplyChains$Response(params, context).pipe(
      map((r: StrictHttpResponse<MeSupplyChainsResponse>): MeSupplyChainsResponse => r.body)
    );
  }

  /** Path part for operation `getMeQuotas()` */
  static readonly GetMeQuotasPath = '/me/quotas';

  /**
   * Get organization quotas for the calling user.
   *
   * Get organization quotas for the calling user for the current month.
   * API users count into users.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeQuotas()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeQuotas$Response(params?: GetMeQuotas$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMeQuotasResponse>> {
    return getMeQuotas(this.http, this.rootUrl, params, context);
  }

  /**
   * Get organization quotas for the calling user.
   *
   * Get organization quotas for the calling user for the current month.
   * API users count into users.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeQuotas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeQuotas(params?: GetMeQuotas$Params, context?: HttpContext): Observable<GetMeQuotasResponse> {
    return this.getMeQuotas$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMeQuotasResponse>): GetMeQuotasResponse => r.body)
    );
  }

}
