/* tslint:disable */
/* eslint-disable */

/**
 * Operations for LoadCarrier
 */
export enum LoadCarrierOperation {
  Adoption = 'adoption',
  Delivery = 'delivery'
}
