import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { AdministrationUserDetails } from '../../../../api/models/administration-user-details';
import { AdministrationService } from '../../../../api/services/administration.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    @Input() orgKey: string;
    @Input() userKey: string;
    @Input() siteKey: string | undefined;

    user: AdministrationUserDetails;

    constructor(
        private spinner: NgxSpinnerService,
        private ref: ChangeDetectorRef,
        private adminService: AdministrationService,
        private sessionService: SessionService
    ) {}

    async ngOnInit() {
        await this.getUser();
    }

    async getUser() {
        await this.spinner.show();
        try {
            if (this.sessionService.isOrganizationAdmin) {
                this.user = await lastValueFrom(
                    this.adminService.getAdministrationOrganizationUser({
                        organizationKey: this.orgKey,
                        userKey: this.userKey,
                    })
                );
            } else {
                const siteKey = this.siteKey
                    ? this.siteKey
                    : this.sessionService.currentOrganizationSiteKey();
                this.user = await lastValueFrom(
                    this.adminService.getAdministrationOrganizationSiteUser({
                        organizationSiteKey: siteKey,
                        userKey: this.userKey,
                    })
                );
            }

            this.ref.detectChanges();
        } catch (error) {
        } finally {
            await this.spinner.hide();
        }
    }
}
