<ng-container *ngIf="!isSelfService; else selfService">
    <app-header *ngIf="showHeader"></app-header>
    <div class="tw-flex tw-h-full tw-flex-col tw-justify-between">
        <div
            class="tw-mx-6 tw-mt-[80px] tw-pb-10 tw-flex-grow tw-rounded-xl tw-bg-white">
            <router-outlet></router-outlet>
        </div>
        <app-global-footer
            class="tw-z-10"
            [footerVisibility]="false"></app-global-footer>
    </div>
</ng-container>

<ng-template #selfService>
    <div
        class="tw-h-svh tw-flex tw-flex-col"
        #contentArea>
        <div class="tw-w-svh tw-h-screen tw-overflow-hidden tw-bg-white">
            <router-outlet></router-outlet>
        </div>
        <app-selfservice-footer
            [footerVisibility]="true"></app-selfservice-footer>
    </div>
</ng-template>
<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.5)"
    size="medium"
    color="#ffffff"
    type="ball-clip-rotate"
    [fullScreen]="true">
</ngx-spinner>
