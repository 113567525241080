<div
    #wrapper
    [class.tw-text-red-500]="deliveryNote.attachmentsCount === attachmentLimit">
    <span
        *ngIf="!isLoading"
        [matTooltip]="
            'INCOMING-GOODS.CHECKIN.ATTACHMENTS-EXPLANATION' | translate
        "
        >{{ deliveryNote.attachmentsCount }} / {{ attachmentLimit }}
        {{ 'INCOMING-GOODS.CHECKIN.ATTACHMENTS' | translate }}</span
    >
    <mat-spinner
        *ngIf="isLoading"
        [diameter]="20"></mat-spinner>
</div>
