/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { LoadCarrierTemplatesService } from './services/load-carrier-templates.service';
import { SignaturePadsService } from './services/signature-pads.service';
import { AdministrationService } from './services/administration.service';
import { AdministrationSelfServiceCheckinService } from './services/administration-self-service-checkin.service';
import { AttachmentsService } from './services/attachments.service';
import { DiscrepancyImagesService } from './services/discrepancy-images.service';
import { SelfServiceCheckinsService } from './services/self-service-checkins.service';
import { ConsigneeLoadCarrierFilesService } from './services/consignee-load-carrier-files.service';
import { ReceiptsService } from './services/receipts.service';
import { FineDiscrepanciesService } from './services/fine-discrepancies.service';
import { PalletTicketsService } from './services/pallet-tickets.service';
import { AuthenticationService } from './services/authentication.service';
import { CheckinsService } from './services/checkins.service';
import { DeliveryNoteBundlesService } from './services/delivery-note-bundles.service';
import { DeliveryNotesService } from './services/delivery-notes.service';
import { PdfService } from './services/pdf.service';
import { ConsignorService } from './services/consignor.service';
import { CarrierService } from './services/carrier.service';
import { ConsigneeService } from './services/consignee.service';
import { DiscrepanciesService } from './services/discrepancies.service';
import { EventsService } from './services/events.service';
import { ExternalService } from './services/external.service';
import { WalletInviteService } from './services/wallet-invite.service';
import { ToursService } from './services/tours.service';
import { DebugService } from './services/debug.service';
import { FeedbackService } from './services/feedback.service';
import { LoadCarriersService } from './services/load-carriers.service';
import { OrganizationSitesService } from './services/organization-sites.service';
import { WalletInviteNotificationService } from './services/wallet-invite-notification.service';
import { OrganizationsService } from './services/organizations.service';
import { PlatformService } from './services/platform.service';
import { PublicService } from './services/public.service';
import { UsersService } from './services/users.service';
import { ServiceProviderService } from './services/service-provider.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    LoadCarrierTemplatesService,
    SignaturePadsService,
    AdministrationService,
    AdministrationSelfServiceCheckinService,
    AttachmentsService,
    DiscrepancyImagesService,
    SelfServiceCheckinsService,
    ConsigneeLoadCarrierFilesService,
    ReceiptsService,
    FineDiscrepanciesService,
    PalletTicketsService,
    AuthenticationService,
    CheckinsService,
    DeliveryNoteBundlesService,
    DeliveryNotesService,
    PdfService,
    ConsignorService,
    CarrierService,
    ConsigneeService,
    DiscrepanciesService,
    EventsService,
    ExternalService,
    WalletInviteService,
    ToursService,
    DebugService,
    FeedbackService,
    LoadCarriersService,
    OrganizationSitesService,
    WalletInviteNotificationService,
    OrganizationsService,
    PlatformService,
    PublicService,
    UsersService,
    ServiceProviderService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
