/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { sendTourWalletInviteMailToDriver } from '../fn/organization-sites/send-tour-wallet-invite-mail-to-driver';
import { SendTourWalletInviteMailToDriver$Params } from '../fn/organization-sites/send-tour-wallet-invite-mail-to-driver';
import { sendWalletInviteMailToDriver } from '../fn/organization-sites/send-wallet-invite-mail-to-driver';
import { SendWalletInviteMailToDriver$Params } from '../fn/organization-sites/send-wallet-invite-mail-to-driver';


/**
 * Endpoint to send the wallet invite via email
 */
@Injectable({ providedIn: 'root' })
export class WalletInviteNotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendWalletInviteMailToDriver()` */
  static readonly SendWalletInviteMailToDriverPath = '/organization-sites/{organizationSiteKey}/delivery-note-bundle/{deliveryNoteBundleKey}/send-wallet-invite';

  /**
   * Send wallet invite mail to driver.
   *
   * Send an email with a wallet invite link to the driver.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendWalletInviteMailToDriver()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWalletInviteMailToDriver$Response(params: SendWalletInviteMailToDriver$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendWalletInviteMailToDriver(this.http, this.rootUrl, params, context);
  }

  /**
   * Send wallet invite mail to driver.
   *
   * Send an email with a wallet invite link to the driver.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendWalletInviteMailToDriver$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWalletInviteMailToDriver(params: SendWalletInviteMailToDriver$Params, context?: HttpContext): Observable<void> {
    return this.sendWalletInviteMailToDriver$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendTourWalletInviteMailToDriver()` */
  static readonly SendTourWalletInviteMailToDriverPath = '/organization-sites/{organizationSiteKey}/tours/{tourKey}/send-wallet-invite';

  /**
   * Send tour wallet invite mail to driver.
   *
   * Send an email with a tour wallet invite link to the driver.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTourWalletInviteMailToDriver()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTourWalletInviteMailToDriver$Response(params: SendTourWalletInviteMailToDriver$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendTourWalletInviteMailToDriver(this.http, this.rootUrl, params, context);
  }

  /**
   * Send tour wallet invite mail to driver.
   *
   * Send an email with a tour wallet invite link to the driver.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendTourWalletInviteMailToDriver$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTourWalletInviteMailToDriver(params: SendTourWalletInviteMailToDriver$Params, context?: HttpContext): Observable<void> {
    return this.sendTourWalletInviteMailToDriver$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
