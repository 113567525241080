/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { deleteTour } from '../fn/tours/delete-tour';
import { DeleteTour$Params } from '../fn/tours/delete-tour';
import { externalGetTourWalletInviteData } from '../fn/external/external-get-tour-wallet-invite-data';
import { ExternalGetTourWalletInviteData$Params } from '../fn/external/external-get-tour-wallet-invite-data';
import { getConsigneeTour } from '../fn/tours/get-consignee-tour';
import { GetConsigneeTour } from '../models/get-consignee-tour';
import { GetConsigneeTour$Params } from '../fn/tours/get-consignee-tour';
import { getConsigneeTours } from '../fn/tours/get-consignee-tours';
import { GetConsigneeTours } from '../models/get-consignee-tours';
import { GetConsigneeTours$Params } from '../fn/tours/get-consignee-tours';
import { getTour } from '../fn/tours/get-tour';
import { GetTour$Params } from '../fn/tours/get-tour';
import { GetTourResponse } from '../models/get-tour-response';
import { getTours } from '../fn/tours/get-tours';
import { GetTours$Params } from '../fn/tours/get-tours';
import { getToursPdfs } from '../fn/tours/get-tours-pdfs';
import { GetToursPdfs$Params } from '../fn/tours/get-tours-pdfs';
import { GetToursResponse } from '../models/get-tours-response';
import { postTour } from '../fn/tours/post-tour';
import { PostTour$Params } from '../fn/tours/post-tour';
import { postToursCheckout } from '../fn/tours/post-tours-checkout';
import { PostToursCheckout$Params } from '../fn/tours/post-tours-checkout';
import { sendTourWalletInviteMailToDriver } from '../fn/organization-sites/send-tour-wallet-invite-mail-to-driver';
import { SendTourWalletInviteMailToDriver$Params } from '../fn/organization-sites/send-tour-wallet-invite-mail-to-driver';
import { TourCheckoutResponse } from '../models/tour-checkout-response';
import { TourResponse } from '../models/tour-response';
import { TourWalletInviteData } from '../models/tour-wallet-invite-data';
import { updateTour } from '../fn/tours/update-tour';
import { UpdateTour$Params } from '../fn/tours/update-tour';

@Injectable({ providedIn: 'root' })
export class ToursService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalGetTourWalletInviteData()` */
  static readonly ExternalGetTourWalletInviteDataPath = '/external/tour/wallet-invite';

  /**
   * Get tour data for wallet invite page.
   *
   * Returns all data that is necessary to build the tour wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourWalletInviteData()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourWalletInviteData$Response(params: ExternalGetTourWalletInviteData$Params, context?: HttpContext): Observable<StrictHttpResponse<TourWalletInviteData>> {
    return externalGetTourWalletInviteData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tour data for wallet invite page.
   *
   * Returns all data that is necessary to build the tour wallet invite page.
   * The token is the accessToken from the checkout response.
   * The access token expires 2 days after checkin or 10 weeks after checkout.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourWalletInviteData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourWalletInviteData(params: ExternalGetTourWalletInviteData$Params, context?: HttpContext): Observable<TourWalletInviteData> {
    return this.externalGetTourWalletInviteData$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourWalletInviteData>): TourWalletInviteData => r.body)
    );
  }

  /** Path part for operation `sendTourWalletInviteMailToDriver()` */
  static readonly SendTourWalletInviteMailToDriverPath = '/organization-sites/{organizationSiteKey}/tours/{tourKey}/send-wallet-invite';

  /**
   * Send tour wallet invite mail to driver.
   *
   * Send an email with a tour wallet invite link to the driver.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTourWalletInviteMailToDriver()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTourWalletInviteMailToDriver$Response(params: SendTourWalletInviteMailToDriver$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendTourWalletInviteMailToDriver(this.http, this.rootUrl, params, context);
  }

  /**
   * Send tour wallet invite mail to driver.
   *
   * Send an email with a tour wallet invite link to the driver.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendTourWalletInviteMailToDriver$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTourWalletInviteMailToDriver(params: SendTourWalletInviteMailToDriver$Params, context?: HttpContext): Observable<void> {
    return this.sendTourWalletInviteMailToDriver$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTours()` */
  static readonly GetToursPath = '/organization-sites/{organizationSiteKey}/carrier/tours';

  /**
   * Get all tours from a site.
   *
   * Get all tours from a site by the siteId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTours()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTours$Response(params: GetTours$Params, context?: HttpContext): Observable<StrictHttpResponse<GetToursResponse>> {
    return getTours(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all tours from a site.
   *
   * Get all tours from a site by the siteId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTours$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTours(params: GetTours$Params, context?: HttpContext): Observable<GetToursResponse> {
    return this.getTours$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetToursResponse>): GetToursResponse => r.body)
    );
  }

  /** Path part for operation `postTour()` */
  static readonly PostTourPath = '/organization-sites/{organizationSiteKey}/carrier/tours';

  /**
   * Create a new tour.
   *
   * Creates a new tour for the organization site, with the deliveryNoteKeys.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTour$Response(params: PostTour$Params, context?: HttpContext): Observable<StrictHttpResponse<TourResponse>> {
    return postTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new tour.
   *
   * Creates a new tour for the organization site, with the deliveryNoteKeys.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTour(params: PostTour$Params, context?: HttpContext): Observable<TourResponse> {
    return this.postTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourResponse>): TourResponse => r.body)
    );
  }

  /** Path part for operation `getTour()` */
  static readonly GetTourPath = '/organization-sites/{organizationSiteKey}/carrier/tours/{tourKey}';

  /**
   * Get a tour.
   *
   * Get a specifiy tour by the tour key, returns also more data for the delivery notes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTour$Response(params: GetTour$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTourResponse>> {
    return getTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a tour.
   *
   * Get a specifiy tour by the tour key, returns also more data for the delivery notes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTour(params: GetTour$Params, context?: HttpContext): Observable<GetTourResponse> {
    return this.getTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTourResponse>): GetTourResponse => r.body)
    );
  }

  /** Path part for operation `updateTour()` */
  static readonly UpdateTourPath = '/organization-sites/{organizationSiteKey}/carrier/tours/{tourKey}';

  /**
   * Update a carrier tour.
   *
   * Update a tour. This is only possible when the tour is not in the checkin. The delivery note keys are alwas needed.
   * When all delivery notes from a tour are removed and the tour is in status 'sent' the tour will be deleted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTour$Response(params: UpdateTour$Params, context?: HttpContext): Observable<StrictHttpResponse<TourResponse>> {
    return updateTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a carrier tour.
   *
   * Update a tour. This is only possible when the tour is not in the checkin. The delivery note keys are alwas needed.
   * When all delivery notes from a tour are removed and the tour is in status 'sent' the tour will be deleted.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTour(params: UpdateTour$Params, context?: HttpContext): Observable<TourResponse> {
    return this.updateTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourResponse>): TourResponse => r.body)
    );
  }

  /** Path part for operation `deleteTour()` */
  static readonly DeleteTourPath = '/organization-sites/{organizationSiteKey}/carrier/tours/{tourKey}';

  /**
   * Delete a carrier tour.
   *
   * Delete a tour. This is only possible when not a signle delivery note from the tour is in checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTour$Response(params: DeleteTour$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a carrier tour.
   *
   * Delete a tour. This is only possible when not a signle delivery note from the tour is in checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTour(params: DeleteTour$Params, context?: HttpContext): Observable<void> {
    return this.deleteTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConsigneeTours()` */
  static readonly GetConsigneeToursPath = '/organization-sites/{organizationSiteKey}/consignee/tours';

  /**
   * Get tour list as consignee.
   *
   * Get overview list of all tours from a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeTours()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeTours$Response(params: GetConsigneeTours$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeTours>> {
    return getConsigneeTours(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tour list as consignee.
   *
   * Get overview list of all tours from a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeTours$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeTours(params: GetConsigneeTours$Params, context?: HttpContext): Observable<GetConsigneeTours> {
    return this.getConsigneeTours$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeTours>): GetConsigneeTours => r.body)
    );
  }

  /** Path part for operation `getConsigneeTour()` */
  static readonly GetConsigneeTourPath = '/organization-sites/{organizationSiteKey}/consignee/tours/{tourKey}';

  /**
   * Get a tour as consignee.
   *
   * Get a specific tour by the tourKey from a consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeTour$Response(params: GetConsigneeTour$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeTour>> {
    return getConsigneeTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a tour as consignee.
   *
   * Get a specific tour by the tourKey from a consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeTour(params: GetConsigneeTour$Params, context?: HttpContext): Observable<GetConsigneeTour> {
    return this.getConsigneeTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeTour>): GetConsigneeTour => r.body)
    );
  }

  /** Path part for operation `postToursCheckout()` */
  static readonly PostToursCheckoutPath = '/organization-sites/{organizationSiteKey}/carrier/tours/{tourKey}/checkout';

  /**
   * Checkout a tour.
   *
   * Checkout one tour. The tours must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postToursCheckout()` instead.
   *
   * This method doesn't expect any request body.
   */
  postToursCheckout$Response(params: PostToursCheckout$Params, context?: HttpContext): Observable<StrictHttpResponse<TourCheckoutResponse>> {
    return postToursCheckout(this.http, this.rootUrl, params, context);
  }

  /**
   * Checkout a tour.
   *
   * Checkout one tour. The tours must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postToursCheckout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postToursCheckout(params: PostToursCheckout$Params, context?: HttpContext): Observable<TourCheckoutResponse> {
    return this.postToursCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<TourCheckoutResponse>): TourCheckoutResponse => r.body)
    );
  }

  /** Path part for operation `getToursPdfs()` */
  static readonly GetToursPdfsPath = '/organization-sites/{organizationSiteKey}/carrier/tours/{tourKey}/delivery-note-pdfs';

  /**
   * Get all pdfs from a tour.
   *
   * Get all pdfs from a tour by the tourKey.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getToursPdfs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getToursPdfs$Response(params: GetToursPdfs$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getToursPdfs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all pdfs from a tour.
   *
   * Get all pdfs from a tour by the tourKey.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getToursPdfs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getToursPdfs(params: GetToursPdfs$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getToursPdfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
