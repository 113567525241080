import { AfterViewChecked, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy({ checkProperties: true })
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    private rangeLabel: string;

    constructor(public translateService: TranslateService) {
        super();

        this.translateService.onLangChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.getTranslations();
            });
        this.getTranslations();
    }

    getTranslations() {
        this.translateService
            .get([
                'MAT-PAGINATOR.ITEMS-PER-PAGE',
                'MAT-PAGINATOR.NEXT-PAGE',
                'MAT-PAGINATOR.PREVIOUS-PAGE',
                'MAT-PAGINATOR.FIRST-PAGE',
                'MAT-PAGINATOR.LAST-PAGE',
                'MAT-PAGINATOR.RANGE',
            ])
            .pipe(untilDestroyed(this))
            .subscribe(translation => {
                this.itemsPerPageLabel =
                    translation['MAT-PAGINATOR.ITEMS-PER-PAGE'];
                this.nextPageLabel = translation['MAT-PAGINATOR.NEXT-PAGE'];
                this.previousPageLabel =
                    translation['MAT-PAGINATOR.PREVIOUS-PAGE'];
                this.firstPageLabel = translation['MAT-PAGINATOR.FIRST-PAGE'];
                this.lastPageLabel = translation['MAT-PAGINATOR.LAST-PAGE'];
                this.rangeLabel = translation['MAT-PAGINATOR.RANGE'];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.rangeLabel} ${length}`;
    };
}
