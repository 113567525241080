/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLoadCarrierTemplates } from '../fn/load-carrier-templates/get-load-carrier-templates';
import { GetLoadCarrierTemplates$Params } from '../fn/load-carrier-templates/get-load-carrier-templates';
import { GetLoadCarrierTemplatesResponse } from '../models/get-load-carrier-templates-response';


/**
 * Endpoints to manage load carrier templates (types)
 */
@Injectable({ providedIn: 'root' })
export class LoadCarrierTemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLoadCarrierTemplates()` */
  static readonly GetLoadCarrierTemplatesPath = '/organizations/{organizationKey}/load-carrier-templates';

  /**
   * Get all load carrier templates of organization.
   *
   * Get all permitted load carrier templates of this organization.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoadCarrierTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadCarrierTemplates$Response(params: GetLoadCarrierTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLoadCarrierTemplatesResponse>> {
    return getLoadCarrierTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all load carrier templates of organization.
   *
   * Get all permitted load carrier templates of this organization.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoadCarrierTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadCarrierTemplates(params: GetLoadCarrierTemplates$Params, context?: HttpContext): Observable<GetLoadCarrierTemplatesResponse> {
    return this.getLoadCarrierTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLoadCarrierTemplatesResponse>): GetLoadCarrierTemplatesResponse => r.body)
    );
  }

}
