/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPublicOrganizations } from '../fn/public/get-public-organizations';
import { GetPublicOrganizations$Params } from '../fn/public/get-public-organizations';
import { GetPublicOrganizationsResponse } from '../models/get-public-organizations-response';
import { postPublicCompleteResetUserPassword } from '../fn/public/post-public-complete-reset-user-password';
import { PostPublicCompleteResetUserPassword$Params } from '../fn/public/post-public-complete-reset-user-password';
import { putPublicResetUserPassword } from '../fn/public/put-public-reset-user-password';
import { PutPublicResetUserPassword$Params } from '../fn/public/put-public-reset-user-password';


/**
 * Endpoints which are accessable without authentication
 */
@Injectable({ providedIn: 'root' })
export class PublicService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPublicOrganizations()` */
  static readonly GetPublicOrganizationsPath = '/public/organizations';

  /**
   * Get all ogranizations.
   *
   * Get all organization names.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicOrganizations$Response(params?: GetPublicOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPublicOrganizationsResponse>> {
    return getPublicOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all ogranizations.
   *
   * Get all organization names.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicOrganizations(params?: GetPublicOrganizations$Params, context?: HttpContext): Observable<GetPublicOrganizationsResponse> {
    return this.getPublicOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPublicOrganizationsResponse>): GetPublicOrganizationsResponse => r.body)
    );
  }

  /** Path part for operation `putPublicResetUserPassword()` */
  static readonly PutPublicResetUserPasswordPath = '/public/reset-user-password';

  /**
   * Initiate user password reset.
   *
   * Send link to reset the password.
   * The link is valid for 15 minutes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPublicResetUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPublicResetUserPassword$Response(params: PutPublicResetUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putPublicResetUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Initiate user password reset.
   *
   * Send link to reset the password.
   * The link is valid for 15 minutes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putPublicResetUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPublicResetUserPassword(params: PutPublicResetUserPassword$Params, context?: HttpContext): Observable<void> {
    return this.putPublicResetUserPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postPublicCompleteResetUserPassword()` */
  static readonly PostPublicCompleteResetUserPasswordPath = '/public/complete-reset-user-password';

  /**
   * Complete user password reset.
   *
   * Reset the user password with the provided password and the token.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPublicCompleteResetUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPublicCompleteResetUserPassword$Response(params: PostPublicCompleteResetUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postPublicCompleteResetUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Complete user password reset.
   *
   * Reset the user password with the provided password and the token.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postPublicCompleteResetUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPublicCompleteResetUserPassword(params: PostPublicCompleteResetUserPassword$Params, context?: HttpContext): Observable<void> {
    return this.postPublicCompleteResetUserPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
