<div class="split-view-wrapper">
    <app-left-splitview></app-left-splitview>
    <div class="right-col d-flex justify-content-center">
        <div class="input-container">
            <h2>{{ 'SET-NEW-PASSWORD.TITLE' | translate }}</h2>
            <h3>{{ 'SET-NEW-PASSWORD.SUBTITLE' | translate }}</h3>
            <div class="login-form">
                <form [formGroup]="setPasswordForm">
                    <div class="mat-field-container">
                        <mat-form-field appearance="fill">
                            <mat-label>{{
                                'SET-NEW-PASSWORD.PLACERHOLDER-1' | translate
                            }}</mat-label>

                            <mat-icon
                                matPrefix
                                fontSet="material-symbols-outlined"
                                >key</mat-icon
                            >

                            <input
                                matInput
                                formControlName="newPassword"
                                [type]="hidePassword ? 'password' : 'text'" />
                            <mat-icon
                                class="icon-color"
                                matSuffix
                                (click)="hidePassword = !hidePassword"
                                fontSet="material-symbols-outlined"
                                >{{
                                    hidePassword
                                        ? 'visibility_off'
                                        : 'visibility'
                                }}</mat-icon
                            >
                        </mat-form-field>
                        <app-password-check
                            [password]="
                                setPasswordForm.get('newPassword')?.value || ''
                            "></app-password-check>
                    </div>
                    <div class="mat-field-container mt-3">
                        <mat-form-field appearance="fill">
                            <mat-label>{{
                                'SET-NEW-PASSWORD.PLACERHOLDER-2' | translate
                            }}</mat-label>
                            <mat-icon
                                matPrefix
                                fontSet="material-symbols-outlined"
                                >key</mat-icon
                            >

                            <input
                                matInput
                                formControlName="confirmNewPassword"
                                [type]="hidePassword ? 'password' : 'text'" />
                            <mat-error
                                *ngIf="
                                    f.confirmNewPassword.errors
                                        ?.confirmedValidator
                                "
                                >{{
                                    'SET-NEW-PASSWORD.PASSWORDS-NO-MATCH'
                                        | translate
                                }}</mat-error
                            >
                            <mat-error
                                *ngIf="f.confirmNewPassword.errors?.minlength"
                                >{{
                                    'SET-NEW-PASSWORD.PASSWORD-MINLENGTH'
                                        | translate
                                }}</mat-error
                            >
                            <mat-error
                                *ngIf="
                                    f.confirmNewPassword.hasError('required')
                                "
                                >{{
                                    'SET-NEW-PASSWORD.PASSWORD-REQUIRED-REPEAT'
                                        | translate
                                }}</mat-error
                            >
                            <mat-icon
                                class="icon-color"
                                matSuffix
                                (click)="hidePassword = !hidePassword"
                                fontSet="material-symbols-outlined"
                                >{{
                                    hidePassword
                                        ? 'visibility_off'
                                        : 'visibility'
                                }}</mat-icon
                            >
                        </mat-form-field>
                    </div>

                    <div class="text-center mt-4">
                        <button
                            class="login-btn"
                            type="button"
                            mat-raised-button
                            color="primary"
                            [disabled]="setPasswordForm.invalid"
                            (click)="onSetNewPassword()"
                            [class.spinner]="loading">
                            {{ 'SET-NEW-PASSWORD.BUTTON' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-unauth-footer page="forgot-password"></app-unauth-footer>
</div>
