/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { getConsignorDeliveryNoteCombinedPdf } from '../fn/delivery-notes/get-consignor-delivery-note-combined-pdf';
import { GetConsignorDeliveryNoteCombinedPdf$Params } from '../fn/delivery-notes/get-consignor-delivery-note-combined-pdf';

@Injectable({ providedIn: 'root' })
export class ConsignorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getConsignorDeliveryNoteCombinedPdf()` */
  static readonly GetConsignorDeliveryNoteCombinedPdfPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: all delivery notes,
   * all attachments, all signatures and all images as a consignor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsignorDeliveryNoteCombinedPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorDeliveryNoteCombinedPdf$Response(params: GetConsignorDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsignorDeliveryNoteCombinedPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: all delivery notes,
   * all attachments, all signatures and all images as a consignor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsignorDeliveryNoteCombinedPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorDeliveryNoteCombinedPdf(params: GetConsignorDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsignorDeliveryNoteCombinedPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
