/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BinaryFileType } from '../../models/binary-file-type';
import { SignaturePadType } from '../../models/signature-pad-type';
import { Token64LengthType } from '../../models/token-64-length-type';

export interface ExternalPatchSignaturePad$Params {
  signaturePadType: SignaturePadType;
  token: Token64LengthType;
      body: BinaryFileType
}

export function externalPatchSignaturePad(http: HttpClient, rootUrl: string, params: ExternalPatchSignaturePad$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken': Token64LengthType;
}>> {
  const rb = new RequestBuilder(rootUrl, externalPatchSignaturePad.PATH, 'patch');
  if (params) {
    rb.path('signaturePadType', params.signaturePadType, {});
    rb.query('token', params.token, {});
    rb.body(params.body, 'image/png');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'accessToken': Token64LengthType;
      }>;
    })
  );
}

externalPatchSignaturePad.PATH = '/external/signature-pads/{signaturePadType}';
