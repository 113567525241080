/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BinaryFileType } from '../../models/binary-file-type';
import { KeyType } from '../../models/key-type';

export interface GetConsigneeSelfCheckinLegalDocument$Params {
  organizationSiteKey: KeyType;
}

export function getConsigneeSelfCheckinLegalDocument(http: HttpClient, rootUrl: string, params: GetConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
  const rb = new RequestBuilder(rootUrl, getConsigneeSelfCheckinLegalDocument.PATH, 'get');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/pdf', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BinaryFileType>;
    })
  );
}

getConsigneeSelfCheckinLegalDocument.PATH = '/organization-sites/{organizationSiteKey}/self-checkin/legal-document';
