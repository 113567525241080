import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { PostFeedbackRequest } from 'api/models';
import { FeedbackService } from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
// import { FeedbackService } from '@app/core/services/feedback.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    rating: number;
    ratingForm: FormGroup;
    hideRating: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private feedbackService: FeedbackService,
        private dialogRef: MatDialogRef<FeedbackComponent>,
        private spinner: NgxSpinnerService,
        private toast: ToastService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.initForm();
    }

    initForm(): void {
        this.ratingForm = this.formBuilder.group({
            feedback: ['', Validators.required],
            text: [''],
        });

        this.hideRating =
            localStorage.getItem('hideRating') === 'true' || false;
    }

    onClick(rating: number) {
        console.log(rating);
        this.rating = rating;
        this.ratingForm.get('feedback')?.patchValue(this.rating);
        if (rating === 1) {
            this.ratingForm.get('text')?.setValidators([Validators.required]);
            this.ratingForm.get('text')?.updateValueAndValidity();
        } else {
            this.ratingForm.get('text')?.setValidators([]);
            this.ratingForm.get('text')?.updateValueAndValidity();
        }
    }

    async onSave() {
        await this.spinner.show();
        try {
            const body: PostFeedbackRequest = {
                feedback: this.ratingForm.get('feedback')?.value,
                text: this.ratingForm.get('text')?.value,
            };
            await lastValueFrom(
                this.feedbackService.postFeedback({
                    body: body,
                })
            );

            this.toast.success(this.translate.instant('FEEDBACK.SEND'));
        } catch (error) {
        } finally {
            this.dialogRef.close();
        }
        await this.spinner.hide();
    }

    async onCancel() {
        try {
            await lastValueFrom(
                this.feedbackService.putLastTimeUserGaveFeedbackIntoUser()
            );
        } catch (error) {
        } finally {
            this.dialogRef.close();
        }
    }

    onHideRating(hideRating: boolean): void {
        this.hideRating = hideRating;
        localStorage.setItem('hideRating', String(this.hideRating));
        this.onCancel();
    }
}
