import { Injectable } from '@angular/core';
import { NewDeliverNoteBundleProgress } from '@app/shared/models/business-model/delivery-note-bundles';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class DlsFolderService {
    newBundleProgessButton$: BehaviorSubject<NewDeliverNoteBundleProgress>;

    constructor() {
        this.newBundleProgessButton$ =
            new BehaviorSubject<NewDeliverNoteBundleProgress>({
                currentTab: 0,
                progess: 0,
            });
    }
}
